import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpUpdateCommerceProfile } from 'app/api/commerce';
import { httpUpdateDispatcherProfile } from 'app/api/dispatcher';
import { useGetProfile } from 'app/hooks/user';
import { isCommerce, removeEmptyKeys } from 'app/utilities/helpers';
import { getNationalNumber } from 'app/utilities/phoneUtils';
import { CompanyDetails as CompanyDetailsInterface } from 'app/utilities/types/dispatcherTypes';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrLocationSelect,
  ElrPhoneInput,
} from 'ui-components';
import * as Yup from 'yup';

interface FormValue {
  companyName: string;
  businessName: string;
  addressNumber: string;
  fullAddress: {
    id: string;
    label: string;
    value: string;
  };
}

const CompanyDetailSchema = Yup.object().shape({
  businessName: Yup.string().optional(),
  companyName: Yup.string().optional(),
  addressNumber: Yup.number().positive().integer().optional(),
  fullAddress: Yup.object().shape({
    id: Yup.string().optional(),
    label: Yup.string().optional(),
    value: Yup.string().optional(),
  }),
});

const EditCompanyDetails: React.FC = () => {
  const queryClient = useQueryClient();
  const {
    id: userId,
    accountInformation: {
      mainAddress,
      businessName,
      companyName,
      id: accountId,
    },
    email,
    phone,
    currentAccessRole,
    role,
  } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const isACommerce = isCommerce(currentRole);
  const [updated, setUpdated] = useState(false);

  const updateFunc = isACommerce
    ? httpUpdateCommerceProfile
    : httpUpdateDispatcherProfile;

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: CompanyDetailsInterface) =>
      updateFunc(payload, userId),

    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: object | undefined) =>
        oldData ? { ...oldData, accountInformation: data } : {}
      );
    },
  });

  const onUpdateCompanyDetails = async (values: FormValue) => {
    try {
      const payload = removeEmptyKeys({
        businessName: values.businessName,
        companyName: values.companyName,
        mainAddress: {
          addressNumber: `${values.addressNumber}`,
          fullAddress: values.fullAddress.label,
          placeId: values.fullAddress.id,
        },
        [isACommerce ? 'commerceId' : 'dispatcherId']: accountId,
      });

      if (Object.keys(payload).length === 0) {
        setUpdated(false);
        return;
        /* noop */
      }

      await mutateAsync(payload);
      setUpdated(true);
    } catch (error) {
      setUpdated(false);
      /* noop */
    }
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik<FormValue>({
      initialValues: {
        businessName: businessName || '',
        companyName: companyName || '',
        addressNumber: mainAddress?.addressNumber || '',
        fullAddress: {
          id: mainAddress?.placeId || '',
          label: mainAddress?.fullAddress || '',
          value: mainAddress?.placeId || '',
        },
      },
      validationSchema: CompanyDetailSchema,
      onSubmit: (value) => onUpdateCompanyDetails(value),
    });

  const phoneNumber = phone ? getNationalNumber(phone)?.toString() : '';

  const companyId = isACommerce ? 'businessName' : 'companyName';

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mt-5 mb-8 text-xl text-center">
          <p>Company details</p>
        </div>
        <div>
          {errors?.[companyId] && (
            <span className="text-xs text-elr-error">
              {errors?.[companyId]}
            </span>
          )}
          <ElrInput
            className="h-10 mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Company name"
            name={companyId}
            value={values[companyId]}
            onChange={handleChange}
          />
          <ElrPhoneInput
            containerClass="w-full mb-3 flex justify-between"
            value={phoneNumber}
            onChange={() => {}}
            placeholder="Phone Number*"
          />
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Email"
            value={email}
            onChange={() => {}}
          />
          {errors?.addressNumber && (
            <span className="text-xs text-elr-error">
              {errors?.addressNumber}
            </span>
          )}
          {!isACommerce && (
            <ElrInput
              className="mb-3 border-none rounded-none bg-elr-gray"
              placeholder="Company address number"
              name="addressNumber"
              type="number"
              value={values.addressNumber}
              onChange={handleChange}
            />
          )}
          {errors?.fullAddress && (
            <span className="text-xs text-elr-error">Required</span>
          )}
          <ElrLocationSelect
            className="bg-white"
            currentValue={values.fullAddress?.label}
            placeholder="Enter address here"
            onChange={(value) => setFieldValue('fullAddress', value)}
          />
        </div>
        <div className="flex justify-end">
          <ElrButton
            text="Save"
            submit
            loading={isPending}
            className="w-40 mb-3 text-lg text-white bg-elr-black"
          />
        </div>
        {updated && (
          <p className="text-sm animate-bounce"> Updated successfully </p>
        )}
        <div className="mb-10 md:mb-12" />
      </form>
    </div>
  );
};

export default EditCompanyDetails;
