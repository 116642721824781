import React from 'react';

interface Props {
  isToggled: boolean;
  toggle: Function;
  parentClassName?: string;
  toggledParentClassName?: string;
  circleClassName?: string;
  toggledCircleClassName?: string;
}

export const ElrSwitch: React.FC<Props> = ({
  isToggled,
  toggle,
  parentClassName,
  toggledParentClassName,
  circleClassName,
  toggledCircleClassName,
}) => (
  <span>
    {!isToggled ? (
      <div
        className={`rounded-full border px-0.5 w-8 h-5 bg-elr-gray items-center flex ${parentClassName}`}
        onClick={() => toggle(!isToggled)}
      >
        <div
          className={`bg-elr-black rounded-full h-3.5 w-3.5 ${circleClassName}`}
        />
      </div>
    ) : (
      <div
        className={`rounded-full border px-0.5 w-8 h-5 bg-elr-gray items-center flex flex-row-reverse ${toggledParentClassName}`}
        onClick={() => toggle(!isToggled)}
      >
        <div
          className={`bg-elr-purple rounded-full h-3.5 w-3.5 ${toggledCircleClassName}`}
        />
      </div>
    )}
  </span>
);
