import CountElement from 'app/components/CountElement';
import { getRandomNumber } from 'app/modules/dispatch/components/utils';
import React, { useState } from 'react';
import { ElrPillButton } from 'ui-components';
import { v4 as uuidv4 } from 'uuid';
import {
  useFetchDeliveriesMade,
  useRefetchNewRequests,
} from 'app/hooks/requests/dashboard';
import { Request, RequestType } from 'app/utilities/types/shared';
import RequestItem from './RequestItem';
import FilterInput from '../components/FilteredInput/FilteredInput';

interface DeliveriesInterface {
  displayOriginalFees?: boolean;
}

const DeliveriesMade: React.FC<DeliveriesInterface> = ({
  displayOriginalFees,
}) => {
  const refetchNewRequests = useRefetchNewRequests();
  const [active, setActive] = useState(0);
  const [requestType, setRequestType] = useState<{
    value: any;
    label: any;
  }>({
    value: '',
    label: '',
  });
  const fetchType: RequestType = requestType.label
    .toLowerCase()
    .split(' ')
    .join('-');

  const {
    latestData: deliveriesMadeRequestData,
    docsList,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isLoading,
  } = useFetchDeliveriesMade(fetchType);

  const onChangeRequestType = (option: any) => {
    const { value, label } = option;
    setRequestType({
      value,
      label,
    });
  };

  const updateCacheList = () => {
    refetch();
    refetchNewRequests();
  };

  const DeliveryRequest = (request: Request, i: number) => {
    const uuid = uuidv4();
    return (
      <div key={`${uuid}${getRandomNumber(0, 1543)}${+new Date()}`}>
        <RequestItem
          request={request}
          index={i}
          refetch={updateCacheList}
          active={active === i}
          onUpdateList={updateCacheList}
          setActive={setActive}
          displayOriginalFees={displayOriginalFees}
          isAssigned
        />
      </div>
    );
  };

  return (
    <div className="w-full md:w-1/2">
      <div className="md:hidden block">
        <FilterInput
          onChangeRequestType={onChangeRequestType}
          requestType={requestType}
        />
      </div>
      <div className="w-11/12 md:w-full md:ml-5 mx-auto border border-elr-green border-opacity-30 h-450 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md">
        <div>
          <div className="flex justify-center md:justify-between items-center pt-5">
            <div className="hidden md:flex md:items-center text-lg text-elr-black">
              Deliveries so far
              <CountElement bgColor="elr-green" size="sm" classNames="ml-5">
                {deliveriesMadeRequestData.totalDocs || 0}
              </CountElement>
            </div>
          </div>
          <div className="pt-4 border-b hidden md:block border-elr-gray-400 border-opacity-20" />
          <div className="hidden md:block">
            <FilterInput
              onChangeRequestType={onChangeRequestType}
              requestType={requestType}
            />
          </div>
          <div className="border-b hidden md:block border-elr-gray-400 border-opacity-20" />
        </div>
        {!isLoading ? (
          <div className="h-690 bg-green md:h-4/5 overflow-y-scroll no-scrollbar">
            {docsList?.map((request: Request, i: number) =>
              DeliveryRequest(request, i)
            )}
            {hasNextPage && (
              <div className="mx-auto text-center mt-5">
                <ElrPillButton
                  text="Load more"
                  size="lg"
                  loading={isFetchingNextPage}
                  spinnerColor="White"
                  onClick={() => {
                    fetchNextPage();
                  }}
                  className="bg-opacity-90 bg-elr-black text-elr-white py-1 w-28"
                />
              </div>
            )}
          </div>
        ) : (
          <div className="opacity-60 text-center pt-2"> loading... </div>
        )}
      </div>
    </div>
  );
};

export default DeliveriesMade;
