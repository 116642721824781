import React from 'react';
import { ElrButton, ElrLeftArrowButton } from 'ui-components';

interface StepsNavigationProps {
  next?: () => void;
  previous?: () => void;
  nextDisabled: boolean;
  isLoading?: boolean;
  containerClassName?: string;
}

const StepsNavigation: React.FC<StepsNavigationProps> = ({
  next,
  previous,
  nextDisabled,
  isLoading,
  containerClassName = '',
}) => (
  <>
    {previous && (
      <div className={`pt-6 pb-6 flex mt-20 ${containerClassName} mr-4`}>
        <ElrLeftArrowButton onClick={previous} />
      </div>
    )}
    {next && (
      <div className={`pt-6 pb-6 flex ${containerClassName}`}>
        <ElrButton
          disabled={isLoading || nextDisabled}
          onClick={next}
          text="Next"
          size="lg"
          className="bg-elr-black w-24 h-12 text-elr-white-400"
        />
      </div>
    )}
  </>
);

export default StepsNavigation;
