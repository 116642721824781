import { axiosDefault as axios } from 'app/utilities/axios';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { RequestType } from 'app/utilities/types/shared';
import { toast } from 'react-toastify';
import { RequestsDataInterface } from './d';

interface Params {
  userId: string;
  dispatcherId: string;
  nextPage: number;
  type?: RequestType;
}

export const httpGetDeliveriesMade = async ({
  userId,
  dispatcherId,
  nextPage,
  type,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(
      `/dispatcher/${userId}/get-total-deliveries`,
      {
        params: { dispatcherId, page: nextPage, ...(type && { type }) },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetUpcomingDeliveries = async ({
  userId,
  dispatcherId,
  nextPage,
  type,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(
      `/dispatcher/${userId}/get-upcoming-deliveries`,
      {
        params: { dispatcherId, page: nextPage, ...(type && { type }) },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
