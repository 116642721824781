import Logo from 'assets/images/logo.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import { MobileHeader } from './Header';
import TopNavigationMenu from './menu/TopNavigationMenu';

interface Props {
  panelComponent: React.ReactNode | null;
  dashboardPanel: React.ReactNode;
  titleTag: string;
  showUnassignedAgents?: () => void;
  unmanagedAgentsCount?: number;
}

const DashboardContainer: React.FC<Props> = ({
  panelComponent,
  dashboardPanel,
  titleTag,
  showUnassignedAgents,
  unmanagedAgentsCount,
}) => (
  <div>
    <ElrPageTitle title={titleTag} />
    <div className="flex justify-between w-full md:w-11/12 md:mx-auto items-center">
      <div className="w-full md:w-11/12 md:mx-auto">
        <Link to="/">
          <img className="hidden md:inline-block pt-10" src={Logo} alt="logo" />
        </Link>
        <MobileHeader
          showUnassignedAgents={showUnassignedAgents}
          unmanagedAgentsCount={unmanagedAgentsCount}
          mdHide
        />
      </div>
      <TopNavigationMenu
        showUnassignedAgents={showUnassignedAgents}
        unmanagedAgentsCount={unmanagedAgentsCount}
        containerClass="md:mr-0"
      />
    </div>

    {/* <StoreSlider showSlider={showSlider} closeSlider={toggleOpenStore} /> */}

    <div className="md:w-11/12 md:pb-5 flex flex-col md:flex-row mx-auto">
      <div className="w-11/12 md:w-1/2 mx-auto md:ml-0">{dashboardPanel}</div>
      {panelComponent}
    </div>
  </div>
);

export default DashboardContainer;
