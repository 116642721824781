import { TopNav } from 'app/components/menu/Navbar';
import { Footer } from 'app/modules/landing/components';
import { publicRoutes } from 'app/utilities/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { ElrAccordion, ElrPageTitle } from 'ui-components';
import PurpleVector from 'assets/images/policies-purple-vector.svg';
import YellowVector from 'assets/images/policies-yellow-vector.svg';
import PoliciesAppBanner from 'app/modules/landing/components/PoliciesAppBanner';

const Faq: React.FC = () => {
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<number>(0);
  const showDropdownOption = () => {
    setShowDropdown(!showDropdown);
  };

  const fieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  const boxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - FAQs" />
      <TopNav isDefault />
      <div className="mx-5 py-10 md:mx-40">
        <div className="text-xl mt-12 mb-16 bg-elr-gray relative rounded-xl overflow-hidden">
          <img
            src={PurpleVector}
            alt=""
            className="absolute md:bottom-0 w-3/12 md:w-32 -bottom-2 -left-2 md:left-0"
          />
          <img
            src={YellowVector}
            alt=""
            className="absolute md:top-0 -right-2 md:right-0 w-3/12 md:w-32 -top-2"
          />
          <p className="md:text-5xl text-center py-16 text-3xl">
            Frequently Asked Questions
          </p>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[1]}
            active={boxes[1] === active}
            transformPositionClass=""
            header="1. What is Errandlr"
          >
            <div className="mb-5">
              Errandlr is a platform that connects individuals, e-commerce
              systems, and organizations to logistics companies, providing all
              users a convenient, reliable, and efficient service. You can
              register as a logistics company by completing our seamless sign-up
              process here
              <span className="px-2 text-elr-gray-400 hover:text-elr-black underline">
                <Link to={publicRoutes.registration}>
                  https://errandlr.com/register
                </Link>
              </span>
              and can also request for delivery service by visiting our website
              <span className="pl-2 text-elr-gray-400 hover:text-elr-black underline">
                <a href="https://www.errandlr.com/estimate">
                  https://www.errandlr.com/estimate
                </a>
              </span>
              ,
              <p className="pt-4">
                For further clarification, please reach out to our support
                channels ...
              </p>
            </div>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="2. Do you offer express delivery?"
          >
            <div className="mb-5">
              Errandlr does not offer express deliveries, we do same day or next
              day deliveries.
            </div>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[4]}
            active={boxes[4] === active}
            transformPositionClass=""
            header="3. How safe is this platform"
          >
            <div className="mb-5">
              We make use of a secure network protocol, to encrypt all data
              passing through our servers. For our payments, we make use of
              payment gateway Paystack, and we do not save users card
              information.
            </div>
            <p className="pt-4">
              All sensitive information is encrypted and stored with encryption
              algorithms that is difficult to decrypt.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[5]}
            active={boxes[5] === active}
            transformPositionClass=""
            header="4. What are the delivery rates and charges?"
          >
            <div className="mb-5">
              Our delivery rates are calculated based on distance and with a
              base fare per estimation.
            </div>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[6]}
            active={boxes[6] === active}
            transformPositionClass=""
            header="5. How to navigate the platform"
          >
            <div className="mb-5">
              As a business you can create an account directly on the platform,
              if you aren&apos;t a business user, you can register on our mobile
              app on playstore or app store
            </div>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[10]}
            active={boxes[10] === active}
            transformPositionClass=""
            header="6. How can I reach out to support"
          >
            <div className="mb-5">
              You can reach out to our support by sending an email to
              <span className="pl-2 text-elr-gray-400 hover:text-elr-black underline">
                <a href="mailto:https://support@errandlr.com">
                  support@errandlr.com
                </a>
              </span>
              , or you can use the live chat box. We’d be glad to attend to any
              issues you may have and answer any questions.
            </div>
          </ElrAccordion>
        </div>
        <PoliciesAppBanner />
      </div>
      <Footer hideTopFooter />
    </div>
  );
};

export default Faq;
