import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ProductType } from 'app/utilities/types/shared';

const CART_QUERY_KEY = 'cart';
interface IProduct {
  id: string;
  name: string;
  price: number;
  quantity: number;
  image?: string;
}

const useProductsCart = () => {
  const queryClient = useQueryClient();

  const { data: cart = [] } = useQuery<IProduct[]>({
    queryKey: [CART_QUERY_KEY],
    queryFn: async () => [],
    retry: false,
    notifyOnChangeProps: 'all',
    initialData: [],
    staleTime: 1000 * 60 * 60 * 24,
  });

  const addToCart = (item: (ProductType & { image: string }) | IProduct) => {
    let newCart = cart;

    const existingItem = cart.find((i) => i.id === item.id);
    if (existingItem) {
      existingItem.quantity + 1;
      newCart = cart.map((i) =>
        existingItem && i.id === existingItem.id ? existingItem : i
      );
    } else {
      newCart = [
        ...cart,
        {
          name: item.name,
          id: item.id,
          price: Number(item.price),
          image: item.image,
          quantity: 1,
        },
      ];
    }

    queryClient.setQueryData([CART_QUERY_KEY], newCart);
  };

  const reduceFromCart = (itemId: string) => {
    let newCart = cart;

    const existingItem = cart.find((i) => i.id === itemId);
    if (existingItem && existingItem.quantity > 1) {
      existingItem.quantity - 1;
      newCart = cart.map((i) =>
        existingItem && i.id === existingItem.id ? existingItem : i
      );
    } else {
      newCart = cart.filter((i) => i.id !== itemId);
    }

    queryClient.setQueryData([CART_QUERY_KEY], newCart);
  };

  const removeFromCart = (itemId: string) => {
    let newCart = cart;
    newCart = cart.filter((i) => i.id !== itemId);
    queryClient.setQueryData([CART_QUERY_KEY], newCart);
  };

  const clearCart = () => {
    queryClient.setQueryData([CART_QUERY_KEY], []);
  };

  const cartCount = cart.reduce((acc, item) => acc + item.quantity, 0);

  return {
    cart,
    cartCount,
    addToCart,
    reduceFromCart,
    removeFromCart,
    clearCart,
  };
};

export default useProductsCart;
