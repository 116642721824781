export const newRequestContainer = (
  panelActive: number,
  isActivePanel: boolean,
  styl: { [val: string]: string }
) =>
  `md:w-305 border hover:border-elr-yellow md:h-64 bg-elr-white-400 rounded-md p-4 relative ${
    panelActive ? `${styl.boxOne} ${styl.activeClass}` : 'w-305 h-40 mb-5'
  } ${
    isActivePanel ? 'border-elr-yellow' : 'border-elr-green border-opacity-30'
  }`;

export const panelActiveStyle = (panelactive: number) =>
  `${panelactive !== 0 ? `hidden md:flex ` : `block md:gap-0`}  md:h-40 w-305`;

export const productsContainer = (
  panelActive: number,
  isActivePanel: boolean,
  styl: { [val: string]: string }
) =>
  `md:w-305 border hover:border-elr-darkgreen-light md:h-64 bg-elr-white-400 rounded-md p-4 relative ${panelActive > 0 && `ml-4 md:ml-0`} ${
    panelActive ? `${styl.boxOne} ${styl.activeClass}` : 'w-305 h-40 mb-5'
  } ${
    isActivePanel ? 'elr-green-dark ' : 'border-elr-green border-opacity-30'
  }`;

export const productsNumContainer = (
  panelActive: number,
  styl: { [val: string]: string }
) => `${panelActive ? styl.numContainer : 'mt-5'}`;

export const newRequestNumContainer = (
  panelActive: number,
  styl: { [val: string]: string }
) => `${panelActive ? styl.numContainer : 'mt-5'}`;

export const newRequestTotalCountElem = (panelActive: number) =>
  `md:text-elr-black md:text-opacity-60 ${
    panelActive
      ? 'text-xl md:text-30 text-elr-black text-opacity-60'
      : 'text-30 text-elr-yellow'
  }`;

export const productsExpandAction = (
  panelActive: number,
  styl: { [val: string]: string }
) =>
  `${
    panelActive && styl.hideExpand
  } text-elr-purple text-center md:mt-4 mt-9 text-xs text-opacity-50 absolute bottom-0 left-0 right-0`;

export const newRequestExpandAction = (
  panelActive: number,
  styl: { [val: string]: string }
) =>
  `${
    panelActive && styl.hideExpand
  } text-elr-purple text-center md:mt-4 mt-9 text-xs text-opacity-50 absolute bottom-0 left-0 right-0`;

export const upcomingContainer = (
  panelActive: number,
  isActivePanel: boolean,
  styl: { [val: string]: string }
) =>
  `${
    panelActive ? `${styl.boxTwo} ${styl.activeClass}` : 'w-305 h-40 mb-5'
  } relative md:w-305 md:h-64 bg-elr-white-400 border hover:border-elr-purple rounded-md p-4 ml-5 ${
    isActivePanel ? 'border-elr-purple' : 'border-elr-green border-opacity-30'
  }`;

export const upcomingNumContainer = (
  panelActive: number,
  styl: { [val: string]: string }
) => `${panelActive ? styl.numContainer : 'mt-5'}`;

export const upcomingTotalCountElem = (panelActive: number) =>
  `${
    panelActive
      ? 'text-xl md:text-30 text-elr-black text-opacity-60'
      : 'text-30 text-elr-purple'
  } md:text-elr-black md:text-opacity-60`;

export const upcomingExpandAction = (
  panelActive: number,
  styl: { [val: string]: string }
) =>
  `${
    panelActive && styl.hideExpand
  } text-elr-purple text-center md:mt-4 mt-9 text-xs text-opacity-50 absolute bottom-0 left-0 right-0`;

export const deliveriesContainer = (
  panelActive: number,
  isActivePanel: boolean,
  styl: { [val: string]: string }
) =>
  `md:w-305 md:h-64 bg-elr-white-400 border border-elr-green hover:border-elr-green hover:border-opacity-60 rounded-md p-4 relative ${
    panelActive ? `${styl.boxThree} ${styl.activeClass}` : 'w-305 h-40'
  } mr-5 ${isActivePanel ? 'border-opacity-60' : 'border-opacity-30'}`;

export const deliveriesNumContainer = (panelActive: number) =>
  `${panelActive ? 'mt-2 md:mt-5' : 'w-20 mt-5'}`;

export const deliveriesTotalCountElem = (panelActive: number) =>
  `${
    panelActive
      ? 'text-xl md:text-30 text-elr-black text-opacity-60'
      : 'text-elr-green text-30'
  } md:text-elr-black text-opacity-60 md:text-opacity-60`;

export const deliveriesExpandAction = (
  panelActive: number,
  styl: { [val: string]: string }
) =>
  `${
    panelActive && styl?.hideExpand
  } text-elr-purple text-center text-xs mt-9 md:mt-28 text-opacity-50 absolute bottom-0 left-0 right-0`;

export const financialContainer = (
  panelActive: number,
  isActivePanel: boolean,
  styl: { [val: string]: string }
) =>
  `${
    panelActive ? `${styl.activeClass}` : 'w-305 h-40'
  } md:w-305 md:h-64 bg-elr-white-400 border hover:border-elr-black rounded-md p-4 relative ${
    isActivePanel ? 'border-elr-black' : 'border-elr-green border-opacity-30'
  }`;

export const financialNumContainer = (panelActive: number) =>
  `${panelActive ? 'mt-2 md:mt-5' : 'mt-5'}`;

export const financialTotalCountElem = (panelActive: number) =>
  `${
    panelActive ? 'text-xl md:text-30' : 'text-30'
  } text-elr-black text-opacity-60 inline`;

export const financialExpandAction = (
  panelActive: number,
  styl: { [val: string]: string }
) =>
  `${
    panelActive && styl.hideExpand
  } text-elr-purple text-center text-xs mt-9 md:mt-28 text-opacity-50 absolute bottom-0 left-0 right-0`;

export const intransitItemsContainer = (active: number) =>
  `mt-8 ${active ? '' : 'grid lg:grid-cols-column-three grid-cols-column-two'}`;

export const intransitItem = (active: number, id: number) =>
  `rounded p-2 items-center justify-between flex flex-wrap border border-elr-gray-600 mb-3 ${
    active
      ? `${
          active === id + 1
            ? 'bg-elr-purple bg-opacity-20 border-elr-purple'
            : ''
        }`
      : `h-32 mr-2 cursor-pointer justify-betwee flex-col`
  }`;

export const requestTypeBadge = () =>
  'text-elr-black text-opacity-60 border border-opacity-40 bg-opacity-20 text-10 rounded-full py-1 px-0.5 border-elr-yellow bg-elr-yellow';

export const completedOrderContainer = (
  panelActive: number,
  isActivePanel: boolean,
  styl: { [val: string]: string }
) =>
  `md:w-305 md:h-64 bg-elr-white-400 border border-elr-yellow hover:border-elr-yellow hover:border-opacity-60 rounded-md p-4 relative mb-3 ${
    panelActive ? `${styl.boxOne} ${styl.activeClass}` : 'w-305 h-40'
  } mr-5 ${isActivePanel ? 'border-opacity-60' : 'border-opacity-30'}`;

export const completedOrderNumContainer = (panelActive: number) =>
  `${
    panelActive ? 'mt-2 md:mt-5' : 'w-20 mt-5'
  } max-w-max text-center text-elr-gray text-opacity-60 text-xl border-b-2 border-elr-yellow bg-elr-yellow bg-opacity-10 h-11 p-2`;

export const completedOrderTotalCountElem = (panelActive: number) =>
  `${
    panelActive
      ? 'text-xl md:text-30 text-elr-black text-opacity-60'
      : 'text-elr-yellow text-30'
  } md:text-elr-black text-opacity-60 md:text-opacity-60`;

export const completedOrderExpandAction = (
  panelActive: number,
  styl: { [val: string]: string }
) =>
  `${
    panelActive && styl?.hideExpand
  } text-elr-purple text-center text-xs mt-9 md:mt-28 text-opacity-50 absolute bottom-0 left-0 right-0`;
