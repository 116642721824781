import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpUpdateDispatcherProfile } from 'app/api/dispatcher';
import { useGetProfile } from 'app/hooks/user';
import { AccountType } from 'app/utilities/types/dispatcherTypes';
import React, { useState } from 'react';
import { ElrButton, ElrInput } from 'ui-components';

const EditPersonalDetails: React.FC = () => {
  const queryClient = useQueryClient();
  const { id: userId, email, firstname, lastname, phone } = useGetProfile();

  const [statePersonalDetails, setPersonalDetails] = useState({ id: userId });
  const [successSaved, setSuccessSaved] = useState<boolean>(false);

  const { mutateAsync, isError } = useMutation({
    mutationFn: (payload: AccountType) =>
      httpUpdateDispatcherProfile(payload, userId),

    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: object | undefined) =>
        oldData ? { ...oldData, accountInformation: data } : {}
      );
      setSuccessSaved(true);
    },
    onError: () => {
      if (successSaved) {
        setSuccessSaved(false);
      }
    },
  });

  const makeUpdate = async () => {
    try {
      const payload = statePersonalDetails;
      await mutateAsync(payload);
    } catch (error) {
      /** noop */
    }
  };

  const onSubmit = () => makeUpdate();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalDetails((bd) => ({
      ...bd,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <div className="mt-8 mb-8 text-xl text-center">Personal Details</div>
      <ElrInput
        className="mb-3 border-none rounded-none bg-elr-gray"
        onChange={onChange}
        name="firstName"
        value={firstname}
        placeholder="First name*"
      />
      <ElrInput
        className="mb-3 border-none rounded-none bg-elr-gray"
        onChange={onChange}
        name="lastName"
        value={lastname}
        placeholder="Last name"
      />
      <ElrInput
        className="mb-3 border-none rounded-none bg-elr-gray"
        name="email"
        disabled
        value={email}
        onChange={() => {}}
        placeholder="Email"
      />
      <ElrInput
        className="mb-3 border-none rounded-none bg-elr-gray"
        onChange={onChange}
        name="phoneNumber"
        value={phone}
        placeholder="Primary Phone Number"
      />
      {isError && (
        <p className="text-xs mb-1 h-3.5 text-elr-green">Error occured</p>
      )}
      {successSaved && (
        <p className="text-xs mb-1 h-3.5 text-elr-green">Successfully saved</p>
      )}
      <div className="flex justify-end">
        <ElrButton
          text="Save"
          size="lg"
          disabled
          onClick={onSubmit}
          className="float-right w-40 mt-10 mb-3 text-lg text-white bg-elr-black"
        />
      </div>
    </div>
  );
};

export default EditPersonalDetails;
