import { Destinations } from 'app/components/Destinations';
import { Batch } from 'app/utilities/types/shared';
import React from 'react';
import { PickUpLocations } from '../PickupLocations';

interface Props {
  details: Batch;
}

const BatchDeliveryDetailsList: React.FC<Props> = ({ details }) => {
  const { pickupAddress, deliverToInformation, mapUrl, businessName } = details;

  const deliveryAddresses = deliverToInformation;

  return (
    <div>
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <div className="text-base md:text-sm mb-3.5"> Delivery details </div>
        </div>
        <div className="flex justify-between mb-6 opacity-60">
          <div>
            <span className="text-sm">Delivery ID: </span>
            <span className="text-base">{pickupAddress.trackingId}</span>
          </div>
        </div>
        {mapUrl ? (
          <div className="mb-4">
            <a
              target="_blank"
              rel="noreferrer"
              className="text-xs border-b text-elr-purple border-elr-purple"
              href={mapUrl}
            >
              View location on map
            </a>
          </div>
        ) : null}
        <div>
          <PickUpLocations
            pickupAddress={pickupAddress}
            businessName={businessName}
          />
        </div>

        {details && (
          <div>
            <Destinations destinations={deliveryAddresses} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BatchDeliveryDetailsList;
