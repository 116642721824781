import BlackSpinner from '../../../assets/images/spinner-black.svg';

interface Props {
  text: string;
}

export const ElrLoadingWithText = ({ text }: Props) => (
  <div className="flex flex-col items-center justify-center h-full">
    <img src={BlackSpinner} className="animate-spin h-5" alt="spinner" />
    <p className="text-elr-black opacity-60 text-center mt-5">{text}</p>
  </div>
);
