/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { httpFetchUnmanagedAgents } from 'app/api/agent';
import { BatchedInterface } from 'app/api/d';
import { InitialInterfaceData } from 'app/api/types';
import DashboardContainer from 'app/components/DashboardContainer';
import MyWallet from 'app/components/MyWallet';
import BatchContainer from 'app/components/dashboard/BatchContainer';
import BatchUpcomingContainer from 'app/components/dashboard/BatchUpcomingContainer';
import {
  useFetchDeliveriesMade,
  useFetchNewBatchRequests,
  useFetchNewRequests,
  useFetchUpcomingRequests,
} from 'app/hooks/requests/dashboard';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import DeliveriesMade from 'app/platform/modules/DeliveriesMade';
import UnassignedAgents from 'app/platform/modules/UnassignedAgents';
import { Session } from 'app/utilities/Session';
import { getCurrentCountry, getDefaultState } from 'app/utilities/country';
import {
  batchRequestTabs,
  canOperateInCountryAndState,
  checkCompanyProfile,
  dispatchTabs,
  isDispatcherPlatform,
  roundUpAmount,
} from 'app/utilities/helpers';
import { privateRoutes } from 'app/utilities/routes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProfile } from '../../hooks/user';
import DashboardPanel from '../../platform/modules/DashboardPanel';

const Dashboard: React.FC = () => {
  const {
    id: userId,
    accountInformation: { areasOfOperations, companyName, id: roleId },
    currentAccessRole,
    isAnOperator,
  } = useGetProfile();
  const history = useNavigate();
  const [getWalletBalance, wallet] = useGetWalletBalance(
    userId,
    roleId,
    currentAccessRole
  );
  const [tabs] = useState(dispatchTabs);
  const currentRole = currentAccessRole || Session.getValue('accessUserType');

  // hooks for fetching requests (single)
  const { latestData: newSingleRequestData } =
    useFetchNewRequests('marketplace');
  const { latestData: singleUpcomingRequestData } =
    useFetchUpcomingRequests('');
  const { latestData: deliveriesMadeRequestData } = useFetchDeliveriesMade();

  // hooks for fetching requests (batches)
  const { latestData: newBatchRequestData } = useFetchNewBatchRequests(
    batchRequestTabs[0].id
  );

  const [batchDeliveriesMade] = useState<BatchedInterface>({
    batches: InitialInterfaceData,
  });

  const searchFields = useMemo(
    () => ({
      areasOfOperations,
      companyName,
    }),
    [areasOfOperations, companyName]
  );

  const [stepState, setStateState] = useState<number>(0);

  // @TODO use state and country of current user
  const options = {
    country: getCurrentCountry(),
    state: getDefaultState(),
    limit: 100,
    offset: 0,
  };

  const {
    data: unassignedAgentsData,
    refetch: refetchUnassignedAgents,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['unassignedAgents'],
    queryFn: ({ pageParam = { userId, options } }) =>
      httpFetchUnmanagedAgents(pageParam),
    placeholderData: keepPreviousData,
    enabled:
      isDispatcherPlatform(currentRole) &&
      canOperateInCountryAndState(areasOfOperations),
    refetchOnMount: true,
    getNextPageParam: (lastPage) => ({
      userId,
      options: {
        ...options,
        offset: lastPage.page * lastPage.limit,
      },
    }),
    initialPageParam: { userId, options },
  });

  const getBalance = useCallback(async () => {
    await getWalletBalance();
  }, [wallet.balance]);

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    const { isNotEmpty } = checkCompanyProfile(searchFields);

    if (isNotEmpty) {
      return history(
        `${privateRoutes.onboarding(currentAccessRole)}?incomplete=true`
      );
    }
  }, []);

  const walletTotal = roundUpAmount(+wallet.balance);

  const panels = [
    null,
    <BatchContainer tabs={tabs} />,
    <BatchUpcomingContainer />,
    <DeliveriesMade displayOriginalFees />,
    <MyWallet walletBalance={walletTotal} />,
    <UnassignedAgents
      unassignedAgentsData={unassignedAgentsData}
      refetchUnassignedAgents={refetchUnassignedAgents}
      paginationProps={{
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
      }}
    />,
  ];

  const onChangePanel = (index: number) => {
    setStateState(index);
  };

  const showUnassignedAgents = () => {
    onChangePanel(5);
  };

  const newRequestsTotal = isAnOperator
    ? newSingleRequestData.totalDocs + newBatchRequestData.totalDocs
    : newBatchRequestData.totalDocs;

  const upcomingRequestsTotal = singleUpcomingRequestData.totalDocs;

  const deliveriesMadeTotal =
    deliveriesMadeRequestData.totalDocs + batchDeliveriesMade.batches.totalDocs;

  return (
    <DashboardContainer
      titleTag="Errandlr - Dispatcher Dashboard"
      panelComponent={panels[stepState] ? panels[stepState] : null}
      dashboardPanel={
        <DashboardPanel
          totalDocs={{
            accountBalance: walletTotal,
            newRequestsTotal,
            upcomingRequestsTotal,
            deliveriesMadeTotal,
          }}
          onClick={onChangePanel}
          financeLabel="Wallet"
          panelActive={stepState}
        />
      }
      showUnassignedAgents={showUnassignedAgents}
      unmanagedAgentsCount={unassignedAgentsData?.pages[0]?.docs?.length}
    />
  );
};

export default Dashboard;
