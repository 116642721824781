import { API_URL } from 'app/constants';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosStatic, axiosDefault as axios } from 'app/utilities/axios';
import { StaticBankInformation } from 'app/utilities/types/dispatcherTypes';
import { toast } from 'react-toastify';

export interface Bank {
  name: string;
  slug: string;
  code: string;
}

export interface VerifiedInformation {
  status: boolean;
  message: string;
  account_number: string;
  account_name: string;
  bank_id: number;
}

export const getBankInfo = async (): Promise<Bank[]> => {
  try {
    const response = await axiosStatic.get(
      `${API_URL}/payment/get-bank-providers`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const verifyBankInformation = async ({
  accountNumber,
  bankCode,
}: {
  accountNumber: string;
  bankCode: string;
}): Promise<VerifiedInformation> => {
  try {
    const response = await axiosStatic.get(
      `${API_URL}/payment/verify-bank-information?account_number=${accountNumber}&bank_code=${bankCode}`
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpWithdrawBalance = async ({
  userId,
  roleId,
  role,
}: {
  userId: string;
  roleId: string;
  role: string;
}): Promise<any> => {
  try {
    const response = await axios.post(
      `wallet/${userId}/withdraw?roleId=${roleId}&role=${role}`,
      {},
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpManualWithdrawBalance = async ({
  userId,
  roleId,
  role,
  bankInfo,
}: {
  userId: string;
  roleId: string;
  role: string;
  bankInfo: {
    bankDetails: StaticBankInformation;
  };
}): Promise<any> => {
  try {
    const response = await axios.post(
      `wallet/${userId}/request-manual-withdrawal?roleId=${roleId}&role=${role}`,
      bankInfo,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
