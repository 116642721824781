import { CenterLogoHeader } from 'app/components/Header';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrLeftArrowButton,
  ElrPageTitle,
} from 'ui-components';
import * as Yup from 'yup';

interface FormValue {
  addressNumber: string;
  streetName: string;
  localGovtArea: string;
  registeredCity: string;
}

const TradingAddressSchema = Yup.object().shape({
  addressNumber: Yup.string().required('Required'),
  streetName: Yup.string().required('Required'),
  localGovtArea: Yup.string().required('Required'),
  registeredCity: Yup.string().required('Required'),
});

const TradingAddress: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const onUpdateTradingAddress = async (values: FormValue) => values;

  const { handleSubmit, handleChange, values, errors } = useFormik<FormValue>({
    initialValues: {
      addressNumber: '',
      streetName: '',
      localGovtArea: '',
      registeredCity: '',
    },
    validationSchema: TradingAddressSchema,
    onSubmit: (value) => onUpdateTradingAddress(value),
  });

  const disableBtn = Object.values(values).every((val) => val);

  return (
    <div>
      <ElrPageTitle title="Errandlr - Trading Address" />
      <div className="flex flex-col items-center">
        <CenterLogoHeader />
        <form onSubmit={handleSubmit} className="w-11/12 md:w-430 h-full mb-12">
          <div className="px-7 md:px-10 bg-elr-white-400 rounded-2xl flex flex-col">
            <div className="mt-14 mb-7 text-xl text-center">
              <p>Trading Address</p>
            </div>
            <div className="mb-7 flex items-center">
              <div className="checkbox-container-style bg-elr-gray rounded border-elr-purple border-05 border-opacity-60 w-6 h-6 flex shrink-0 justify-center items-center mr-2 focus-within:border-elr-purple focus-within:border-opacity-60 `cursor-pointer">
                <input
                  type="checkbox"
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                  className="opacity-0 absolute cursor-pointer"
                />
                <svg
                  className="fill-current hidden w-5 h-4 text-elr-purple"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <span className="text-elr-black opacity-60 text-sm">
                Same as company address
              </span>
            </div>
            <div>
              {errors?.addressNumber && (
                <span className="text-xs text-elr-error">
                  {errors?.addressNumber}
                </span>
              )}
              <ElrInput
                className="bg-elr-gray rounded-none border-none mb-3"
                placeholder="Company address number"
                name="addressNumber"
                value={values.addressNumber}
                onChange={handleChange}
              />
              {errors?.streetName && (
                <span className="text-xs text-elr-error">
                  {errors?.streetName}
                </span>
              )}
              <ElrInput
                className="bg-elr-gray rounded-none border-none mb-3"
                placeholder="Street name"
                name="streetName"
                value={values.streetName}
                onChange={handleChange}
              />
              {errors?.localGovtArea && (
                <span className="text-xs text-elr-error">
                  {errors?.localGovtArea}
                </span>
              )}
              <ElrInput
                className="bg-elr-gray rounded-none border-none mb-3"
                placeholder="Local government area"
                name="localGovtArea"
                value={values.localGovtArea}
                onChange={handleChange}
              />
              {errors?.registeredCity && (
                <span className="text-xs text-elr-error">
                  {errors?.registeredCity}
                </span>
              )}
              <ElrInput
                className="bg-elr-gray rounded-none border-none mb-16 md:mb-11"
                placeholder="Registered city"
                name="registeredCity"
                value={values.registeredCity}
                onChange={handleChange}
              />
            </div>
            <div className="w-full border-b border-elr-gray-600 mb-10 md:mb-12 opacity-60" />
            <div className="flex justify-between">
              <ElrLeftArrowButton />
              <ElrButton
                text="Next"
                submit
                disabled={!disableBtn && !checked}
                className="bg-elr-black text-white text-lg w-40 mb-3"
              />
            </div>
            <div className="mb-10 md:mb-12" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default TradingAddress;
