import ArrowUp from '@material-ui/icons/ExpandLess';
import ArrowDown from '@material-ui/icons/ExpandMore';
import React from 'react';

type FontSize = 'small' | 'inherit' | 'default' | 'large';
interface Props {
  isToggled: boolean;
  toggle: Function;
  fontSize?: FontSize;
}

export const ElrDropdownIconToggle: React.FC<Props> = ({
  isToggled,
  toggle,
  fontSize,
}) => (
  <span>
    {!isToggled ? (
      <ArrowDown
        fontSize={fontSize}
        role="button"
        onClick={() => toggle(!isToggled)}
      />
    ) : (
      <ArrowUp
        fontSize={fontSize}
        role="button"
        onClick={() => toggle(!isToggled)}
      />
    )}
  </span>
);
