import { TopNav } from 'app/components/menu/Navbar';
import { publicRoutes } from 'app/utilities/routes';
import React from 'react';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';
import { Footer } from '../landing/components';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';

export const Issues: React.FC = () => (
  <div className="bg-white">
    <ElrPageTitle title="Errandlr - Issues" />
    <TopNav isDefault />
    <div className="mx-5 py-10 md:mx-40">
      <div className="w-full bg-elr-gray rounded-md md:py-20 md:px-80 px-6 py-12 md:mt-20 mt-12 flex justify-center flex-col items-center">
        <p className="text-2xl font-semibold mb-6">Submit a query</p>
        <div className="flex flex-col items-center md:w-480 w-full border rounded-md p-8 bg-elr-white-400 border-elr-gray-200 border-opacity-30">
          <div className="w-full">
            <div className="text-lg">
              <p className="text-elr-black text-opacity-60 md:pb-0 pb-8">
                Report an issue about your parcel or the delivery person or{' '}
                <span>
                  <a href={publicRoutes.track} className="text-elr-purple">
                    track a parcel.
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="md:mt-5 w-full">
            <ElrInput
              className="h-10 border-none rounded-none outline-none bg-elr-gray mb-10 md:mb-7"
              placeholder="Enter parcel ID"
              onChange={() => {}}
            />
            <div className="flex items-end justify-end">
              <ElrButton
                text="Next"
                className="bg-elr-black text-white text-lg w-44 mb-3"
                onClick={() => null}
              />
            </div>
          </div>
        </div>
      </div>
      <PoliciesAppBanner />
    </div>
    <Footer hideTopFooter />
  </div>
);
