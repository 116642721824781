import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import { publicRoutes } from 'app/utilities/routes';
import LocationPin from '../../../assets/images/location-line.svg';
import arrowForward from '../../../assets/images/arrowForward.svg';
import { EventData, exploreEvents } from './mock';

const EventCard: React.FC<{ event: EventData }> = ({ event }) => {
  const handleViewEvent = () => {
    navigate(`${publicRoutes.event}/${event.uuid}`);
  };
  const navigate = useNavigate();
  return (
    <div className="bg-white p-4 rounded-md mx-3">
      <img
        src={event.bannerUrl}
        alt="banner"
        className="rounded-md mb-4 w-full"
      />
      <p className="text-xl">{event.eventName}</p>
      <div className="flex items-center gap-2 pb-2 pt-1">
        <p className="text-base text-elr-black-400">{event.eventDate}</p>
        <span className="h-[4px] w-[4px] rounded-full bg-elr-black-400 opacity-60 inline-block" />
        <p className="text-base text-elr-black-400">{event.eventTime}</p>
      </div>
      <div className="flex items-center gap-2 align-middle">
        <img src={LocationPin} alt="" className="w-[16px]" />
        <p className="text-base text-elr-green">{event.eventLocation}</p>
      </div>
      <ElrButton
        text="View Event"
        className="text-elr-black bg-elr-gray w-full mt-6"
        onClick={handleViewEvent}
      />
    </div>
  );
};

const UpcomingEvent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = exploreEvents.length - itemsPerView;
      return prevIndex < maxIndex ? prevIndex + 1 : prevIndex;
    });
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const itemsPerView = windowWidth >= 1024 ? 3 : 1;

  return (
    <div className="bg-elr-gray md:py-25 py-14 px-8 sm:px-12 md:px-20 lg:px-40 md:mt-40 mt-24">
      <p className="text-4xl mx-auto md:w-full w-10/12 md:text-5xl text-center">
        Explore a Variety of Upcoming Events
      </p>
      <div className="md:pt-12 pt-10 flex flex-col justify-center items-center">
        <div className="relative w-full overflow-hidden">
          <div
            className="flex transition-transform duration-500"
            style={{
              transform: `translateX(-${currentIndex * (100 / itemsPerView)}%)`,
            }}
          >
            {exploreEvents.map((event) => (
              <div
                key={event.uuid}
                className={`flex-shrink-0 ${windowWidth >= 1024 ? 'w-1/3' : 'w-full'}`}
              >
                <EventCard event={event} />
              </div>
            ))}
          </div>
        </div>
        <div className="md:flex gap-6 mt-12 hidden">
          <button
            onClick={handleBack}
            type="button"
            className={`bg-elr-black w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center rounded-full 
    ${currentIndex === 0 ? 'opacity-80 cursor-not-allowed' : 'opacity-100'}`}
            disabled={currentIndex === 0}
          >
            <img
              src={arrowForward}
              alt="Back"
              className="filter invert brightness-0 rotate-180"
            />
          </button>

          <button
            onClick={handleNext}
            type="button"
            className={`bg-elr-black w-10 h-10 md:w-12 md:h-12 flex items-center justify-center rounded-full 
    ${currentIndex + itemsPerView >= exploreEvents.length ? 'opacity-80 cursor-not-allowed' : 'opacity-100'}`}
            disabled={currentIndex + itemsPerView >= exploreEvents.length}
          >
            <img
              src={arrowForward}
              alt="Next"
              className="filter invert brightness-0"
            />
          </button>
        </div>

        <div className="flex gap-2 mt-6 sm:hidden">
          {exploreEvents.map((event) => (
            <button
              type="button"
              key={event.uuid}
              aria-label={`Event ${event.uuid}`}
              onClick={() => setCurrentIndex(exploreEvents.indexOf(event))}
              className={`h-[10px] w-[10px] mt-4 rounded-full ${
                exploreEvents.indexOf(event) === currentIndex
                  ? 'bg-elr-black'
                  : 'bg-elr-black-400 opacity-30'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;
