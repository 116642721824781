import { PagesState } from 'app/sender/modules/authOptionsModal';
import { publicRoutes } from 'app/utilities/routes';
import React from 'react';
import { Link } from 'react-router-dom';

export const SignUpLinkBtn: React.FC<{
  slideInMode?: boolean;
  setCurrentScreen?: (state: PagesState) => void;
}> = ({ slideInMode, setCurrentScreen }) =>
  slideInMode && setCurrentScreen ? (
    <span
      onClick={() => setCurrentScreen('createAccount')}
      className="text-elr-purple opacity-60 cursor-pointer"
    >
      Sign Up
    </span>
  ) : (
    <Link to={publicRoutes.registration}>
      <span className="text-elr-purple opacity-60"> Sign Up </span>
    </Link>
  );

export const LoginLinkBtn: React.FC<{
  slideInMode?: boolean;
  setCurrentScreen?: (state: PagesState) => void;
}> = ({ slideInMode, setCurrentScreen }) =>
  slideInMode && setCurrentScreen ? (
    <span
      onClick={() => setCurrentScreen('login')}
      className="text-elr-purple opacity-60 cursor-pointer"
    >
      Login
    </span>
  ) : (
    <span className="text-elr-purple opacity-60">
      <Link to={publicRoutes.login}>Login</Link>
    </span>
  );
