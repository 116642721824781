import React from 'react';
import removeIcon from 'assets/images/remove-icon.svg';

export const ElrRemoveButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <button
    className="flex gap-1 items-center h-fit pt-1"
    type="button"
    onClick={onClick}
  >
    <img src={removeIcon} alt="remove icon" />
    <p className="text-10 text-elr-error">Remove</p>
  </button>
);
