import { useQueryClient } from '@tanstack/react-query';
import { httpUploadFile } from 'app/api/utils';
import { BackIconWonWithBG, PencilIcon } from 'app/components/SvgAssets';
import { useCreateEvent } from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import {
  CreateEventProps,
  CreateEventResponse,
  EventChargesType,
  Ticket,
} from 'app/utilities/types/shared';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ElrButton } from 'ui-components';

const EventPreview = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    eventName,
    eventLocation,
    eventDescription,
    eventDate,
    eventTime,
    ticketTypes,
  } = location.state || {};

  const handleEditClick = () => {
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.events}`,
      {
        state: { event: location.state },
      }
    );
  };

  const { createEvent, loading } = useCreateEvent();
  const { id: userId, ...profile } = useGetProfile();
  const roleId = profile.accountInformation.id;

  const handleUploadBanner = async (file: File) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: [file.name],
        queryFn: () => httpUploadFile(userId, file, 'image'),
        gcTime: 30000,
      });

      const response = {
        key: data.key,
        originalFilename: file.name,
        type: file.type,
        url: `${new URL(data.url).origin}${new URL(data.url).pathname}`,
      };
      return response;
    } catch (error: any) {
      // Handle error
      return null;
    }
  };

  const handleStartSelling = async () => {
    const { eventUUId, ...eventData } = location.state || {};
    setIsLoading(true);
    if (eventData.bannerImage) {
      const bannerRes = await handleUploadBanner(eventData.bannerImage);
      eventData.bannerUrl = bannerRes?.url || eventData.bannerUrl;
    }
    const payload: CreateEventProps = {
      name: eventData.eventName,
      description: eventData.eventDescription,
      location: eventData.eventLocation?.label || '',
      roleId,
      eventDate: new Date(eventData.eventDate).toISOString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      bannerUrl: eventData.bannerUrl || undefined,
      tickets: eventData.ticketTypes.map((ticket: Ticket) => ({
        id: ticket.id,
        type: ticket.type.toLowerCase(),
        price: ticket.price,
        quantity: ticket.quantity,
        active: true,
        expiryDate: ticket.expiryDate,
        charge:
          ticket.whoPays === EventChargesType.Business
            ? 'business'
            : 'customer',
        ticketCategory: ticket.ticketCategory || 'single',
        groupQuantity:
          ticket.ticketCategory === 'group' ? ticket.groupQuantity : undefined,
      })),
    };
    try {
      const response: CreateEventResponse = await createEvent(payload);
      const eventUuid = response?.uuid;
      navigate(
        `/${COMMERCE}/${privateRoutes.ecommerce}/${eventUuid}/${privateRoutes.eventsDashboard}`
      );
    } catch (error) {
      // Handle error
    }
    setIsLoading(false);
  };

  const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

  return (
    <div className="flex justify-center pt-16">
      <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
        <section
          className="flex ml-6 cursor-pointer gap gap-x-2"
          onClick={() => navigate(-1)}
        >
          <BackIconWonWithBG />
          <p className="self-center">Back</p>
        </section>
        <div className="w-full pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
              Event Preview
            </h1>
            <p className="text-lg leading-5 text-elr-black-400">
              Please confirm your event details
            </p>
          </header>
          <div className="w-full px-10 py-8 mx-auto mb-10 bg-elr-white-400">
            <div>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Event Name</p>
                <p>{eventName}</p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">
                  Event Description
                </p>
                <p className="md:w-1/2 w-9/10">{eventDescription}</p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Event Location</p>
                <p className="md:w-1/2 w-9/10">{eventLocation?.label}</p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Event Date</p>
                <p className="w-1/2">
                  {new Date(eventDate).toLocaleDateString()}
                </p>
              </section>
              <section className="flex-col mb-8 md:flex md:flex-row">
                <p className="w-1/2 pb-1 text-elr-black-300">Event Time</p>
                <p className="w-1/2">{eventTime}</p>
              </section>

              {ticketTypes?.map((ticket: Ticket, index: number) => (
                <section
                  key={ticket.id}
                  className="flex-col mb-8 md:flex md:flex-row"
                >
                  <p className="w-1/2 pb-1 text-elr-black-300">
                    Ticket {index + 1}
                  </p>
                  <div className="w-full md:w-1/2">
                    <p>{capitalize(ticket.type)}</p>
                    <p>NGN {ticket.price}</p>
                    <p>Quantity - {ticket.quantity}</p>
                    <p>
                      Deadline -{' '}
                      {ticket.expiryDate
                        ? new Date(ticket.expiryDate).toLocaleDateString()
                        : 'N/A'}
                    </p>
                    <p>
                      {ticket.ticketCategory === 'single'
                        ? 'One person'
                        : `Max people allowed - ${ticket.groupQuantity}`}
                    </p>
                    <p>
                      {ticket.whoPays === EventChargesType.Business
                        ? 'Business pays'
                        : 'Customer pays'}{' '}
                      payment charges
                    </p>
                  </div>
                </section>
              ))}
            </div>
            <div className="flex w-full gap-2 mt-14">
              <ElrButton
                text={'Edit'}
                className="flex items-center w-full gap-2 align-middle rounded-sm bg-elr-gray text-elr-black"
                onClick={handleEditClick}
                imageSrc={<PencilIcon />}
                imagePosition="Right"
              />
              <ElrButton
                text="Start Selling"
                className="w-full rounded-sm bg-elr-black text-elr-white-400"
                onClick={handleStartSelling}
                spinnerColor="White"
                disabled={loading || isLoading}
                loading={loading || isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPreview;
