import * as Yup from 'yup';

const YupFileStructure = {
  key: Yup.string(),
  originalFilename: Yup.string(),
  type: Yup.string(),
  url: Yup.string(),
};

export const AgentFormSchema = Yup.object().shape({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  alternativePhone: Yup.string(),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  vehiclePlateNumber: Yup.string().required('Required'),
  driversLicense: Yup.object().shape(YupFileStructure),
  additionalId: Yup.object().shape(YupFileStructure),
  vehicleRegistrationDocument: Yup.object().shape(YupFileStructure),
});
