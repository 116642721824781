import { CustomAccessView } from 'app/utilities/ComponentView';
import { DISPATCHER, OPERATOR } from 'app/utilities/roles';
import { PickupAddress } from 'app/utilities/types/shared';
import businessIcon from 'assets/images/businessIcon.svg';
import React from 'react';
import { ElrDropdownIconToggle, ElrModal, ElrPillButton } from 'ui-components';
import { ElrRemoveButton } from 'ui-components/components/ElrActionButtons';
import { isDispatcherPlatform } from 'app/utilities/helpers';
import { useGetProfile } from 'app/hooks/user';

interface MergedPickupAddress extends PickupAddress {
  locker?: {
    lockerMerchant: string;
    boxId: string;
    sizeId: string;
    boxName: string;
    boxAddress: string;
    dropCode: string;
    collectCode: string;
    boxLockerNumber: string;
    createdAt: string;
    updatedAt: string;
  };
}
interface Props {
  pickupAddress: MergedPickupAddress;
  showRemoveButton?: boolean;
  businessName?: string;
}

export const PickUpLocations: React.FC<Props> = ({
  pickupAddress,
  businessName,
  showRemoveButton = false,
}) => {
  const { currentAccessRole: role } = useGetProfile();
  const [toggled, setToggleState] = React.useState<boolean[]>([]);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const {
    fullAddress,
    senderInformation: {
      data: { name, phone },
    },
    id,
    locker,
  } = pickupAddress;

  const togglePickupDetails = (index: number) => {
    const updateToggleState = [...toggled];
    updateToggleState[index] = !toggled[index];
    setToggleState(updateToggleState);
  };

  const idAsNumber = parseInt(id, 10);

  const showRemoveForSpecificInfo =
    isDispatcherPlatform(role) || role === OPERATOR;

  return (
    <div>
      <ElrModal
        height="h-200"
        width="w-304"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="flex flex-col gap-2 text-center">
          <h3>Are you sure you want to remove</h3>
          <span className="text-12 opacity-60">{fullAddress}</span>
          <p>from the deliveries</p>
        </div>
        <div className="flex justify-center gap-8 mt-10">
          <button type="button" onClick={() => setShowModal(false)}>
            Back
          </button>
          <ElrPillButton
            size="sm"
            text="Remove"
            className="bg-elr-black h-12 px-8 text-white text-base flex items-center justify-center ml-10"
            onClick={() => {}}
          />
        </div>
      </ElrModal>
      <div className="flex">
        <div className="flex flex-col">
          <span className="h-1.5 md:h-2.5 w-1.5 md:w-2.5 ml-0.5 md:ml-0 mb-1.5 my-2 rounded-full bg-elr-purple" />
          <span className="h-7 md:h-5 mx-1 w-0.3 bg-elr-black bg-opacity-40" />
        </div>
        <div
          className="flex justify-between w-full ml-3 mt-1 cursor-pointer"
          onClick={() => togglePickupDetails(idAsNumber)}
        >
          <span className="text-sm opacity-60 w-4/6 pt-0.5">{fullAddress}</span>
          <div className="flex gap-5">
            {showRemoveButton && showRemoveForSpecificInfo && (
              <CustomAccessView showFor={[DISPATCHER, OPERATOR]}>
                <ElrRemoveButton onClick={() => setShowModal(true)} />
              </CustomAccessView>
            )}
            <span className="opacity-40 relative bottom-0.5">
              <ElrDropdownIconToggle
                isToggled={toggled[idAsNumber]}
                toggle={() => togglePickupDetails(idAsNumber)}
                fontSize="small"
              />
            </span>
          </div>
        </div>
      </div>
      {toggled[idAsNumber] && (
        <div className="flex h-full mx-1">
          <span className="w-0.3 bg-elr-black bg-opacity-40" />
          <div className="flex flex-col justify-center w-full px-3 pb-2 mb-6 ml-4 h-32 md:h-24 bg-elr-gray">
            <div className="flex flex-row justify-between">
              <div className="my-2.5 text-12"> Sender </div>
            </div>
            <div className="flex flex-col text-12 md:flex-row opacity-60 mb-2">
              <div className="flex flex-col w-full md:w-5/12">
                <p className="w-full mb-2"> {name}</p>
                {businessName && (
                  <div className="mb-2 flex  gap-2 items-center ">
                    <img
                      src={businessIcon}
                      alt="business Icon"
                      className="w-4 h-4"
                    />
                    {businessName}
                  </div>
                )}
              </div>
              <span className="flex items-start">{phone}</span>
              {locker && (
                <span className="md:ml-20">{locker?.collectCode}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
