import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ElrButton, ElrInput } from 'ui-components';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';

const validationSchema = Yup.object({
  bankName: Yup.string().required('Bank name is required'),
  accountNumber: Yup.string()
    .required('Account number is required')
    .matches(/^[0-9]{10}$/, 'Account number must be 10 digits'),
});

const BankDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const [, wallet] = useGetWalletBalance(userId, roleId);

  const { handleSubmit, values, setFieldValue, isValid } = useFormik({
    initialValues: {
      bankName: wallet.paystackAccountName || '',
      accountNumber: wallet.paystackAccountNumber || '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      setIsModalOpen(false);
    },
  });

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Bank Information</p>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-5 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="text-base pb-2 text-elr-black-400">Bank Name</p>
            <ElrInput
              className="border-none bg-elr-gray h-12 outline-none"
              value={values.bankName}
              onChange={(e) => setFieldValue('bankName', e.target.value)}
              placeholder="Bank Name"
            />
          </div>

          <div className="w-full">
            <p className="text-base pb-2 text-elr-black-400">Account Number</p>
            <ElrInput
              className="border-none bg-elr-gray h-12 outline-none"
              value={values.accountNumber}
              onChange={(e) => setFieldValue('accountNumber', e.target.value)}
              placeholder="Account Number"
            />
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled={!isValid}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <div
        className="absolute right-5 top-6 text-base px-8 py-3 text-elr-black flex gap-3 rounded-md items-center justify-center cursor-pointer bg-elr-gray text-center"
        onClick={() => setIsModalOpen(true)}
      >
        <p>Change Bank</p>
      </div>
      {isModalOpen && renderEditInfo}
      <p className="text-xl pb-7">Bank Information</p>
      <div className="grid grid-cols-1 md:grid-rows-1 md:grid-flow-col gap-7 md:gap-0">
        <div className="flex flex-col">
          <p className="text-base text-elr-black-400 pb-2">Bank Name</p>
          <p className="text-elr-black text-base">
            {wallet?.paystackAccountName || 'N/A'}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-base text-elr-black-400">Account Number</p>
          <div className="flex pt-2 pb-2 text-base">
            <p className="pr-4 pt-1 text-elr-black text-base">
              {wallet?.paystackAccountNumber || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
