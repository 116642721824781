import TropicalSummerImg from '../../../assets/images/tropical-summer.png';
import DanceMixImg from '../../../assets/images/dance-mix.png';
import MusicDanceImg from '../../../assets/images/music-and-dance.png';

export type EventData = {
  uuid: number;
  eventName: string;
  eventDate: string;
  eventTime: string;
  eventLocation: string;
  bannerUrl: string;
};

export const exploreEvents: EventData[] = [
  {
    uuid: 1,
    eventName: 'Tropical Summer Party',
    eventDate: 'August 20, 2024',
    eventTime: '10:00 AM',
    eventLocation: 'Lagos',
    bannerUrl: TropicalSummerImg,
  },
  {
    uuid: 2,
    eventName: 'Dance Mix Party',
    eventDate: 'September 12, 2024',
    eventTime: '8:00 PM',
    eventLocation: 'Abuja',
    bannerUrl: DanceMixImg,
  },
  {
    uuid: 3,
    eventName: 'Music and Dance Festival',
    eventDate: 'October 5, 2024',
    eventTime: '6:00 PM',
    eventLocation: 'Ibadan',
    bannerUrl: MusicDanceImg,
  },
  {
    uuid: 4,
    eventName: 'Rock Festival',
    eventDate: 'November 3, 2024',
    eventTime: '5:00 PM',
    eventLocation: 'Enugu',
    bannerUrl: TropicalSummerImg,
  },
];
