import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import React from 'react';
import { ElrPageTitle } from 'ui-components';
import SidebarMenu from './sidebarMenu';

const PremiumSupport: React.FC = () => (
  <div className="h-full">
    <ElrPageTitle title="Errandlr - 1 - 1 premium support" />
    <SidebarMenu>
      <div className="h-full bg-white">
        <div className="h-1000 md:h-auto">
          <TopNavigationMenu />
          <div className="px-8 md:px-24 pt-10 pb-8 text-xl">
            Premium 1 to 1 support
          </div>
          <p className="px-8 md:px-24 pb-8 text-elr-black text-opacity-60">
            As a valued customer and more we are decicated to you by offering
            you your own support personnel, You can access your support with the
            detail below:
          </p>
          <div className="pb-16 md:pb-28 mx-4 pt-12 md:mx-24 border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-5 mb-7">
            <div className="mx-4 md:mx-10 flex flex-col md:flex-row">
              <div className="md:w-4/12 mb-10 md:mb-0 ml-16 md:ml-0">
                <p className="text-sm text-elr-black mb-2 md:mb-5">
                  Support name
                </p>
                <p className="text-sm text-elr-black opacity-60">
                  Errandlr Support
                </p>
              </div>

              <div className="md:w-4/12 ml-16 md:ml-0">
                <p className="md:ml-0 text-sm text-elr-black mb-2 md:mb-5">
                  Email Address
                </p>
                <p className="md:ml-0 text-sm text-elr-black opacity-60">
                  support@errandlr.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarMenu>
  </div>
);

export default PremiumSupport;
