import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpUpdateDispatcherProfile } from 'app/api/dispatcher';
import { CenterLogoHeader } from 'app/components/Header';
import { SaveForLater } from 'app/components/SaveForLaterTooltip';
import { useGetProfile } from 'app/hooks/user';
import { CompanyDocuments } from 'app/utilities/types/dispatcherTypes';
import { FileStructureResponse } from 'app/utilities/types/shared';
import omit from 'ramda/src/omit';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrLeftArrowButton,
  ElrPageTitle,
  ElrUploadInput,
} from 'ui-components';

interface Props {
  stepIndex: number;
  onChangeStep: (index: number) => void;
  pendingRequirements?: boolean;
}

const CompanyDocument: React.FC<Props> = ({
  stepIndex,
  onChangeStep,
  pendingRequirements,
}) => {
  const queryClient = useQueryClient();
  const {
    id: userId,
    accountInformation: { id: dispatcherId, ...account },
  } = useGetProfile();

  const [filesUploaded, setFileUploaded] = useState<{
    cac?: FileStructureResponse;
  }>({
    cac: account.certificateOfIncorporation,
  });

  const [savingForLater, setSaveForLater] = useState<boolean>(false);
  const tags = ['cac'];

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: (payload: CompanyDocuments) =>
      httpUpdateDispatcherProfile(payload, userId),
    onSuccess(data) {
      queryClient.setQueryData(['user'], (oldData: object | undefined) =>
        oldData ? { ...oldData, accountInformation: data } : {}
      );
    },
  });

  const onUploaded = (payload: FileStructureResponse, tag: string): void => {
    setFileUploaded({ [tag]: payload });
  };

  const uploadedKeys = Object.keys({
    ...(filesUploaded.cac && {
      cac: filesUploaded.cac,
    }),
  });

  const onSaveForLater = () => {
    setSaveForLater(true);
    if (uploadedKeys.length >= 1) {
      // upload here
      makeUpdate();
    }
  };

  const makeUpdate = async () => {
    try {
      const { cac } = filesUploaded;

      const payload = {
        ...(cac && { certificateOfIncorporation: omit(['_id'], cac) }),
        dispatcherId,
      };
      await mutateAsync(payload);
    } catch (error) {
      /** noop */
    }
  };

  const cacFormUploadedFileName =
    account?.certificateOfIncorporation?.originalFilename;

  const hasUploadedAllToDb = Boolean(cacFormUploadedFileName);

  const onGotoNext = async () => {
    if (uploadedKeys.length >= 1) await makeUpdate();
    onChangeStep(stepIndex + 1);
  };

  const requiredUploadLength = 1; // certificate

  const nextBtnDisabled = () =>
    isPending ||
    (uploadedKeys.length < requiredUploadLength && !savingForLater) ||
    (!hasUploadedAllToDb && !uploadedKeys.length && !savingForLater);

  return (
    <div>
      <ElrPageTitle title="Errandlr - Bank-Information" />
      <div className="flex flex-col items-center">
        <CenterLogoHeader />
        <div className="flex flex-col w-11/12 h-full mb-12 px-7 md:px-10 md:w-430 bg-elr-white-400 rounded-2xl">
          <div className="mb-16 text-xl text-center mt-14">
            Company Documentation
          </div>
          <div>
            <ElrUploadInput
              type="file"
              category="document"
              onUploaded={onUploaded}
              isValid={isError || Boolean(cacFormUploadedFileName)}
              tag={tags[0]}
              accept=".jpg,.jpeg,.png,.pdf,.docx,.doc"
              placeholder={cacFormUploadedFileName || 'CAC Document*'}
            />
          </div>
          <div className={`${pendingRequirements && 'mt-14'}`}>
            {!pendingRequirements && (
              <SaveForLater
                isLoading={isPending}
                onSaveForLater={onSaveForLater}
              />
            )}
            <div className="w-full mb-10 border-b border-elr-gray-600 md:mb-12 opacity-60" />
            <div
              className={`flex ${
                pendingRequirements ? 'justify-end' : 'justify-between'
              }`}
            >
              {!pendingRequirements && (
                <div
                  onClick={() => onChangeStep(stepIndex - 1)}
                  className="rounded-full h-9 w-9 mr-4 pl-2 pl-2.5"
                >
                  <ElrLeftArrowButton />
                </div>
              )}
              <div>
                <ElrButton
                  text={`${pendingRequirements ? 'Complete' : 'Next'}`}
                  disabled={nextBtnDisabled()}
                  className="w-40 mb-3 text-lg text-white bg-elr-black"
                  onClick={onGotoNext}
                />
              </div>
            </div>
            <div className="mb-10 md:mb-12" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CompanyDocument);
