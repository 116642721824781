import countries, { CountryProperty } from 'country-codes-list';
import { parseDomain } from 'parse-domain';

export const useSubdomain = () => {
  // @ts-ignore
  const { subDomains, domain, topLevelDomains } = parseDomain(
    window.location.hostname
  );
  const whiteListedSubdomains = ['purple', 'www'];
  const subdomain =
    subDomains && subDomains.filter((s: string) => s && s !== 'www');
  if (subdomain && !whiteListedSubdomains.includes(subdomain[0])) {
    const topLevelDomain = topLevelDomains?.join('.');

    return {
      subdomain: subdomain.join('.').replace('uk', 'gb'),
      domain,
      topLevelDomain,
      hasSubdomain: Boolean(subdomain.length),
    };
  }
  return {};
};

export const useGetDomainCountry = () => {
  const DEFAULT_COUNTRY_CODE = 'NG';
  const { subdomain, hasSubdomain } = useSubdomain();
  const ct = countries.findOne(
    'countryCode' as CountryProperty,
    hasSubdomain ? subdomain.toUpperCase() : DEFAULT_COUNTRY_CODE
  );
  return ct.countryNameEn.toLowerCase();
};
