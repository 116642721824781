import { SENDER } from 'app/utilities/roles';
import { DestinationInterface } from 'app/utilities/types/shared';
import React from 'react';

interface IBeneficiaries {
  beneficiaries: { data: DestinationInterface }[];
  setValues: any;
  selectFriend: (setValues: () => void, data: any) => void;
  type?: string;
}

export const Beneficiaries: React.FC<IBeneficiaries> = ({
  beneficiaries,
  setValues,
  selectFriend,
  type = SENDER,
}) => (
  <div className="h-96 overflow-y-auto">
    {beneficiaries.length ? (
      beneficiaries.map((beneficiary) => (
        <div
          key={`${beneficiary.data.phone}${beneficiary.data.name}`}
          onClick={() => selectFriend(setValues, beneficiary.data)}
          className="py-5 px-5 hover:bg-elr-gray-500 cursor-pointer"
        >
          <h4 className="text-xl opacity-60">{beneficiary.data.name}</h4>
          <p className="opacity-60">
            {beneficiary.data?.state || beneficiary.data.address.fullAddress}
          </p>
        </div>
      ))
    ) : (
      <p className="text-elr-black text-opacity-60 text-center">
        No {type} added yet!
      </p>
    )}
  </div>
);

export default Beneficiaries;
