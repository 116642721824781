import { useGetTrackingId } from 'app/hooks/utils';
import { formattedDate } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import { CancelRequestTypes, RequestTypes } from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import { ElrCopy } from 'ui-components/components/ElrCopy/ElrCopy';
import CancelDeliveryModal from './CancelDeliveryModal';
import TipModal from './TipModal';

export const CancelOrderBtn: React.FC<{
  trackingId: string;
  onSubmit: () => void;
  onTrackUpdate?: boolean;
  loading: boolean;
  cancelConfirm: React.Dispatch<React.SetStateAction<string>>;
}> = ({ trackingId, onSubmit, cancelConfirm, onTrackUpdate, loading }) => {
  const trackingUID = useGetTrackingId(trackingId);

  return (
    <div
      className={`mt-16 ${
        onTrackUpdate ? 'w-350' : 'w-11/12'
      } mx-auto md:w-600 h-72 bg-elr-white-400 border border-elr-green border-opacity-30 rounded flex flex-col items-center`}
    >
      <div className="mb-5 text-base mt-9 md:text-sm opacity-60">
        Please confirm cancellation
      </div>
      <div className="mb-6 text-3xl md:mb-5 md:text-4xl"> Cancel Order </div>
      <div className="mb-6 text-base md:text-sm opacity-60 md:mb-8">
        Delivery ID : {trackingUID}
      </div>
      <ElrButton
        text="Confirm"
        loading={loading}
        className="w-40 mb-3 text-lg text-white bg-elr-black"
        onClick={onSubmit}
      />
      <div
        onClick={() => cancelConfirm('cancel')}
        className="text-xs text-opacity-50 cursor-pointer text-elr-purple"
      >
        No thanks, I&apos;ll keep my order
      </div>
    </div>
  );
};

const cancelFunctionality = ({
  userID,
  containerClassName,
  childClassName,
  onCancelClicked,
  canCancel,
}: {
  userID: string;
  containerClassName: string;
  childClassName: string;
  onCancelClicked: () => void;
  canCancel: boolean;
}) => {
  if (!userID) return <p className={childClassName}>Login to cancel order</p>;
  if (canCancel)
    return (
      <ElrButton
        className={containerClassName}
        text="Cancel request"
        onClick={onCancelClicked}
      />
    );
  return null;
};

export const CancelRequest: React.FC<{
  showCancelAndTipBtn: boolean;
  trackingId: string;
  orderStatus: string;
  onTrackUpdate?: boolean;
  userID: string;
  onSubmit: React.Dispatch<React.SetStateAction<string>>;
  createdAt: string;
  loading: boolean;
  directCancelFn: () => void;
  setCancelReason: React.Dispatch<React.SetStateAction<string>>;
  cancelReason: string;
  requestOpenedBy: string;
}> = ({
  trackingId,
  onSubmit,
  showCancelAndTipBtn,
  orderStatus,
  onTrackUpdate,
  createdAt,
  loading,
  userID,
  directCancelFn,
  setCancelReason,
  cancelReason,
  requestOpenedBy,
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showTipModal, setShowTipModal] = useState(false);
  const trackingUID = useGetTrackingId(trackingId);

  const canCancel =
    showCancelAndTipBtn && Boolean(userID) && requestOpenedBy === userID;

  const onCancelClicked = () => {
    if (orderStatus === RequestTypes.accepted || RequestTypes.assigned) {
      setShowCancelModal(true);
    } else {
      onSubmit('confirmCancel');
    }
  };

  return (
    <div>
      <CancelDeliveryModal
        onCancelDelivery={directCancelFn}
        text={CancelRequestTypes.partial_refund}
        onClose={() => setShowCancelModal(false)}
        isOpen={showCancelModal}
        loading={loading}
        reason={cancelReason}
        setReason={setCancelReason}
      />
      <TipModal
        isOpen={showTipModal}
        onClose={() => setShowTipModal(false)}
        trackingId={trackingUID}
      />
      {onTrackUpdate ? (
        <div className="mt-16 border rounded-md w-350 h-58 md:w-600 bg-elr-white-400 border-opacity-40 border-elr-green">
          <div className="pt-12 text-3xl text-center">
            {`Order ${orderStatus}`}
          </div>
          <div className="pt-5 text-sm text-center opacity-50">
            Delivery ID: {trackingUID}
          </div>
          <div className="flex flex-row items-center justify-center pt-2 md:pt-3 gap-x-2">
            <p className="opacity-50 text-12">
              Order created: {formattedDate(createdAt, 'dd/MM/yyyy hh:mm a')}
            </p>
          </div>
          <div className="flex items-center justify-center h-20 gap-2 px-4 ">
            {showCancelAndTipBtn && (
              <ElrButton
                text="Tip Rider"
                className="text-base text-white min-h-2 bg-elr-black"
                onClick={() => setShowTipModal(true)}
              />
            )}
            {cancelFunctionality({
              userID,
              containerClassName: 'min-h-2 border-[1px] text-base',
              childClassName:
                'text-base text-center text-elr-black text-opacity-40 whitespace-nowrap cursor-pointer',
              onCancelClicked,
              canCancel,
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-16 border rounded md:w-600 h-72 bg-elr-white-400 border-elr-green border-opacity-30">
          <div className="mb-5 text-base mt-9 md:text-sm opacity-60">
            We&apos;re working on your delivery request
          </div>
          <div className="mb-6 text-3xl md:mb-5 md:text-4xl">
            Your dispatch details
          </div>
          <div className="flex items-center gap-1 mb-6 md:gap-4 md:mb-8">
            <p className="md:text-base text-10 opacity-60">
              Delivery ID : {trackingUID}
            </p>
            <ElrCopy content={trackingUID} />
          </div>

          <Link
            to={`${publicRoutes.trackParcelUpdate}?trackingId=${trackingUID}`}
          >
            <ElrButton
              text="Track parcel"
              className="w-40 mb-3 text-lg text-white bg-elr-black"
            />
          </Link>
          {showCancelAndTipBtn &&
            (userID ? (
              <div
                className="text-xs text-opacity-50 cursor-pointer text-elr-purple"
                onClick={onCancelClicked}
              >
                Cancel order
              </div>
            ) : (
              <div className="mt-4 text-xs text-center text-opacity-50 text-elr-purple">
                Login to cancel order
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
