import { getCurrentCountry, getDefaultState } from 'app/utilities/country';
import { capitalize } from 'lodash';

export const sampleData = [
  {
    SenderName: 'Errandlr Enterprise.',
    SenderPhone: '+234 7000 000 000',
    SenderEmail: 'test@email.com',
    SenderCountry: capitalize(getCurrentCountry()),
    SenderState: capitalize(getDefaultState()),
    ReceiverAddress: 'Lekki Phase 1 106104, Lekki, Lagos, Nigeria',
    ReceiverPhone: '+234 7000 000 000',
    ReceiverName: 'Chiamaka John Doe',
    ReceiverCountry: capitalize(getCurrentCountry()),
    ReceiverState: capitalize(getDefaultState()),
    PickupAddress: '12 Ikeja Street, Off Ikeja road, Lagos',
    PackageType: 'Letters',
    PackageValue: '1000',
    AdditionalNote:
      'This is the additional note when a customer has something really long to add',
  },
];

export const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';

export const dataKeys = Object.keys(sampleData[0]);
