import { httpDeleteAgent } from 'app/api/agent';
import React from 'react';
import { ElrModal } from 'ui-components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCompleted: () => void;
  userId: string;
  agentId: string;
}

const deleteTheAgent = async (
  userId: string,
  agentId: string,
  onCompleted: () => void
) => {
  const response = await httpDeleteAgent(userId, agentId);
  response && onCompleted();
};

const DeleteAgent: React.FC<Props> = ({
  isOpen,
  onClose,
  onCompleted,
  userId,
  agentId,
}) =>
  isOpen ? (
    <ElrModal height={'200px'} isOpen={isOpen} onClose={onClose}>
      <div className="pb-5">
        <h2 className="text-center mb-8">Delete Agent</h2>
        <p>Are you sure you want to delete this agent?</p>
        <div className="flex justify-between mt-10">
          <button
            onClick={() => deleteTheAgent(userId, agentId, onCompleted)}
            type="button"
            className="text-elr-green"
          >
            Yes
          </button>
          <button onClick={onClose} type="button">
            Cancel
          </button>
        </div>
      </div>
    </ElrModal>
  ) : null;

export default DeleteAgent;
