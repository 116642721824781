export const SENDER = 'sender';
export const DISPATCHER = 'dispatcher';
export const DISPATCHER_ONLY = 'dispatcherOnly';
export const COMMERCE = 'commerce';
export const AFFILIATE = 'affiliate';
export const SUPPORT = 'support';
export const RIDER = 'rider';
export const BUSINESS = 'business';
export const AGENT = 'agent';
export const OPERATOR = 'operator';
