import { Roles } from 'app/hooks/user';
import { axiosDefault as axios } from 'app/utilities/axios';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { toast } from 'react-toastify';

export const httpUserProfile = async (access?: Roles) => {
  try {
    const response = await axios.get('/user/profile', {
      params: {
        access,
      },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    // logout user on fail here
    return null;
  }
};

export const httpForgotPassword = async (email: string) => {
  try {
    const response = await axios.post(
      '/auth/forgot-password',
      { email },
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpResetPassword = async (password: string, token?: string) => {
  try {
    const response = await axios.post(
      `/auth/reset-password?token=${token}`,
      { password },
      SupportHeader()
    );
    toast.success('Password reset successful');
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpUpdatePassword = async (
  payload: { password: string; confirmPassword: string },
  userId: string
) => {
  try {
    const response = await axios.post(
      `/auth/${userId}/update-password`,
      payload,
      SupportHeader()
    );
    toast.success('Password change successful');
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};
