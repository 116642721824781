/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ElrButton } from 'ui-components';
import FocusIcon from '../../../assets/images/focus-3-line.svg';
import BoxLineIcon from '../../../assets/images/box-3-line.svg';
import PanthonLineIcon from '../../../assets/images/pantone-line.svg';

interface APIBenefitsProps {
  icon: string;
  title: string;
  description: string;
}

const APIBenefits: React.FC<APIBenefitsProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="flex flex-col items-center bg-elr-gray justify-center py-10 px-8 rounded-md">
    <div className="md:p-6 rounded-full p-5 flex justify-center bg-white">
      <img
        src={icon}
        alt={title}
        className="w-[38px] h-[38px] md:w-[48px] md:h-[48px]"
      />
    </div>
    <p className="md:text-3xl text-2xl text-center mt-10 w-8/12 md:w-10/12">
      {title}
    </p>
    <p className="text-elr-black-300 md:text-xl text-lg mt-4 text-center w-9/12 md:w-10/12">
      {description}
    </p>
  </div>
);

const CustomLogisticSection: React.FC = () => {
  const handleAPIQuickstart = () => {
    window.open('https://errandlr.stoplight.io/', '_blank');
  };

  const apiBenefits = [
    {
      icon: BoxLineIcon,
      title: 'Process single and batch deliveries',
      description:
        'Errandlr API allows you to process deliveries in the most convenient and efficient way.',
    },
    {
      icon: FocusIcon,
      title: 'Monitor and track delivery status',
      description:
        'Following up with orders and their delivery progress is made to available to you.',
    },
    {
      icon: PanthonLineIcon,
      title: 'Customize delivery for your customers',
      description:
        'There is a suitable option for every of your customer delivery needs.',
    },
  ];
  return (
    <div className="bg-white flex flex-col md:flex-col py-24 md:py-48 px-5 md:px-20 lg:px-40">
      <p className="text-4xl mx-auto md:w-full w-11/12 md:text-5xl text-center">
        Request Custom Logistics with Errandlr APIs
      </p>
      <p className="w-9/12 md:text-2xl text-center text-lg md:w-6/12 text-elr-black-300 mx-auto pt-6">
        Access well-documented APIs to integrate seamless checkout into your
        commerce service.
      </p>
      <div className="md:mt-16 mt-10 flex flex-wrap items-center justify-center md:gap-8 gap-6">
        {apiBenefits.map((benefits) => (
          <div key={benefits.title} className="w-full md:w-[30%]">
            <APIBenefits
              icon={benefits.icon}
              title={benefits.title}
              description={benefits.description}
            />
          </div>
        ))}
      </div>
      <div className="relative flex justify-center md:mt-16 mt-10 w-full">
        <ElrButton
          text="API Quickstart"
          className="bg-elr-black text-white px-14 md:mb-8 w-full md:w-auto"
          onClick={handleAPIQuickstart}
        />
      </div>
    </div>
  );
};

export default CustomLogisticSection;
