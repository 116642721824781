import { Roles } from 'app/hooks/user';
import { axiosDefault as axios } from 'app/utilities/axios';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { toast } from 'react-toastify';
import { FinanceAggregate, GetFinanceIncome, RequestPayoutResponse } from './d';
import { PayoutResponse } from './types';

export const httpGetFinancialIncome = async (
  userId: string,
  role: Roles
): Promise<GetFinanceIncome | null> => {
  try {
    const response = await axios.get(
      `/${role}/${userId}/get-financial-records`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const httpRequestPayout = async (
  userId: string,
  role: Roles
): Promise<RequestPayoutResponse> => {
  try {
    const url: { [key: string]: string } = {
      dispatcher: `/finance/${userId}/request-payout`,
    };
    const response = await axios.post<{}, PayoutResponse>(
      url[role],
      {},
      SupportHeader()
    );
    toast.success(response?.data?.message);
    return [response?.data?.message, null];
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    return [null, error.response?.data?.message];
  }
};

export const httpFinanceAggregate = async (
  userId: string,
  role: Roles
): Promise<FinanceAggregate> => {
  try {
    const response = await axios.get(
      `/${role}/${userId}/get-finance-aggregate`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    return [];
  }
};
