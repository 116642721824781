import successIcon from 'assets/images/success-icon.svg';
import errorIcon from 'assets/images/error-icon.svg';
import { useState } from 'react';
import { ElrButton } from 'ui-components';

const PaymentGateWayResponse = () => {
  const [paymentSuccessful] = useState(true);
  return (
    <div className="flex items-center bg-white h-full justify-center">
      <div className="bg-elr-gray rounded-3xl w-11/12 md:w-10/12 h-5/6 flex items-center justify-center">
        {paymentSuccessful ? <SuccessfulTransaction /> : <FailedTransaction />}
      </div>
    </div>
  );
};

export default PaymentGateWayResponse;

const SuccessfulTransaction = () => {
  const onDismiss = () => {};

  return (
    <div className="flex flex-col justify-center items-center md:w-80 w-full mx-4 md:mx-0 h-80 bg-white rounded-lg p-4">
      <img src={successIcon} className="w-20 h-20" />
      <div className="flex flex-col items-center mt-8">
        <div className="text-lg text-elr-black">Payment Successful</div>
        <p className="text-elr-black-300 text-base text-center pt-0.5">
          You have succesfully funded your wallet
        </p>
      </div>
      <ElrButton
        text="Done"
        size="sm"
        className="bg-elr-black text-white disabled:bg-elr-gray-neutral-300 text-sm w-full mt-8 py-3.5"
        onClick={onDismiss}
      />
    </div>
  );
};

const FailedTransaction = () => {
  const onRetry = () => {};
  const onClose = () => {};
  return (
    <div className="flex flex-col justify-center items-center md:w-80 w-full mx-4 md:mx-0 h-80 bg-white rounded-lg">
      <img src={errorIcon} className="w-20 h-20" />
      <div className="flex flex-col items-center mt-8">
        <div className=" text-lg text-elr-black">Not Successful</div>
        <p className="text-elr-black-300 text-base text-center pt-0.5">
          Your payment could not be processed. <br /> Please check and try
          again.
        </p>
      </div>
      <div className="flex gap-4">
        <ElrButton
          text="Close"
          size="sm"
          className="bg-elr-gray text-black disabled:bg-elr-gray-neutral-300 text-sm w-full mt-8 py-3.5"
          onClick={onClose}
        />
        <ElrButton
          text="Retry"
          size="sm"
          className="bg-elr-black text-white disabled:bg-elr-gray-neutral-300 text-sm w-full mt-8 py-3.5"
          onClick={onRetry}
        />
      </div>
    </div>
  );
};
