import { EachAvailabilityInterface } from 'app/utilities/types/dispatcherTypes';
import { DateTime } from 'luxon';
import React from 'react';
import { ElrSelect } from 'ui-components/components/ElrSelect/ElrSelect';
import * as styles from './ClassUtils';

interface TimeProps {
  value: string;
  label: string;
}

const times: TimeProps[] = [];
let start = DateTime.fromObject({ hour: 8, minute: 0 });
const end = '17:0';
let timeStr: string;
while (`${start.hour}:${start.minute}` !== end) {
  timeStr = start.toLocaleString({ ...DateTime.TIME_SIMPLE }).toLowerCase();
  times.push({
    value: timeStr,
    label: timeStr,
  });
  start = start.plus({ hour: 1 });
}

const formatToTime = (timeRaw: string) => {
  const formatted = DateTime.fromFormat(timeRaw, 'T')
    .toLocaleString({ ...DateTime.TIME_SIMPLE })
    .toLowerCase();

  return {
    value: formatted,
    label: formatted,
  };
};

type SetTime = (type: 'from' | 'to', time: string) => void;

interface TimeDropdownOptions {
  availability: EachAvailabilityInterface;
  setTime: SetTime;
}

interface TimeOptions {
  type?: 'from' | 'to';
  isAvailable?: boolean;
  selectedTime: string;
  setTime: SetTime;
}

const TimeDropdown: React.FC<TimeDropdownOptions> = ({
  availability,
  setTime,
}) => (
  <>
    <div className={styles.timeType(availability.isAvailable)}>
      <p className="mr-2 text-elr-black text-opacity-60">From</p>
      <Time
        selectedTime={availability.from}
        isAvailable={availability.isAvailable}
        setTime={setTime}
      />
    </div>
    <div className={styles.timeType(availability.isAvailable)}>
      <p className="mr-2 text-elr-black text-opacity-60">To</p>
      <Time
        selectedTime={availability.to}
        isAvailable={availability.isAvailable}
        type="to"
        setTime={setTime}
      />
    </div>
  </>
);

const Time: React.FC<TimeOptions> = ({
  type = 'from',
  isAvailable,
  selectedTime,
  setTime,
}) => {
  const defaultTime = type === 'to' ? times[times.length - 1] : times[0];

  const onChange = (time: { value: string }) => {
    const timeString =
      time.value.split(':')[0].length < 2 ? `0${time.value}` : time.value;
    setTime(type, timeString);
  };

  return (
    <div>
      <ElrSelect
        isSearchable={false}
        isClearable={false}
        options={times}
        value={formatToTime(selectedTime) || defaultTime}
        showIndicator
        controlHeight={30}
        containerHeight={35}
        bgcolor="#FFF"
        className="px-1 mb-0 text-sm md:text-base"
        controlBackgroundColor="transparent"
        {...{ onChange, isDisabled: !isAvailable }}
      />
    </div>
  );
};

export default TimeDropdown;
