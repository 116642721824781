interface SliderProps {
  images: {
    img: string;
    alt: string;
  }[];
}

export const ElrSlider: React.FC<SliderProps> = ({ images }) => (
  <div className="overflow-hidden md:overflow-auto ">
    <div className="flex justify-between py-16 md:w-auto w-[calc(112px*5)] animate-scroll md:animate-none">
      {images.map(({ img, alt }) => (
        <img src={img} alt={alt} key={img} className="w-28" />
      ))}
    </div>
  </div>
);
