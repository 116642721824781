import React from 'react';
import { ElrMainSelect } from 'ui-components';

export type RequestTypeOnchangeType = { value: string; label: string };

interface Props {
  requestType: RequestTypeOnchangeType;
  onChange: (value: any) => void;
  displayAdditionalFilterOptions?: boolean;
}

const datum = (displayAdditionalFilterOptions: boolean) => {
  const dt = ['Marketplace'];
  if (displayAdditionalFilterOptions) dt.push('Quote');
  return dt;
};

const RequestTypeDropdown: React.FC<Props> = ({
  requestType,
  onChange,
  displayAdditionalFilterOptions = false,
}) => (
  <div className="flex items-center text-elr-black">
    <span className="opacity-60"> Type </span>
    <span className="mr-4 ml-1.5">
      <ElrMainSelect
        data={datum(displayAdditionalFilterOptions)}
        // @ts-ignore
        containerHeight="30px"
        isSearchable={false}
        isClearable={false}
        containerBackgroundColor="#FFFFFF"
        controlBackgroundColor="transparent"
        controlHeight="28px"
        minHeight="0"
        className="w-[135px] mt-4 border md:z-20 border-opacity-60 border-elr-green rounded-md"
        placeholder="Select"
        boxShadowStyle="0 0 14px rgba(0, 0, 0, 0.274)"
        showIndicator
        onChange={onChange}
        currentValue={requestType.label}
        value={requestType}
      />
    </span>
  </div>
);

export default RequestTypeDropdown;
