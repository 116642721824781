import ShareIcon from '@material-ui/icons/Share';
import { ERRANDLR_REDIRECT_URL } from 'app/constants';
import { useLogout } from 'app/hooks/auth';
import { useGetProfile } from 'app/hooks/user';
import copyToClipboard from 'app/utilities/copyToClipboard';
import {
  canOperateInCountryAndState,
  isDispatcherPlatform,
} from 'app/utilities/helpers';
import mappedNavigation from 'app/utilities/navigations';
import { privateRoutes } from 'app/utilities/routes';
import copy from 'assets/images/copy.svg';
import newIcon from 'assets/images/newIcon.svg';
import truncate from 'lodash/truncate';
import React from 'react';
import { Link } from 'react-router-dom';
import DynamicIconDropDownMenu from '../DynamicIconDropDownMenu';
import { UnmanagedAgentsIcon } from './misc';

interface Props {
  containerClass?: string;
  showUnassignedAgents?: () => void;
  unmanagedAgentsCount?: number;
}

const TopNavigationMenu: React.FC<Props> = ({
  containerClass = '',
  showUnassignedAgents,
  unmanagedAgentsCount,
}) => {
  const [sendLogout] = useLogout();
  const {
    firstname = '',
    role,
    currentAccessRole,
    accountInformation: { areasOfOperations },
  } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const navigations = mappedNavigation(currentRole);
  const navRoute = (navigation: { title: string; route: string }) =>
    navigation.title === 'Settings'
      ? `/dispatcher/${privateRoutes.profile}`
      : navigation.route;

  return (
    <div
      className={`hidden md:flex items-center justify-end md:mx-10 ${containerClass} my-6`}
    >
      {isDispatcherPlatform(currentRole) && (
        <RoleLinkActions
          topClassNames="mr-8 relative"
          sectionClassNames="absolute bg-white -translate-x-52 rounded-lg w-60 p-5 mt-2 z-100 shadow-xl"
        />
      )}

      {isDispatcherPlatform(currentRole) &&
      canOperateInCountryAndState(areasOfOperations) ? (
        <UnmanagedAgentsIcon
          onClick={showUnassignedAgents}
          unmanagedAgentsCount={unmanagedAgentsCount}
        />
      ) : null}

      <div className="mr-8 opacity-60">
        <Link to={privateRoutes.personalSupport}>Help</Link>
      </div>
      <div className="flex">
        <span className="opacity-60">
          {truncate(firstname, { length: 10 })}
        </span>
        <DynamicIconDropDownMenu
          containerStyles="border-05 border-elr-green bg-white border-opacity-40 z-100"
          transformPositionClass="-translate-x-24"
        >
          <ul className="list-none bg-white text-elr-black text-opacity-40 text-sm">
            {navigations.map((navigation) => (
              <Link key={navigation.route} to={navRoute(navigation)}>
                <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                  {navigation.title}
                </li>
              </Link>
            ))}
            <Link to={`/${currentRole}/${privateRoutes.bulkEstimate}`}>
              <li className="cursor-pointer px-1 m-2 w-24 leading-7 flex hover:bg-elr-green hover:bg-opacity-10">
                Bulk Order
                <span>
                  <img src={newIcon} alt="new icon" />
                </span>
              </li>
            </Link>
            <Link to={privateRoutes.selectPortal}>
              <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                Select Portal
              </li>
            </Link>
            <div onClick={sendLogout}>
              <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                Logout
              </li>
            </div>
          </ul>
        </DynamicIconDropDownMenu>
      </div>
    </div>
  );
};

type RoleLinkMenu = {
  topClassNames: string;
  sectionClassNames: string;
};
export const RoleLinkActions: React.FC<RoleLinkMenu> = ({
  topClassNames,
  sectionClassNames,
}) => {
  const {
    accountInformation: { slug = '' },
  } = useGetProfile();
  const [showHiddenText, toggleHidden] = React.useState(false);
  return (
    <div className={topClassNames}>
      <span className="cursor-pointer" onClick={() => toggleHidden((a) => !a)}>
        <ShareIcon fontSize="medium" role="button" />
      </span>

      {showHiddenText && (
        <div className={sectionClassNames}>
          <p className="text-elr-black text-medium">Booking Link</p>
          <p className="text-elr-purple break-words text-sm my-2">
            {`${ERRANDLR_REDIRECT_URL}/${slug}`}
          </p>
          <button
            className="flex items-center focus:opacity-60 bg-elr-gray-600 bg-opacity-60 px-2 py-1"
            type="button"
            onClick={() => copyToClipboard(`${ERRANDLR_REDIRECT_URL}/${slug}`)}
          >
            <span className="mr-4 text-elr-black text-opacity-60  text-sm">
              Copy link
            </span>
            <img alt="copy" width="14px" src={copy} />
          </button>
        </div>
      )}
    </div>
  );
};

export default TopNavigationMenu;
