import QRCodeIcon from '../../../assets/images/qr-code-purple.svg';
import WalletIcon from '../../../assets/images/wallet-line.svg';
import TableViewIcon from '../../../assets/images/table-view.svg';
import GallaryIcon from '../../../assets/images/gallery-view-2.svg';
import AlignIcon from '../../../assets/images/align-item.svg';
import LinkIcon from '../../../assets/images/links-line.svg';

interface EventBenefitsProps {
  icon: string;
  title: string;
  description: string;
  backgroundColor: string;
}

const EventBenefits: React.FC<EventBenefitsProps> = ({
  icon,
  title,
  description,
  backgroundColor,
}) => (
  <div className="flex flex-col items-start">
    <div
      className={`md:p-5 rounded-full p-4 flex justify-center`}
      style={{ backgroundColor }}
    >
      <img
        src={icon}
        alt={title}
        className="w-[38px] h-[38px] md:w-full md:h-full"
      />
    </div>
    <p className="md:text-3xl text-2xl mt-10 text-left w-10/12 md:w-11/12">
      {title}
    </p>
    <p className="text-elr-black-300 md:text-xl text-lg mt-4 text-left w-full md:w-11/12">
      {description}
    </p>
  </div>
);

const WhyErrandlr = () => {
  const eventsBenefits = [
    {
      icon: QRCodeIcon,
      title: 'Unique QR Code for Your Event',
      description:
        'A QR code is made for your event and this code can be shared with people for direct access to your event page.',
      backgroundColor: '#EFEDFE',
    },
    {
      icon: TableViewIcon,
      title: 'Easy Attendee Verification Process',
      description:
        'Each attendee gets a unique QR code and this code can be verified using the provided QR code scan link.',
      backgroundColor: '#F2F2F7',
    },
    {
      icon: GallaryIcon,
      title: 'Efficient Management of Ticket Sales',
      description:
        'You get to manage your event in real time and also set the ticket types, prices, and availability as desired.',
      backgroundColor: '#FFF8E1',
    },
    {
      icon: AlignIcon,
      title: 'Double Visibility for Your Event',
      description:
        'Our platform gives your event two access points: a web page and our mobile app ensuring seamless access.',
      backgroundColor: '#E6EDEE',
    },
    {
      icon: LinkIcon,
      title: 'Exclusive and Dedicated Event Page',
      description:
        'Your event gets a dedicated web page and its link can be copied and shared with desired people.',
      backgroundColor: '#F2F2F7',
    },
    {
      icon: WalletIcon,
      title: 'Smooth Cash Withdrawal Process',
      description:
        'Withdrawing cash made from ticket sales is hassle-free and takes just a few clicks.',
      backgroundColor: '#FFF2EA',
    },
  ];

  return (
    <div className="bg-white md:py-40 py-24 text-center">
      <h1 className="text-14 md:text-2xl leading-5 text-elr-green">
        Why Errandlr
      </h1>
      <div className="flex flex-col justify-center pt-4 pb-12 md:pt-6 md:pb-16">
        <h2 className="text-28 md:text-40 leading-tight font-bold w-full mx-auto md:w-8/12">
          Create Events Effortlessly with Our All-in-One Platform
        </h2>
        <div className="mt-16 flex flex-wrap justify-between gap-10">
          {eventsBenefits.map((service) => (
            <div key={service.title} className="w-full md:w-[30%]">
              <EventBenefits
                icon={service.icon}
                title={service.title}
                description={service.description}
                backgroundColor={service.backgroundColor}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyErrandlr;
