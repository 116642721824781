import { publicRoutes } from 'app/utilities/routes';
import { useNavigate } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import apiImage from '../../../assets/images/apiImage.png';

const ApiHeader = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate(publicRoutes.registration);
  };
  return (
    <div className="mx-5 mt-24 mb-3 md:mb-5 md:flex md:justify-center items-center align-middle md:px-20 lg:px-40">
      <div className="w-full flex flex-col">
        <p className="font-bold lg:text-6xl md:text-40 w-11/12 text-32 leading-10 md:leading-[52px] text-center md:text-left md:w-9/12 mx-auto md:mx-0">
          Create a World-
          <br />
          class <span className="text-elr-green">Post-purchase</span>{' '}
          Experience.
        </p>
        <p className="opacity-60 my-5 md:text-xl text-lg lg:w-8/12 md:w-8/12 text-center md:text-left">
          Errandlr APIs let you offload order fulfillment with scalable,
          customizable delivery services designed for developers.
        </p>

        <div className="relative flex justify-start md:mt-16 mt-10 w-full">
          <ElrButton
            text="Get Started"
            className="bg-elr-black text-white px-14 md:mb-8 mb-12 w-full md:w-auto"
            onClick={handleGetStarted}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <img className="w-[950px]" src={apiImage} alt="" />
      </div>
    </div>
  );
};
export default ApiHeader;
