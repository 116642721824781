import { axiosDefault as axios } from 'app/utilities/axios';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { toast } from 'react-toastify';
import {
  EventDataInterface,
  ListSoldTicketsParams,
  PurchaseTicketPayload,
  SoldTicketsResponse,
  TicketSalesDataInterface,
} from './types';

interface Params {
  eventUUId: string;
}

export const httpGetEvent = async ({
  eventUUId,
}: Params): Promise<EventDataInterface> => {
  try {
    const response = await axios.get(`/event/${eventUUId}`, {
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpCloseEvent = async ({
  eventId,
  roleId,
}: {
  eventId: string;
  roleId: string;
}): Promise<EventDataInterface> => {
  try {
    const response = await axios.delete(`/event/${eventId}`, {
      ...SupportHeader(),
      data: { roleId },
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpPurchaseTicket = async (
  payload: PurchaseTicketPayload
): Promise<any> => {
  try {
    const response = await axios.post(
      `/event/purchase-ticket`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetTicketSales = async ({
  eventId,
}: {
  eventId: string;
}): Promise<TicketSalesDataInterface[]> => {
  try {
    const response = await axios.get<TicketSalesDataInterface[]>(
      `/event/${eventId}/ticket-sales`,
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpToggleTicket = async (
  eventId: string,
  ticketType: string,
  ticketStatus: boolean
): Promise<{ ok: boolean }> => {
  try {
    const response = await axios.put(
      `/event/${eventId}/toggle-ticket`,
      {
        ticketType,
        ticketStatus,
      },
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetListSoldTickets = async ({
  eventId,
  page,
  limit,
}: ListSoldTicketsParams): Promise<SoldTicketsResponse[]> => {
  try {
    const response = await axios.get(`/event/${eventId}/list-sold-tickets`, {
      params: { page, limit },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetEventTicketLeft = async ({
  eventId,
  tickets,
}: {
  eventId: string;
  tickets: Array<{ id: string; quantity: number }>;
}): Promise<Array<{ type: string; remaining: number }>> => {
  try {
    const response = await axios.post(
      `/event/${eventId}/tickets-left`,
      tickets,
      {
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
