import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { Roles, useGetProfile } from 'app/hooks/user';
import { OperatorView } from 'app/utilities/ComponentView';
import { COMMERCE, DISPATCHER, SENDER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';

const Settings: React.FC = () => {
  const { currentAccessRole, role, isAnOperator } = useGetProfile();
  const currentRole = currentAccessRole || role;

  const navigation = (navType: Roles) => {
    switch (navType) {
      case COMMERCE:
        return (
          <>
            <Link to={`/${COMMERCE}/${privateRoutes.commerceSettings}`}>
              <p>Profile</p>
            </Link>
            <Link to={`/${COMMERCE}/${privateRoutes.company}`}>
              <p>Company</p>
            </Link>
            <Link to={`/${COMMERCE}/${privateRoutes.developer}`}>
              <p>API</p>
            </Link>
          </>
        );

      case DISPATCHER:
        return (
          <>
            <Link to={`/${DISPATCHER}/${privateRoutes.profile}`}>
              <p>Profile</p>
            </Link>
            <Link to={`/${DISPATCHER}/${privateRoutes.company}`}>
              <p>Company</p>
            </Link>
            <Link to={`/${DISPATCHER}/${privateRoutes.payment}`}>
              <p>Payments</p>
            </Link>
            <Link to={`/${DISPATCHER}/${privateRoutes.agents}`}>
              <p>Agents</p>
            </Link>
            <Link to={`/${DISPATCHER}/${privateRoutes.availability}`}>
              <p>Availability</p>
            </Link>
            <Link to={`/${DISPATCHER}/${privateRoutes.analytics}`}>
              <p>Analytics</p>
            </Link>
            <Link to={`/${DISPATCHER}/${privateRoutes.bulkEstimate}`}>
              <p>Bulk Estimate</p>
            </Link>
            <OperatorView isOperator={isAnOperator}>
              <Link to={`/${DISPATCHER}/${privateRoutes.partners}`}>
                <p>Dispatchers</p>
              </Link>
            </OperatorView>
          </>
        );

      case SENDER:
        return (
          <Link to={`/${SENDER}/${privateRoutes.profile}`}>
            <p>Profile</p>
          </Link>
        );

      default:
        return (
          <Link to={`/${SENDER}/${privateRoutes.profile}`}>
            <p>Profile</p>
          </Link>
        );
    }
  };

  return (
    <>
      <ElrPageTitle title="Errandlr - Settings" />
      <ProfileNavigationMenu>
        <TopNavigationMenu />
        <div className="h-screen ml-5 md:ml-20">
          <div className="py-6 text-xl">Settings</div>
          <div className="flex flex-col gap-y-2">{navigation(currentRole)}</div>
        </div>
      </ProfileNavigationMenu>
    </>
  );
};
export default Settings;
