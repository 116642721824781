export const DeliveryModeLabel = (checked: boolean) =>
  `flex flex-row justify-between w-full items-center gap-2 bg-elr-gray rounded-full px-3 py-2 ${
    checked ? 'border-2' : ''
  }`;

export const CategoryButton = (selected: boolean) =>
  `px-3 ${selected ? 'border-b-2 border-b' : 'opacity-60'}`;

export const MainContainer = (canAddProduct: boolean) =>
  `${canAddProduct ? 'flex flex-col md:flex-row md:h-screen' : ''}`;
export const DeliveryDetailsContainer = (canAddProduct: boolean) =>
  `flex flex-col items-center py-8 ${canAddProduct ? 'bg-white md:h-screen md:w-5/12' : 'h-screen'}`;

export const DeliveryDetailsInnerContainer = (canAddProduct: boolean) =>
  `px-4 py-5 w-366 ${canAddProduct ? 'mt-5' : 'mt-10 bg-white shadow-sm rounded-xl'}`;
