import { MAX_BULK_ORDER } from 'app/constants';
import * as Yup from 'yup';

export const addressValidations = {
  parcelCode: Yup.string().required('Required'),
  pickupLocation: Yup.object().shape({
    id: Yup.string().required('Required'),
    label: Yup.string().required('Required'),
  }),
  dropoffLocations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        label: Yup.string().required('Required'),
      })
    )
    .compact((value: any) => !value)
    .min(1, 'Please enter destination')
    .max(
      MAX_BULK_ORDER,
      `Cannot select more than ${MAX_BULK_ORDER} destinations`
    ),
};

export const estimateValidationSchema = Yup.object().shape(addressValidations);
