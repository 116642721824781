import React from 'react';

interface Props {
  src?: string;
  initials?: string;
  classes: string;
}

export const ProfileImage: React.FC<Props> = ({ src, initials, classes }) => (
  <div>
    <div
      className={`${classes} mr-3 bg-elr-gray border border-elr-green border-opacity-30 rounded-full`}
    >
      {src ? (
        <img src={src} alt="pic" />
      ) : (
        <span className="text-lg text-elr-green opacity-30"> {initials} </span>
      )}
    </div>
  </div>
);
