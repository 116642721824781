import * as Sentry from '@sentry/react';
import { NavBar } from 'app/components/menu/Navbar';
import logo from 'assets/images/logo.svg';
import React from 'react';

interface ErrorBoundaryProps {
  user: {
    id?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
  };
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    const { user } = props;

    user.email &&
      Sentry.setUser({
        ip_address: '{{auto}}',
        email: user.email,
        username: `${user.firstname} ${user.lastname}`,
        id: user.id,
      });
  }

  render() {
    return (
      <div className="m-auto">
        <NavBar>
          <div className="flex justify-between">
            <img src={logo} alt="site logo" className="h-6 md:h-9" />
            <div className="flex items-center text-elr-purple">
              <div className="w-1.5 h-1.5 rounded-full mr-1.5" />
              contact us
            </div>
          </div>
        </NavBar>
        <div className="text-center relative top-52">
          <div className="text-2xl">Sorry, An Unexpected Error Occured!</div>
          <div className="my-20 mx-auto w-10/12 text-3xl">
            We are now aware and working on it!
          </div>
          <div className="text-xm md:text-base">
            Return to
            <a className="text-elr-purple pl-1 underline" href="/">
              Homepage
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
