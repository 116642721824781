import { httpUploadFile } from 'app/api/utils';
import { useGetProfile } from 'app/hooks/user';
import truncate from 'lodash/truncate';
import { toast } from 'react-toastify';
import React, { useRef, useState } from 'react';
import errorIcon from 'assets/images/error-notify.svg';

interface Props {
  filesSupported: string;
  maxMBSize: string;
  className?: string;
  accept: string;
}

export const ElrProductUpload: React.FC<Props> = ({
  filesSupported,
  maxMBSize,
  accept,
  className = 'w-full h-24',
}) => {
  const [fileImage, setFileImage] = useState('');
  const uploadRef = useRef<HTMLInputElement>(null);
  const [upload, setUpload] = useState('');
  const [fileIdentifier, setFileIdentifier] = useState<string[]>([]);
  const [isError, setErrorUploading] = useState<boolean>(false);
  const [fileName, setInputFileName] = useState('');
  const [fileSize, setInputFileSize] = useState('');
  const [fileType, setInputFileType] = useState('');
  const { id: userId } = useGetProfile();

  const requiredFileExt = (file: string) => {
    if (!file.match(/\.(jpg|JPG|JPEG|PNG|jpeg|png|webp|WEBP)$/)) {
      return false;
    }
    return true;
  };

  const makeUploadFileRequest = async (file: {
    name: string;
    type: string;
  }) => {
    const isImage = file.type.includes('image') ? 'image' : null;
    const fileGroup = isImage || 'image';
    if (!requiredFileExt(file.name)) {
      toast.error('File not supported');
      return setFileIdentifier([]);
    }

    try {
      await httpUploadFile(userId, file, fileGroup);
    } catch (error: any) {
      setFileIdentifier([]);
    }
    return file;
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files || [];
    const fileValue = event.target.value;
    const files = Array.from(selectedFile);
    const maxSizeInBytes = Number(maxMBSize) * 1024 * 1024; // Max size of 3MB in Bytes
    setUpload(fileValue);
    if (!files.length) return;
    if (files[0]?.size > maxSizeInBytes) {
      setErrorUploading(true);
      return;
    }
    const selectedFileName = files[0]?.name;
    const cleanedFileType = files[0]?.type.replace('image/', '');
    const fileSizeInBytes = files[0]?.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    setFileIdentifier([fileValue]);
    setFileImage(URL.createObjectURL(files[0]));
    setInputFileSize(fileSizeInMB.toFixed(2));
    setInputFileType(cleanedFileType.toUpperCase());
    setInputFileName(selectedFileName);
    makeUploadFileRequest(files[0]);
  };

  const handleClick = () => {
    if (uploadRef.current !== null) {
      uploadRef.current.click();
    }
  };

  return (
    <div>
      {!fileIdentifier.length && (
        <div
          onClick={handleClick}
          className={`cursor-pointer border border-dashed border-elr-purple-300 bg-elr-gray ${className} flex flex-col justify-center items-center mb-5`}
        >
          <div className="text-elr-purple text-opacity-80 pb-1 pt-0.5 font-thin">
            Upload image
          </div>
          <p className="text-sm opacity-40">
            Files supported: {filesSupported}
          </p>
          <p className="text-sm opacity-40">Maximum size: {maxMBSize}MB</p>
        </div>
      )}
      {fileIdentifier.length > 0 && !isError && (
        <div className="cursor-pointer justify-between px-5 py-2 border border-dashed border-elr-purple-300 bg-elr-gray  w-full h-20 flex mb-5">
          <div className="flex gap-5">
            <img className="w-14 h-18" src={fileImage} alt="fileImage" />
            <div className="flex flex-col pt-3">
              <p>{truncate(fileName, { length: 28 })}</p>
              <div className=" text-elr-black-400 flex items-center gap-1.5 text-xs">
                <div>{`${fileSize} MB`}</div>
                <span className="bg-elr-black-400 rounded-full w-1.5 h-1.5 " />
                <div>{fileType}</div>
              </div>
            </div>
          </div>
          <div
            onClick={handleClick}
            className="pt-6 text-elr-purple text-opacity-80 text-sm"
          >
            Replace Image
          </div>
        </div>
      )}
      {isError && (
        <div
          className={`cursor-pointer border border-dashed border-elr-error bg-elr-gray ${className} flex flex-col justify-center items-center mb-5`}
        >
          <img src={errorIcon} alt="error-Icon" />
          <div className="text-elr-black-400 text-sm px-6 text-center pt-1">
            The file size is large. Click{' '}
            <span className="text-elr-black">here</span> to compress the image
            or <span className="text-elr-black">change image</span>
          </div>
        </div>
      )}

      <input
        accept={accept}
        ref={uploadRef}
        value={upload}
        type="file"
        className="hidden"
        onChange={onChangeInput}
      />
    </div>
  );
};
