import { formatDuration } from 'app/modules/dispatch/components/utils';
import { CustomAccessView } from 'app/utilities/ComponentView';
import { currencyFormatter } from 'app/utilities/helpers';
import { OPERATOR } from 'app/utilities/roles';
import { DestinationInterface, RequestTypes } from 'app/utilities/types/shared';
import tickIcon from 'assets/images/tickIcon.svg';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { ElrDropdownIconToggle, ElrModal, ElrPillButton } from 'ui-components';
import { ElrRemoveButton } from 'ui-components/components/ElrActionButtons';
import { dropdownWrapperStyle } from './ClassUtils';

interface Props {
  destinations: DestinationInterface;
  showRemoveButton?: boolean;
  index?: number;
  last?: number;
  batch?: boolean;
}

export const Destinations: React.FC<Props> = ({
  destinations,
  showRemoveButton = false,
  index,
  batch = false,
  last,
}) => {
  const [toggled, setToggleState] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const notLastItem = last !== index;
  const { address, name, phone, packageValue, deliveryNotes, status } =
    destinations;

  const toggleOption = () => {
    setToggleState(!toggled);
  };

  const deliveryStatus = (parcelStatus: string | undefined) => {
    if (parcelStatus === RequestTypes.completed) {
      return 'Delivered';
    }
    return capitalize(parcelStatus);
  };

  return (
    <div>
      <ElrModal
        height="h-200"
        width="w-304"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="flex flex-col gap-2 text-center">
          <h3>Are you sure you want to remove</h3>
          <span className="text-12 opacity-60">{address.fullAddress}</span>
          <p>from the deliveries</p>
        </div>
        <div className="flex justify-center gap-8 mt-10">
          <button type="button" onClick={() => setShowModal(false)}>
            Back
          </button>
          <ElrPillButton
            size="sm"
            text="Remove"
            className="bg-elr-black h-12 px-8 text-white text-base flex items-center justify-center ml-10"
            onClick={() => {}}
          />
        </div>
      </ElrModal>
      <div className="flex">
        <div className="flex flex-col">
          <span className="h-1.5 md:h-2.5 w-1.5 md:w-2.5 ml-0.5 md:ml-0 my-2 bg-elr-yellow" />
          {notLastItem && (
            <span className="h-7 md:h-5 mx-02 w-0.3 bg-elr-black bg-opacity-40" />
          )}
        </div>
        <div
          className="flex w-full ml-3 justify-between cursor-pointer"
          onClick={toggleOption}
        >
          <span className="text-12 opacity-60 w-4/6 pt-0.5">
            {address.fullAddress}
          </span>
          <div className="flex gap-5">
            {showRemoveButton && (
              <CustomAccessView showFor={[OPERATOR]}>
                <ElrRemoveButton onClick={() => setShowModal(true)} />
              </CustomAccessView>
            )}
            <span className="opacity-40 relative bottom-0.5">
              <ElrDropdownIconToggle
                isToggled={toggled}
                toggle={() => toggleOption}
                fontSize="small"
              />
            </span>
          </div>
        </div>
      </div>

      {toggled && (
        <div className="flex mx-02 h-full">
          {notLastItem && <span className="w-0.3 bg-elr-black bg-opacity-40" />}
          <div className={dropdownWrapperStyle(!notLastItem)}>
            {batch && (
              <div className=" flex justify-end mb-2">
                <div className="flex items-center text-elr-purple rounded-md bg-elr-purple-200 px-1 pt-0.5 gap-1">
                  <img
                    src={tickIcon}
                    alt="tickIcon"
                    className="relative bottom-0.5"
                  />
                  <span className="text-sm">{deliveryStatus(status)}</span>
                </div>
              </div>
            )}
            <div className="flex justify-between">
              <div className="text-12 opacity-60 md:w-8/12">
                <div className="flex flex-col md:flex-row">
                  <div className="w-full mb-3 md:w-8/12">{name}</div>
                  <div className="mb-3"> {phone} </div>
                </div>
                <div className="flex flex-col md:flex-row md:justify-between">
                  <p className="md:justify-end w-full md:text-right">
                    package value: {currencyFormatter(packageValue)}
                  </p>
                </div>
              </div>
              <div className="md:self-end text-sm md:mb-3 w-full md:w-auto text-right">
                <div className="mb-3 opacity-60">
                  Est time: {formatDuration(+address.duration)}
                </div>
                <span className="flex justify-around bg-elr-white-400 w-11 text-sm items-center ml-auto rounded-md">
                  <p className="h-1.5 w-1.5 bg-elr-yellow"> </p>
                  {address.distance}km
                </span>
              </div>
            </div>
            <div className="mt-2 text-12 opacity-60">
              <div className="flex md:flex-row justify-between flex-col-reverse">
                <p>Additional notes</p>
              </div>
              <p>{deliveryNotes}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
