import { httpUpdateCommerceSettings } from 'app/api/commerce';
import { httpUpdateWalletSettings } from 'app/api/wallet';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import { getNonEmptyValues } from 'app/utilities/helpers';
import React from 'react';
import { ElrPageTitle } from 'ui-components';
import LowBalanceAlert from './LowBalanceAlert';
import DirectBookingLink from './DirectBookingLink';
import BatchMode from './BatchMode';
import ChangePassword from './ChangePassword';

export interface SettingsValue {
  contactEmail: string;
  criticalAmount: number;
  warningAmount: number;
  contactEmails: string[];
  lowBalanceNotificationChannel: {
    email: boolean;
    webhook: boolean;
  };
  autoProcessBatch: boolean;
  lockerClient: boolean;
  directLinkSettings: {
    businessPays: boolean;
    activateDirectory: boolean;
  };
}

const Settings: React.FC = () => {
  const { id: userId, accountInformation } = useGetProfile();

  const updateWalletSettings = async (
    lowBalanceNotificationChannel: {
      email: boolean;
      webhook: boolean;
    },
    optionalValues: {
      warningAmount: number;
      criticalAmount: number;
      lowBalanceNotificationEmail: string[];
    }
  ) => {
    const payload = {
      lowBalanceNotification: {
        lowBalanceNotificationChannel,
        ...getNonEmptyValues(optionalValues),
      },
    };
    await httpUpdateWalletSettings({
      payload,
      userId,
      roleId: accountInformation.id,
    });
  };

  const handleLowBalance = (values: SettingsValue) => {
    const optionalValues = {
      warningAmount: values.warningAmount,
      criticalAmount: values.criticalAmount,
      lowBalanceNotificationEmail: values.contactEmails,
    };
    updateWalletSettings(values.lowBalanceNotificationChannel, optionalValues);
  };

  const handleToggle = (
    settingKey: keyof SettingsValue,
    setFieldValue: (valueKey: string, newValue: boolean) => void,
    values: SettingsValue
  ) => {
    setFieldValue(settingKey, !values[settingKey]);
    const updatedValues = {
      [settingKey]: !values[settingKey],
    };
    updateSettings(updatedValues);
  };

  const handlePlan = (
    setFieldValue: (field: string, value: boolean) => void,
    option: boolean,
    field: string,
    values: SettingsValue,
    updateSettings: (settings: SettingsValue) => void
  ) => {
    setFieldValue(field, option);
    const updatedValues: SettingsValue = {
      ...values,
      directLinkSettings: {
        ...values.directLinkSettings,
        businessPays: option,
      },
    };
    updateSettings(updatedValues);
  };

  const updateSettings = async (payload: {
    autoProcessBatch?: boolean;
    directLinkSettings?: {
      businessPays: boolean;
      activateDirectory: boolean;
    };
  }) => {
    await httpUpdateCommerceSettings(payload, userId);
  };

  const handleChangePassowrd = () => {};

  return (
    <div>
      <ElrPageTitle title="Settings" />
      <ProfileNavigationMenu>
        <TopNavigationMenu />
        <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
          <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
            <h3 className="pt-6 text-xl mb-2">Company Settings</h3>
            <p className="text-elr-black-300 text-base">
              Manage your preferences and security
            </p>
          </div>
          <div className="md:w-10/12 w-full flex flex-col gap-5 md:justify-start">
            <LowBalanceAlert handleLowBalance={handleLowBalance} />
            <DirectBookingLink
              handlePlan={handlePlan}
              updateSettings={updateSettings}
            />
            <BatchMode
              handleToggle={handleToggle}
              updateSettings={updateSettings}
            />
            <ChangePassword handleChangePassowrd={handleChangePassowrd} />
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default Settings;
