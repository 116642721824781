import React from 'react';
import LeftArrow from '../../../assets/images/arrowLeft.svg';
import RightArrow from '../../../assets/images/arrowRight.svg';

interface Props {
  isSubmit?: boolean;
  className?: string;
  size?: 'sm' | 'lg' | 'xm';
}

export const ElrLeftArrowButton: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className = '', size = 'lg', ...props }) => {
  const sizes = {
    lg: 'min-h-3',
    sm: 'min-w-8 min-h-2',
    xm: 'min-w-8 min-h-full',
  };

  return (
    <div className="justify-items-center bg-elr-gray w-12 h-12 rounded-full pl-4">
      <button
        {...props}
        type="button"
        className={`${className} ${sizes[size]} disabled:opacity-50 hover:opacity-70 focus:outline-none disabled:cursor-auto`}
      >
        <img src={LeftArrow} alt="left-arrow" />
      </button>
    </div>
  );
};

export const ElrRightArrowButton: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className = '', size = 'lg', ...props }) => {
  const sizes = {
    lg: 'min-h-3',
    sm: 'min-w-8 min-h-2',
    xm: 'min-w-8 min-h-full',
  };

  return (
    <div className="justify-items-center bg-elr-gray w-12 h-12 rounded-full pl-4">
      <button
        {...props}
        type="button"
        className={`${className} ${sizes[size]} disabled:opacity-50 hover:opacity-70 focus:outline-none disabled:cursor-auto`}
      >
        <img src={RightArrow} alt="right-arrow" />
      </button>
    </div>
  );
};
