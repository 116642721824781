import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';

interface Props {
  barColor?: string;
  data: {
    daily: {
      amount: string;
      totalTripsPerDay: number;
      dayOfWeek: number;
    }[];
    day: number;
    month: number;
    monthly: {
      amount: string;
      totalTripsPerMonth: number;
      month: number;
    }[];
    week: number;
    weekly: {
      amount: string;
      totalTripsPerWeek: number;
      week: number;
    }[];
    year: number;
    yearly: {
      amount: string;
      totalTripsPerYear: number;
      year: number;
    }[];
  }[];
  range: string;
}

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const monthsMap = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// const monthlyData = [{ amount: 0, month: 0, totalTripsPerMonth: 200 }];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const BarChart: React.FC<Props> = ({
  barColor = '#054853',
  data,
  range,
}) => {
  const rangeValues = data.map(({ daily, weekly, monthly, yearly }) => {
    switch (range) {
      case 'weekly':
        return weekly.map(({ amount }) => amount);
      case 'monthly':
        return monthly.map(({ amount }) => amount);
      case 'yearly':
        return yearly.map(({ amount }) => amount);
      default:
        return daily.map(({ amount }) => amount);
    }
  });

  const rangeLabels = data.map(({ daily, weekly, monthly, yearly }) => {
    switch (range) {
      case 'weekly':
        return weekly.map(({ week }) => week);
      case 'monthly':
        // return monthly.map(({ month }) => monthsMap[month]);
        return monthly.map(({ month }) =>
          monthsMap.splice(month, 1, monthsMap[month])
        );
      case 'yearly':
        return yearly.map(({ year }) => year);
      default:
        return daily.map(({ dayOfWeek }) => days[dayOfWeek]);
    }
  });

  const [barChartData] = useState({
    labels: rangeLabels,
    datasets: [
      {
        label: '',
        data: rangeValues,
        borderColor: barColor,
        backgroundColor: barColor,
        fill: true,
        barThickness: 25,
        borderRadius: 5,
      },
    ],
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      responsive: true,
      scales: {
        y: {
          grid: {
            color: '#ffffff',
          },
        },
        x: {
          grid: {
            color: '#ffffff',
          },
        },
      },
    },
  });

  const barChart = <Bar data={barChartData} />;

  return barChart;
};

interface PieChartProps {
  statusAccumulation: {
    status: string;
    total: number;
  }[];
}

export const PieChart: React.FC<PieChartProps> = ({ statusAccumulation }) => {
  const statuses = statusAccumulation.map((status) => status.status);
  const totals = statusAccumulation.map((total) => total.total);

  const [data] = useState({
    labels: statuses,
    datasets: [
      {
        label: '',
        data: totals,
        backgroundColor: ['#FCCD33', '#5A4AF2', '#054853'],
        borderColor: ['#FCCD33', '#5A4AF2', '#054853'],
        borderWidth: 1,
      },
    ],
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
  });

  return (
    <div>
      <Pie data={data} />
    </div>
  );
};
