import { useFormik } from 'formik';
import { ElrButton, ElrInput } from 'ui-components';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useEffect, useState } from 'react';
import { PencilIcon } from 'app/components/SvgAssets';
import validationSchema from './validationSchema';
import { SettingsValue } from '.';
import BalanceDropdown from './BalanceDropdown';

interface LowBalanceAlertProps {
  handleLowBalance: (values: SettingsValue) => void;
}

const LowBalanceAlert = ({ handleLowBalance }: LowBalanceAlertProps) => {
  const { id: userId, accountInformation, currentAccessRole } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [, wallet] = useGetWalletBalance(
    userId,
    accountInformation.id,
    currentAccessRole
  );

  const { values, setFieldValue, handleSubmit, isValid, setValues } = useFormik(
    {
      initialValues: {
        criticalAmount: 0,
        warningAmount: 0,
        lowBalanceNotificationChannel: {
          email: false,
          webhook: false,
        },
      },
      validationSchema,
      validateOnChange: true,
      onSubmit: async () => {
        setIsModalOpen(false);
        handleLowBalance(values as SettingsValue);
      },
    }
  );

  useEffect(() => {
    if (wallet) {
      setValues({
        criticalAmount: wallet.lowBalanceNotification?.criticalAmount || 0,
        warningAmount: wallet.lowBalanceNotification?.warningAmount || 0,
        lowBalanceNotificationChannel: {
          email:
            wallet.lowBalanceNotification?.lowBalanceNotificationChannel
              ?.email || false,
          webhook:
            wallet.lowBalanceNotification?.lowBalanceNotificationChannel
              ?.webhook || false,
        },
      });
    }
  }, [wallet]);

  const handleAmountChange = (field: string, value: string) => {
    const numericValue = Math.max(0, Number(value));
    setFieldValue(field, numericValue);
  };

  const notificationOptions = [
    { value: 'Email', label: 'Email' },
    { value: 'Webhooks', label: 'Webhooks' },
  ];

  const handleNotificationOptionSelect = (selectedValue: string) => {
    setFieldValue('lowBalanceNotificationChannel', {
      email: selectedValue === 'Email',
      webhook: selectedValue === 'Webhooks',
    });
  };

  const isFormDisabled =
    !isValid || values.warningAmount <= 0 || values.criticalAmount <= 0;

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Low Balance Alert</p>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-7 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">
              Warning Amount
            </p>
            <div className="text-elr-black text-base flex justify-center align-middle items-center h-12">
              <div className="bg-elr-gray-300 bg-opacity-20 h-full items-center flex px-3">
                NGN
              </div>
              <ElrInput
                placeholder="0.00"
                value={String(values.warningAmount)}
                onChange={(e) =>
                  handleAmountChange('warningAmount', e.target.value)
                }
                className="border-none bg-elr-gray !h-12 outline-none"
                type="number"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">
              Critical Amount
            </p>
            <div className="text-elr-black text-base flex justify-center align-middle items-center h-12">
              <div className="bg-elr-gray-300 bg-opacity-20 h-full items-center flex px-3">
                NGN
              </div>
              <ElrInput
                placeholder="0.00"
                value={String(values.criticalAmount)}
                onChange={(e) =>
                  handleAmountChange('criticalAmount', e.target.value)
                }
                className="border-none bg-elr-gray !h-12 outline-none"
                type="number"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">
              Receive Notifications Via
            </p>
            <BalanceDropdown
              options={notificationOptions}
              onSelect={handleNotificationOptionSelect}
              showDropdownIcon
              className="bg-elr-gray text-elr-black-300 text-base px-3 !h-12"
            />
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled={isFormDisabled}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <div
        className="absolute right-5 top-6 text-base px-8 py-3 text-elr-black flex gap-3 rounded-md items-center justify-center cursor-pointer bg-elr-gray text-center"
        onClick={() => setIsModalOpen(true)}
      >
        <p>Edit</p>
        <PencilIcon />
      </div>

      {isModalOpen && renderEditInfo}

      <p className="text-xl pb-7">Low Balance Alert</p>
      <div className="grid grid-cols-1 md:grid-rows-2 md:grid-flow-col gap-7 md:gap-8">
        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Warning amount</p>
          <p className="text-elr-black text-base">
            NGN {values.warningAmount || 0}
          </p>
        </div>

        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">
            Receive Notifications Via
          </p>
          <p className="text-elr-black text-base">
            {values.lowBalanceNotificationChannel.email ? 'Email' : 'Webhooks'}
          </p>
        </div>

        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Critical amount</p>
          <p className="text-elr-black text-base">
            NGN {values.criticalAmount || 0}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LowBalanceAlert;
