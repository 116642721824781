import React from 'react';
import Select from 'react-select';

type ElrSelectProps = {
  className?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  containerHeight?: number;
  containerBackgroundColor?: string;
  controlBackgroundColor?: string;
  controlHeight?: number;
  showIndicator?: boolean;
  minHeight?: number;
  bgcolor?: string;
  options?: any;
  value?: any;
  boxShadowStyle?: string;
  optionBorderTop?: boolean;
  menuStyles?: {};
  placeHolder?: string;
  onChange?: any;
  defaultValue?: any;
};

export const ElrSelect: React.FC<ElrSelectProps> = ({
  className,
  isSearchable = true,
  isClearable = true,
  onChange,
  containerHeight = 50,
  containerBackgroundColor = '#F7F7F7',
  controlBackgroundColor = '#F7F7F7',
  controlHeight = 50,
  showIndicator = false,
  minHeight,
  bgcolor,
  placeHolder,
  boxShadowStyle = '0px 0px 10px -4px rgba(0, 0, 0, 0.273975)',
  optionBorderTop,
  menuStyles,
  ...other
}) => (
  <Select
    {...other}
    className={`${className} mb-4`}
    isSearchable={isSearchable}
    isClearable={isClearable}
    menuPortalTarget={document.body}
    onChange={onChange}
    placeholder={placeHolder}
    styles={{
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      container: (base) => ({
        ...base,
        backgroundColor: containerBackgroundColor,
        height: containerHeight,
      }),
      control: (base) => ({
        ...base,
        backgroundColor: controlBackgroundColor,
        border: 'none',
        boxShadow: 'none',
        height: controlHeight,
        minHeight: minHeight || base.minHeight,
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: 'none',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        display: `${showIndicator ? 'block' : 'none'}`,
        padding: '0 2px 0 0',
        marginTop: '-5px',
      }),
      menu: (base) => ({
        ...base,
        backgroundColor: `${bgcolor}`,
        boxShadow: boxShadowStyle,
        borderRadius: '5px',
        borderTop: '0.5px solid rgba(196, 196, 196, 0.3)',
        padding: 5,
        zIndex: 9999,
        ...menuStyles,
      }),
      menuList: (base) => ({
        ...base,
        '::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }),
      singleValue: (base) => ({
        ...base,
        color: '#999',
      }),
      option: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? '#F1F1F1' : `${bgcolor}`,
        color: isFocused ? '#054853' : '##081120',
        ':hover': { backgroundColor: '#F1F1F1', color: '#054853' },
        ':focus': { backgroundColor: '#F1F1F1', color: '#054853' },
        textAlign: 'left',
        borderTop: optionBorderTop ? '1px solid #05485366' : '',
      }),
      placeholder: (base) => ({
        ...base,
        color: '#999',
      }),
    }}
  />
);
