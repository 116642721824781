import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../index';

const StyledAnchor = styled.a`
  color: ${Colors.elrPurple};
  text-decoration: none;
`;

const StyledSpan = styled.span`
  color: ${Colors.elrPurple};
`;

interface Props {
  href?: string;
  onClick?: () => void;
  text?: string;
  target?: string;
  rel?: string;
  children: React.ReactNode;
}

export const ElrAnchor: React.FC<Props> = ({
  href,
  onClick,
  text,
  children,
  ...other
}) => {
  if (href) {
    return (
      <StyledAnchor href={href} onClick={onClick} {...other}>
        {text}
        {children}
      </StyledAnchor>
    );
  }

  return (
    <StyledSpan onClick={onClick} {...other}>
      {text}
      {children}
    </StyledSpan>
  );
};
