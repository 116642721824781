import { API_URL } from 'app/constants';
import axios, { AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import isDeepEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { Session, accessToken, refreshToken } from './Session';
import { setRedirectUrl } from './helpers';
import { privateRoutes, publicRoutes } from './routes';

const instance = axios.create({
  baseURL: `${API_URL}/v1`,
  withCredentials: true,
});

interface JwtPayloadExp {
  exp: number;
  iat: number;
  sub: string;
}

/* @todo
 * what should happen here is
 * when the main token expires, we should use the refreshtoken to get a new one
 */
async function checkAndRefreshToken(config: AxiosRequestConfig) {
  const getToken = Session.currentUser();
  if (getToken && config?.headers?.Authorization) {
    const decoded = jwtDecode<JwtPayloadExp>(getToken);
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
      try {
        const response = await instance.post<
          { refreshToken: string | null },
          {
            data: {
              access: { token: string };
              refresh: { token: string };
            };
          }
        >('/auth/refresh-tokens', {
          refreshToken: Session.refreshToken(),
        });
        return response.data;
      } catch (error: any) {
        // @todo if error, push to log out
      }
    }
  }
  return null;
}

instance.interceptors.request.use(
  async (config) => {
    const requestConfig = config;
    const newAccessToken = await checkAndRefreshToken(config);
    if (newAccessToken) {
      Session.setValue(accessToken, newAccessToken.access.token);
      Session.setValue(refreshToken, newAccessToken.refresh.token);
      // set refresh token as well
      // @ts-ignore
      requestConfig.headers.Authorization = `Bearer ${newAccessToken.accessToken}`;
    }
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (r) => r,
  (error) => {
    redirectOnUnAuth(
      {
        status: error.response.status,
        message: error.response.data.message,
      },
      window.location.pathname
    );
    return Promise.reject(error);
  }
);

const redirectOnUnAuth = memoizeOne(
  ({ status, message }: { status: number; message: string }, path: string) => {
    if (
      status === 401 &&
      message === 'Please authenticate' && // @todo very volatile FIXME
      Object.values(privateRoutes).includes(path)
    ) {
      const session = Session.currentUser();
      if (session) {
        Session.logout();
        return window.location.replace(
          `${publicRoutes.login}${setRedirectUrl(path)}`
        );
      }
    }
    return null;
  },
  isDeepEqual
);

export { instance as axiosDefault, axios as axiosStatic };
