import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpSetAvailability } from 'app/api/dispatcher';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import { privateRoutes } from 'app/utilities/routes';
import {
  AvailabilityInterface,
  EachAvailabilityInterface,
} from 'app/utilities/types/dispatcherTypes';
import BackArrow from 'assets/images/arrowLeft.svg';
import { Info } from 'luxon';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ElrButton, ElrPageTitle } from 'ui-components';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import * as styles from './ClassUtils';
import TimeDropdown from './TimeDropdown';

interface TimeDefault {
  day: string;
  dayShort: string;
}

interface TimeData {
  from: string;
  to: string;
  isAvailable: boolean;
}

const initialTimeData: TimeData = {
  from: '08:00',
  to: '16:00',
  isAvailable: true,
};

const weekdays = Info.weekdays();
const weekdaysShort = Info.weekdays('short');

const timeDataDefault = weekdays.map((day, index) => ({
  day,
  dayShort: weekdaysShort[index],
}));

const Availability: React.FC = () => {
  const profile = useGetProfile();
  const queryClient = useQueryClient();
  const {
    currentAccessRole,
    accountInformation: { availability },
    id,
  } = profile;

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: AvailabilityInterface) =>
      httpSetAvailability(id, payload),
    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: object | undefined) =>
        oldData ? { ...oldData, accountInformation: data } : {}
      );
    },
  });

  const [data, setData] = useState<AvailabilityInterface>(availability || {});

  const setDatum = (d: string, avail: EachAvailabilityInterface) => {
    setData((dt) => ({ ...dt, [d]: avail }));
  };

  const save = async () => {
    await mutateAsync(data);
  };

  return (
    <div>
      <ElrPageTitle title="Errandlr - Availability" />
      <ProfileNavigationMenu>
        <div className="h-1000">
          <TopNavigationMenu />
          <div className="flex items-center pt-8 md:flex-col md:items-start md:pt-3">
            <div className="mx-4 md:px-24">
              <Link
                to={privateRoutes.dashboard(currentAccessRole)}
                className="border-none text-elr-black text-opacity-60"
              >
                <img
                  className="inline w-5 md:opacity-60 md:mr-3"
                  src={BackArrow}
                  alt="Back to Dashboard"
                />
                <span className="hidden md:inline">Back to Dashboard</span>
              </Link>
            </div>

            <div className="text-xl md:px-24 md:pt-6">Availability</div>
          </div>

          <div className="p-8 rounded-md md:mt-5 md:mx-24 md:border border-elr-green border-opacity-30">
            {timeDataDefault.map((timeData) => (
              <Day
                data={data}
                key={timeData.day}
                setData={setDatum}
                timeData={timeData}
              />
            ))}

            <div className="flex items-center justify-end pt-8 mb-10 border-t-elr-green border-opacity-30 border-t-03 mt-14">
              <ElrButton
                className="text-elr-black text-opacity-60"
                text="Cancel"
              />
              <ElrButton
                className="bg-elr-black text-elr-white"
                text="Save Changes"
                onClick={save}
                disabled={isPending}
              />
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

const Day: React.FC<{
  timeData: TimeDefault;
  setData: (day: string, av: EachAvailabilityInterface) => void;
  data: AvailabilityInterface;
}> = ({ timeData, setData, data }) => {
  const availability = data[timeData.day.toLowerCase()] || initialTimeData;

  const toggleAvailability = () => {
    setData(timeData.day.toLowerCase(), {
      ...availability,
      isAvailable: !availability.isAvailable,
    });
  };

  const setTime = (type: string, time: string) => {
    setData(timeData.day.toLowerCase(), {
      ...availability,
      [type]: time,
    });
  };

  return (
    <div className={styles.dayContainer(timeData.day === 'Sunday')}>
      <div className="w-1/12 md:w-1/4">
        <p className="hidden md:block">{timeData.day}s</p>
        <p className="md:hidden">{timeData.dayShort}</p>
      </div>

      <TimeDropdown setTime={setTime} availability={availability} />

      <div className="flex items-center justify-center w-1/5 mt-1 md:w-1/4 md:ml-0">
        <ElrRadio
          onChange={toggleAvailability}
          checked={!availability.isAvailable}
          label="Unavailable"
          index={timeData.day}
        />
      </div>
    </div>
  );
};

export default Availability;
