import { ElrMainSelect } from 'ui-components';

const AddressFilter: React.FC<{ addressFilterdata: Array<string> }> = ({
  addressFilterdata,
}) => (
  <div className="text-elr-black flex md:ml-6 items-center">
    <span className="opacity-60 text-base ml-2.5 mr-1.5"> Find </span>
    <ElrMainSelect
      data={addressFilterdata}
      // @ts-ignore
      containerHeight="30px"
      containerBackgroundColor="#FFFFFF"
      controlBackgroundColor="transparent"
      controlHeight="28px"
      minHeight="0"
      className="w-40 mt-4 border md:z-30 border-opacity-40 border-elr-green rounded-md"
      placeholder="Search address"
      boxShadowStyle="0 0 14px rgba(0, 0, 0, 0.274)"
      showIndicator
    />
  </div>
);

export default AddressFilter;
