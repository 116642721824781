import SlideIn from 'app/components/SlideIn/SlideIn';
import React from 'react';
import { AuthModalInterface, PagesState } from '../modules/authOptionsModal';

interface AuthOptionsInterface extends AuthModalInterface {
  setCurrentScreen: React.Dispatch<React.SetStateAction<PagesState>>;
}

const AuthOptions: React.FC<AuthOptionsInterface> = ({
  showSlideIn,
  setShowSlideIn,
  setCurrentScreen,
}) => (
  <SlideIn shown={showSlideIn} close={() => setShowSlideIn(!showSlideIn)}>
    <div className="pt-3">
      <h1 className="text-center text-18 leading-5 pb-7">Welcome back</h1>
      <div
        className="w-full cursor-pointer h-50 flex items-center px-2 md:px-4 mb-3 rounded-5 border-05 border-elr-green"
        onClick={() => setCurrentScreen('createAccount')}
      >
        Sign Up
      </div>
      <div
        className="w-full cursor-pointer h-50 flex items-center px-2 md:px-4 mb-3 rounded-5 border-05 border-elr-green"
        onClick={() => setCurrentScreen('login')}
      >
        Login
      </div>
      <p className="text-elr-black text-opacity-60 text-center text-sm mt-5">
        To complete order
      </p>
    </div>
  </SlideIn>
);

export default AuthOptions;
