import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  httpAddAgent,
  httpEditAgent,
  httpFetchInterestedRiders,
  httpFetchNearbyAgents,
  httpGetAgentsByDispatcherId,
  httpRateAgent,
} from 'app/api/agent';
import { httpFetchPartners } from 'app/api/batch';
import { IFetchNearbyAgentsPayload } from 'app/api/d';
import {
  useFetchInfiniteQuery,
  useFetchQueryOnce,
  useFetchRequestQueries,
} from 'app/components/dashboard/hooks';
import { COMPANY_AGENTS, NEARBY_COMPANIES_REQUEST } from 'app/state/constants';
import { omitNodeKey, removeEmptyKeys } from 'app/utilities/helpers';
import {
  AgentFormInterface,
  AgentPayloadInterface,
  AgentRatingInterface,
} from 'app/utilities/types/shared';
import { useCallback, useState } from 'react';
import { useGetProfile } from '../user';

type AddAgentHook = (
  completeCallback: () => void,
  onCloseCallback: () => void
) => [
  (
    profileId: string,
    dispatcherId: string,
    payload: AgentPayloadInterface
  ) => Promise<void>,
  string | null,
];

export class useFetchError extends Error {}

export const useAddAgent: AddAgentHook = (
  completeCallback,
  onCloseCallback
) => {
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const addAgentMutation = useMutation({
    mutationFn: ({
      userId,
      dispatcherId,
      payload,
    }: {
      userId: string;
      dispatcherId: string;
      payload: AgentPayloadInterface;
    }) => httpAddAgent(userId, dispatcherId, payload),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['agentsList'],
        (prevData: AgentPayloadInterface[] = []) => [...prevData, ...[data]]
      );
      completeCallback();
      onCloseCallback();
    },
  });

  const addAgent = useCallback(
    async (
      userId: string,
      dispatcherId: string,
      payload: AgentPayloadInterface
    ) => {
      setError(null);
      try {
        await addAgentMutation.mutateAsync({
          userId,
          dispatcherId,
          payload,
        });
      } catch (err) {
        /** no op */
      }
    },
    [addAgentMutation]
  );

  return [addAgent, error];
};

const editAgentRequest = async (
  input: AgentPayloadInterface,
  profileId: string,
  onCompleteCallback: () => void,
  onCloseCallback: () => void,
  agentId?: string
) => {
  try {
    if (!agentId) throw new Error('Agent ID is required');
    httpEditAgent(profileId, agentId, input).then((result) => {
      if (result) {
        onCompleteCallback();
        onCloseCallback();
      }
    });
  } catch {
    /** no op */
  }
};

type FormValue = AgentFormInterface;
interface SubmitAgentHookInterface {
  profileId: string;
  dispatcherId: string;
  agentId?: string;
  agentPhoneNumber: string;
  alternativeAgentPhoneNumber: string;
  editAgent?: boolean;
  profileAddAgentButton?: boolean;
  input: FormValue;
  onCloseCallback: () => void;
}

type SubmitAgentHook = (
  completeCallback: () => void,
  onCloseCallback: () => void
) => [
  ({
    profileId,
    dispatcherId,
    agentId,
    agentPhoneNumber,
    alternativeAgentPhoneNumber,
    editAgent,
    profileAddAgentButton,
  }: SubmitAgentHookInterface) => Promise<void>,
  string | null,
];

export const useSubmitAgentForm: SubmitAgentHook = (
  onCompleteCallback,
  onCloseCallback
) => {
  const [error, setError] = useState<string | null>(null);
  const [addAgent] = useAddAgent(onCompleteCallback, onCloseCallback);

  const sendCompleteForm = useCallback(
    async ({
      profileId,
      dispatcherId,
      agentId,
      agentPhoneNumber,
      alternativeAgentPhoneNumber,
      editAgent,
      input,
    }: SubmitAgentHookInterface) => {
      setError(null);
      const {
        firstname,
        lastname,
        state,
        country,
        vehiclePlateNumber,
        driversLicense,
        additionalId,
        vehicleRegistrationDocument,
        bankDetails,
      } = input;

      const payload = {
        firstname,
        lastname,
        country,
        state,
        vehiclePlateNumber,
        driversLicense: omitNodeKey('_id', driversLicense),
        additionalId: omitNodeKey('_id', additionalId),
        vehicleRegistrationDocument: omitNodeKey(
          '_id',
          vehicleRegistrationDocument
        ),
        phone: [
          {
            primary: true,
            number: agentPhoneNumber,
          },
          ...(alternativeAgentPhoneNumber
            ? [
                {
                  primary: false,
                  number: alternativeAgentPhoneNumber,
                },
              ]
            : []),
        ],
        ...(bankDetails?.accountName && { bankDetails }),
      };

      const newPayload = removeEmptyKeys(payload);

      if (editAgent) {
        await editAgentRequest(
          newPayload,
          profileId,
          onCompleteCallback,
          onCloseCallback,
          agentId
        );
      } else {
        await addAgent(profileId, dispatcherId, newPayload);
      }
    },
    [addAgent, onCloseCallback, onCompleteCallback]
  );

  return [sendCompleteForm, error];
};

type FetchNearByStationHook = () => [
  ({
    userId,
    state,
    country,
    page,
    location,
  }: {
    userId: string;
    state: string;
    country: string;
    page: number;
    location: { latitude: string; longitude: string };
  }) => Promise<void>,
  boolean,
  string | null,
  {},
];

export const useFetchNeabyStations: FetchNearByStationHook = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendQuery] = useFetchRequestQueries();
  const [preresult, setResult] = useState<{}>({});

  const fetchNearbyStations = useCallback(
    async ({
      userId,
      state,
      country,
      page,
      location,
    }: {
      userId: string;
      state: string;
      country: string;
      page: number;
      location: { latitude: string; longitude: string };
    }) => {
      setError(null);
      setIsLoading(true);
      try {
        const result = await sendQuery(
          httpFetchPartners({
            userId,
            state,
            country,
            page,
            location,
          }),
          NEARBY_COMPANIES_REQUEST
        );
        setIsLoading(false);
        setResult(result);
        return result;
      } catch (err) {
        /** noop */
        setIsLoading(false);
      }
      return null;
    },
    [sendQuery]
  );
  return [fetchNearbyStations, isLoading, error, preresult];
};

export const useFetchAgentsData = () => {
  const {
    id: userId,
    accountInformation: { id: dispatcherId },
  } = useGetProfile();

  return useFetchInfiniteQuery({
    key: COMPANY_AGENTS,
    fn: httpGetAgentsByDispatcherId,
    payload: { userId, dispatcherId },
  });
};

type SubmitAgentRatingType = () => [
  (
    userId: string,
    ratingDetails: AgentRatingInterface
  ) => Promise<AgentRatingInterface | null>,
  boolean,
];

export const useSubmitAgentRating: SubmitAgentRatingType = () => {
  const [isLoading, setIsLoading] = useState(false);

  const submitAgentRating = async (
    userId: string,
    ratingDetails: AgentRatingInterface
  ) => {
    setIsLoading(true);
    try {
      const result = await httpRateAgent(userId, ratingDetails);
      setIsLoading(false);
      return result;
    } catch (err) {
      // no op
      setIsLoading(false);
    }
    return null;
  };

  return [submitAgentRating, isLoading];
};

export const useFetchInterestedRiders = (
  userId: string,
  requestId: string,
  enabled: boolean = true
) => {
  const response = useFetchQueryOnce({
    key: 'interestedRiders',
    fn: httpFetchInterestedRiders,
    payload: { userId, requestId },
    querySettings: { enabled },
  });

  return { ...response, data: response.data ?? [] };
};

export const useFetchNearbyAgents = (
  userId: string,
  agentPayload: IFetchNearbyAgentsPayload,
  enabled: boolean = true
) => {
  const response = useFetchQueryOnce({
    key: 'nearbyAgents',
    fn: httpFetchNearbyAgents,
    payload: { userId, payload: agentPayload },
    querySettings: { enabled },
  });

  return { ...response, data: response.data ?? [] };
};
