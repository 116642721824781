export const logistics = [
  {
    id: 1,
    name: '6DIGITS CARGO',
    address: '59, Afariogun Street, Mafoluku, Oshodi, Lagos, Lagos, Nigeria',
    phoneNumber: '07030303929',
    website: 'https://www.6digitscargo.com',
    description:
      '6digits cargo company is a full-service logistics services provider. We focuses on providing complex logistic solutions with the utmost consideration for the environment, and work with clients home and abroad to support their domestic and international logistics needs via road, air, rail, and sea transport.',
  },
  {
    id: 2,
    name: 'TEGA XPRESS GENERAL LOGISTICS COMPANY',
    address: '2/4 JAMIU BALOGUN STREET IKOSI KETU LAGOS 100001 LAGOS',
    phoneNumber: '0705 977 0455',
    website: 'https://tega-xpress.business.site/',
    description:
      'TEGA XPRESS is a world-class dispatch company that inspires creativity and enhances productivity. We provide fast reliable dispatch of goods.',
  },
  {
    id: 3,
    name: 'MDS Logistics',
    address: 'Plot 32, Kudirat Abiola Way, Oregun, Ikeja, Lagos',
    phoneNumber: '+234 (0) 815 989 0211',
    website: 'https://www.mdslogistics.net/',
    description:
      'Our team of experienced supply chain professionals leverage on our unparalleled distribution network with you from start to finish, designing and implementing your unique supply chain model.',
  },
  {
    id: 4,
    name: 'Dausam Nigeria Limited',
    address: 'virgo block, Eleganza plaza, Apapa Lagos, Lagos, Lagos, Nigeria',
    phoneNumber: '+2348037809587',
    website: 'http://dausam.com',
    description:
      'Dausam nigeria limited handled our shipment excellently well, delivery was done before the expected time and their charges was fair compare to other companies we have worked with. i recommend dausam Nigeria Limited for all who does business in the freight and logistics industry.',
  },
  {
    id: 5,
    name: 'RedoxCorp REDOXCORP SHIPPING & LOGISTICS LIMITED',
    address: 'Suit 3B 3-9 Olu Koleosho Street Medical Road, Ikeja Lagos',
    phoneNumber: '09062832947',
    website: 'https://redoxcorp.com/',
    description:
      'Redoxcorp Shipping and Logistics Limited is a world-class provider of innovative logistics and supply-chain service and solutions.',
  },
  {
    id: 6,
    name: 'GWX',
    address: '45, Opebi Road, Ikeja Lagos, Nigeria',
    phoneNumber: '+234-704-292-9292',
    website: 'https://www.greaterwashingtonng.com/',
    description:
      'GWX is a 2nd, 3rd & 4th party consumer oriented logistics company with core competence in courier delivery service, haulage, supply chain management, air cargo services, service quality audit, third party verification and customs clearing & handling. ',
  },
  {
    id: 7,
    name: 'BRANDVIEW LOGISTICS AGENCY LIMITED',
    address:
      'SUITE F1-D.VGC shopping mall. VGC gardens. Lekki-Epe express, Lagos, Lagos, Nigeria',
    phoneNumber: '09099000910',
    website: 'http://www.brandviewng.com',
    description:
      'Brandview Limited is a company with innovative solutions involved in freight forwarding services by air and sea, custom clearance and haulage .We can take care of all your imported and exported cargoes by air or sea from anywhere in the world to your desired destination in Nigeria and from Nigeria to any part of the world.',
  },
  {
    id: 8,
    name: 'Dbell Logistics',
    address:
      '63 capitol road off old abeokuta road Agege Lagos., Lagos, Lagos, Nigeria',
    phoneNumber: '08171166009',
    website: 'http://www.dbell-logistics.com',
    description:
      'FOB Global Logistics designs and operates smart globally integrated supply chains. We combine origin and destination logistics solutions with transportation services across all modes and regions of the world.',
  },
  {
    id: 9,
    name: 'Gat Logistics Limited',
    address:
      '310, Herbert Macaulay Way, Sabo, Yaba, Lagos, Lagos, Lagos, Nigeria',
    phoneNumber: '08170077112',
    website: 'http://www.gatlogistics.ng',
    description:
      'Gat Logistics Limited is a full-service logistics services provider registered with the Corporate Affairs Commission with RC Number: 1442220 and headquartered in Lagos Nigeria. We work with clients in Nigeria and abroad to support their domestic and international logistics needs.',
  },
  {
    id: 10,
    name: 'The Mainland Movers',
    address:
      'Lagos Terminus, Iddo road Lagos Mainland Lagos, Lagos, Lagos, Nigeria',
    phoneNumber: '09068487681',
    website: 'https://themainlandmovers.business.site/',
    description:
      'They provide all you need to give you a stress free move. Competent Movers who pay attention to details, various sizes of Moving Trucks, Moving Cartons, Wrapping paper and film, fragile tapes. They do both intra-state and inter-state moves.',
  },
  {
    id: 11,
    name: 'PUISSANT LOGISTICS LIMITED',
    address: '5, NNPC ROAD, EJIGBO LAGOS NIGERIA, Lagos, Lagos',
    phoneNumber: '07066212578',
    website: 'http://www.puissantlogiestics.com',
    description:
      'PUISSANT LOGISTICS LIMITED (PLL) is aimed at offering the fastest and safest way to deliver goods within the committed delivery time, to any part of the world, with optimized & cost effective solutions. Based on years of experience we have vast expertise in offering reliable Air & Sea Cargo Services.',
  },
  {
    id: 12,
    name: 'TNT/IAS Express',
    address:
      'Post Office Premises, Shop No. 2 Oshodi Rd, beside Kairo Market, Oshodi, Lagos, Lagos, Lagos, Nigeria',
    phoneNumber: '+234 1 444 4417',
    website: 'https://www.tnt.com/express/en_ng/site/home.html',
    description:
      'TNT Express, a FedEx company, is one of the world’s largest express delivery companies. On a daily basis, TNT Express delivers close to one million consignments ranging from documents and parcels to palletised freight. The company offers road and air delivery services in Europe, the Middle East and Africa, Asia-Pacific and the Americas.',
  },
  {
    id: 13,
    name: 'Antorg Recruiters Limited',
    address: 'Lagos, Nigeria, Lagos, Lagos',
    phoneNumber: '08099393888',
    website: 'http://www.antorgrecruiters.com',
    description:
      'ANTORG Recruiters Limited, is a Management services consultancy outfit established and registered under the Trade Mark, to seek out, harness and galvanize the best of nature’s resources; Man and other materials like Machine, Money and Moments to the equation of life to provide a lasting and soothing solution to the everyday dilemma akin to organizational management and social well-being.',
  },
  {
    id: 14,
    name: 'IBIC XPRESS',
    address:
      'The Hive Mall, Plot 16, TF, Kuboye Road, Oniru, Victoria Island, Lagos, Lagos, Lagos, Nigeria',
    phoneNumber: '08167749986',
    website: 'http://www.ibicxpress.com',
    description:
      'As a leading logistics and delivery service provider in Lagos Nigeria, IBIC Xpress offers a unique blend of experience, service, and technology, from our team of experienced personnel to our technological tracking solutions, ensuring the safety of your goods from pick up to final delivery destination. We place attention to every detail of our clients’ delivery needs.',
  },
  {
    id: 15,
    name: 'Peu Knoxville Limited',
    address:
      '1, Majekodunmi Street, behind Oshopey Plaza, off Allen Avenue, Ikeja, Ikeja, Lagos, Lagos, Lagos, Nigeria',
    phoneNumber: '08037128033',
    website: 'http://www.peuknoxvilleltd.com',
    description:
      'Corporate Transport & Logistics services with state of the art driving school. Drivers training and recruitment. Peu Knoxville Farms coming soon',
  },
  {
    id: 16,
    name: 'EFK World Logistics Limited',
    address: '518 Ikorodu Road, Ketu, Lagos Nigeria, Lagos, Lagos',
    phoneNumber: '08137161094',
    website: 'https://www.efkworld.com',
    description:
      'EFK World Logistics limited Nigeria is the best logistics, supply chain company in Lagos that moves business and industry through innovation, inspiration and foresight. An employer of great number of labour internationally, we deliver excellence in end-to-end logistics and supply chain management, daily – enabling customers to grow in an efficient, proactive and cost effective manner.',
  },
  {
    id: 17,
    name: 'AP Logistics',
    address:
      'Idimu Shopping Complex Suite C6, No 54, Ikotun/Idimu Road, Council, Lagos, Nigeria, Lagos, Lagos',
    phoneNumber: '08098743474',
    website: 'http://www.aplogistics.org',
    description:
      'At AP Logistics Inc, we are committed to providing a logistics solution that also covers the logistics chain. We consider advanced methodologies for domestic business processes.  With many years of experience, we offer long term benefits to our clients, and our primary goal is to provide excellent services based on our client needs.',
  },
  {
    id: 18,
    name: 'Chris Ernest (Nig.) Ltd.',
    address:
      '8 Adeshina Str. Bye Ijeshatedo B/Stop Oshodi Apapa E/Way 1st/2nd Flr. Lagos , Lagos, Nigeria',
    phoneNumber: '+234 8033211611',
    website: 'https://www.chrisernestshipping.org',
    description:
      "Chris Ernest Nigeria Limited was registered in Federal Republic of Nigeria in 1982. It was among the first licensed Shipping Agents in the Federal Republic of Nigeria that the Federal Government gave a provisional license in 1996 when all Shipping Agents licenses were revoked. The company's aim is to evolve the most transparent way of clearing and forwarding goods in Nigerian ports. It also ensures greater participation of indigenous shipping thereby contributing to the economic growth of Nigeria.",
  },
  {
    id: 19,
    name: 'Jhaki Technologies Limited',
    address:
      '42 Nnamdi Azikiwe Avenue, Railway Compound, Ebute Metta, Lagos, Lagos, Nigeria',
    phoneNumber: '09080774081',
    website: 'https://jhaki.com',
    description:
      'Jhaki is a platform that connects shippers with carriers. The Webapp platform providers shippers with real-time tracking of cargo, transparent pricing and access to a rich pool of verified carriers.',
  },
  {
    id: 20,
    name: 'Limited Logistics',
    address: '1 Daily Times Estate Satellite Town Lagos, Nigeria, Lagos, Lagos',
    phoneNumber: '+2348129129390',
    website: 'https://llpsltd.com/',
    description:
      'Limitless Logistics & Procurement Services Ltd is established to meet and exceed the needs of all our customers, we are driven and committed to offer nothing but the most exceptional service.',
  },
];
