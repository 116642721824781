import { ElrMainSelect } from 'ui-components';

const LocationFilter: React.FC<{ locationFilterdata: Array<string> }> = ({
  locationFilterdata,
}) => (
  <div className="text-elr-black flex items-center">
    <span className="opacity-60 text-base ml-2.5"> Location </span>
    <span className="order-by-select mr-4 ml-3">
      <ElrMainSelect
        data={locationFilterdata}
        // @ts-ignore
        containerHeight="30px"
        containerBackgroundColor="#FFFFFF"
        controlBackgroundColor="transparent"
        controlHeight="28px"
        minHeight="0"
        className="w-24 md:w-32 mt-4 border md:z-20 border-opacity-40 border-elr-green rounded-md"
        placeholder="All"
        boxShadowStyle="0 0 14px rgba(0, 0, 0, 0.274)"
      />
    </span>
  </div>
);

export default LocationFilter;
