import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Bank, getBankInfo } from 'app/api/payment';
import React from 'react';
import { Props } from 'react-select';
import { ElrSelect } from '../ElrSelect/ElrSelect';

interface SelectBankProps {
  currentValue: string;
}

export const ElrBankSelect: React.FC<Props & SelectBankProps> = ({
  currentValue,
  ...other
}) => {
  const queryClient = useQueryClient();
  const bankList: Array<Bank> = queryClient.getQueryData(['banks']) || [];

  const { data: banks } = useQuery({
    queryKey: ['banks'],
    queryFn: getBankInfo,
    enabled: !bankList.length,
  });

  const normalizeOptions = (state: Bank) => ({
    value: state.name,
    label: state.name,
    code: state.code,
  });
  const getOptions = (data: Array<Bank> | undefined) =>
    data?.map((state) => normalizeOptions(state));

  return (
    <ElrSelect
      {...other}
      bgcolor="#FFF"
      options={getOptions(banks)}
      value={currentValue ? { value: currentValue, label: currentValue } : null}
    />
  );
};
