import { TopNav } from 'app/components/menu/Navbar';
import appMockup from 'assets/images/event-mockup-2.png';
import bgGradient from 'assets/images/event-bg-gradient.svg';
import { ElrButton } from 'ui-components';
import { publicRoutes } from 'app/utilities/routes';
import { useNavigate } from 'react-router';

const EventHeader = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate(publicRoutes.registration);
  };
  return (
    <div>
      <TopNav isDefault />
      <div className="overflow-hidden">
        <div className="flex flex-col justify-between flex-wrap md:py-10 px-5 items-center relative">
          <img
            src={bgGradient}
            alt="bg-gradient"
            className="absolute md:-top-80 top-52 w-full"
          />
          <div className="relative flex flex-col items-center justify-center gap-8 pt-20 md:mb-14 mb-10 text-center md:pt-28">
            <h1 className="font-bold lg:text-6xl md:text-40 md:w-full w-11/12 px-8 text-32 leading-10 md:leading-[52px] ">
              Bring Your <span className="text-elr-purple">Event</span> to Life
              with Ease
            </h1>
            <p className="w-11/12 md:text-xl text-lg lg:w-7/12 md:w-10/12 text-elr-black-300">
              Create, manage, and share your events effortlessly with our
              user-friendly platform—designed to make event process hassle-free.
            </p>
          </div>
          <div className="relative flex justify-center w-full">
            <ElrButton
              text="Create An Event"
              className="bg-elr-black text-white px-14 md:mb-8 mb-12 w-11/12 md:w-auto"
              onClick={handleGetStarted}
            />
          </div>
        </div>
        <div className="relative flex items-center justify-center">
          <img
            src={appMockup}
            alt="app-mockup"
            className="md:block md:w-6/12"
          />
        </div>
      </div>
    </div>
  );
};
export default EventHeader;
