import { NavBar } from 'app/components/menu/Navbar';
import { publicRoutes } from 'app/utilities/routes';
import logo from 'assets/images/logo.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';

const NoMatch: React.FC = () => (
  <div className="h-screen bg-error-bg bg-no-repeat bg-auto">
    <ElrPageTitle title="Errandlr - Error" />
    <div className="m-auto">
      <NavBar>
        <div className="flex justify-between">
          <img src={logo} alt="site logo" className="h-6 md:h-9" />
          <div className="flex items-center text-elr-purple">
            <div className="w-1.5 h-1.5 rounded-full mr-1.5" />
            <Link to={publicRoutes.contact}>contact us</Link>
          </div>
        </div>
      </NavBar>
      <div className="text-center relative top-52">
        <div className="text-2xl">Sorry, that errand is incomplete</div>
        <div className="text-xm md:text-base">
          Return to
          <Link className="text-elr-purple pl-1 underline" to="/">
            Homepage
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default NoMatch;
