import {
  GeoLocationData,
  HttpLocationValues,
  LocationWrapperValues,
  UserDetailsValues,
} from 'app/utilities/types/shared';
import { addressValidations } from 'app/utilities/validationSchema';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import '../styles.css';
import EstimateForm from './EstimateForm';

const validationSchema = Yup.object().shape({
  ...addressValidations,
  deliverToInformation: Yup.mixed().when(
    'isDispatchDetails',
    ([isDispatchDetails], schema) =>
      isDispatchDetails
        ? Yup.array().of(
            Yup.object().shape({
              packageValue: Yup.number().required('Required'),
              name: Yup.string().required('Required'),
              phone: Yup.string().required('Required'),
              deliveryNotes: Yup.string(),
            })
          )
        : schema.notRequired()
  ),
});

interface Props {
  onSubmit?: any;
  isDispatchDetails?: boolean;
  requestSuccess?: boolean;
  initialValues: HttpLocationValues;
  onSetUserRouteAddresses?: (routes: LocationWrapperValues) => void;
  senderInformation?: UserDetailsValues;
  geolocation?: GeoLocationData;
}

export const Estimate: React.FC<Props> = ({
  onSubmit,
  isDispatchDetails,
  requestSuccess,
  initialValues,
  onSetUserRouteAddresses,
  senderInformation,
  geolocation,
}) => (
  <div>
    <Formik
      initialValues={{
        ...initialValues,
        isDispatchDetails,
        deliverToInformation: [],
      }}
      validateOnBlur
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, setFieldValue, setValues, submitForm }) => (
        <EstimateForm
          {...{ errors, values, setFieldValue, setValues, submitForm }}
          isDispatchDetails={isDispatchDetails}
          requestSuccess={requestSuccess}
          onSetUserRouteAddresses={onSetUserRouteAddresses}
          senderInformation={senderInformation}
          geolocation={geolocation}
        />
      )}
    </Formik>
  </div>
);
