import * as Sentry from '@sentry/react';
import ErrorBoundary from 'app/components/ErrorBoundary';

import { useQueryClient } from '@tanstack/react-query';
import {
  defaultProfileEntry,
  Profile,
  Roles,
  useFetchUser,
} from 'app/hooks/user';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import ServiceWorkerWrapper from 'service-worker-wrapper';
import Loading from './components/Loading';
import { ContextProviders } from './contexts';
import { RequestCountProvider } from './contexts/requestCount';
import useGetCountry from './hooks/useGetCountry';
import { Routes } from './Routes';
import { STATE_USER } from './state/constants';
import { loadTenantData } from './utilities/helpers';
import { useLoadGoogleApi } from './utilities/loadGoogleApi';
import { RoleHash } from './utilities/navigations';
import {
  fetchProfileOnRoutes,
  FetchProfileOnRouteType,
} from './utilities/routes';
import { currentCountryKey, Session } from './utilities/Session';

declare global {
  interface Window {
    ga: any;
    appVersion: string;
    grecaptcha: any;
  }
  interface Navigator {
    standalone: any;
  }
}

const App: React.FC = React.memo(() => {
  useLoadGoogleApi();
  const queryClient = useQueryClient();
  const session = Session.currentUser();
  const currentCountry = useGetCountry();
  Session.setValue(currentCountryKey, currentCountry);

  const restrictFetchProfileOnRoute =
    fetchProfileOnRoutes[
      window.location.pathname.substring(1) as keyof FetchProfileOnRouteType
    ] || session;

  const access = window.location.pathname
    .split('/')
    .find((path) => path && RoleHash[path]) as Roles;

  const { isFetched } = useFetchUser(restrictFetchProfileOnRoute, access);

  const user: Profile =
    queryClient.getQueryData([STATE_USER]) || defaultProfileEntry;

  loadTenantData();

  if (!isFetched) return <Loading />;

  return (
    <main className="h-full">
      <Sentry.ErrorBoundary fallback={<ErrorBoundary user={user} />}>
        <ContextProviders components={[RequestCountProvider]}>
          <HelmetProvider>
            <Routes user={user} />
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              stacked
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </HelmetProvider>
        </ContextProviders>
      </Sentry.ErrorBoundary>
      <ServiceWorkerWrapper />
    </main>
  );
});

export default App;
