import { FinanceAggregate } from 'app/api/d';
import { getCurrentCountryData } from 'app/utilities/country';
import { roundUpAmount } from 'app/utilities/helpers';
import { Info } from 'luxon';
import React from 'react';
import star from '../../../../../assets/images/star.svg';
import * as style from './classUtils';

type AggregationProps = {
  financialAggregate?: FinanceAggregate;
  isError?: boolean;
  isLoading?: boolean;
};

export const Aggregation: React.FC<AggregationProps> = ({
  financialAggregate,
  isError,
  isLoading,
}: AggregationProps) => {
  if (!isLoading && isError) {
    return (
      <div className="mt-10">
        Couldn&apos;t load financial record, please refresh
      </div>
    );
  }

  // const items = [
  //   {
  //     month: 1,
  //     numTrips: 43,
  //     totalMonthAmount: 8000,
  //     totalMonthIncome: 8000,
  //     bonuses: [
  //       { title: 'Over 50 trips', amount: 20000 },
  //       { title: '1 Referral', amount: 1000 },
  //     ],
  //   },
  //   ...(financialAggregate || []),
  // ];
  // /* {items && /*

  return (
    <div className="grid gap-2 mt-8 grid-cols-column-three md:grid-cols-column-four md:gap-4">
      {financialAggregate &&
        financialAggregate.map((item) => (
          <AggregationItem key={item.month} item={item} />
        ))}
    </div>
  );
};

export const AggregationItem: React.FC<{ item: any }> = ({ item }) => {
  const [showBonusesInfo, setShowBonusesInfo] = React.useState(false);

  const toggleBonusesInfo = () => {
    setShowBonusesInfo((p) => !p);
  };
  return (
    <div className="">
      <div className="py-4 text-sm md:text-base">
        <div className="pb-1 text-sm text-elr-black text-opacity-60">
          {Info.months()[item.month - 1]}
        </div>
        <div className="pb-1 text-xs text-elr-green text-opacity-80">
          {item.numTrips} trips &nbsp;
          {item.bonuses ? (
            <img className="inline w-4" src={star} alt="star" />
          ) : null}
        </div>
        <div className={style.FinanceAggregateAmount(Boolean(item.bonuses))}>
          <div>
            {roundUpAmount(+item.totalMonthAmount)}&nbsp;
            <span className="text-xs">
              {getCurrentCountryData().currencyCode.toUpperCase()}
            </span>
          </div>
          {item.bonuses ? (
            <div className={style.BonusContainer(showBonusesInfo)}>
              <button
                type="button"
                onClick={toggleBonusesInfo}
                className="text-xs cursor-pointer"
              >
                {showBonusesInfo ? 'close' : 'See bonuses'}
              </button>
              {showBonusesInfo ? (
                <div>
                  {item.bonuses.map((bonus: any) => (
                    <div className="flex flex-row justify-between items-center px-0.5 border-b-05 border-b-grey">
                      <p className="text-8 text-elr-black text-opacity-40">
                        {bonus.title}
                      </p>
                      <p className="text-xs">
                        {roundUpAmount(+item.totalMonthAmount)}&nbsp;
                        <span className="text-8">
                          {getCurrentCountryData().currencyCode.toUpperCase()}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
