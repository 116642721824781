export const ElrButtonSwitchToggle = (isSelected: boolean) =>
  `${
    isSelected
      ? 'text-elr-white'
      : 'text-elr-black border-opacity-40 bg-opacity-0'
  }`;

export const batchItemButtonStyle = (enabled: boolean) =>
  `${
    enabled
      ? 'text-elr-gray'
      : 'text-elr-purple bg-opacity-20 disabled:opacity-100'
  } bg-elr-purple w-full md:w-fit text-xs flex items-center justify-center !min-w-fit`;
