import {
  Document,
  Font,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { getRandomNumber } from 'app/modules/dispatch/components/utils';
import { currencyFormatter } from 'app/utilities/helpers';
import {
  DestinationInterface,
  Request,
  RequestTypes,
} from 'app/utilities/types/shared';
import printIcon from 'assets/images/print.svg';
import KollektifBold from 'fonts/Kollektif-Bold.ttf';
import Kollektif from 'fonts/Kollektif.ttf';
import { DateTime } from 'luxon';

Font.register({
  family: 'Kollektif',
  fonts: [
    {
      src: Kollektif,
      fontWeight: 'normal',
    },
    {
      src: KollektifBold,
      fontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontFamily: 'Kollektif',
  },
  container: {
    margin: 20,
    fontFamily: 'Kollektif',
    flexDirection: 'column',
  },
  company: {
    fontSize: '12px',
    fontFamily: 'Kollektif',
  },
  header: {
    marginTop: 25,
    borderBottom: '0.5px solid #9CA0A6',
    paddingBottom: 4,
    marginHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: '15px',
    fontFamily: 'Kollektif',
    textAlign: 'left',
  },
  fieldsContainer: {
    marginTop: 25,
    marginHorizontal: 10,
  },
  fieldContainer: {
    flexDirection: 'row',
    marginBottom: 7,
  },
  fieldName: {
    fontSize: '10px',
    width: '30%',
  },
  fieldValue: {
    fontSize: '10px',
    color: '#39414D',
    lineHeight: 1.8,
  },
  footer: {
    marginTop: 50,
    borderBottom: '0.5px solid #9CA0A6',
    paddingBottom: 8,
    marginHorizontal: 10,
  },
  footerText: {
    fontSize: '13px',
    textAlign: 'center',
  },
});

const genUUID = () => getRandomNumber(1, 3200);

const Template = ({
  fields,
  printType,
}: {
  fields: { name: string; value: string | DestinationInterface[] }[];
  printType: string;
}) => (
  <Document author="Errandlr" creator="Errandlr" producer="Errandlr">
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        <View>
          <Text style={styles.company}>Errandlr</Text>
        </View>

        <View style={styles.header}>
          <Text style={styles.headerText}>Delivery {printType}</Text>
          <Text
            style={{
              fontSize: '13px',
              textAlign: 'right',
            }}
          >
            {DateTime.now().toFormat('dd LLLL yyyy, t')}
          </Text>
        </View>

        <View style={styles.fieldsContainer}>
          {fields.map((field, key) =>
            field.name === 'Receivers' && typeof field.value === 'object' ? (
              // eslint-disable-next-line react/no-array-index-key
              <View key={`${genUUID()}${key}`}>
                <View style={{ flexDirection: 'row', marginBottom: '10px' }}>
                  <Text
                    style={{
                      fontSize: '10px',
                      borderBottom: '0.5px solid #9CA0A6',
                      width: '100%',
                    }}
                  >
                    Receivers:
                  </Text>
                </View>
                {field.value.map((val, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={`${val.name}${index}`}>
                    <View style={styles.fieldContainer}>
                      <Text style={styles.fieldName}>Receiver name:</Text>
                      <Text style={styles.fieldValue}>{val.name}</Text>
                    </View>
                    <View style={styles.fieldContainer}>
                      <Text style={styles.fieldName}>Receiver phone:</Text>
                      <Text style={styles.fieldValue}>{val.phone}</Text>
                    </View>
                    <View style={styles.fieldContainer}>
                      <Text style={styles.fieldName}>Receiver address:</Text>
                      <Text style={styles.fieldValue}>
                        {val.address.fullAddress}
                      </Text>
                    </View>

                    <View style={styles.fieldContainer}>
                      <Text style={styles.fieldName}>
                        Estimated package value:
                      </Text>
                      <Text style={styles.fieldValue}>{val.packageValue}</Text>
                    </View>
                    <Text
                      style={{
                        borderBottom: '0.5px solid #9CA0A6',
                        width:
                          field.value.length - 1 === index ? '100%' : '50%',
                        marginTop: '5px',
                        marginBottom: '10px',
                      }}
                    />
                  </span>
                ))}
              </View>
            ) : (
              <View key={field.name} style={styles.fieldContainer}>
                <Text style={styles.fieldName}>{field.name}:</Text>
                <Text style={styles.fieldValue}>
                  {typeof field.value === 'string' && field.value}
                </Text>
              </View>
            )
          )}
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText}>Disclaimer</Text>
        </View>
      </View>
    </Page>
  </Document>
);

interface IGenerateInvoice {
  data: Request;
}

const GenerateInvoice: React.FC<IGenerateInvoice> = ({ data }) => {
  const isReceipt = data.status === RequestTypes.completed;

  const printType = isReceipt ? 'Receipt' : 'Invoice';

  const paymentMethod = () => {
    if (data.payWithCash) {
      return 'Cash Payment';
    }
    if (data.status === RequestTypes.quote) {
      return 'Awaiting Quote';
    }
    return 'Transfer';
  };

  const fields = [
    { name: 'Sender name', value: data.senderInformation.data.name },
    { name: 'Sender phone', value: data.senderInformation.data.phone },
    { name: 'Pick-up address', value: data.pickupAddress.fullAddress },
    { name: 'Receivers', value: data.deliverToInformation },
    {
      name: 'Delivery amount',
      value: currencyFormatter(+data.priceEstimate),
    },
    {
      name: 'Payment method',
      value: paymentMethod(),
    },
  ];
  if (data.trackingId) {
    fields.push({ name: 'Tracking number', value: data.trackingId });
  }

  return (
    <PDFDownloadLink
      document={<Template printType={printType} fields={fields} />}
      fileName={printType}
    >
      <div className="bg-elr-white rounded-full py-0.5 text-elr-black border border-elr-black w-24 md:w-40 text-center flex items-center justify-center cursor-pointer">
        <img className="mr-2" src={printIcon} alt="print" />
        <span>
          Print <span className="hidden md:inline">{printType}</span>
        </span>
      </div>
    </PDFDownloadLink>
  );
};

export default GenerateInvoice;
