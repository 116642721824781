import React from 'react';
import { ElrButton } from 'ui-components';

const LateDeliveryNotice: React.FC<{
  closeModal: (value: boolean) => void;
  onProceed: () => void;
}> = ({ closeModal, onProceed }) => (
  <div className="w-350 md:bottom-24 bottom-28 md:w-336 absolute bg-elr-green text-white pb-5 px-5 rounded-lg z-40">
    <div
      className="text-sm flex justify-end mt-1.5 text-elr-gray cursor-pointer"
      onClick={() => closeModal(false)}
    >
      x
    </div>
    <p className="text-lg pb-3">2:00pm notice !</p>
    <p className="text-14 md:text-base">
      From 2pm we may have difficulties finding you a dispatch service to
      deliver today. If its not urgent we will be able to guarantee your
      delivery tomorrow.
    </p>
    <ElrButton
      onClick={onProceed}
      spinnerColor="White"
      text="Proceed"
      className="w-36 h-9 bg-elr-white text-elr-black text-base mt-5"
    />
  </div>
);

export default LateDeliveryNotice;
