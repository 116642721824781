import { useQueryClient } from '@tanstack/react-query';
import { DeliveryDestinationsInput } from 'app/components/DeliveryDestinationsInput';
import {
  BATCH_ESTIMATE_KEY,
  ESTIMATE_KEY,
  PackageTypeCode,
} from 'app/constants';
import {
  useCheckPremiumOption,
  useGetDispatchPayload,
  useGetEstimate,
} from 'app/hooks/utils';
import { STATE_DISPATCH_PAYLOAD } from 'app/state/constants';
import { getCurrentCountryData } from 'app/utilities/country';
import { currencyFormatter } from 'app/utilities/helpers';
import { LocationTagValues } from 'app/utilities/types/shared';
import classNames from 'classnames';
import { FormikErrors, FormikValues } from 'formik';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import EstimateBulkContainer from '../BulkEstimate';
import SelectParcelType from './SelectParcelType';

interface DispatchDetailsNavProps {
  currentStep: number;
  steps: Array<any>;
  onSelectRecipientOption: (i: number) => void;
}
const uuid = uuidv4();
export const DispatchDetailsNav: React.FC<DispatchDetailsNavProps> = ({
  currentStep,
  steps,
  onSelectRecipientOption,
}) => (
  <div className="absolute flex w-11/12 -mt-16 -ml-6 overflow-x-scroll mb-14 md:w-auto no-scrollbar md:relative md:-mt-0">
    {steps.map((_, i) => {
      const pillCss = classNames('pr-1 pl-2', {
        'rounded-full mr-2 w-3 h-3 bg-elr-purple opacity-60': i === 0,
        'mr-1 w-0.5 h-2.5 bg-elr-yellow': i !== 0,
      });

      const roundedPillCss = classNames(
        'rounded-full text-xs md:text-sm h-7 w-0.53 md:w-24 flex items-center justify-center cursor-pointer bg-elr-gray',
        {
          'w-0.57 md:w-28 text-sm md:text-base px-6 py-2.5 text-elr-white bg-elr-purple-500':
            i === currentStep,
        }
      );

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${uuid}${i}`} className="flex items-center">
          {i > 0 && <div className="w-5 h-0 border border-b-0" />}
          <div
            className={roundedPillCss}
            onClick={() => onSelectRecipientOption(i)}
          >
            <div className={pillCss} />
            {i === 0 ? 'Estimate' : <p>Delivery&nbsp;{i}</p>}
          </div>
        </div>
      );
    })}
  </div>
);

interface DestinationInputProps {
  onLocationInputChange: (
    locationType: string,
    value: LocationTagValues,
    returningNew?: boolean
  ) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  onSelectParcelType: (options: { code: string; value: string }) => void;
}

export const DestinationInput: React.FC<DestinationInputProps> = ({
  values,
  errors,
  onLocationInputChange,
  onSelectParcelType,
}) => {
  const queryClient = useQueryClient();
  const estimateDetails = useGetDispatchPayload();
  const premiumOptionDisplay = useCheckPremiumOption();

  const premiumOnlyOption = [
    PackageTypeCode.FOOD,
    PackageTypeCode.LARGE_PARCEL,
  ].includes(estimateDetails.parcelCode);
  const [isSegmentedDelivery, setSegmentedDelivery] = useState(
    estimateDetails.batch
  );
  const isEconomyDelivery = premiumOnlyOption ? false : isSegmentedDelivery;

  const [setSelection, estimate] = useGetEstimate({
    estimateDetails,
  });

  const onDeliverySelectionMode = () => {
    setSegmentedDelivery((p: boolean) => !p);

    queryClient.setQueriesData(
      {
        queryKey: [STATE_DISPATCH_PAYLOAD],
      },
      (dispatchPayload: any) => ({
        ...dispatchPayload,
        batch: !isEconomyDelivery,
      })
    );
  };
  const toggleDeliveryType = (value: string) => {
    setSelection(value);
    onDeliverySelectionMode();
  };

  const checkEconomyDelivery = isEconomyDelivery || !premiumOptionDisplay;

  return (
    <div>
      <div className="mb-4 md:mb-0">
        <p className="text-xl">Your delivery estimate</p>
      </div>
      <SelectParcelType
        errors={errors}
        onSelectParcelType={onSelectParcelType}
      />
      <DeliveryDestinationsInput
        values={values as any}
        errors={errors}
        onLocationInputChange={onLocationInputChange}
      />
      <div className="mt-5" />
      <EstimateBulkContainer textContainerClass="w-10/12" />
      <div className="w-11/12 mx-auto my-0 md:w-9/12">
        {estimateDetails?.reasons?.map((reason: string) => (
          <div
            key={uuid}
            className="small_caption py-2.5 px-8 text-xs text-elr-purple-400"
          >
            {reason}
          </div>
        ))}
      </div>
      {Boolean(estimate) && (
        <div className="mt-14">
          <div className="flex flex-col items-start justify-center gap-y-4">
            {premiumOptionDisplay && (
              <div className="flex items-center justify-between w-full">
                <label className="flex items-center gap-2">
                  <input
                    className="accent-elr-black-200"
                    type="radio"
                    checked={!isEconomyDelivery}
                    onChange={() => toggleDeliveryType(ESTIMATE_KEY)}
                  />
                  <span className="leading-5 text-12 md:text-14 opacity-60">
                    Premium delivery
                  </span>
                </label>
                <span className="leading-5 text-12 opacity-60">
                  {estimateDetails.estimateLabel}
                </span>
                <p className="leading-4 text-12 md:text-14 text-elr-black-200">
                  {currencyFormatter(
                    estimateDetails?.estimate,
                    'en-US',
                    getCurrentCountryData().currencyCode.toUpperCase()
                  )}
                </p>
              </div>
            )}
            {!premiumOnlyOption && (
              <div className="flex items-center justify-between w-full gap-x-3">
                <label className="flex items-center gap-2">
                  <input
                    className="accent-elr-black-200"
                    type="radio"
                    checked={checkEconomyDelivery}
                    onChange={() => toggleDeliveryType(BATCH_ESTIMATE_KEY)}
                  />
                  <span className="leading-5 text-12 md:text-14 opacity-60">
                    Economy delivery
                  </span>
                </label>
                <span className="leading-5 text-12 opacity-60">
                  {estimateDetails.batchLabel}
                </span>
                <p className="leading-4 text-12 md:text-14 text-elr-black-200">
                  {currencyFormatter(
                    estimateDetails?.batchEstimate,
                    'en-US',
                    getCurrentCountryData().currencyCode.toUpperCase()
                  )}
                </p>
              </div>
            )}
            {checkEconomyDelivery && (
              <div className="w-full p-2 text-white bg-elr-green h-11">
                <p>Maximum load is 3KG.</p>
              </div>
            )}
          </div>
          <div className="mt-7">
            <div className="w-full mb-4 border-b border-elr-gray-600 opacity-60" />
            <span className="flex justify-between md:text-base">
              <p className="self-end opacity-60"> Order Estimate: </p>
              <p className="text-xl">
                {currencyFormatter(
                  /* @ts-ignore */
                  estimate,
                  'en-US',
                  getCurrentCountryData().currencyCode.toUpperCase()
                )}
              </p>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
