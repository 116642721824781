import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { InitialInterfaceData } from 'app/api/types';
import {
  BATCH_LOCALITIES_REQUEST,
  DELIVERIES_MADE_REQUESTS,
  NEARBY_COMPANIES_REQUEST,
  NEW_BATCH_REQUESTS,
  NEW_SINGLE_REQUESTS,
  UPCOMING_BATCH_REQUESTS,
  UPCOMING_SINGLE_REQUESTS,
} from 'app/state/constants';
import { Request } from 'app/utilities/types/shared';

export const useGetNewSingleRequestData = () => {
  const queryClient = useQueryClient();

  const newSingleRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([NEW_SINGLE_REQUESTS]) || InitialInterfaceData;

  return newSingleRequestData;
};

export const useGetNewBatchData = () => {
  const queryClient = useQueryClient();

  const newBatchRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([NEW_BATCH_REQUESTS]) || InitialInterfaceData;

  return newBatchRequestData;
};

export const useGetUpcomingBatchData = () => {
  const queryClient = useQueryClient();

  const batchUpcomingRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([UPCOMING_BATCH_REQUESTS]) || InitialInterfaceData;

  return batchUpcomingRequestData;
};

export const useGetUpcomingSinglesData = () => {
  const queryClient = useQueryClient();

  const batchUpcomingRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([UPCOMING_SINGLE_REQUESTS]) ||
    InitialInterfaceData;

  return batchUpcomingRequestData;
};

export const useGetDeliveriesMadeData = () => {
  const queryClient = useQueryClient();

  const deliveriesMadeRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([DELIVERIES_MADE_REQUESTS]) ||
    InitialInterfaceData;

  return deliveriesMadeRequestData;
};
export const useSingleRequestData = () => {
  const queryClient = useQueryClient();

  const newSingleRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([NEW_SINGLE_REQUESTS]) || InitialInterfaceData;

  return newSingleRequestData;
};

export const useFetchRequestQueries = () => {
  const queryClient = useQueryClient();

  /**
   * This makes an http call and also store the response in state.
   * @param payload The data to be send
   * @param key The state key
   * @param fn The http function to call
   * @returns data
   */
  const sendQuery = async (fn: any, key: string, payload?: any) => {
    const data = await queryClient.fetchQuery({
      queryKey: [key],
      queryFn: () => {
        if (payload) {
          return fn(payload);
        }
        return fn;
      },
      gcTime: Infinity,
    });
    return data;
  };
  return [sendQuery];
};

/**
 * This makes an http call and also store the response in state.
 * @param fn The http function to call
 * @param key The state key
 * @param payload The data to be sent
 * @returns data
 */
export const useRequestQueries = (fn: any, key: string, payload?: any) =>
  useQuery({
    queryKey: [key],
    queryFn: () => {
      if (payload) {
        return fn(payload);
      }
      return fn;
    },
    gcTime: Infinity,
    notifyOnChangeProps: ['data'],
  });

export const useGetNearbyCompaniesData = () => {
  const queryClient = useQueryClient();

  const nearbyCompaniesRequestData: typeof InitialInterfaceData =
    queryClient.getQueryData([NEARBY_COMPANIES_REQUEST]) ||
    InitialInterfaceData;

  return nearbyCompaniesRequestData;
};

export const useGetBatchRequestLocalities = () => {
  const queryClient = useQueryClient();

  const batchRequestLocality = queryClient.getQueryData<Request>([
    BATCH_LOCALITIES_REQUEST,
  ]);

  return batchRequestLocality;
};

type FetchInfiniteQueryHook = {
  fn: Function;
  key: string;
  payload: object;
  type?: string | undefined;
  querySettings?: object;
  keyPayload?: object;
};

export const useFetchInfiniteQuery = ({
  fn,
  key,
  payload,
  type,
  querySettings,
  keyPayload,
}: FetchInfiniteQueryHook) => {
  const response = useInfiniteQuery({
    queryKey: [key, { type, ...keyPayload }],
    queryFn: ({ pageParam }) => fn({ ...payload, nextPage: pageParam, type }),
    gcTime: Infinity,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    ...querySettings,
  });

  return {
    ...response,
    hasNextPage:
      response.data?.pages[response.data.pages.length - 1]?.hasNextPage ??
      false,
    latestData:
      response.data?.pages[response.data.pages.length - 1] ??
      InitialInterfaceData,
    docsList: response.data?.pages.map((page) => page.docs).flat() ?? [],
  };
};

export const useRefetchQuery = (key: Array<string | {}>): Function => {
  const queryClient = useQueryClient();
  const refetchFunction = () => {
    queryClient.refetchQueries({
      queryKey: key,
    });
  };

  return refetchFunction;
};

type FetchQueryOnceHook = {
  fn: Function;
  key: string;
  payload?: object | string;
  querySettings?: object;
};

export const useFetchQueryOnce = ({
  fn,
  key,
  payload,
  querySettings,
}: FetchQueryOnceHook) =>
  useQuery({
    queryKey: [key],
    queryFn: () => {
      if (payload) {
        return fn(payload);
      }
      return fn;
    },
    gcTime: 0,
    ...querySettings,
  });

export const useMutateQuery = ({
  fn,
  onSuccessFn,
  mutationSettings,
}: {
  fn: (variables: any) => Promise<unknown>;
  onSuccessFn?: (
    data?: unknown,
    variables?: unknown
  ) => Promise<unknown> | unknown;
  mutationSettings?: Object;
}) =>
  useMutation({
    mutationFn: fn,
    gcTime: 0,
    onSuccess: onSuccessFn,
    ...mutationSettings,
  });
