import { TopNav } from 'app/components/menu/Navbar';
import PoliciesNavigationMenu from 'app/components/WithSideNavigationMenu';
import NoMatch from 'app/static/NoMatch';
import { publicRoutes } from 'app/utilities/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Faq from './faq';
import PrivacyPolicy from './privacyPolicy';
import TermsAndCondition from './termsAndCondition';

const Policy: React.FC = () => (
  <PoliciesNavigationMenu>
    <TopNav />
    <div className="h-auto mb-16 bg-white">
      <Routes>
        <Route
          path={publicRoutes.termsAndCondition}
          element={<TermsAndCondition />}
        />
        <Route path={publicRoutes.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={publicRoutes.faq} element={<Faq />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  </PoliciesNavigationMenu>
);

export default Policy;
