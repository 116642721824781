import { PickupAddressesInterface } from 'app/utilities/types/shared';

interface Props {
  pickupAddress: PickupAddressesInterface;
  className?: string;
}
const LockerComponent: React.FC<Props> = ({
  pickupAddress,
  className = '',
}) => {
  const renderDigits = (code: string) =>
    code.split('').map((digit: string, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <p key={index} className="bg-elr-gray-100 py-2 px-4 rounded text-4xl">
        {digit}
      </p>
    ));

  if (!pickupAddress.lockerRequest) return null;
  if (!pickupAddress.locker) {
    return (
      <div
        className={`${className} pb-7 mt-2.5 md:mt-4 px-4 md:px-6 bg-elr-white-400 border border-elr-green border-opacity-30 rounded`}
      >
        <h2 className="py-4 text-center text-lg">
          Your Dropoff Code is being generated
        </h2>
        <div className="flex gap-2 justify-center mb-2 ">
          <span className="block h-1.5 md:h-2 w-1.5 md:w-[0.5rem] ml-0.5 md:ml-0 mb-1.5 my-2 rounded-full bg-elr-purple" />

          <p className="inline text-base opacity-60">
            Refresh this page to see the dropoff locker code.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${className} pb-7 mt-2.5 md:mt-4 px-4 md:px-6 bg-elr-white-400 border border-elr-green border-opacity-30 rounded`}
    >
      <h3 className="py-4 text-base">Drop off your parcel at this locker:</h3>
      <div className="flex gap-2 justify-center mb-2 ">
        <span className="block h-1.5 md:h-2 w-1.5 md:w-[0.5rem] ml-0.5 md:ml-0 mb-1.5 my-2 rounded-full bg-elr-purple" />
        <p className="inline text-base opacity-60">
          {pickupAddress.locker.boxAddress}
        </p>
      </div>
      <div className="flex justify-center gap-2">
        {renderDigits(pickupAddress.locker.dropCode)}
      </div>
      <p className="text-center text-base opacity-60 mt-1">
        BOX NUMBER: {pickupAddress.locker.boxLockerNumber}
      </p>
      <p className="text-center text-base opacity-60 mt-1">
        Use this code to drop your parcel
      </p>

      <i className="flex justify-end text-sm opacity-60 mt-4">
        Powered by {pickupAddress.locker.lockerMerchant}
      </i>
    </div>
  );
};

export default LockerComponent;
