import React, { useState, useRef, useEffect } from 'react';
import Dropdown from '../../../../assets/images/dropdown.svg';

interface DropdownProps {
  options: { value: string; label: string; icon?: JSX.Element }[];
  onSelect: (value: string) => void;
  buttonIcon?: JSX.Element;
  className?: string;
  showDropdownIcon?: boolean;
}

export const BalanceDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  buttonIcon,
  className,
  showDropdownIcon = true,
}) => {
  const [selectedValue, setSelectedValue] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option: { value: string; label: string }) => {
    setSelectedValue(option);
    onSelect(option.value);
    setIsOpen(false);
  };

  return (
    <div
      className={`relative flex items-center justify-between align-middle cursor-pointer ${className}`}
      ref={dropdownRef}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full"
      >
        {buttonIcon && <span>{buttonIcon}</span>}
        <span>{selectedValue.label}</span>
        <div className="flex align-middle items-center">
          {showDropdownIcon && (
            <img src={Dropdown} alt="" className="w-4 ml-2" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="absolute top-14 rounded-md z-10 left-0 right-0 w-full shadow-md bg-white p-1.5 text-elr-black-400">
          {options.map((option) => (
            <div
              key={option.value}
              className="px-2 py-2 flex items-center gap-3 cursor-pointer hover:bg-elr-gray focus:bg-gray-100 hover:text-elr-green focus:text-elr-green focus:outline-none"
              onClick={() => handleSelect(option)}
            >
              {option.icon && <span className="icon">{option.icon}</span>}
              <span className="text-sm">{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BalanceDropdown;
