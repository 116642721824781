import { axiosDefault as axios } from 'app/utilities/axios';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import {
  AccountType,
  AvailabilityInterface,
  BankDetails,
  Dispatcher,
} from 'app/utilities/types/dispatcherTypes';
import { Available } from 'app/utilities/types/shared';
import { toast } from 'react-toastify';
import { AccountPayloadResponse } from './types';

export const httpUpdateDispatcherProfile = async (
  payload: Dispatcher,
  userId: string
): Promise<AccountType> => {
  try {
    const response = await axios.put<Dispatcher, AccountPayloadResponse>(
      `/dispatcher/${userId}`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpOnboardDispatcher = async (
  payload: Dispatcher,
  userId: string
): Promise<AccountType> => {
  try {
    const response = await axios.post<Dispatcher, AccountPayloadResponse>(
      `/dispatcher/${userId}/onboarding`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpUpdateDispatcherBankInformation = async (
  payload: BankDetails,
  userId: string
): Promise<AccountType> => {
  try {
    const response = await axios.put<BankDetails, AccountPayloadResponse>(
      `/dispatcher/${userId}/update-bank-information`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpUpdateSenderBankInformation = async (
  payload: BankDetails,
  userId: string
): Promise<AccountType> => {
  try {
    const response = await axios.put<BankDetails, AccountPayloadResponse>(
      `/sender/${userId}/update-bank-information`,
      payload,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const parsePrefixedUrl = (param?: string) => param && param.slice(3);

export const httpGetCompanyInfo = async (
  profileId: string
): Promise<{
  companyname: string;
  fullname: string;
  address: string;
  phoneNumber: string;
  availability: Available;
}> => {
  const dispatcherUrl = `/dispatcher/${profileId}/company`;
  const url = dispatcherUrl; // If url has affiliate referral

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpSetAvailability = async (
  userId: string,
  availabilityPayload: AvailabilityInterface
) => {
  try {
    const response = await axios.post(
      `/dispatcher/${userId}/availability`,
      availabilityPayload,
      SupportHeader()
    );
    toast.success('saved successfully');
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    return false;
  }
};

export const httpGetAnalyticsTotal = async (
  userId: string,
  dispatcherId: string,
  rangeFilter: string
): Promise<any> => {
  try {
    const response = await axios.get(`/dispatcher/${userId}/analytics/total`, {
      params: { dispatcherId, rangeFilter },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

interface Params {
  userId: string;
  nextPage: number;
}

export const httpGetAllDispacther = async ({ userId, nextPage }: Params) => {
  try {
    const response = await axios.get(`/dispatcher/${userId}/get-dispatchers`, {
      params: { page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetPartners = async ({ userId, nextPage }: Params) => {
  try {
    const response = await axios.get(`/dispatcher/${userId}/partners`, {
      params: { page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetNonPartners = async ({ userId, nextPage }: Params) => {
  try {
    const response = await axios.get(`/dispatcher/${userId}/non-partners`, {
      params: { page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpAddAsPartner = async (
  userId: string,
  dispatcherId: string
) => {
  try {
    const response = await axios.post(
      `/dispatcher/${userId}/partners?dispatcherId=${dispatcherId}`,
      {},
      SupportHeader()
    );
    toast.success('Dispatcher has been added as partner.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpRemoveAsPartner = async (
  userId: string,
  dispatcherId: string
) => {
  try {
    const response = await axios.delete(
      `/dispatcher/${userId}/partners?dispatcherId=${dispatcherId}`,
      SupportHeader()
    );
    toast.success('Dispatcher has been removed as partner.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetAnalyticsData = async (
  userId: string,
  dispatcherId: string
): Promise<any> => {
  try {
    const response = await axios.get(`/dispatcher/${userId}/analytics/chart`, {
      params: { dispatcherId },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpSearchDispatchers = async (
  userId: string,
  email: string
): Promise<any> => {
  try {
    const response = await axios.get(`/dispatcher/${userId}/search`, {
      params: { email },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};
