import { useGetProfile } from 'app/hooks/user';
// import { getDispatchedRequestDuration } from 'app/modules/dispatch/components/utils';
import { OperatorView } from 'app/utilities/ComponentView';
import { currencyFormatter } from 'app/utilities/helpers';
import { DestinationInterface } from 'app/utilities/types/shared';
import { truncate } from 'lodash';
import React from 'react';
import { ElrDropdownIconToggle } from 'ui-components';
import { ElrCopy } from 'ui-components/components/ElrCopy/ElrCopy';
import { useFetchPackageType } from 'app/hooks/utils';

interface BatchItemDefaultContentInterface {
  amountPerItem: number;
  pickupAddress: {
    fullAddress: string;
    latitude: string;
    longitude: string;
  };
  parcelCode: string;
  onClickItem: () => void;
}

export const BatchItemDefaultContent: React.FC<
  BatchItemDefaultContentInterface
> = ({ amountPerItem, pickupAddress, onClickItem, parcelCode }) => {
  const { isAnOperator } = useGetProfile();

  const [itemTypes] = useFetchPackageType();
  return (
    <div
      onClick={onClickItem}
      className="text-sm cursor-pointer md:text-base py-4 border-b border-elr-gray-400 border-opacity-20 flex justify-between"
    >
      <div
        className="opacity-60 w-6/12 self-center"
        title={pickupAddress.fullAddress}
      >
        {truncate(pickupAddress.fullAddress, { length: 39 })}
      </div>

      <div className="md:w-5/12 flex flex-col md:flex-row items-center justify-between">
        <span>
          {itemTypes.map((item) => {
            if (parcelCode === item.code) {
              return <img src={item.imageSrc} key={item.code} alt="imgSrc" />;
            }
            return null;
          })}
        </span>
        <OperatorView isOperator={isAnOperator}>
          <span className="opacity-60">{currencyFormatter(amountPerItem)}</span>
        </OperatorView>
        {/* <span className="text-xs opacity-60 md:block hidden">
          {getDispatchedRequestDuration(deliverToInformation)}
        </span> */}
      </div>
    </div>
  );
};

interface BatchItemAltContentInterface {
  deliverToInformation: DestinationInterface[];
  trackingId: string;
  onClickItem: () => void;
  active: boolean;
}

export const BatchItemAltContent: React.FC<BatchItemAltContentInterface> = ({
  deliverToInformation,
  trackingId,
  onClickItem,
  active,
}) => (
  <div
    onClick={onClickItem}
    className="text-sm cursor-pointer md:text-base py-4 border-b border-elr-gray-400 border-opacity-20 flex justify-between"
  >
    <div
      className="opacity-60 self-center text-sm md:text-base mr-1.5"
      title={deliverToInformation[0].address.fullAddress}
    >
      {truncate(deliverToInformation[0].address.fullAddress, { length: 47 })}
    </div>

    <div className="flex flex-row items-center" title={trackingId}>
      <p className="text-xs md:text-sm mr-2.5 md:hidden">
        ID:...{trackingId.slice(-10)}
      </p>
      <p className="text-xs md:text-sm mr-2.5 hidden md:block">
        ID:{trackingId}
      </p>
      <span className="p-1.5 w-7 mr-1 bg-elr-gray-100 rounded flex justify-center items-center">
        <ElrCopy content={trackingId} theme="dark" />
      </span>
      <span className="opacity-50">
        <ElrDropdownIconToggle isToggled={active} toggle={onClickItem} />
      </span>
    </div>
  </div>
);
