import React from 'react';

type BoxProps = {
  title: string;
  num?: string | number;
  subText?: string;
  hyperLink: boolean;
};

export const ElrRectangleBox: React.FC<BoxProps> = ({
  title,
  num,
  subText,
  hyperLink,
}) => (
  <div className="border border-elr-black border-opacity-30 rounded-[10px] w-[100%] min-w-[150px] md:min-w-60 py-3 px-[23px]">
    <h3 className="text-14 text-elr-black text-opacity-80">{title}</h3>
    <h4 className="md:text-32 text-24 break-words text-elr-black text-opacity-80">
      {num}
    </h4>
    {hyperLink ? (
      <a className="text-12 text-elr-green underline cursor-pointer">
        {subText}
      </a>
    ) : (
      <p className="text-12 text-elr-green">{subText}</p>
    )}
  </div>
);
