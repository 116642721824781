import {
  httpFetchActivePartners,
  httpFetchRequestLocalities,
  httpProcessAPIBatch,
} from 'app/api/batch';
import { useFetchRequestQueries } from 'app/components/dashboard/hooks';
import { BATCH_LOCALITIES_REQUEST } from 'app/state/constants';
import { Request } from 'app/utilities/types/shared';
import { useCallback, useState } from 'react';

type FetchDeliveriesHook = () => [
  (userId: string) => Promise<any[]>,
  boolean,
  string | null
];

export const useFetchActivePartner: FetchDeliveriesHook = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchActivePartners = useCallback(
    async (userId: string) => {
      setError(null);
      setIsLoading(true);
      try {
        const data = await httpFetchActivePartners({
          userId,
        });
        setIsLoading(false);
        return data;
      } catch (err: any) {
        /** noop */
        setIsLoading(false);
        return undefined;
      }
    },

    []
  );
  return [fetchActivePartners, isLoading, error];
};

type FetchRequestLocalityHook = () => [
  ({
    userId,
    pickupArea,
  }: {
    userId: string;
    pickupArea: string;
  }) => Promise<Request[]>,
  boolean,
  string | null
];

export const useFetchRequestLocality: FetchRequestLocalityHook = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendQuery] = useFetchRequestQueries();

  const getBatchRequestLocalities = useCallback(
    async ({ userId, pickupArea }: { userId: string; pickupArea: string }) => {
      setError(null);
      setIsLoading(true);
      try {
        const result = await sendQuery(
          httpFetchRequestLocalities({
            userId,
            pickupArea,
          }),
          BATCH_LOCALITIES_REQUEST
        );
        setIsLoading(false);
        return result;
      } catch (err) {
        /** noop */
        setIsLoading(false);
      }
      return null;
    },
    [sendQuery]
  );
  return [getBatchRequestLocalities, isLoading, error];
};

type ProcessAPIBatchHook = () => [
  ({
    userId,
    identifier,
    roleId,
  }: {
    userId: string;
    identifier: string;
    roleId: string;
  }) => Promise<any>,
  boolean,
  string | null
];

export const useHttpProcessAPIBatch: ProcessAPIBatchHook = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const processBatch = useCallback(
    async ({
      userId,
      identifier,
      roleId,
    }: {
      userId: string;
      identifier: string;
      roleId: string;
    }) => {
      setIsLoading(true);
      setError(null);
      try {
        const result = await httpProcessAPIBatch({
          userId,
          identifier,
          roleId,
        });
        setIsLoading(false);
        return result;
      } catch (err: any) {
        setIsLoading(false);
        setError(err.message);
      }
      return null;
    },
    []
  );
  return [processBatch, isLoading, error];
};
