import { useQuery } from '@tanstack/react-query';
import { httpFetchReceivers } from 'app/api/estimate';
import { BOOKING } from 'app/constants';
import { useGetProfile } from 'app/hooks/user';
import { useGetDispatchPayload, useGetEstimate } from 'app/hooks/utils';
import { getCurrentCountryData } from 'app/utilities/country';
import { currencyFormatter } from 'app/utilities/helpers';
import { DestinationInterface } from 'app/utilities/types/shared';
import { Field, FormikValues } from 'formik';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrLoadingWithText,
  ElrLocationSelect,
} from 'ui-components';
import InputVar2 from '../InputVar2';
import '../styles.css';
import Beneficiaries from '../user-details/Beneficiaries';
import { PhoneNumberInputDestination } from '../user-details/UsersReceiverDetails';
import { AdditionalInfo } from './AdditionalInfo';

interface Props {
  index: number;
  values: FormikValues;
  phoneValidityStatus: Function;
  setValues: (data: FormikValues) => void;
  setIsAdding: Function;
}

export const DestinationDetails: React.FC<Props> = ({
  index,
  values,
  phoneValidityStatus,
  setValues,
  setIsAdding,
}) => {
  const { id } = useGetProfile();
  const [showBeneficiary, setShowBeneficiary] = useState(Boolean(id));
  const estimateDetails = useGetDispatchPayload();
  const [, estimate] = useGetEstimate({
    estimateDetails,
  });

  const onBookingRoute = window.location.pathname.split('/')[1] === BOOKING;

  const { data: receivers = [], isLoading } = useQuery({
    queryKey: ['receivers'],
    queryFn: () => httpFetchReceivers(id),
    gcTime: 5000,
  });

  const selectFriend = (
    setTheValues: (data: FormikValues) => void,
    friend: DestinationInterface
  ) => {
    const { deliverToInformation } = values;
    deliverToInformation[index] = {
      name: friend.name,
      phone: friend.phone,
      packageValue: friend.packageValue,
      deliveryNotes: friend.deliveryNotes,
    };

    const beneficiary: FormikValues = {
      ...values,
      deliverToInformation,
    };
    setShowBeneficiary(false);
    setTheValues(beneficiary);
  };

  const showForm = () => {
    setIsAdding((p: boolean[]) => [
      ...p.map((v, i) => (i === index ? true : v)),
    ]);
    selectFriend(setValues, {
      name: '',
      phone: '',
      packageValue: 1000,
      address: {
        fullAddress: '',
        latitude: '',
        longitude: '',
        duration: '',
        distance: '',
      },
      id: 0,
      state: '',
      country: '',
      order: 0,
    });
  };

  const displayEstimateCost = !onBookingRoute && Boolean(id.trim());

  return (
    <div className="overflow-hidden">
      <ElrLocationSelect
        isDisabled
        isDestination
        estimate={values.dropoffLocations[index]}
        origin={origin}
        currentValue={values.dropoffLocations[index].label}
      />
      {showBeneficiary ? (
        <div className="mt-5 mb-3">
          <div className="flex items-center justify-between mb-10">
            <p className="text-xl">Choose from previous</p>

            <ElrButton
              text="Add new receiver"
              size="sm"
              className="text-sm text-elr-purple bg-elr-purple bg-opacity-20"
              onClick={showForm}
            />
          </div>
          {isLoading ? (
            <ElrLoadingWithText text="Checking for beneficiaries" />
          ) : (
            <Beneficiaries
              beneficiaries={receivers}
              selectFriend={selectFriend}
              setValues={setValues}
              type="receiver"
            />
          )}

          {!receivers.length && (
            <div className="text-center">
              <ElrButton
                text="Add Receiver"
                size="sm"
                className="mt-4 text-sm text-elr-purple bg-elr-purple bg-opacity-20"
                onClick={showForm}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {id && (
            <div className="flex justify-end">
              <ElrButton
                text="Choose receiver"
                size="sm"
                className="text-sm text-elr-purple bg-elr-purple bg-opacity-20"
                onClick={() => setShowBeneficiary(true)}
              />
            </div>
          )}

          <div className="my-10">
            <Field
              name={`deliverToInformation.${index}.name`}
              label="Receiver name"
              required
              placeholder="Personal or company name"
              component={InputVar2}
            />
          </div>
          <div className="my-10">
            <Field
              name={`deliverToInformation.${index}.phone`}
              label="Receiver phone number"
              placeholder="Phone number"
              required
              type="tel"
              phoneValidityStatus={phoneValidityStatus}
              component={PhoneNumberInputDestination}
            />
          </div>
          <div className="flex my-10">
            <div className="w-1/2">
              <Field
                name={`deliverToInformation.${index}.packageValue`}
                label="Package value"
                placeholder="10.00"
                type="number"
                required
                inputPaddingY={3}
                preComponent="&#x20A6;"
                component={InputVar2}
              />
            </div>
          </div>
          <AdditionalInfo
            name={`deliverToInformation.${index}.deliveryNotes`}
          />
          {displayEstimateCost && (
            <div className="flex justify-between pt-3 my-8 border-t md:my-14 md:text-base border-elr-black border-opacity-40">
              <p className="opacity-60"> Order Estimate: </p>
              <p className="text-lg">
                {currencyFormatter(
                  /* @ts-ignore */
                  estimate,
                  'en-US',
                  getCurrentCountryData().currencyCode.toUpperCase()
                )}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
