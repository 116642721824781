import React, { createContext, useState } from 'react';

type RequestsCountTypes =
  | 'NEW_OPERATOR_SINGLE_REQUEST_COUNT'
  | 'UPCOMING_OPERATOR_SINGLE_REQUEST_COUNT'
  | 'NEW_COMMERCE_SINGLE_REQUEST_COUNT'
  | 'NEW_BATCH_REQUEST_COUNT'
  | 'UPCOMING_COMMERCE_BATCH_REQUEST_COUNT'
  | 'UPCOMING_OPERATOR_BATCH_REQUEST_COUNT'
  | 'UPCOMING_COMMERCE_SINGLE_REQUEST_COUNT'
  | 'DELIVERED_COMMERCE_BATCH_REQUEST_COUNT'
  | 'DELIVERED_OPERATOR_BATCH_REQUEST_COUNT'
  | 'DELIVERED_COMMERCE_SINGLE_REQUEST_COUNT'
  | 'DELIVERED_OPERATOR_SINGLE_REQUEST_COUNT';

export type RequestsCountType = {
  NEW_OPERATOR_SINGLE_REQUEST_COUNT: number;
  NEW_COMMERCE_SINGLE_REQUEST_COUNT: number;
  NEW_BATCH_REQUEST_COUNT: number;
  UPCOMING_COMMERCE_BATCH_REQUEST_COUNT: number;
  UPCOMING_OPERATOR_BATCH_REQUEST_COUNT: number;
  UPCOMING_OPERATOR_SINGLE_REQUEST_COUNT: number;
  UPCOMING_COMMERCE_SINGLE_REQUEST_COUNT: number;
  DELIVERED_COMMERCE_BATCH_REQUEST_COUNT: number;
  DELIVERED_OPERATOR_BATCH_REQUEST_COUNT: number;
  DELIVERED_COMMERCE_SINGLE_REQUEST_COUNT: number;
  DELIVERED_OPERATOR_SINGLE_REQUEST_COUNT: number;
  updateRequestCount: (type: RequestsCountTypes, newcount: number) => void;
};

const initialCountContext = {
  NEW_OPERATOR_SINGLE_REQUEST_COUNT: 0,
  NEW_COMMERCE_SINGLE_REQUEST_COUNT: 0,
  NEW_BATCH_REQUEST_COUNT: 0,
  UPCOMING_COMMERCE_BATCH_REQUEST_COUNT: 0,
  UPCOMING_OPERATOR_BATCH_REQUEST_COUNT: 0,
  UPCOMING_OPERATOR_SINGLE_REQUEST_COUNT: 0,
  UPCOMING_COMMERCE_SINGLE_REQUEST_COUNT: 0,
  DELIVERED_COMMERCE_BATCH_REQUEST_COUNT: 0,
  DELIVERED_OPERATOR_BATCH_REQUEST_COUNT: 0,
  DELIVERED_COMMERCE_SINGLE_REQUEST_COUNT: 0,
  DELIVERED_OPERATOR_SINGLE_REQUEST_COUNT: 0,
};

export const RequestCountContext = createContext<RequestsCountType>({
  ...initialCountContext,
  updateRequestCount: () => {},
});

export const RequestCountProvider = ({ children }: any) => {
  const [requestCount, setRequestCount] = useState(initialCountContext);

  const updateRequestCount = (type: RequestsCountTypes, newcount: number) => {
    setRequestCount((prevState) => ({
      ...prevState,
      [type]: newcount,
    }));
  };

  const value = React.useMemo(
    () => ({
      updateRequestCount,
      ...requestCount,
    }),
    [requestCount]
  );

  return (
    <RequestCountContext.Provider value={value}>
      {children}
    </RequestCountContext.Provider>
  );
};
