import { useQuery } from '@tanstack/react-query';
import { httpGetDispatchRequest } from 'app/api/requests';
import CancelOrderFlow from 'app/components/CancelOrderFlow';
import { Destinations } from 'app/components/Destinations';
import GenerateInvoice from 'app/components/GenerateInvoice';
import { MobileHeader } from 'app/components/Header';
import LockerComponent from 'app/components/LockerComponent';
import { useGetTrackingId } from 'app/hooks/utils';
import { RIB_TRACKING_PREFIX, currencyFormatter } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import { DestinationInterface } from 'app/utilities/types/shared';
import Logo from 'assets/images/logo.svg';
import { DateTime } from 'luxon';
import qs from 'qs';
import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ElrButton, ElrDropdownIconToggle, ElrPageTitle } from 'ui-components';
import { getDispatchedRequestDuration } from './components/utils';

const DispatchNotFound = () => (
  <div className="flex flex-col items-center w-11/12 mt-16 border rounded md:w-600 h-72 bg-elr-white-400 border-elr-green border-opacity-30">
    <div className="mb-5 text-base mt-9 md:text-sm opacity-60">
      :( Unfortunately we could not find that request.
    </div>
    <Link to={publicRoutes.track}>
      <ElrButton
        text="Track parcel"
        className="w-40 mt-10 mb-3 text-lg text-white bg-elr-black"
      />
    </Link>
    <Link to={publicRoutes.estimate}>
      <div className="text-xs text-opacity-50 text-elr-purple">
        Send a new package
      </div>
    </Link>
  </div>
);

type UrlParams = {
  requestId: string;
};

const SuccessDispatchDetails: React.FC = () => {
  const [showDestination, setShowDestinations] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const params = useParams<keyof UrlParams>() as UrlParams;

  const query = useLocation();
  const { track: trackingId }: any = qs.parse(query.search, {
    ignoreQueryPrefix: true,
  });
  const trackingUID = useGetTrackingId(trackingId);
  const { requestId } = params;
  const { data, isLoading } = useQuery({
    queryKey: ['dispatchRequest'],
    queryFn: () => fetchRequestDetail(),
    retryOnMount: false,
    refetchOnMount: false,
    notifyOnChangeProps: ['data'],
    retry: 1,
    enabled: !!trackingId,
  });

  const fetchRequestDetail = async () => {
    if (!trackingId) {
      return null;
    }

    const result = await httpGetDispatchRequest(trackingId);
    if (!result) return null;

    const renderConditions =
      result.id === requestId ||
      trackingId === result.trackingId ||
      trackingId.includes(RIB_TRACKING_PREFIX);

    return renderConditions ? result : null;
  };

  if (isLoading) return null;

  const destinations = data?.deliverToInformation || [];

  return (
    <div className="pb-48 bg-elr-gray md:flex md:flex-col md:items-center">
      <ElrPageTitle title="Errandlr - Dispatch Details" />
      <div className="w-full md:w-11/12 md:mx-auto">
        <a aria-label="Estimate" href="/estimate" className="hidden md:block">
          <img className="pt-10" src={Logo} alt="logo" />
        </a>
        <MobileHeader mdHide />
      </div>

      {data ? (
        <div className="mx-7">
          <CancelOrderFlow request={data} />
          <div className="w-full px-6 py-4 mt-4 text-center text-white rounded-md md:w-600 bg-elr-green">
            <h2 className="text-sm font-bold leading-5 text-elr-yellow">
              DISCLAIMER
            </h2>
            <p className="leading-5">
              By using our service, you agree that we will not be held
              accountable for interrupted service caused by your unavailability
              such as not answering phone calls for a delivery pickup or a
              dropoff, or providing an inaccurate address.
            </p>
          </div>
          <LockerComponent pickupAddress={data.pickupAddress} />
          <div className="pb-7 mt-2.5 md:mt-4 px-4 md:px-6 bg-elr-white-400 border border-elr-green border-opacity-30 rounded">
            <div className="py-4 text-base"> Summary </div>
            <div className="flex justify-between py-4 text-base border-t border-b md:text-sm border-elr-gray-400 border-opacity-20">
              <div className="opacity-60">
                {destinations.length} way Parcel delivery,&nbsp;
                {DateTime.fromISO(data.createdAt).toFormat('ff')}
              </div>
              {!data.businessPays && (
                <div className="opacity-60">
                  {currencyFormatter(+data.priceEstimate)}
                </div>
              )}
              <ElrDropdownIconToggle
                isToggled={showDetails}
                toggle={setShowDetails}
              />
            </div>
            {showDetails && (
              <div className="mt-3">
                <div className="text-base md:text-sm mb-3.5">
                  Delivery details
                </div>
                <div className="flex justify-between mb-5 text-base md:text-sm opacity-60">
                  <div>Delivery ID : {trackingUID}</div>
                  <div>
                    Est time: {getDispatchedRequestDuration(destinations)}
                  </div>
                </div>
                <div>
                  <div>
                    <div className="flex">
                      <div className="flex flex-col">
                        <span className="h-1.5 md:h-2 w-1.5 md:w-[0.5rem] ml-0.5 md:ml-0 mb-1.5 my-2 rounded-full bg-elr-purple" />
                        <span className="h-7 md:h-5 mx-1 w-0.3 bg-elr-black bg-opacity-40" />
                      </div>
                      <div className="flex justify-between w-full ml-3">
                        <span className="text-base md:text-sm opacity-60">
                          {data.pickupAddress.fullAddress}
                        </span>
                        <ElrDropdownIconToggle
                          isToggled={showDestination}
                          toggle={setShowDestinations}
                        />
                      </div>
                    </div>
                    {showDestination && (
                      <div className="flex h-full px-1">
                        <span className="w-0.3 bg-elr-black bg-opacity-40" />
                        <div className="flex flex-col justify-center w-full px-3 mb-6 ml-4 h-28 md:h-20 bg-elr-gray">
                          <div className="mb-2.5 text-sm"> Sender </div>
                          <div className="flex flex-col text-sm md:flex-row opacity-60">
                            <span className="w-full mb-2 md:w-5/12">
                              {data.senderInformation.data.name}
                            </span>
                            <span> {data.senderInformation.data.phone} </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {destinations.map(
                    (dest: DestinationInterface, index: number) => {
                      const last = destinations.indexOf(
                        destinations[destinations.length - 1]
                      );
                      return (
                        <div key={dest.id}>
                          <Destinations
                            destinations={dest}
                            last={last}
                            index={index}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
                {!data.businessPays && (
                  <div>
                    <div className="mt-2">
                      <span className="flex justify-between mt-7 md:mt-11 md:text-base">
                        <p className="opacity-60"> Total charged: </p>
                        <p className="text-xl">
                          {currencyFormatter(+data.priceEstimate)}
                        </p>
                      </span>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-center mt-10">
                  <GenerateInvoice data={data} />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <DispatchNotFound />
      )}
    </div>
  );
};

export default SuccessDispatchDetails;
