import { useLocation } from 'react-router-dom';
import EventForm from './EventForm';

const Events = () => {
  const { state } = useLocation();
  const event = state?.event || null;

  return (
    <div className="flex justify-center pt-16">
      <div className="flex flex-col justify-center w-full px-4 md:w-8/12 md:px-0">
        <EventForm event={event} />
      </div>
    </div>
  );
};

export default Events;
