import { Roles, useGetProfile } from 'app/hooks/user';
import {
  isCommerce,
  isDispatcher,
  isDispatcherOnly,
  isSender,
} from './helpers';

type ComponentShowForRoles = Array<
  'sender' | 'commerce' | 'dispatcher' | 'operator' | 'dispatcherOnly' | ''
>;

export const DispatcherView = ({
  children,
  role,
}: {
  children: JSX.Element;
  role: Roles;
}) => {
  if (!isDispatcher(role)) {
    return null;
  }

  return children;
};

export const DispatcherOnlyView = ({
  children,
  role,
  access,
}: {
  children: JSX.Element;
  role: Roles;
  access: string[];
}) => {
  if (!isDispatcherOnly(access, role)) {
    return null;
  }

  return children;
};

export const SenderView = ({
  children,
  role,
}: {
  children: JSX.Element;
  role: Roles;
}) => {
  if (!isSender(role)) {
    return null;
  }

  return children;
};

export const CommerceView = ({
  children,
  role,
}: {
  children: JSX.Element;
  role: Roles;
}) => {
  if (!isCommerce(role)) {
    return null;
  }

  return children;
};

export const OperatorView = ({
  children,
  isOperator,
}: {
  children: JSX.Element;
  isOperator: boolean;
}) => {
  if (!isOperator) {
    return null;
  }

  return children;
};

export const SDCView = ({
  children,
  isOperator,
}: {
  children: JSX.Element;
  isOperator: boolean;
}) => {
  if (isOperator) {
    return null;
  }

  return children;
};

export const CustomAccessView = ({
  children,
  showFor,
}: {
  children: JSX.Element;
  showFor: ComponentShowForRoles;
}) => {
  const { isAnOperator, currentAccessRole: role, access } = useGetProfile();

  const checks = {
    '': false,
    sender: isSender(role),
    dispatcher: isDispatcher(role),
    commerce: isCommerce(role),
    operator: isAnOperator,
    dispatcherOnly: isDispatcherOnly(access, role),
  };

  const renderComponent = () => {
    const anyCheckPasses = showFor.some((item) => checks[item]);
    if (!anyCheckPasses) {
      return null;
    }
    return children;
  };

  return renderComponent();
};
