import { MobileHeader } from 'app/components/Header';
import { publicRoutes } from 'app/utilities/routes';
import logo from 'assets/images/logo.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';

const FailedBilling: React.FC = () => (
  <div className="bg-elr-gray pb-48 flex flex-col items-center">
    <ElrPageTitle title="Errandlr - Dispatch Details" />
    <div className="w-full md:w-11/12 md:mx-auto">
      <img className="hidden md:block pt-10" src={logo} alt="logo" />
      <MobileHeader mdHide />
    </div>
    <div className="mt-16 w-11/12 md:w-600 h-72 bg-elr-white-400 border border-elr-green border-opacity-30 rounded flex flex-col items-center">
      <div className="mt-9 mb-5 text-base md:text-sm opacity-60">
        <p>
          :( Unfortunately we weren&apos;t able to bill your card successfully.
        </p>
      </div>
      <div className="p-4">
        <p className="text-xs text-elr-black">
          We currently do not support being able to retry a request, please bear
          with us until we release this feature.
        </p>
        <br />
        <p className="text-xs text-elr-black">
          If you have been debited by error, please reach out to us at
          support@errandlr.com about the failed transaction.
        </p>
      </div>
      <br />
      <Link to={publicRoutes.estimate}>
        <div className="text-xs text-elr-purple text-opacity-50">
          Send a new package
        </div>
      </Link>
    </div>
  </div>
);

export default FailedBilling;
