import { useQueryClient } from '@tanstack/react-query';
import { httpUserProfile } from 'app/api/user';
import { CenterLogoHeader } from 'app/components/Header';
import { Roles, useGetProfile } from 'app/hooks/user';
import { Session } from 'app/utilities/Session';
import { checkCompanyProfile, hasOperatorAccess } from 'app/utilities/helpers';
import { COMMERCE, DISPATCHER, SENDER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import apiPortalIcon from 'assets/images/apiPortalIcon.svg';
import checkCircle from 'assets/images/check-circle.svg';
import courierIcon from 'assets/images/courierIcon.svg';
import exclamationIcon from 'assets/images/exclamationIcon.svg';
// import senderIcon from 'assets/images/senderIcon.svg';
import { uniq } from 'ramda';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import * as styls from './ClassUtils';

interface PortalBoxInterface {
  userType?: typeof SENDER | typeof DISPATCHER | typeof COMMERCE;
  imgAlt: string;
  imgSrc: any;
  text: string;
  identifier: typeof SENDER | typeof DISPATCHER | typeof COMMERCE;
  defaultUser?: typeof SENDER;
}

const PortalBox: React.FC<PortalBoxInterface> = ({
  userType,
  imgAlt,
  imgSrc,
  text,
  identifier, // the access type sender, dispatcher, commerce
  defaultUser,
}) => {
  const user = useGetProfile();
  const uniqueAccess = uniq(user.access);
  const queryClient = useQueryClient();
  const history = useNavigate();
  const authenticateUserAccess = async (portalIdentifier: Roles) => {
    // if user doesn't have dispatcher in role
    // redirect to onboarding.
    const allowedRole = user.access?.find((role) => role === portalIdentifier);
    if (!allowedRole) {
      const portal = portalIdentifier;
      return history(privateRoutes.onboarding(portal));
    }

    const getUserInfo = await queryClient.fetchQuery({
      queryKey: ['user'],
      queryFn: () => httpUserProfile(portalIdentifier),
    });

    const redirectToDashboard = async () => {
      const portal = portalIdentifier;
      history(privateRoutes.dashboard(portal), {
        replace: true,
      });
    };

    const searchFields = {
      accountInfo: getUserInfo.accountInformation,
    };

    const { isNotEmpty: pendingDetails } = checkCompanyProfile(searchFields);

    const redirectIncompleteDispatcher =
      pendingDetails && allowedRole === DISPATCHER;

    return allowedRole && !redirectIncompleteDispatcher
      ? redirectToDashboard()
      : history(privateRoutes.onboarding(portalIdentifier));
  };

  const onClick = async () => {
    try {
      authenticateUserAccess(identifier);
      Session.setValue('accessUserType', identifier);
    } catch {
      /** no op, display error on here if needed */
    }
  };

  return (
    <div onClick={onClick}>
      <div className={styls.portalBoxContainer()}>
        <img
          className="absolute top-4 left-4 opacity-60"
          src={exclamationIcon}
          alt={imgAlt}
        />
        {uniqueAccess.map((users: Roles) =>
          users === userType ? (
            <img
              className="absolute top-4 right-4"
              src={checkCircle}
              key={users}
              alt="check circle"
            />
          ) : null
        )}
        <img className="mx-auto h-28" src={imgSrc} alt={imgAlt} />
        <div className="text-center">
          <p className="mt-8 text-lg opacity-40">{text}</p>
          {defaultUser && (
            <p className="mt-1 leading-5 text-elr-green text-12">default</p>
          )}
        </div>
      </div>
    </div>
  );
};

const LoginPortal: React.FC = () => {
  const user = useGetProfile();
  return (
    <div>
      <ElrPageTitle title="Errandlr - Select Portal" />
      <div className="flex flex-col items-center h-screen bg-elr-gray-500">
        <CenterLogoHeader />
        <div className="px-5 pt-12 pb-24 mb-10 bg-elr-white-400 rounded-2xl md:w-[60rem] w-[90%]">
          <div className="mb-12 text-xl text-center">Select portal</div>
          <div className="flex flex-col items-center justify-center md:flex-row md:flex-wrap">
            {/* <PortalBox
            userType="sender"
            text="Regular sender"
            imgAlt="sender"
            identifier="sender"
            imgSrc={senderIcon}
            defaultUser="sender"
          /> */}

            <PortalBox
              userType="commerce"
              text="Business"
              identifier="commerce"
              imgAlt="api"
              imgSrc={apiPortalIcon}
            />

            {hasOperatorAccess(user.access) && (
              <PortalBox
                text="Courier"
                identifier="dispatcher"
                imgAlt="courier"
                imgSrc={courierIcon}
              />
            )}

            {/* <PortalBox
            userType="commerce"
            text="Ecommerce"
            imgAlt="ecommerce"
            imgSrc={eCommerceIcon}
            identifier="commerce"
            last
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPortal;
