import React from 'react';
import sla from 'assets/documents/errandlr-online-service-sla.pdf';

const CustomerSla: React.FC = () => (
  <iframe
    src={sla}
    style={{ width: '100%', height: '100%' }}
    title="customer-sla"
  />
);

export default CustomerSla;
