import { useCancelRequest } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import { Steps } from 'app/modules/dispatch/DispatchOrder';
import { Request, RequestTypes } from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { CancelOrderBtn, CancelRequest } from './CancelOrderBtn';
import { OrderCancelled } from './OrderCancelled';

const CancelOrderFlow: React.FC<{
  request: Request;
  onTrackUpdate?: boolean;
}> = ({ request: data, onTrackUpdate }) => {
  const [currentStep, setCurrentStep] = useState('cancel');
  const [cancelReason, setCancelReason] = useState('');
  const { id: userID, accountInformation } = useGetProfile();
  const {
    trackingId: singleTrackingId,
    status,
    createdAt,
    requestOpenedBy,
  } = data;
  const trackingId = singleTrackingId;
  const { loading, cancelRequest: initiateCancel } = useCancelRequest(
    trackingId,
    accountInformation.id,
    userID,
    cancelReason
  );

  const cancelRequest = async () => {
    initiateCancel();
    setCurrentStep('reorder');
  };
  const showCancelAndTipBtn =
    status === RequestTypes.open ||
    status === RequestTypes.accepted ||
    status === RequestTypes.assigned;

  const steps: Steps = {
    cancel: (
      <CancelRequest
        showCancelAndTipBtn={showCancelAndTipBtn}
        trackingId={trackingId}
        orderStatus={status}
        userID={userID}
        onSubmit={setCurrentStep}
        loading={loading}
        onTrackUpdate={onTrackUpdate}
        createdAt={createdAt}
        directCancelFn={cancelRequest}
        cancelReason={cancelReason}
        setCancelReason={setCancelReason}
        requestOpenedBy={requestOpenedBy}
      />
    ),
    confirmCancel: (
      <CancelOrderBtn
        onTrackUpdate={onTrackUpdate}
        loading={loading}
        trackingId={trackingId}
        onSubmit={cancelRequest}
        cancelConfirm={setCurrentStep}
      />
    ),
    reorder: (
      <OrderCancelled onTrackUpdate={onTrackUpdate} trackingId={trackingId} />
    ),
  };

  const renderComponent = steps[currentStep];

  return <span>{renderComponent}</span>;
};

export default CancelOrderFlow;
