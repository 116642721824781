/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import { httpGetAvailableAgents } from 'app/api/agent';
import { BatchPrepopulatedData } from 'app/api/d';
import BatchRequestItem from 'app/components/batch/BatchRequestItem';
import { useHttpProcessAPIBatch } from 'app/hooks/requests/batch';
import {
  useFetchNewBatchRequests,
  useRefetchUpcomingBatchRequests,
} from 'app/hooks/requests/dashboard';
import { useGetProfile } from 'app/hooks/user';
import { CommerceView, OperatorView } from 'app/utilities/ComponentView';
import {
  formattedDate,
  isDispatcher,
  isDispatcherPlatform,
  isSender,
} from 'app/utilities/helpers';
import {
  AgentPayloadInterface,
  Batch,
  BatchTabsInterface,
} from 'app/utilities/types/shared';
import ArrowLeft from 'assets/images/arrowLeft.svg';
import ArrowRight from 'assets/images/arrowRight.svg';
import { useCallback, useEffect, useState } from 'react';
import { ElrPillButton } from 'ui-components';
import SlideIn from '../SlideIn/SlideIn';
import BatchGetLocality from './BatchGetLocality';
import * as styles from './ClassUtils';

type BatchDeliveryProps = {
  isAssigned?: boolean;
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
  currentTypeTab: BatchTabsInterface;
};
const BatchDelivery: React.FC<BatchDeliveryProps> = ({
  active,
  setActive,
  currentTypeTab,
}) => {
  const [showSlideIn, setShowSlideIn] = useState(false);
  const [, setAvailableAgents] = useState<AgentPayloadInterface[]>([]);
  const [isLoading] = useState(false);
  const [ProcessBatch, isLoadingProcessBatch] = useHttpProcessAPIBatch();
  const refetchUpcomingBatchRequests = useRefetchUpcomingBatchRequests();

  const {
    id: userId,
    accountInformation,
    currentAccessRole,
    isAnOperator,
  } = useGetProfile();
  const queryClient = useQueryClient();
  const {
    fetchNextPage,
    hasNextPage,
    refetch,
    docsList,
    latestData: batches,
    isFetchingNextPage,
  } = useFetchNewBatchRequests(currentTypeTab.id);
  const requestDocuments = batches?.docs;
  const [selectedDispatcherBatch, setSelectedDispatcherBatch] = useState<Batch>(
    BatchPrepopulatedData as any
  );
  const selectedBatchRequest = selectedDispatcherBatch;

  const fetchAvailableAgentOnListOpen = useCallback(() => {
    if (selectedBatchRequest) {
      httpGetAvailableAgents(userId, {
        dispatcherId: accountInformation.id,
        country: selectedBatchRequest.country,
        state: selectedBatchRequest.state,
        limit: 100,
      })
        .then((response) => {
          const { docs = [] } = response;
          setAvailableAgents(docs);
          queryClient.setQueryData(['availableAgents'], () => docs);
        })
        .catch(() => {
          /** no op */
        });
    }
  }, [selectedBatchRequest, active]);
  useEffect(() => {
    if (
      isDispatcherPlatform(currentAccessRole) &&
      selectedDispatcherBatch._id
    ) {
      fetchAvailableAgentOnListOpen();
    }
  }, [currentAccessRole, fetchAvailableAgentOnListOpen]);

  const closeSideIn = () => setShowSlideIn(false);

  const onClickBatchRequest = (item: Batch) => {
    setSelectedDispatcherBatch(item);
    setShowSlideIn(true);
  };

  // the pickup address will always be the same, so use the first address from a request.
  // this may change later.
  const _renderLoadState = () => {
    if (!requestDocuments.length) {
      return (
        <div className="pt-2 text-center opacity-60">
          <p>There are no new requests.</p>
        </div>
      );
    }
    return <div className="pt-2 text-center opacity-60"> loading... </div>;
  };

  const displayPatchRegion = (item: Batch) => {
    if (isSender(currentAccessRole) || isDispatcher(currentAccessRole)) {
      return item.area;
    }
    return item.destinationArea.area;
  };

  const onProcessBatch = async () => {
    if (!selectedBatchRequest) return;
    const response = await ProcessBatch({
      userId,
      identifier: selectedBatchRequest.id || selectedBatchRequest._id,
      roleId: accountInformation.id,
    });
    if (response) {
      closeSideIn();
      refetch();
    }
  };

  return (
    <div className="relative mt-2 overflow-y-scroll h-5/6 no-scrollbar">
      {requestDocuments.length
        ? docsList?.map((item: Batch, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <div
                onClick={() => onClickBatchRequest(item)}
                className={styles.batchItemContainer}
              >
                <div className={styles.itemStyle()} title="Batch Area">
                  {displayPatchRegion(item)}
                </div>
                <div className={styles.itemStyle()} title="Batch Items length">
                  {item.mergedInformation.length} items
                </div>

                <div className={styles.itemStyle()}>
                  {formattedDate(item.createdAt, 'dd-LL-yyyy')}
                </div>
              </div>
              {(selectedBatchRequest.id || selectedBatchRequest._id) && (
                <SlideIn
                  shown={showSlideIn}
                  close={closeSideIn}
                  leftMarginOffset="md:ml-52p"
                >
                  <div className="flex justify-between mb-3">
                    <div className="flex">
                      <img
                        className="h-3.5 mr-3.5 cursor-pointer"
                        src={ArrowLeft}
                        alt="arrowLeft"
                      />
                      <img
                        className="h-3.5 cursor-pointer"
                        src={ArrowRight}
                        alt="right-arrow"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between px-3 py-5 border rounded-t-lg bg-elr-purple-light border-elr-purple border-opacity-30">
                    <div>
                      <span className="mr-9">
                        {displayPatchRegion(selectedBatchRequest)}
                      </span>
                      <span>
                        {selectedBatchRequest.mergedInformation.length} items
                      </span>
                    </div>
                    <p>
                      {formattedDate(
                        selectedBatchRequest.createdAt,
                        'dd-LL-yyyy'
                      )}
                    </p>
                  </div>
                  <div className="overflow-y-scroll border-b border-l border-r rounded-b-lg h-540 md:h-100 border-elr-purple border-opacity-30 no-scrollbar">
                    {selectedBatchRequest.mergedInformation.map(
                      (requestInBatch: any, i: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="mx-3" key={i}>
                          <BatchRequestItem
                            request={requestInBatch}
                            index={i}
                            active={active === i}
                            setActive={setActive}
                            batchFee={
                              +requestInBatch.deliverToInformation.estimate
                            }
                          />
                        </div>
                      )
                    )}
                  </div>

                  {isDispatcherPlatform(currentAccessRole) && (
                    <OperatorView isOperator={isAnOperator}>
                      <BatchGetLocality
                        item={selectedBatchRequest}
                        isAssignLoading={isLoading}
                        closeSideIn={closeSideIn}
                        refetch={refetch}
                        refetchUpcomingBatchRequests={
                          refetchUpcomingBatchRequests
                        }
                      />
                    </OperatorView>
                  )}
                  {Boolean(selectedBatchRequest) && (
                    <CommerceView role={currentAccessRole}>
                      <div className="w-full flex flex-col md:flex-row my-7  items- justify-end">
                        <div className="w-full md:w-32 mt-5 md:mt-0">
                          <ElrPillButton
                            onClick={onProcessBatch}
                            size="lg"
                            spinnerColor="White"
                            loading={isLoadingProcessBatch}
                            text="Process Batch"
                            className="bg-elr-black h-8 w-full text-white text-base flex items-center justify-center"
                          />
                        </div>
                      </div>
                    </CommerceView>
                  )}
                </SlideIn>
              )}
            </div>
          ))
        : _renderLoadState()}
      {hasNextPage && (
        <div className="mx-auto mt-5 text-center">
          <ElrPillButton
            text="Load more"
            size="lg"
            spinnerColor="White"
            loading={isFetchingNextPage}
            onClick={() => {
              fetchNextPage();
            }}
            className="py-1 bg-opacity-90 bg-elr-black text-elr-white w-28"
          />
        </div>
      )}
    </div>
  );
};

export default BatchDelivery;
