import { size } from 'lodash';

export const searchLocation = (
  input: string
): Promise<readonly { label: string; value: string; id: string }[]> =>
  new Promise((resolve) => {
    if (size(input) < 3) {
      resolve([]);
      return;
    }

    if (window.google?.maps?.places) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      // @note this place predicts nigeria only
      // @todo manage country here
      autocompleteService.getPlacePredictions(
        { input, componentRestrictions: { country: ['ng'] } },
        (data, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            resolve([]);
            return;
          }

          const predictions = data || [];
          const normalizedPredictions = predictions.map((prediction) => ({
            label: prediction.description,
            id: prediction.place_id,
            value: prediction.place_id,
          }));
          resolve(normalizedPredictions);
        }
      );
    } else {
      resolve([]);
    }
  });
