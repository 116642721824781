import { IFetchNearbyAgentsPayload } from 'app/api/d';
import { ProfileImage } from 'app/components/ProfileImage';
import {
  useFetchInterestedRiders,
  useFetchNearbyAgents,
} from 'app/hooks/requests/agent';
import {
  AgentInterface,
  AgentPayloadInterface,
  AssignedToAgent,
  DesignatedMiddleAgent,
  NearbyAgentsInterface,
  TrackInterfaceRider,
  ValueAgentInterface,
} from 'app/utilities/types/shared';
import BlackSpinner from 'assets/images/spinner-black.svg';
import React, { forwardRef, useState } from 'react';
import { ElrAgentsSelect, ElrPillButton } from 'ui-components';

interface AssignedDriverProps {
  agent:
    | AgentInterface
    | ValueAgentInterface
    | DesignatedMiddleAgent
    | (AssignedToAgent & DesignatedMiddleAgent);
  isOnCommercePlatform?: boolean;
  withoutPadding?: boolean;
}

const initials = (name: string) => name.charAt(0).toUpperCase();

export const AssignedDriver: React.FC<AssignedDriverProps> = ({
  agent,
  isOnCommercePlatform,
  withoutPadding,
}) => {
  const headerText = isOnCommercePlatform
    ? 'Personal help contact'
    : 'Assigned driver';

  return (
    <div className={`${!withoutPadding && 'mt-6 mb-10'}`}>
      <div className="mb-4 mr-4 text-base md:text-sm opacity-60">
        {headerText}
      </div>
      <div className="flex flex-col pt-4 md:flex-row">
        <div className="flex">
          <ProfileImage
            classes="w-8 h-8 flex items-center justify-center"
            initials={`${initials(agent.firstname)}${initials(agent.lastname)}`}
          />

          <span className="pt-2 pr-5 text-sm text-elr-green md:pl-7 opacity-60">
            {agent.firstname} {agent.lastname}
          </span>
        </div>
        <div className="pt-2 text-xs opacity-60">
          <span className="pl-5 sm:pl-10 md:pl-5 xl:pl-15">{agent.phone}</span>
          <span className="pl-5 sm:pl-10">{agent.tripsCount} trips</span>
        </div>
      </div>
    </div>
  );
};

interface AssignDriverProps {
  data: any[];
  currentValue?: string;
  withoutPadding?: boolean;
  selectClassnames?: string;
  onChange: (value: AgentPayloadInterface | unknown) => void;
}
export const AssignDriver: React.FC<AssignDriverProps> = ({
  data,
  onChange,
  currentValue,
  withoutPadding,
  selectClassnames = '',
}) => (
  <div className={`${!withoutPadding && 'mt-6 mb-10'} flex items-center`}>
    <div className="mr-4 text-base md:text-sm opacity-60">Assign driver:</div>
    <ElrAgentsSelect
      data={data}
      // @ts-ignore
      containerHeight="35px"
      containerBackgroundColor="#FFFFFF"
      controlBackgroundColor="transparent"
      controlHeight="30px"
      minHeight="0"
      onChange={onChange}
      showIndicator
      currentValue={currentValue}
      className={`${selectClassnames} mt-4 w-2/3 border border-opacity-40 border-elr-green rounded-md`}
      placeholder="Select Rider"
    />
  </div>
);

export const NotAssignedDriver: React.FC<{}> = () => (
  <div className="mt-6 mb-10">
    <div className="mb-4 mr-4 text-base md:text-sm opacity-60">
      Assigned driver
    </div>
    <div className="flex flex-col pt-2 md:flex-row">
      <div className="flex">
        <ProfileImage
          initials="PJ"
          classes="w-8 h-8 flex items-center justify-center"
        />

        <span className="pt-2 text-sm text-elr-gray-300 opacity-80">
          Your delivery driver is yet to be confirmed
        </span>
      </div>
    </div>
  </div>
);

interface AssignNearRidesProps {
  isOpen: boolean;
  isBooking?: boolean;
  currentlyAssignedRider?: string;
  assignRequestToRider: (value: NearbyAgentsInterface) => void;
  isUnassignedRequest: boolean;
  fetchAgentsData: {
    userId: string;
    payload: IFetchNearbyAgentsPayload;
    requestId: string;
  };
}

const ridersTypeValues = {
  All: 'All',
  Interested: 'Interested',
  Other: 'Other',
};

export const AssignNearRider: React.FC<AssignNearRidesProps> = ({
  isOpen,
  assignRequestToRider,
  isBooking,
  isUnassignedRequest,
  currentlyAssignedRider,
  fetchAgentsData: { userId, payload, requestId },
}) => {
  const { data: agentsWithInterest, isLoading: fetchingInterestedRiders } =
    useFetchInterestedRiders(userId, requestId, !!isOpen);
  const {
    data: riders,
    isLoading,
    fetchStatus,
  } = useFetchNearbyAgents(userId, payload, !!isOpen && !isUnassignedRequest);
  const fetchingNearbyAgents = fetchStatus !== 'idle' && isLoading;

  const agentsShowedInterest = !!agentsWithInterest?.length;
  const [ridersType, setRidersType] = useState<keyof typeof ridersTypeValues>(
    isUnassignedRequest ? 'Interested' : 'All'
  );

  const nearbyRiders = agentsShowedInterest
    ? riders?.filter((rider: NearbyAgentsInterface) =>
        agentsWithInterest?.some(
          (agent: NearbyAgentsInterface) => agent.id !== rider.id
        )
      )
    : riders;

  return (
    <span>
      {fetchingNearbyAgents || fetchingInterestedRiders ? (
        <div className="flex flex-col items-center justify-center h-full">
          <img src={BlackSpinner} className="h-5 animate-spin" alt="spinner" />
          <p className="mt-5 text-center text-elr-black opacity-60">
            Checking for riders near pick up location
          </p>
        </div>
      ) : (
        <div>
          <div className="flex gap-x-14 px-6 py-4 rounded-t-lg bg-elr-purple-light max-md:mt-4">
            {!isUnassignedRequest && (
              <button
                type="button"
                onClick={() => setRidersType('All')}
                disabled={ridersType === ridersTypeValues.All}
                className="enabled:opacity-60"
              >
                All riders
              </button>
            )}
            <button
              type="button"
              onClick={() => setRidersType('Interested')}
              disabled={ridersType === ridersTypeValues.Interested}
              className="enabled:opacity-60"
            >
              <span className="hidden md:inline">Interested riders</span>
              <span className="md:hidden">Interested</span>
            </button>
            {!isUnassignedRequest && (
              <button
                type="button"
                onClick={() => setRidersType('Other')}
                disabled={ridersType === ridersTypeValues.Other}
                className="enabled:opacity-60"
              >
                <span className="hidden md:inline">Other riders</span>
                <span className="md:hidden">Others</span>
              </button>
            )}
          </div>
          <div className="overflow-y-scroll md:border-b md:border-x rounded-b-lg h-540 md:h-100 border-elr-black border-opacity-40 no-scrollbar">
            <div className="px-5">
              {ridersType !== ridersTypeValues.Other && (
                <>
                  <p className="mt-5 mb-2 text-sm opacity-60">{`INTERESTED RIDERS (${agentsWithInterest.length})`}</p>
                  <div>
                    {agentsWithInterest.map((agent: NearbyAgentsInterface) => (
                      <AssignRiderDisplay
                        key={agent.id}
                        rider={agent}
                        assignRequestToRider={assignRequestToRider}
                        isBooking={!!isBooking}
                        currentlyAssignedRider={currentlyAssignedRider}
                      />
                    ))}
                  </div>
                </>
              )}

              {ridersType !== ridersTypeValues.Interested && (
                <>
                  <p className="mt-5 mb-2 text-sm opacity-60">{`OTHER RIDERS (${nearbyRiders.length})`}</p>
                  <div>
                    {nearbyRiders.map((agent: NearbyAgentsInterface) => (
                      <AssignRiderDisplay
                        key={agent.id}
                        rider={agent}
                        assignRequestToRider={assignRequestToRider}
                        isBooking={!!isBooking}
                        currentlyAssignedRider={currentlyAssignedRider}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </span>
  );
};

interface AssignRiderDisplayProps {
  rider: NearbyAgentsInterface;
  isBooking: boolean;
  currentlyAssignedRider: string | undefined;
  assignRequestToRider: (value: NearbyAgentsInterface) => void;
}

export const AssignRiderDisplay = forwardRef<
  HTMLDivElement,
  AssignRiderDisplayProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function AssignRiderDisplay(
    { rider, isBooking, currentlyAssignedRider, assignRequestToRider },
    ref
  ) {
    const [savedAssignedRider, setSavedAssignRider] = useState('');

    const riderCurrentlyAssigned = (riderId: string) =>
      currentlyAssignedRider === riderId;

    const selectedRider = (riderId: string) => savedAssignedRider === riderId;

    const onAssignRequestToRider = (chosenRider: NearbyAgentsInterface) => {
      setSavedAssignRider(chosenRider.id);
      assignRequestToRider(chosenRider);
    };

    return (
      <div
        key={rider.id}
        ref={ref}
        className="grid grid-cols-column-four mb-4 items-center pb-3 md:pb-1.5 max-md:border-b border-elr-gray-600 border-opacity-20"
      >
        <div className="grid md:grid-cols-column-three col-span-3">
          <p className="text-black text-opacity-80 max-md:pb-0.5">{`${rider.name}`}</p>
          <p className="hidden md:flex text-black opacity-60 text-sm leading-normal pt-px md:justify-self-center">
            {rider.phone}
          </p>
          <div className="hidden md:flex text-black opacity-60 text-sm leading-normal pt-px md:justify-self-center items-center">
            <div className="h-1.5 w-1.5 mx-2 rounded-full bg-elr-black bg-opacity-80" />
            <span>
              {rider.distance?.text ? rider.distance?.text : '-km'} away
            </span>
          </div>
        </div>
        {!riderCurrentlyAssigned(rider.id) && (
          <ElrPillButton
            size="sm"
            loading={isBooking && selectedRider(rider.id)}
            disabled={isBooking && selectedRider(rider.id)}
            onClick={() => onAssignRequestToRider(rider)}
            spinnerColor="White"
            text="Assign"
            className="py-0.5 w-20 md:w-24 text-white text-sm flex self-start items-center justify-center bg-elr-black justify-self-end"
          />
        )}
        <div className="flex md:hidden flex-row gap-x-5 row-start-2">
          <p className="text-black opacity-60 text-sm leading-normal pt-px md:justify-self-center">
            {rider.phone}
          </p>
          <div className="text-black opacity-60 text-sm leading-normal pt-px md:justify-self-center items-center">
            <div className="h-1.5 w-1.5 mx-2 rounded-full bg-elr-black bg-opacity-80" />
            <span>
              {rider.distance?.text ? rider.distance?.text : '-km'} away
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export const InModalAssignRiderDisplay: React.FC<AssignRiderDisplayProps> = ({
  rider,
  isBooking,
  currentlyAssignedRider,
  assignRequestToRider,
}) => {
  const [savedAssignedRider, setSavedAssignRider] = useState('');

  const riderCurrentlyAssigned = (riderId: string) =>
    currentlyAssignedRider === riderId;

  const selectedRider = (riderId: string) => savedAssignedRider === riderId;

  const onAssignRequestToRider = (chosenRider: NearbyAgentsInterface) => {
    setSavedAssignRider(chosenRider.id);
    assignRequestToRider(chosenRider);
  };

  return (
    <div key={rider.id} className="flex justify-between mb-4">
      <div className="flex flex-col">
        <p className="mb-1 text-black text-opacity-80">{`${rider.name}`}</p>
        <p className="flex">
          <span className="h-1.5 w-1.5 ml-0.5 md:ml-0 mt-1.5 my-2 rounded-full bg-elr-black bg-opacity-60" />
          &nbsp;
          <span className="text-sm text-black opacity-60">
            {rider.distance?.text ? `${rider.distance.text} away,` : ''}
            &nbsp;&nbsp;
            {rider.phone}
          </span>
        </p>
      </div>
      {!riderCurrentlyAssigned(rider.id) && (
        <ElrPillButton
          size="sm"
          loading={isBooking && selectedRider(rider.id)}
          disabled={isBooking && selectedRider(rider.id)}
          onClick={() => onAssignRequestToRider(rider)}
          spinnerColor="White"
          text="Assign"
          className="flex items-center justify-center w-20 h-8 text-base text-white bg-elr-purple md:w-28"
        />
      )}
    </div>
  );
};

interface RiderDisplayProps {
  rider: TrackInterfaceRider;
}

export const RiderDisplay: React.FC<RiderDisplayProps> = ({ rider }) => (
  <div className="flex flex-col md:flex-row pb-1.5 md:items-center">
    <div className="flex items-center">
      <ProfileImage
        classes="w-8 h-8 flex items-center justify-center"
        initials={`${initials(rider.firstname)}`}
      />
      <span className="pr-2 text-sm md:pl-1 opacity-60">{rider.firstname}</span>
    </div>
    <div className="text-xs opacity-60">
      <span className="pl-10 md:pl-2">{rider.phone}</span>
    </div>
  </div>
);
