import { useMutation, useQuery } from '@tanstack/react-query';
import { httpAddSender, httpFetchSenders } from 'app/api/estimate';
import { useGetProfile } from 'app/hooks/user';
import {
  HttpLocationValues,
  SenderType,
  UserDetailsValues,
} from 'app/utilities/types/shared';
import { Form, Formik, FormikValues } from 'formik';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrButtonSwitch,
  ElrLoadingWithText,
  ElrLocationSelect,
  ElrPillButton,
} from 'ui-components';
import * as Yup from 'yup';
import { isForMe } from '../utils';
import Beneficiaries from './Beneficiaries';
import StepsNavigation from './StepsNavigation';
import { UserName } from './UserName';
import { UserPhone, isPhoneValid } from './UserPhone';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3)
    .matches(/^([a-zA-Z]+[-']?[a-zA-Z]+[\s]?)+$/, 'Must be letters only')
    .required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    { message: 'Must be a valid email' }
  ),
});

interface Props {
  onSubmit: (sender: UserDetailsValues) => void;
  onEditPickupAddress: () => void;
  estimateRoutes: HttpLocationValues;
}

export const UserDetails: React.FC<Props> = ({
  onSubmit,
  estimateRoutes,
  onEditPickupAddress,
}) => {
  const profile = useGetProfile();
  const [senderType, setSenderType] = useState<SenderType>('Me');
  const [showFriends, setShowFriends] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  const { data: senders = [], isLoading } = useQuery({
    queryKey: ['senders'],
    queryFn: () => httpFetchSenders(profile.id),
    gcTime: 5000,
  });

  const senderMutation = useMutation({ mutationFn: httpAddSender });

  const userInitialValues = () => {
    const user = profile.id;
    return {
      email: (user && profile.email) || '',
      name: (user && `${profile.firstname} ${profile.lastname}`) || '',
      phone: (user && profile.phone) || '',
    };
  };

  const { email, name, phone } = userInitialValues();

  const setFormState = async (
    _values: FormikValues,
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    try {
      submitForm();
      return null;
    } catch (error) {
      return error;
    }
  };

  const switchToFriend = () => {
    setShowFriends(true);
    setIsAdding(false);
    setSenderType('Friend');
  };

  const switchToMe = (setValues: (data: any) => void) => {
    setSenderType('Me');
    setIsAdding(false);
    setValues({ email, name, phone });
  };

  const selectFriend = (
    setValues: (data: any) => void,
    friend: object = { email: '', name: '', phone: '' }
  ) => {
    setShowFriends(false);

    setValues(friend);
  };

  const canShowDetails = () =>
    isForMe(senderType) || (!isForMe(senderType) && !showFriends);

  const addFriend = async (
    values: FormikValues,
    submitForm: () => Promise<void>
  ) => {
    const s = await senderMutation.mutateAsync({
      userId: profile.id,
      beneficiary: values,
    });

    setIsAdding(false);

    if (s) {
      setFormState(values, submitForm);
    }
  };

  const addNewFriend = (setValues: (data: any) => void) => {
    setIsAdding(true);
    selectFriend(setValues);
  };

  const next = (values: FormikValues, submitForm: () => Promise<void>) => {
    const newValues = {
      email: values.email || `${values.phone}@errandlr.com`,
      name: values.name,
      phone: values.phone,
    };
    if (!profile?.id) {
      return submitForm();
    }
    return isAdding
      ? addFriend(newValues, submitForm)
      : setFormState(newValues, submitForm);
  };

  return (
    <div className="mt-5 md:mt-0 mx-7 md:mx-16 ">
      <Formik
        enableReinitialize
        initialValues={{
          email,
          name,
          phone,
        }}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values, submitForm, setValues }) => (
          <Form>
            <div className="mb-10 md:px-5">
              <div className="relative z-30 flex justify-end top-11 right-2 md:right-4">
                <ElrPillButton
                  size="lg"
                  text="Change pickup"
                  className="px-3 py-1 bg-elr-purple bg-opacity-10 text-elr-purple"
                  onClick={onEditPickupAddress}
                />
              </div>
              <ElrLocationSelect
                isDisabled
                currentValue={truncate(estimateRoutes.pickupLocation.label, {
                  length: 30,
                })}
              />
            </div>

            {profile?.id && (
              <div className="mb-8 md:px-5">
                <ElrButtonSwitch
                  size="lg"
                  text="Send for myself"
                  isSelected={isForMe(senderType)}
                  className="w-1/2 px-10 py-3 text-xl rounded-l-lg"
                  onClick={() => switchToMe(setValues)}
                />
                <ElrButtonSwitch
                  size="lg"
                  text="Send for friend"
                  className="w-1/2 px-10 py-3 text-xl rounded-r-lg"
                  isSelected={!isForMe(senderType)}
                  onClick={switchToFriend}
                />
              </div>
            )}

            {!isForMe(senderType) && (
              <div className="mx-10 my-4">
                <div className="flex items-center justify-between mb-10">
                  <p className="text-xl">Select a sender</p>
                  {!isAdding ? (
                    <ElrButton
                      text="+ Add new friend"
                      size="sm"
                      className="text-sm text-elr-purple bg-elr-purple bg-opacity-20"
                      onClick={() => addNewFriend(setValues)}
                    />
                  ) : (
                    <ElrButton
                      text="Back"
                      size="sm"
                      className="text-sm text-elr-purple bg-elr-purple bg-opacity-20"
                      onClick={switchToFriend}
                    />
                  )}
                </div>
                {isLoading ? (
                  <ElrLoadingWithText text="Checking for beneficiaries" />
                ) : (
                  <div>
                    {showFriends && (
                      <Beneficiaries
                        beneficiaries={senders}
                        selectFriend={selectFriend}
                        setValues={setValues}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {canShowDetails() && (
              <>
                <UserName />
                <UserPhone phoneValue={values.phone} />
                <StepsNavigation
                  containerClassName="md:mx-7"
                  nextDisabled={
                    Boolean(Object.keys(errors).length) ||
                    !isPhoneValid(values.phone)
                  }
                  next={() => next(values, submitForm)}
                />
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
