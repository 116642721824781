import { ENV } from '../constants';

export const isProduction = ENV === 'production';
export const isLocal = ENV === 'development';
export const isStaging = ENV === 'staging';
export const isTest = ENV === 'test';
export const isProdOrStage = isProduction || isStaging;
export const isLocalOrTest = isLocal || isTest;
export const isLocalOrStaging = isLocal || isStaging;
export const isLocalOrTestorStaging = isLocal || isTest || isStaging;
