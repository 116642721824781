import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddAgentModal from 'app/components/AgentFormModal';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { ProfileImage } from 'app/components/ProfileImage';
import { useGetProfile } from 'app/hooks/user';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrMainSelect,
  ElrPageTitle,
  ElrPillButton,
} from 'ui-components';
import { useFetchAgentsData } from 'app/hooks/requests/agent';
import { AVAILABLE_RIDERS, YOUR_RIDERS } from 'app/constants';
import { initials } from '../../../utilities/helpers';
import DeleteAgent from './DeleteAgent';
import EditAgent from './EditAgent';
import { riderButtonStyle } from './ClassUtils';
import ViewAgent from './ViewAgent';

const AgentsDetails: React.FC = () => {
  const profile = useGetProfile();
  const [showModal, setShowModal] = useState(false);
  const [showEditAgentModal, setShowEditAgentModal] = useState(false);
  const [showViewAgentModal, setShowViewAgentModal] = useState(false);
  const [showDeleteAgentModal, setShowDeleteAgentModal] = useState(false);
  const [active, setActive] = useState(YOUR_RIDERS);
  const [currentAgent, setCurrentAgent] = useState(0);
  // @todo use cache state to update the UI,
  // fetching should only happen on first page load or refresh
  const {
    docsList,
    latestData: agentsData,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useFetchAgentsData();

  const onAddCompleted = () => {
    refetch();
  };

  const handleChange = () => setShowModal(!showModal);

  return (
    <div>
      <ElrPageTitle title="Errandlr - Personal Profile" />
      <ProfileNavigationMenu>
        <div className="h-1000">
          <TopNavigationMenu />
          <div className="flex justify-between items-end">
            <div className="px-8 md:px-24 pt-10 pb-3 text-xl">
              Agents Details
            </div>
            <div className="lg:hidden pb-3 px-8">
              <ElrPillButton
                size="lg"
                text="Add Riders"
                className="bg-elr-purple bg-opacity-10 text-elr-purple px-3 py-1"
                onClick={handleChange}
              />
            </div>
          </div>
          <div className="mx-4 md:mx-24 pb-6 pt-4 flex gap-3">
            <ElrButton
              className={riderButtonStyle(active, YOUR_RIDERS)}
              onClick={() => setActive(YOUR_RIDERS)}
              text={YOUR_RIDERS}
            />
            <ElrButton
              className={riderButtonStyle(active, AVAILABLE_RIDERS)}
              onClick={() => setActive(AVAILABLE_RIDERS)}
              text={AVAILABLE_RIDERS}
            />
          </div>
          {showModal && (
            <AddAgentModal
              onCompleted={onAddCompleted}
              isOpen
              onClose={handleChange}
            />
          )}
          <div className="mx-4 md:mx-24">
            <div className="h-16 flex mb-10 justify-between items-center border-t border-b border-elr-gray-600 border-opacity-60">
              <div className="text-elr-green opacity-60 hidden md:inline-block">
                <span className="text-elr-black">Agents: </span>
                {(agentsData && agentsData?.totalDocs) || 0}
              </div>
              <div className="text-elr-black w-64 flex items-center">
                <span className="hidden md:inline-block opacity-60">
                  Order by:
                </span>
                <span className="order-by-select ml-3 z-50">
                  <ElrMainSelect
                    data={['Unique ID', 'Trips']}
                    // @ts-ignore
                    containerHeight="35px"
                    containerBackgroundColor="#FFFFFF"
                    controlBackgroundColor="transparent"
                    controlHeight="33px"
                    minHeight="0"
                    className="w-108 mt-4 border border-opacity-40 border-elr-green rounded-md"
                    placeholder="Name"
                  />
                </span>
              </div>
              <div className="text-elr-black opacity-60 flex items-center w-600 md:w-96">
                <span className="hidden md:inline-block">Find</span>
                <ElrInput
                  onChange={() => {}}
                  height="35px"
                  placeholder="search name or ID"
                  className="bg-elr-white-400 border border-opacity-60 border-elr-green ml-4 rounded"
                />
              </div>
              <div className="hidden lg:block">
                <ElrPillButton
                  size="lg"
                  text="Add Riders"
                  className="bg-elr-purple bg-opacity-10 text-elr-purple px-3 py-1"
                  onClick={handleChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-medium-auto-fill md:grid-cols-auto-fill gap-2 md:gap-4">
              {agentsData &&
                docsList?.map((agent, index) => (
                  <div
                    key={agent.id}
                    className="overlay h-28 mb-6 border border-elr-green border-opacity-30 rounded-md"
                  >
                    {showViewAgentModal && currentAgent === index && (
                      <ViewAgent
                        active={active}
                        agent={agent}
                        isOpen={showViewAgentModal}
                        onClose={() => setShowViewAgentModal(false)}
                      />
                    )}
                    <div className="overlay-first-elem bg-elr-white-400 opacity-90 rounded-md text-center translate-x-0 translate-y-10">
                      <button
                        type="button"
                        onClick={() => {
                          setCurrentAgent(index);
                          setShowViewAgentModal((prev) => !prev);
                        }}
                      >
                        <VisibilityOutlinedIcon
                          className="mr-4 cursor-pointer p-1 bg-elr-green bg-opacity-10"
                          htmlColor="#054853"
                          fontSize="medium"
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setCurrentAgent(index);
                          setShowEditAgentModal((prev) => !prev);
                        }}
                      >
                        <EditOutlinedIcon
                          className="mr-4 p-1 cursor-pointer bg-elr-green bg-opacity-10"
                          htmlColor="#054853"
                          fontSize="medium"
                        />
                      </button>
                      {showEditAgentModal && currentAgent === index ? (
                        <EditAgent
                          isOpen={showEditAgentModal}
                          onClose={() => setShowEditAgentModal(false)}
                          onCompleted={() => {
                            setShowEditAgentModal(false);
                            refetch();
                          }}
                          agent={agent}
                        />
                      ) : null}
                      <button
                        type="button"
                        onClick={() => {
                          setCurrentAgent(index);
                          setShowDeleteAgentModal((prev) => !prev);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon
                          className="cursor-pointer p-1 bg-elr-green bg-opacity-10"
                          htmlColor="#054853"
                          fontSize="medium"
                        />
                      </button>
                      {showDeleteAgentModal && currentAgent === index ? (
                        <DeleteAgent
                          userId={profile!.id}
                          agentId={agent.id}
                          isOpen={showDeleteAgentModal}
                          onClose={() => setShowDeleteAgentModal(false)}
                          onCompleted={() => {
                            setShowDeleteAgentModal(false);
                            refetch();
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="overlay-second-elem flex ml-3 md:ml-5 mt-5 mr-1.5">
                      <ProfileImage
                        initials={`${initials(agent.firstname)}${initials(
                          agent.lastname
                        )} `}
                        classes="px-1 flex items-center justify-center"
                      />
                      <div>
                        <div className="opacity-60 text-sm mb-1">
                          {`${agent.firstname} ${agent.lastname}`}
                        </div>
                        <div className="opacity-40 text-sm mb-1 md:mb-2">
                          {agent.agentUUID}
                        </div>
                        <div className="text-elr-green text-sm opacity-80">
                          {agent.tripsCount} trips
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {hasNextPage && (
              <div className="mx-auto text-center mt-5">
                <button
                  onClick={() => {
                    fetchNextPage();
                  }}
                  className="bg-elr-green hover:bg-elr-green-500 rounded-3xl px-8 py-2 shadow-lg text-white inline-block"
                  type="button"
                >
                  Load more
                </button>
              </div>
            )}
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};
export default AgentsDetails;
