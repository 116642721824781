import { axiosDefault as axios } from 'app/utilities/axios';
import { Request } from 'app/utilities/types/shared';
import { toast } from 'react-toastify';

export const httpTrackParcel = async (
  trackingId: string | undefined
): Promise<Request | null> => {
  try {
    const response = await axios.get(`/request?trackingId=${trackingId}`);
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpReOpenRequest = async (trackingId: string): Promise<any> => {
  try {
    const response = await axios.post(`/request/reopen`, {
      trackingId,
    });
    toast.success('Request is now open to other delivery services');
    return response?.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    return null;
  }
};
