import spinner from 'assets/images/spinner-black.svg';
import React from 'react';
import { ElrButton } from 'ui-components';

const SaveForLaterTooltip = () => (
  <p className="bg-elr-gray-500 text-xs text-elr-black text-opacity-50 p-2 rounded-md">
    Fill the required section(s) then you can skip the rest of this process
  </p>
);

export const SaveForLater: React.FC<{
  isLoading: boolean;
  onSaveForLater: () => void;
}> = ({ isLoading, onSaveForLater }) => (
  <div className="flex justify-end mb-10 text-elr-purple mt-5">
    <SaveForLaterTooltip />
    <span>
      {isLoading && (
        <span className="mt-2 -mr-2">
          <img src={spinner} className="animate-spin h-3.5" alt="spinner" />
        </span>
      )}
      <ElrButton
        text="save for later"
        size="xm"
        onClick={onSaveForLater}
        disabled={isLoading}
        className="bg-elr-white-400 w-12 h-7 text-elr-purple opacity-70"
      />
    </span>
  </div>
);
