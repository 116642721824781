import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import React, { useEffect, useState } from 'react';
import {
  ElrButtonSwitch,
  ElrInput,
  ElrLoadingWithText,
  ElrModal,
  ElrPageTitle,
  ElrPillButton,
} from 'ui-components';
import { ALL, NON_PARTNERS, PARTNERS } from 'app/constants';
import {
  Tabs,
  useAddAsPartner,
  useFetchDispatchersAndPartners,
  useRemoveAsPartner,
  useSearchDispatchers,
} from 'app/hooks/dispatch';
import { useGetProfile } from 'app/hooks/user';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DispatcherBox from './components/DispatcherBox';

const SearchSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email'),
});

const Partners: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState<Tabs>(ALL);
  const [action, setAction] = useState('');
  const [currentDispatcher, setCurrentDispatcher] = useState<any>('');
  const [searchResult, setSearchResult] = useState<Object>({});

  const {
    refetch,
    docsList,
    latestData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useFetchDispatchersAndPartners(tab);

  const [addAsPartner, loading] = useAddAsPartner();
  const [removeAsPartner, loadingRemovePartner] = useRemoveAsPartner();
  const [searchDispatchers, loadingSearch] = useSearchDispatchers();
  const { id: userId } = useGetProfile();

  const { handleSubmit, isValid, handleChange, values, errors } = useFormik<{
    email: '';
  }>({
    initialValues: { email: '' },
    validationSchema: SearchSchema,
    onSubmit: (value) =>
      searchDispatchers(userId, value.email).then((response: any) =>
        setSearchResult(response)
      ),
  });

  const onClickDispatcher = (dispatcher: any) => {
    setCurrentDispatcher(dispatcher);
    setShowModal(true);
  };

  useEffect(() => {
    setSearchResult({});
  }, [values.email]);

  const modalText: { [key: string]: string } = {
    add: 'add',
    remove: 'remove',
  };

  const modalParagraph: { [key: string]: string } = {
    add: 'to your partners',
    remove: 'from your partners',
  };

  const loadingTexts: { [key in Tabs]: string } = {
    All: 'Dispatchers',
    Partners: 'Partners',
    'Non-Partners': 'Non-Partners',
  };

  const searchResultsCheck = Object.keys(searchResult || {}).length > 0;
  const handleChangeTab = (tabName: Tabs, data: any) => {
    const switchTab: {
      [key in Tabs]: {
        companyName: string;
        country: string;
        area: string;
      };
    } = {
      All: {
        companyName: data?.companyName ?? '',
        country: data?.mainAddress?.country ?? '',
        area:
          (data?.mainAddress?.localGovt || data?.mainAddress?.localGovtArea) ??
          '',
      },
      Partners: {
        companyName: data?.dispatcherAccount?.companyName ?? '',
        country: data?.dispatcherAccount?.mainAddress?.country ?? '',
        area:
          (data?.dispatcherAccount?.mainAddress?.localGovt ||
            data?.mainAddress?.localGovtArea) ??
          '',
      },
      'Non-Partners': {
        companyName: data?.companyName ?? '',
        country: data?.mainAddress?.country ?? '',
        area:
          (data?.mainAddress?.localGovt || data?.mainAddress?.localGovtArea) ??
          '',
      },
    };

    return switchTab[tabName];
  };
  const handleSearchData = (data: any) => {
    const companyName = data?.companyName ?? '';
    const country = data?.country ?? '';
    const area =
      (data?.mainAddress?.localGovt || data?.mainAddress?.localGovtArea) ?? '';
    return { companyName, country, area };
  };

  const searchData = handleSearchData(searchResult);

  const ModalView: React.FC<{ data: any }> = ({ data }) => {
    const companyName: { [key in Tabs]: string } = {
      All: data?.companyName ?? '',
      Partners: data?.dispatcherAccount?.companyName ?? '',
      'Non-Partners': '',
    };

    const handleAddAsPartner = async () => {
      modalText[action] === 'add'
        ? addAsPartner(userId, data.id || data._id).then(() => {
            setShowModal(false);
            refetch();
          })
        : removeAsPartner(userId, data.dispatcherAccount._id).then(() => {
            setShowModal(false);
            refetch();
          });
    };

    return data ? (
      <ElrModal
        height="h-200"
        width="w-304"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="flex flex-col h-full w-full justify-center align-center">
          <div className="flex flex-col gap-2 text-center">
            <h3>Are you sure you want to {modalText[action]}</h3>
            <div className="flex justify-center gap-1">
              <span>{`${companyName[tab]}`}</span>
              <p>{modalParagraph[action]}</p>
            </div>
          </div>
          <div className="flex justify-center gap-8 mt-6 pb-4">
            <ElrPillButton
              size="sm"
              text="Cancel"
              onClick={() => setShowModal(false)}
              className="text-elr-black border border-elr-black border-opacity-40 h-10 px-8 flex items-center justify-center"
            />
            <ElrPillButton
              size="sm"
              loading={
                modalText[action] === 'add' ? loading : loadingRemovePartner
              }
              onClick={handleAddAsPartner}
              text={modalText[action].replace(/\w/, (c: string) =>
                c.toUpperCase()
              )}
              className="bg-elr-black h-10 px-8 text-white text-base flex items-center justify-center"
            />
          </div>
        </div>
      </ElrModal>
    ) : null;
  };

  return (
    <div>
      <ElrPageTitle title="Errandlr - Partners List" />
      <ProfileNavigationMenu>
        <div className="h-1000">
          <TopNavigationMenu />
          {showModal && <ModalView data={currentDispatcher} />}
          <div className="flex gap-8 items-start justify-between flex-col pt-7 md:pt-10 pb-7 mx-4 md:mx-24">
            <h1 className="text-xl">Dispatchers</h1>
            <div className="flex gap-5 flex-wrap">
              <ElrButtonSwitch
                text="All dispatchers"
                isSelected={tab === ALL}
                onClick={() => setTab(ALL)}
                disabled={searchResultsCheck}
                className="rounded-full border-opacity-100 py-0"
              />
              <ElrButtonSwitch
                text={PARTNERS}
                isSelected={tab === PARTNERS}
                onClick={() => setTab(PARTNERS)}
                disabled={searchResultsCheck}
                className="rounded-full border-opacity-100 py-0"
              />
              <ElrButtonSwitch
                text={NON_PARTNERS}
                isSelected={tab === NON_PARTNERS}
                onClick={() => setTab(NON_PARTNERS)}
                disabled={searchResultsCheck}
                className="rounded-full border-opacity-100 py-0"
              />
            </div>
          </div>
          <div className="mx-4 md:mx-24">
            <div className="h-20 flex mb-10 gap-x-20 items-center border-t border-b border-elr-gray-600 border-opacity-60">
              <div className="text-elr-green opacity-60 hidden md:inline-block">
                <span className="text-elr-black">Total dispatchers: </span>
                {(latestData && latestData?.totalDocs) || 0}
              </div>
              <div className="flex items-center">
                <span className="text-elr-black opacity-60 hidden md:inline-block whitespace-nowrap">
                  Search dispatcher
                </span>
                <form
                  className="flex justify-between items-center gap-6"
                  onSubmit={handleSubmit}
                >
                  <div className="relative">
                    <ElrInput
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      height="35px"
                      placeholder="search by email"
                      className="bg-elr-white-400 border border-opacity-60 border-elr-green ml-4 rounded"
                    />
                    {errors?.email && (
                      <span className="ml-4 text-xs text-elr-error absolute">
                        {errors?.email}
                      </span>
                    )}
                  </div>
                  <ElrPillButton
                    text="Search"
                    size="lg"
                    loading={loadingSearch}
                    submit
                    disabled={!isValid || values.email === ''}
                    spinnerColor="White"
                    className="bg-elr-black px-4 py-2 text-12 text-white inline-block"
                  />
                </form>
              </div>
            </div>
            <div className="grid grid-cols-medium-auto-fill md:grid-cols-auto-fill gap-2 md:gap-4">
              {searchResultsCheck ? (
                <DispatcherBox
                  data={searchResult}
                  tabData={searchData}
                  onClickDispatcher={onClickDispatcher}
                  setAction={setAction}
                />
              ) : (
                latestData &&
                docsList?.map((data) => {
                  const tabData = handleChangeTab(tab, data);
                  return (
                    <DispatcherBox
                      key={data.id}
                      tab={tab}
                      data={data}
                      tabData={tabData}
                      onClickDispatcher={onClickDispatcher}
                      setAction={setAction}
                    />
                  );
                })
              )}
            </div>
            {isLoading && (
              <div className="flex justify-center items-center mt-48">
                <ElrLoadingWithText
                  text={`Checking for ${loadingTexts[tab]}`}
                />
              </div>
            )}
            {Object.keys(searchResult || {}).length === 0 && (
              <div>
                {hasNextPage && (
                  <div className="mx-auto text-center mt-5">
                    <ElrPillButton
                      text="Load more"
                      size="lg"
                      loading={isFetchingNextPage}
                      spinnerColor="White"
                      onClick={() => {
                        fetchNextPage();
                      }}
                      className="bg-elr-green px-8 py-2 shadow-lg text-white inline-block"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default Partners;
