import { EstimateProps, SenderType } from 'app/utilities/types/shared';

export const getTotalDistance = (routes: EstimateProps) =>
  routes.dropoffLocations.reduce((memo, route) => memo + route.distance, 0);

export const getTotalDuration = (routes: EstimateProps) => {
  const totalMinutes = routes.dropoffLocations.reduce(
    (memo, route) => memo + route.duration,
    0
  );
  return formatDuration(totalMinutes);
};

export const getDispatchedRequestDuration = (
  destinations: Array<{
    address: {
      duration: number | string;
    };
  }>
) => {
  const totalMinutes = destinations.reduce(
    (memo, route) => memo + +route.address.duration,
    0
  );
  return formatDuration(totalMinutes);
};

export const formatDuration = (totalMinutes: number): string => {
  if (isNaN(totalMinutes)) return '~mins';
  const mins = totalMinutes % 60;
  const hours = totalMinutes / 60;
  const hr = Math.floor(hours) > 0 ? `${Math.floor(hours)}hr` : '';
  return `${hr} ${mins}mins`;
};

export const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const deliverToInformationStructure = (
  deliverToInformation: {
    order: number;
    name: string;
    country: string;
    state: string;
    packageType: string;
    phone: string;
  }[],
  country: string,
  toState: string
) =>
  deliverToInformation.map((deliverTo: any, index: number) => ({
    ...deliverTo,
    order: index,
    country,
    state: toState,
  }));

export const isForMe = (senderType: SenderType) => senderType === 'Me';

export const prefixedProfileIds = ['AF-', 'RD-'];

export const isAffiliateId = (storeId: string) => storeId.includes('AF-');
