import * as Yup from 'yup';

const validationSchema = Yup.object({
  warningAmount: Yup.number().moreThan(-1, 'Amount cannot be less than 0'),

  criticalAmount: Yup.number().moreThan(-1, 'Amount cannot be less than 0'),

  contactEmail: Yup.string().email('Invalid email address'),
});

export default validationSchema;
