import { ElrMainSelect } from 'ui-components';

const ProductsFilter: React.FC<{ productsFilterdata: Array<string> }> = ({
  productsFilterdata,
}) => (
  <div className="text-elr-black flex items-center !w-3/6 md:w-28">
    <span className="opacity-60 text-base "> Find</span>
    <span className="order-by-select ml-2.5 ml-3 !w-full ">
      <ElrMainSelect
        data={productsFilterdata}
        // @ts-ignore
        containerHeight="30px"
        containerBackgroundColor="#FFFFFF"
        controlBackgroundColor="transparent"
        controlHeight="28px"
        minHeight="0"
        className="!w-full md:w-32 mt-4 border md:z-20 border-opacity-40 border-elr-green rounded-md"
        placeholder="All"
        boxShadowStyle="0 0 14px rgba(0, 0, 0, 0.274)"
      />
    </span>
  </div>
);

export default ProductsFilter;
