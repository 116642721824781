import SlideIn from 'app/components/SlideIn/SlideIn';
import { PencilIcon } from 'app/components/SvgAssets';
import { useGetProfile } from 'app/hooks/user';
import { useState } from 'react';
import { ElrButton, ElrInput, ElrPhoneInputFlat } from 'ui-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  phone: Yup.string().required('Phone is required'),
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
});

const PersonalDetails = () => {
  const { firstname, lastname, email, phone } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [telephone, setTelephone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });

  const { handleSubmit, values, setFieldValue, isValid } = useFormik({
    initialValues: {
      phone: phone || '',
      firstname: firstname || '',
      lastname: lastname || '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      setIsModalOpen(false);
    },
  });

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setTelephone((prev) => ({ ...prev, ...payload }));
    setFieldValue('phone', payload.formattedNumber);
  };

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Personal Details</p>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-5 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="text-base pb-2 text-elr-black-400">First Name</p>
            <ElrInput
              className="border-none bg-elr-gray h-12 outline-none"
              value={values.firstname}
              onChange={(e) => setFieldValue('firstname', e.target.value)}
              placeholder="First Name"
            />
          </div>

          <div className="w-full">
            <p className="text-base pb-2 text-elr-black-400">Last Name</p>
            <ElrInput
              className="border-none bg-elr-gray h-12 outline-none"
              value={values.lastname}
              onChange={(e) => setFieldValue('lastname', e.target.value)}
              placeholder="Last Name"
            />
          </div>

          <div className="w-full">
            <p className="text-base pb-2 text-elr-black-400">Phone Number</p>
            <ElrPhoneInputFlat
              containerClass="w-full flex"
              className="border-none bg-elr-gray h-12"
              value={telephone.mobile}
              onChange={onPhoneInputChange}
              placeholder="Phone Number"
            />
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled={!isValid}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <div
        className="absolute right-5 top-6 text-base px-8 py-3 text-elr-black flex gap-3 rounded-md items-center justify-center cursor-pointer bg-elr-gray text-center"
        onClick={() => setIsModalOpen(true)}
      >
        <p>Edit</p>
        <PencilIcon />
      </div>

      {isModalOpen && renderEditInfo}

      <p className="text-xl">Personal Details</p>
      <p className="text-elr-gray-400 pt-1 text-base pb-7">{email}</p>

      <div className="grid grid-cols-1 md:grid-rows-1 md:grid-flow-col gap-7 md:gap-0">
        <div className="flex flex-col">
          <p className="text-base text-elr-black-400 pb-2">Name</p>
          <p className="text-elr-black text-base">
            {lastname} {''} {firstname}
          </p>
        </div>

        <div className="flex flex-col">
          <p className="text-base text-elr-black-400">Phone numbers</p>
          <div className="flex pt-2 pb-2 text-base">
            <p className="pr-4 pt-1 text-elr-black text-base">{phone}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
