import { currencyFormatter } from 'app/utilities/helpers';
import { truncate } from 'lodash';
import { useState } from 'react';
import { ElrDropdownIconToggle } from 'ui-components';

const Preview: React.FC<{
  data: {
    [key: string]: { [key: string]: string }[];
  } | null;
}> = ({ data }) => {
  const [active, setActive] = useState<string | null>(null);

  const toggleItems = (openIndex: string) => {
    setActive((a) => (a ? null : openIndex));
  };

  const isActive = (index: string) => index === active;

  return (
    <div className="mt-10">
      <div className="flex justify-between pb-1 text-elr-black text-opacity-60 lg:max-w-[600px] border-b border-elr-gray-400 border-opacity-20 mb-3">
        <h3 className="w-8/12">Destination address</h3>
        <h3 className="mr-5 1/12">Items</h3>
        <div className="1/12" />
      </div>

      <div>
        {data &&
          Object.keys(data).map((key) => (
            <div key={key}>
              <div
                onClick={() => toggleItems(key)}
                className="flex justify-between py-4 text-sm border-b cursor-pointer md:text-base border-elr-gray-400 border-opacity-20 lg:max-w-[600px] mb-3"
              >
                <div
                  className="self-center w-9/12 opacity-60"
                  title={data[key][0].ReceiverAddress}
                >
                  {truncate(data[key][0].ReceiverAddress, { length: 39 })}
                </div>
                <div className="w-1/12 opacity-60">
                  {data[key].length} item{data[key].length > 1 ? 's' : ''}
                </div>
                <span className="w-1/12 opacity-50">
                  <ElrDropdownIconToggle
                    isToggled={isActive(key)}
                    toggle={() => {}}
                  />
                </span>
              </div>

              {isActive(key) &&
                data[key].map((destinationDetails) => (
                  <div
                    className="flex lg:max-w-[600px] flex-col px-4 py-4 rounded bg-elr-gray-100 bg-opacity-50 gap-y-4 mb-2"
                    key={destinationDetails.ReceiverAddress}
                  >
                    <h4 className="text-sm">Destination</h4>
                    <div className="flex flex-row items-center text-sm gap-x-10">
                      <p className="opacity-60">
                        {destinationDetails.ReceiverName}
                      </p>
                      <p className="opacity-60">
                        {destinationDetails.ReceiverPhone}
                      </p>
                    </div>
                    <div className="flex flex-row items-center text-sm gap-x-10">
                      <p className="opacity-60">
                        <span className="text-xs">package type</span>&nbsp;
                        {destinationDetails.PackageType}
                      </p>
                      <p className="opacity-60">
                        <span className="text-sm">package value</span>&nbsp;
                        {currencyFormatter(+destinationDetails.PackageValue)}
                      </p>
                    </div>
                    <div className="flex flex-col text-sm gap-x-10">
                      <p className="opacity-60">Additional note:</p>
                      <p className="opacity-60">
                        {destinationDetails.AdditionalNote}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Preview;
