import CacheBust from 'cache-bust';
import React, { useEffect } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';

const ServiceWorkerWrapper: React.FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const forceReload = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = window.location.href;
    document.body.appendChild(form);
    form.submit();
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);

    forceReload();
  };

  return (
    <div>
      <CacheBust />
      {showReload && (
        <button type="button" onClick={reloadPage}>
          <div id="top-bar-notification">
            <div>
              A new version is available! <br />
              Click here to update 💃🏼
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default ServiceWorkerWrapper;
