import React from 'react';

interface CountElementInterface {
  bgColor: string;
  size: 'sm' | 'md';
  children: React.ReactNode;
  classNames?: string;
}

const CountElement: React.FC<CountElementInterface> = ({
  bgColor,
  size,
  children,
  classNames = '',
}) => {
  const sizes = {
    sm: 'text-xl leading-8',
    md: 'text-30 leading-10',
  };

  const elementColor = `border-${bgColor} bg-${bgColor}`;

  return (
    <div
      className={`${classNames} max-w-max text-opacity-50 border-b-2 ${elementColor} bg-opacity-10 px-2`}
    >
      <p className={`${sizes[size]} text-opacity-60`}>{children}</p>
    </div>
  );
};

export default CountElement;
