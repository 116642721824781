import { httpReopenRequest } from 'app/api/requests';
import { Destinations } from 'app/components/Destinations';
import { useGetProfile } from 'app/hooks/user';
import { useGetTrackingId } from 'app/hooks/utils';
import { Session } from 'app/utilities/Session';
import { getCurrentCountryData } from 'app/utilities/country';
import {
  generateMapUrl,
  getArrLastItem,
  isDispatcher,
} from 'app/utilities/helpers';
import {
  DestinationInterface,
  Request,
  RequestTypes,
} from 'app/utilities/types/shared';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import { ElrButton, ElrModal } from 'ui-components';
import { PickUpLocations } from './PickupLocations';

interface Props {
  details: Request;
  onRemoveListing?: (listing: string) => void;
}

type pickupDetail = {
  businessname?: string;
};

const reasons = [
  'Distance is too far',
  'No available rider',
  'It is late already',
  'And something else',
];

const DeliveryDetails: React.FC<Props> = ({ details, onRemoveListing }) => {
  const [reopenModal, setReopenModal] = useState(false);
  const [reason, setReason] = useState('');
  const { id, accountInformation } = useGetProfile();
  const {
    trackingId,
    pickupAddress,
    senderInformation,
    deliverToInformation,
    afterChargeEstimate,
    status,
    acceptedBy,
    businessName,
    id: requestId,
    deliveryNotes,
    pickupArea,
    batch,
    deliveryTimelineLabel,
  } = details;

  const trackingUID = useGetTrackingId(trackingId);
  const mapUrl = generateMapUrl(
    { latitude: pickupAddress.latitude, longitude: pickupAddress.longitude },
    {
      latitude: deliverToInformation[0].address.latitude,
      longitude: deliverToInformation[0].address.longitude,
    }
  );

  const isValidTOProceed = (requestStatus: string) =>
    requestStatus === RequestTypes.accepted ||
    requestStatus === RequestTypes.collected;
  const access = Session.getValue('accessUserType') || '';

  const acceptedByDispatcher =
    // the acceptedBy can return a string of dispatcher id or an object {id:string}
    typeof acceptedBy === 'string'
      ? acceptedBy
      : acceptedBy?.id === accountInformation?.id;

  const canReopen =
    isValidTOProceed(status) && isDispatcher(access) && acceptedByDispatcher;

  const showRemoveForDest =
    deliverToInformation.length > 1 && status !== RequestTypes.completed;

  const closeModal = () => {
    setReopenModal(false);
  };

  const reopenRequest = async () => {
    const result = await httpReopenRequest(id, trackingId, reason);
    result && closeModal();
    if (onRemoveListing) {
      onRemoveListing(trackingId);
    }
  };

  const deliveryAddresses = deliverToInformation;
  const pickupAddressList = {
    id: requestId,
    senderInformation: {
      data: {
        name: senderInformation.data.name,
        phone: senderInformation.data.phone,
        email: senderInformation.data.email,
      },
    },
    fullAddress: pickupAddress.fullAddress,
    latitude: pickupAddress.latitude,
    deliveryNotes,
    distance: pickupAddress.distance ?? '0',
    duration: pickupAddress.duration ?? '0',
    deliveryTimelineLabel,
    pickupArea: pickupArea?.area ?? '',
    longitude: pickupAddress.longitude,
  };

  const RenderPickups: React.FC<pickupDetail> = ({ businessname }) => (
    <>
      {batch && (
        <div>
          <PickUpLocations
            pickupAddress={{ ...pickupAddressList }}
            businessName={businessname}
            showRemoveButton={status === RequestTypes.open}
          />
        </div>
      )}

      {!batch && (
        <div>
          <PickUpLocations
            pickupAddress={pickupAddressList}
            businessName={businessname}
          />
        </div>
      )}
    </>
  );

  return (
    <div>
      <ElrModal
        width="w-11/12 md:w-560"
        height="h-7/12 md:h-3/6"
        isOpen={reopenModal}
        onClose={closeModal}
      >
        <div className="py-5 text-center">
          <h3 className="text-xl">Are you sure ?</h3>

          <div className="flex justify-between py-2 mt-5 mb-10 border-y border-y-elr-gray-400 border-opacity-20">
            <div className="opacity-60">
              {truncate(pickupAddress?.fullAddress, { length: 39 })}
            </div>
            <span className="opacity-60">
              {afterChargeEstimate}{' '}
              {getCurrentCountryData().currencyCode.toUpperCase()}
            </span>
          </div>

          <h5 className="mb-3">What&apos;s the reason?</h5>
          <div className="flex flex-row flex-wrap">
            <Reasons reason={reason} setReason={setReason} />
          </div>
          <div className="flex justify-center mt-14">
            <ElrButton
              onClick={closeModal}
              text="Cancel"
              className="text-opacity-60"
            />

            <ElrButton
              onClick={reopenRequest}
              text="Confirm"
              className="px-10 text-sm text-white bg-elr-black"
              disabled={!reason}
            />
          </div>
        </div>
      </ElrModal>
      <div className="mt-3">
        <div className="flex items-center justify-between">
          <div className="text-base md:text-sm mb-3.5"> Delivery details </div>
          {canReopen && (
            <ElrButton
              onClick={() => setReopenModal(true)}
              size="sm"
              text="Reopen request"
              className="px-10 text-sm text-white bg-elr-black"
            />
          )}
        </div>
        <div className="flex justify-between mb-6 opacity-60">
          <div>
            <span className="text-sm">Delivery ID: </span>
            <span className="text-base">{trackingUID}</span>
          </div>
        </div>
        {mapUrl ? (
          <div className="mb-4">
            <a
              target="_blank"
              rel="noreferrer"
              className="text-xs border-b text-elr-purple border-elr-purple"
              href={mapUrl}
            >
              View location on map
            </a>
          </div>
        ) : null}
        <RenderPickups businessname={businessName} />
        {details &&
          deliveryAddresses.map((dest: DestinationInterface, index: number) => {
            const last = getArrLastItem(deliveryAddresses);
            return (
              <div key={dest.id}>
                <Destinations
                  batch={batch}
                  destinations={dest}
                  showRemoveButton={showRemoveForDest}
                  last={last}
                  index={index}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

interface ReasonI {
  reason: string;
  setReason: (reason: string) => void;
}

const Reasons: React.FC<ReasonI> = ({ reason, setReason }) => (
  <>
    {reasons.map((reasonMap: string) => (
      <ElrButton
        onClick={() => setReason(reasonMap)}
        text={reasonMap}
        key={reasonMap}
        size="xm"
        className={`text-sm px-4 py-2 mr-3 mb-3 ${
          reasonMap === reason
            ? 'bg-elr-black text-white'
            : ' text-elr-black text-opacity-60 border border-elr-gray-400 border-opacity-60'
        }`}
      />
    ))}
  </>
);

export default DeliveryDetails;
