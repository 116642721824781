import { httpGetConfirmationRequest } from 'app/api/requests';
import { PAYSTACK_PUBLIC_KEY } from 'app/constants';
import { useTipFromWallet } from 'app/hooks/requests/payment';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import { getCurrentCountryData } from 'app/utilities/country';
import { calculatePaymentFee, isOrderRIB } from 'app/utilities/helpers';
import { Request } from 'app/utilities/types/shared';
import { useCallback, useEffect, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { ElrButton, ElrInput, ElrLeftArrowButton } from 'ui-components';

interface Props {
  trackingId: string;
  onClose: () => void;
}

const TipRiderDetails: React.FC<Props> = ({ trackingId, onClose }) => {
  const {
    id,
    accountInformation: { id: roleId },
  } = useGetProfile();

  const minimumTip = 100;
  const [tipAmount, setTipAmount] = useState<number>(0);
  const [request, setRequest] = useState<Request>();

  const [showTipWalletDetails, setshowTipWalletDetails] =
    useState<boolean>(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletID, setWalletID] = useState('');
  const [getWalletBalance, wallet] = useGetWalletBalance(id, roleId);

  const [tipFromWallet, loading] = useTipFromWallet();

  const amountInNaira = calculatePaymentFee(Number(tipAmount)) * 100;
  const disableTipButton = Number(tipAmount) < minimumTip;

  const getBalance = useCallback(async () => {
    const account: any = await getWalletBalance();
    if (account.data) {
      const { walletId, balance } = account.data;
      setWalletBalance(balance);
      walletId && setWalletID(walletId);
    }
  }, [wallet?.balance]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  const balanceIsLow = walletBalance < tipAmount;

  const metadata = isOrderRIB(trackingId)
    ? {
        trackingId,
        tip: true,
        batch: true,
        tipAmount,
      }
    : {
        trackingId,
        tip: true,
        tipAmount,
      };

  const params = isOrderRIB(trackingId)
    ? {
        trackingId,
        batch: true,
        tipAmount,
      }
    : {
        trackingId,
        batch: false,
        tipAmount,
      };

  const fetchRequest = useCallback(async () => {
    if (!trackingId) return;
    const response = await httpGetConfirmationRequest(trackingId);

    setRequest(response);
  }, [trackingId]);

  useEffect(() => {
    fetchRequest();
  }, [fetchRequest]);

  const { senderInformation } = request || {};

  const config = {
    email: `${senderInformation?.data.phone}@errandlr.com`,
    amount: amountInNaira,
    publicKey: PAYSTACK_PUBLIC_KEY,
    channels: ['card', 'ussd', 'bank_transfer'],
    metadata,
  };

  const initializePayment = usePaystackPayment(config as any);

  const goToPaystack = () => {
    const MINIMUM_PAYSTACK = 50;
    Number(tipAmount) >= MINIMUM_PAYSTACK &&
      initializePayment({ onSuccess, onClose: close });
    onClose();
  };

  const onSuccess = () => {
    close();
    if (request) {
      window.location.reload();
    }
  };

  const close = () => {};

  const onMakePayment = (): void => {
    goToPaystack();
  };

  const onTipFromWallet = () => {
    setshowTipWalletDetails(true);
  };

  const returnToTipModal = () => {
    setshowTipWalletDetails(false);
  };

  const onMakePaymentFromWallet = async () => {
    const TippedResponse = await tipFromWallet({ ...params });
    if (TippedResponse) {
      onClose();
    }
  };

  const enterTipAmountSection = (
    <div>
      <div className="pt-6 pb-4">
        <div className="text-base text-elr-black">Enter tip amount</div>
        <div
          className={`w-full flex  rounded items-center justify-center
                      color-elr-black text-lg border-elr-border border`}
          id="divFocus"
        >
          <ElrInput
            onChange={(e) => setTipAmount(e.target.value)}
            className="box-border flex-grow w-11/12 h-full rounded focus:outline-none"
            placeholder="Enter amount"
            height="h-6"
          />
        </div>
      </div>

      <div className="flex flex-col gap-3 pb-6">
        {id && (
          <ElrButton
            text="Tip from wallet"
            className="min-h-2 border-[1px] text-base"
            onClick={onTipFromWallet}
            disabled={disableTipButton}
          />
        )}
        <ElrButton
          text="Make Payment"
          className="text-base text-white min-h-2 bg-elr-black min-w-2"
          onClick={onMakePayment}
          disabled={disableTipButton}
        />
      </div>
    </div>
  );

  const tipFromWalletSection = (
    <div className="md:mx-3">
      <div className="pt-8">
        <div className="flex justify-between">
          <h2 className="pb-2 leading-5 text-16">Tip via wallet</h2>
          {balanceIsLow && (
            <p className="text-sm text-center text-elr-error text-opacity-60">
              Insufficient Funds.
            </p>
          )}
        </div>
        <div className="p-4 mb-5 border rounded-md border-elr-green border-opacity-30">
          <div className="flex justify-between">
            <h3 className="leading-5 text-14 md:text-18">Your Wallet</h3>
            <p className="leading-5 text-10 opacity-40">
              Wallet ID: {walletID.toUpperCase()}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-2 pt-6">
            <p className="leading-5 text-12">Current balance</p>
            <div className="flex border-b-2 bg-elr-gray opacity-60">
              <p className="text-3xl">{walletBalance}</p>
              <p className="self-end mb-0.5 ml-2">
                {getCurrentCountryData().currencyCode.toUpperCase()}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between w-full gap-4 pb-6">
          <div
            className="flex items-center justify-center w-12 h-10 border rounded-full cursor-pointer border-1 border-elr-green border-opacity-30"
            onClick={returnToTipModal}
          >
            <ElrLeftArrowButton />
          </div>

          <ElrButton
            text="Make payment"
            onClick={onMakePaymentFromWallet}
            className="w-full p-4 py-1 text-sm rounded-full bg-elr-black text-elr-white"
            size="lg"
            disabled={balanceIsLow}
            loading={loading}
            spinnerColor="White"
          />
        </div>
      </div>
    </div>
  );
  return showTipWalletDetails ? tipFromWalletSection : enterTipAmountSection;
};

export default TipRiderDetails;
