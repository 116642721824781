import paymentSuccessLogo from 'assets/images/payment-success-logo.svg';
import React from 'react';

import hamburger from 'assets/images/hamburger.svg';
import logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';

type PaymentSuccessProps = {
  children: React.ReactNode; // 👈️ type children
};

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ children }) => (
  <div className="w-full h-screen bg-elr-gray">
    <div className="pt-10 px-8 lg:px-16 flex justify-between">
      <Link to="/">
        <img src={logo} alt="elr-logo" />
      </Link>
      <img src={hamburger} alt="hamburger" className="sm:hidden" />
    </div>
    <div className="w-full h-5/6 flex items-center justify-center">
      {children}
    </div>
  </div>
);

const EventPurchaseSuccess: React.FC = () => (
  <PaymentSuccess>
    <div className="w-full mx-8 sm:w-406 md:w-560 sm:min-w-2/6 h-96 md:h-120 rounded border border-elr-green border-opacity-60 bg-white flex items-center justify-center flex-col gap-10">
      <div>
        <img
          src={paymentSuccessLogo}
          alt="payment success logo"
          className="w-16 sm:w-20"
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-xl md:text-2xl font-medium pb-3">
          Payment Successful
        </p>
        <p className="text-base text-elr-black text-opacity-60 font-medium">
          Your have successfully purchased your tickets
        </p>
        <br />
        <div className="text-base text-elr-black text-opacity-60 hidden sm:flex flex-col justify-center items-center">
          <p>The tickets have been sent to the email address you provided</p>
        </div>
      </div>
    </div>
  </PaymentSuccess>
);

export default EventPurchaseSuccess;
