import { ADD, ALL, PARTNERS } from 'app/constants';
import { Tabs } from 'app/hooks/dispatch';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { ProfileImage } from 'app/components/ProfileImage';
import { initials } from 'app/utilities/helpers';
import star from 'assets/images/partner-star.svg';

interface Props {
  tab?: Tabs;
  data: any;
  tabData: any;
  onClickDispatcher: (dispatcher: any) => void;
  setAction: React.Dispatch<React.SetStateAction<string>>;
}
const DispatcherBox = ({
  tab,
  tabData,
  onClickDispatcher,
  setAction,
  data,
}: Props) => (
  <div className="overlay overflow-hidden h-28 mb-6 border border-elr-green border-opacity-30 rounded-md">
    <div className="overlay-first-elem bg-elr-white-400 opacity-90 rounded-md text-center translate-x-8 translate-y-10">
      {tab === PARTNERS ? (
        <button
          type="button"
          className="flex flex-row gap-1"
          onClick={() => {
            onClickDispatcher(data);
            setAction('remove');
          }}
        >
          <RemoveCircleOutlineOutlinedIcon
            className="cursor-pointer p-1 bg-elr-green bg-opacity-10"
            htmlColor="#054853"
            fontSize="medium"
          />
          <p>Remove as partner</p>
        </button>
      ) : (
        <div>
          {tab === ALL && data?.partner ? (
            <p className="flex">Partner</p>
          ) : (
            <button
              type="button"
              className="flex flex-row gap-1"
              onClick={() => {
                onClickDispatcher(data);
                setAction(ADD);
              }}
            >
              <AddCircleOutlineOutlinedIcon
                className="p-1 cursor-pointer bg-elr-green bg-opacity-10"
                htmlColor="#054853"
                fontSize="medium"
              />
              <p>Add as partner</p>
            </button>
          )}
        </div>
      )}
    </div>
    <div className="overlay-second-elem flex ml-3 md:ml-5 mt-5 mr-1.5">
      <div className="relative">
        <ProfileImage
          initials={`${initials(tabData.companyName)}`}
          classes="w-9 h-10 px-1 flex items-center justify-center"
        />
        {tab === ALL && data?.partner && (
          <div className="absolute h-13 top-6 right-2 w-13 flex justify-center items-center bg-elr-black rounded-full">
            <img src={star} alt="" />
          </div>
        )}
      </div>
      <div>
        <div className="opacity-60 text-sm mb-1">{tabData?.companyName}</div>
        <div className="opacity-40 text-sm mb-1 md:mb-2">{tabData?.area}</div>
        <div className="text-elr-green text-sm opacity-80">
          {tabData?.country}
        </div>
      </div>
    </div>
  </div>
);

export default DispatcherBox;
