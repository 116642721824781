import { httpGetPendingRequestsInfo } from 'app/api/commerce';
import { useProcessPendingPayment } from 'app/hooks/requests/payment';
import { useGetProfile } from 'app/hooks/user';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { ElrPillButton } from 'ui-components';

type NotificationType = {
  [key: string]: ReactElement;
};

interface Props {
  walletBalance: number;
  fundWalletAction?: () => void;
  proceedToDeliveryAction?: () => void;
}
type INotificationType = 'default' | 'pending' | 'resolved';

const PendingRequestNotification: React.FC<Props> = ({ fundWalletAction }) => {
  const {
    id: userId,
    accountInformation: { id: commerceId },
  } = useGetProfile();

  const [notificationType, setNotificationType] =
    useState<INotificationType>('default');
  const { process, status } = useProcessPendingPayment();
  const [pendingRequestInfo, setPendingRequestInfo] = useState<{
    count: number;
    cost: number;
  }>({
    count: 0,
    cost: 0,
  });

  const queryPendingPayment = useCallback(async () => {
    const data = await httpGetPendingRequestsInfo(userId, commerceId);
    setPendingRequestInfo(data);
  }, [userId, commerceId]);

  useEffect(() => {
    queryPendingPayment();
  }, [queryPendingPayment]);

  useEffect(() => {
    if (pendingRequestInfo.count > 0) {
      return setNotificationType('pending');
    }
    return setNotificationType('default');
  }, [pendingRequestInfo]);

  /// TODO: listen to a new fund wallet event and process the payment

  const pendingNotification: NotificationType = {
    default: <div />,
    pending: (
      <div className="w-full my-4 px-3 md:px-8 py-2 flex items-center justify-between rounded bg-elr-green">
        <p className="text-white self-center text-sm md:text-base">
          Pending delivery due to insufficient balance
        </p>
        <ElrPillButton
          text="Fund your wallet"
          size="xm"
          className="bg-elr-yellow px-3 md:px-5 w-[137px] text-sm py-1 text-elr-black"
          onClick={fundWalletAction}
        />
      </div>
    ),
    resolved: (
      <div className="w-full my-4 px-3 md:px-6 py-2 flex items-center justify-between rounded bg-elr-green bg-opacity-5 border border-elr-green border-opacity-60">
        <p>Your account has been funded. Process pending deliveries?</p>
        <ElrPillButton
          text="Continue"
          size="sm"
          loading={status === 'pending'}
          className="bg-elr-gray px-3 md:px-5 w-[137px] text-sm py-1 text-elr-black border border-elr-green border-opacity-60"
          onClick={process}
        />
      </div>
    ),
  };

  const component = pendingNotification[notificationType];

  return component;
};

export default PendingRequestNotification;
