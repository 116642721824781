import markIcon from 'assets/images/mark-icon.svg';
import React, { InputHTMLAttributes } from 'react';
import { InputProps } from 'app/utilities/types/dispatcherTypes';

const Input: React.FC<InputHTMLAttributes<HTMLInputElement> & InputProps> = ({
  label,
  field,
  form,
  preComponent,
  type,
  optional,
  inputPaddingY = 4,
  ...other
}) => {
  const { errors } = form;
  const error = errors[field.name] as string;

  return (
    <>
      <div className="text-xl text-elr-black mb-2">{label}</div>
      <div
        className={`w-full flex py-${inputPaddingY} px-3 border-b-05 items-center`}
        style={{ borderBottom: '0.5px solid rgba(8, 17, 32, 0.4)' }}
      >
        {preComponent && <div className="mr-2">{preComponent}</div>}

        <input
          {...other}
          {...field}
          onChange={(event) =>
            form.setFieldValue(
              event.target.name,
              event.target.value.trimStart()
            )
          }
          onBlur={(event) =>
            form.setFieldValue(event.target.name, event.target.value.trimEnd())
          }
          value={field.value || ''}
          className="flex-grow focus:outline-none border-elr-black border-none color-elr-black"
          type={type || 'text'}
        />
        {!error && !optional && <img src={markIcon} alt="done" />}
      </div>
      {error && (
        <div className="text-xs mt-2 text-elr-error text-right">{error}</div>
      )}
    </>
  );
};

export default Input;
