import { currencyFormatter } from 'app/utilities/helpers';
import { ProductType } from 'app/utilities/types/shared';
import cancelIcon from 'assets/images/black-cancel.svg';
import cartIcon from 'assets/images/cart.svg';
import defaultPhoto from 'assets/images/default-product.png';
import { RefObject, useState } from 'react';
import { ElrButton } from 'ui-components';
import { CategoryButton } from './ClassUtils';
import useProductsCart from './useProductCart';
import { useFetchDirectProductsList } from './utils';

const DirectProductListing: React.FC<{
  containerRef: RefObject<HTMLDivElement>;
  roleId: string;
}> = ({ containerRef, roleId }) => {
  const [showCart, setShowCart] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(categories[0].value);
  const { cart, cartCount, reduceFromCart, addToCart } = useProductsCart();
  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };
  const toggleShowCart = () => setShowCart(!showCart);

  const { docs } = useFetchDirectProductsList(roleId);

  return (
    <div
      className="px-4 py-5 bg-white md:px-12 md:py-8 md:w-7/12 md:bg-transparent lg:pr-20 md:overflow-y-scroll"
      ref={containerRef}
    >
      <h3 className="mb-3 text-xl font-medium">
        Get more items from our store
      </h3>
      <section className="flex items-center justify-between mt-5">
        <div className="flex items-center gap-x-3 md:gap-x-5">
          {categories.map((category) => (
            <button
              type="button"
              onClick={() => handleCategoryChange(category.value)}
              className={CategoryButton(category.value === selectedCategory)}
              key={category.value}
            >
              {category.label}
            </button>
          ))}
        </div>
        <div className="relative">
          <button
            className="relative flex"
            onClick={toggleShowCart}
            type="button"
          >
            <img className="" src={cartIcon} alt="cart" />
            {cartCount ? (
              <span className="absolute flex items-center justify-center w-5 h-5 text-sm text-white rounded-full bg-elr-black -right-1 -top-1">
                {cartCount}
              </span>
            ) : null}
          </button>
          {showCart && cartCount ? (
            <div className="absolute z-10 px-1 py-3 bg-white rounded-lg shadow-lg -left-80 md:-left-72 top-12 w-350">
              {cart.map((item) => (
                <div
                  key={`cartItem-${item.id}`}
                  className="flex items-start justify-between px-4 py-1 mb-2 cursor-pointer hover:bg-elr-gray-100"
                >
                  <div className="flex items-center gap-x-4">
                    <img
                      alt="product"
                      className="w-12"
                      src={item.image || defaultPhoto}
                    />
                    <div>
                      <h4 className="text-elr-black text-opacity-70">
                        {item.name}
                      </h4>
                      <div className="flex items-center justify-between px-3 text-elr-black bg-elr-gray-100 gap-x-2 mt-0.5 text-sm rounded-3xl">
                        <button
                          type="button"
                          onClick={() => reduceFromCart(item.id)}
                          className="p-0.5 font-bold"
                        >
                          -
                        </button>
                        <span className="">{item.quantity}</span>
                        <button
                          type="button"
                          onClick={() => addToCart(item)}
                          className="p-0.5 font-bold"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <p>NGN {currencyFormatter(Number(item.price))}</p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </section>

      <section className="flex flex-wrap gap-4 mt-8 md:gap-8">
        {docs ? (
          docs.map((product) => <Product key={product.id} product={product} />)
        ) : (
          <p className="mt-5 text-center text-black text-opacity-60">
            No products available at the moment.
          </p>
        )}
      </section>
    </div>
  );
};

export default DirectProductListing;

const Product: React.FC<{
  product: ProductType;
}> = ({ product }) => {
  const { cart, addToCart, reduceFromCart } = useProductsCart();

  const itemInCart = cart.find((i) => i.id === product.id);

  return (
    <div className="flex flex-col px-4 py-2 pb-3 bg-white rounded-lg shadow-lg cursor-pointer">
      <img
        alt="product"
        className="w-40 md:w-72"
        src={product.images[0] || defaultPhoto}
      />
      <h4 className="mt-4 text-lg text-elr-black">{product.name}</h4>
      <p className="my-0.5 text-elr-purple opacity-70">#{product.id}</p>
      <p className="opacity-70">
        NGN {currencyFormatter(Number(product.price))}
      </p>

      {itemInCart ? (
        <div className="flex items-center justify-between px-3 py-0.5 text-white bg-elr-black gap-x-2.5 mt-4 rounded-3xl">
          <button
            type="button"
            onClick={() => reduceFromCart(product.id)}
            className="p-1 font-bold"
          >
            -
          </button>
          <span className="">{itemInCart.quantity}</span>
          <button
            type="button"
            onClick={() => addToCart({ ...product, image: product.images[0] })}
            className="p-1 font-bold"
          >
            +
          </button>
        </div>
      ) : (
        <ElrButton
          text="Add"
          onClick={() => addToCart({ ...product, image: product.images[0] })}
          spinnerColor="Black"
          size="sm"
          className="mt-4 text-elr-black bg-elr-gray-100"
        />
      )}
    </div>
  );
};

const categories = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Shoes',
    value: 'shoes',
  },
  {
    label: 'Clothes',
    value: 'clothes',
  },
  {
    label: 'Bags',
    value: 'bags',
  },
];

export const CartSlider: React.FC = () => {
  const { cart, cartCount, removeFromCart } = useProductsCart();

  return cartCount ? (
    <div className="flex max-w-md p-3 mt-8 overflow-x-scroll gap-x-4 bg-elr-gray-100">
      {cart.map((item) => (
        <div
          key={`cartItem-${item.id}`}
          className="relative flex flex-col items-start justify-between w-32 px-4 py-2 mb-2 bg-white cursor-pointer"
        >
          <button
            type="button"
            onClick={() => removeFromCart(item.id)}
            className="absolute right-2"
          >
            <img alt="product" className="w-4 opacity-40" src={cancelIcon} />
          </button>
          <img
            alt="product"
            className="w-14"
            src={item.image || defaultPhoto}
          />
          <h4 className="mt-1 truncate w-28 text-elr-black text-opacity-70">
            {item.name}
          </h4>
          <p className="mt-0.5 mb-1 font-bold">{item.quantity}</p>
          <p>NGN {currencyFormatter(Number(item.price))}</p>
        </div>
      ))}
    </div>
  ) : null;
};
