import { Field } from 'formik';
import React from 'react';
import Input from '../Input';

export const UserName: React.FC = () => (
  <div className="md:mx-7 mb-10">
    <Field
      name="name"
      label="Sender name"
      placeholder="Name"
      component={Input}
    />
  </div>
);
