import { useQueryClient } from '@tanstack/react-query';
import { PackageTypeLabel } from 'app/constants';
import { PARCEL_CODE } from 'app/state/constants';
import { HttpLocationValues } from 'app/utilities/types/shared';
import { FormikErrors } from 'formik';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { ElrSelect } from 'ui-components/components/ElrSelect/ElrSelect';
import { useFetchPackageType } from 'app/hooks/utils';

interface Props {
  errors: FormikErrors<HttpLocationValues>;
  onSelectParcelType: (options: { code: string; value: string }) => void;
}

const optionLabelElement = ({
  name,
  imageSrc,
}: {
  name: string;
  imageSrc: string;
}) => (
  <span className="flex gap-x-3">
    <img className="w-5 relative bottom-0.5" src={imageSrc} alt="imageSrc" />
    {capitalize(name)}
  </span>
);

const SelectParcelType: React.FC<Props> = ({ onSelectParcelType, errors }) => {
  const queryClient = useQueryClient();
  const [showDescription, setShowDescription] = useState(false);
  const [itemTypes] = useFetchPackageType();

  const data = queryClient.getQueryData([PARCEL_CODE]) as {
    code: string;
  };

  const options = itemTypes.map((item) => ({
    label: optionLabelElement(item),
    value: item.name,
    code: item.code,
  }));

  const cx = () => {
    if (data) {
      const getCodeData = options.find((item) => item.code === data.code);
      if (!getCodeData) return null;
      return getCodeData;
    }
    return null;
  };

  const internalSelection = (option: { value: string; code: string }) => {
    option.value === PackageTypeLabel.FOOD
      ? setShowDescription(true)
      : setShowDescription(false);
    onSelectParcelType(option);
    queryClient.setQueryData([PARCEL_CODE], () => option);
  };

  return (
    <>
      {errors.parcelCode && (
        <span className="text-xs mb-1 text-elr-error">Required</span>
      )}
      <ElrSelect
        options={options}
        defaultValue={cx()}
        controlHeight={50}
        containerHeight={45}
        className="w-54"
        showIndicator
        isClearable={false}
        bgcolor="#F7F7F7"
        placeHolder="What are you delivering"
        onChange={internalSelection}
      />
      {showDescription && (
        <p className="text-sm text-elr-green relative bottom-1">
          Food item should be Airtight sealed.
        </p>
      )}
    </>
  );
};

export default SelectParcelType;
