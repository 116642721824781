/* eslint-disable react-hooks/exhaustive-deps */

import { publicRoutes } from 'app/utilities/routes';
import { Request } from 'app/utilities/types/shared';
import React, { useEffect, useState } from 'react';
import { ElrPillButton } from 'ui-components';
import { useFetchNewRequests } from 'app/hooks/requests/dashboard';
import FilterInput from 'app/platform/components/FilteredInput/FilteredInput';
import RequestItem from 'app/platform/modules/RequestItem';
import { handleScroll } from 'app/platform/modules/utility';

interface NewRequestsInterface {
  displayOriginalFees?: boolean;
}

const NewRequests: React.FC<NewRequestsInterface> = ({
  displayOriginalFees,
}) => {
  const [active, setActive] = useState(0);
  const requestList = React.useRef(null);

  const {
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
    isLoading,
    docsList,
    latestData,
  } = useFetchNewRequests();

  const onUpdateList = () => {
    refetch();
  };

  useEffect(() => {
    handleScroll(requestList);
  }, []);

  const _renderLoadState = () => {
    if (!isLoading && !docsList?.length) {
      return (
        <div className="opacity-60 text-center pt-2">
          <p>
            There are no new requests.&nbsp;
            <span>
              Create a&nbsp;
              <a
                href={publicRoutes.estimate}
                className="cursor-pointer underline"
              >
                new order here
              </a>
            </span>
          </p>
        </div>
      );
    }
    return <div className="opacity-60 text-center pt-2"> loading... </div>;
  };

  return (
    <div className="w-full md:w-1/2">
      <div className="md:hidden block">
        <FilterInput />
      </div>
      <div className="w-11/12 md:w-full md:ml-5 mx-auto border border-elr-green border-opacity-30 h-450 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md">
        <div>
          <div className="flex justify-center md:justify-between items-center pt-5">
            <div className="hidden md:flex text-lg text-elr-black">
              New Requests
              <p className="ml-5 px-2.5 bg-elr-yellow bg-opacity-10 border-b-2 border-elr-yellow">
                <span className="text-elr-black text-opacity-60 text-xl">
                  {latestData.totalDocs}
                </span>
              </p>
            </div>
          </div>
          <div className="pt-4 border-b hidden md:block border-elr-gray-400 border-opacity-20" />
          <div className="hidden md:block">
            <FilterInput />
          </div>
          <div className="border-b hidden md:block border-elr-gray-400 border-opacity-20" />
        </div>
        <div className="h-5/6 md:h-4/5 overflow-y-scroll no-scrollbar">
          {docsList?.length
            ? docsList?.map((request: Request, i: number) => (
                <div key={request.id}>
                  <RequestItem
                    request={request}
                    index={i}
                    active={active === i}
                    initialPanel
                    refetch={refetch}
                    displayOriginalFees={displayOriginalFees}
                    onUpdateList={onUpdateList}
                    setActive={setActive}
                  />
                </div>
              ))
            : _renderLoadState()}
          {hasNextPage && (
            <div className="mx-auto text-center mt-5">
              <ElrPillButton
                text="Load more"
                size="lg"
                loading={isFetchingNextPage}
                spinnerColor="White"
                onClick={() => {
                  fetchNextPage();
                }}
                className="bg-opacity-90 bg-elr-black text-elr-white py-1 w-28"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewRequests;
