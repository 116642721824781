import { useEffect } from 'react';
import { ElrButton, ElrPageTitle } from 'ui-components';
import { useNavigate } from 'react-router';
import { publicRoutes } from 'app/utilities/routes';
import { Footer } from '../landing/components';
import EventHeader from './EventHeader';
import UpcomingEvent from './UpcomingEvent';
import WhyErrandlr from './WhyErrandlr';
import EventMockups from '../../../assets/images/event-devices-mockup.png';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';

const EventPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate(publicRoutes.registration);
  };

  return (
    <>
      <ElrPageTitle title="Errandlr - Event" />
      <div className="bg-white">
        <EventHeader />
        <UpcomingEvent />
        <div className="md:px-40 px-0 mx-6">
          <WhyErrandlr />
          <div className="flex flex-col md:flex-row-reverse align-middle items-center gap-16">
            <div className="flex flex-col">
              <h2 className="text-28 mb-6 md:text-5xl font-bold w-full md:w-8/12">
                Turn your ideas into memorable experiences
              </h2>
              <p className="w-11/12 mb-16 md:text-xl text-lg md:w-8/12 text-elr-black-300">
                Create, customize, and manage unforgettable events seamlessly
                with our easy-to-use platform.
              </p>
              <div className="flex w-full md:w-auto">
                <ElrButton
                  text="Get Started"
                  className="bg-elr-black text-white w-full md:w-auto"
                  onClick={handleGetStarted}
                />
              </div>
            </div>
            <img
              src={EventMockups}
              alt="event-devices-mockups"
              className="w-full md:w-auto"
            />
          </div>
          <PoliciesAppBanner />
        </div>
        <Footer hideTopFooter />
      </div>
    </>
  );
};

export default EventPage;
