import { publicRoutes } from 'app/utilities/routes';
import APIMockupImg from 'assets/images/personal-details-mockup-1.png';
import React from 'react';
import { useNavigate } from 'react-router';
import { ElrButton } from 'ui-components';

interface StepItemProps {
  number: string;
  color: string;
  title: string;
  description: string;
}

const StepItem: React.FC<StepItemProps> = ({
  number,
  color,
  title,
  description,
}) => (
  <div className="flex flex-row gap-6 items-start">
    <div
      className={`rounded-full text-white text-center justify-center flex align-middle items-center px-4`}
      style={{ backgroundColor: color, width: '34px', height: '34px' }}
    >
      {number}
    </div>
    <div className="flex flex-col md:w-7/12 w-9/12">
      <p className="md:text-2xl text-xl">{title}</p>
      <p className="md:text-lg text-base text-elr-black opacity-60  mt-2">
        {description}
      </p>
    </div>
  </div>
);

const ServicesSection: React.FC = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => {
    navigate(publicRoutes.registration);
  };

  return (
    <div className="bg-white flex md:flex-row flex-col-reverse items-center gap-16 pt-12">
      <div className="md:flex md:flex-row justify-left md:w-5/12 w-full">
        <img className="w-full" src={APIMockupImg} alt="api-mockup" />
      </div>
      <div>
        <p className="text-3xl md:w-9/12 w-10/12 md:text-5xl text-left md:mb-14 mb-10">
          Steps to Use Errandlr APIs
        </p>
        <div className="flex flex-col md:gap-12 gap-8 pb-4">
          <StepItem
            number="1"
            color="#5A4AF2"
            title="Create An Account"
            description="Sign up by providing the required details and fund your wallet."
          />
          <StepItem
            number="2"
            color="#054853"
            title="Generate Web and Secret Key"
            description="Once your account is set up, generate and copy the secret key."
          />
          <StepItem
            number="3"
            color="#FCCD33"
            title="Start Requesting Deliveries"
            description="Process your deliveries and keep your customers happy and satisfied without hassle."
          />
        </div>
        <div className="relative flex justify-start md:mt-16 mt-10 w-full">
          <ElrButton
            text="Get Started"
            className="bg-elr-black text-white px-14 md:mb-8 mb-4 w-full md:w-auto"
            onClick={handleGetStarted}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
