import { useGetProfile } from 'app/hooks/user';
import { BankDetails } from 'app/utilities/types/dispatcherTypes';
import { useUpdateBankInformation } from 'app/hooks/requests/finance';

import React, { useState } from 'react';
import { ElrButton } from 'ui-components';
import BankDetailsInput from './BankDetailsInput';

interface IBankInfoForm {
  setShowAddBankAccount: React.Dispatch<React.SetStateAction<boolean>>;
  onBankDetailsUpdated: (updatedBankDetails: BankDetails) => void;
}

const BankSenderInfoForm: React.FC<IBankInfoForm> = ({
  setShowAddBankAccount,
  onBankDetailsUpdated,
}) => {
  const {
    id: userId,
    accountInformation: { bankDetails },
    role,
  } = useGetProfile();
  const [updateBankInformation, isLoading] = useUpdateBankInformation();

  const [stateBankDetails, setBankDetails] = useState<BankDetails>({
    bankCode: bankDetails?.bankCode || '',
    bankName: bankDetails?.bankName || '',
    accountName: bankDetails?.accountName || '',
    accountNumber: bankDetails?.accountNumber || '',
  });

  const [successSaved, setSuccessSaved] = useState<boolean>(false);

  const updateBankInfo = async () => {
    try {
      await updateBankInformation(stateBankDetails, userId, role);
      setSuccessSaved(true);
      setShowAddBankAccount(false);
      onBankDetailsUpdated(stateBankDetails);
    } catch (err) {
      /* no opp */
    }
  };

  const checkAccountInputs = Object.keys(stateBankDetails).some(
    (ds) => !(stateBankDetails as any)[ds]
  );

  return (
    <div>
      <div className="mt-8 mb-8 text-xl text-center">Bank Information</div>
      <div>
        <BankDetailsInput
          bankDetails={stateBankDetails}
          setBankDetails={setBankDetails}
        />

        {successSaved && (
          <p className="text-xs mb-1 h-3.5 text-elr-green">
            Successfully saved
          </p>
        )}

        <div className="flex justify-center">
          <ElrButton
            text="Save changes"
            size="lg"
            disabled={checkAccountInputs}
            onClick={updateBankInfo}
            className="w-full mt-10 mb-3 text-lg text-white bg-elr-black"
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default BankSenderInfoForm;
