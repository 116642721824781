import { CenterLogoHeader } from 'app/components/Header';
import React from 'react';

const Contact: React.FC = () => (
  <>
    <title>Errandlr - Contact</title>
    <div className="h-full w-full mx-5">
      <CenterLogoHeader />
      <section className="absolute top-1/2 text-center w-full">
        <p className="w-1/2 m-auto">
          To contact support, send an email to&nbsp;
          <a href="mailto:https://support@errandlr.com" className="underline">
            support@errandlr.com
          </a>
          &nbsp; or click the chat button at the bottom right of your screen.
        </p>
        <p>Mobile: +2348039172280</p>
      </section>
    </div>
  </>
);

export default Contact;
