import { useGetProfile } from 'app/hooks/user';
import { DispatcherView } from 'app/utilities/ComponentView';
import React from 'react';
import AddressFilter from './AddressFilter';
import LocationFilter from './LocationFilter';
import ProductsSort from './ProductsSort';
import ProductsFiilter from './ProductsFilter';
import RequestTypeDropdown, {
  RequestTypeOnchangeType,
} from './RequestTypeDropdown';
import { COMMERCE } from '../../../utilities/roles';
import { ElrButton } from '../../../../ui-components';

interface Props {
  requestType?: RequestTypeOnchangeType;
  onChangeRequestType?: (value: RequestTypeOnchangeType) => void;
  displayAdditionalFilterOptions?: boolean;
  showType?: boolean;
  hideAddressSelect?: boolean;
  locationFilterdata?: string[];
  addressFilterdata?: string[];
  productsSortdata?: string[];
  productsTab?: boolean;
  setShowSlideIn?: (value: boolean) => void;
}

const FilterInput: React.FC<Props> = ({
  requestType = { value: '', label: '' },
  onChangeRequestType = () => {},
  displayAdditionalFilterOptions = false,
  showType = true,
  hideAddressSelect = false,
  locationFilterdata = ['All'],
  addressFilterdata = ['Tracking ID'],
  productsSortdata = ['Recent'],
  productsTab,
  setShowSlideIn,
}) => {
  const { currentAccessRole, role } = useGetProfile();
  const currentRole = currentAccessRole || role;

  const handleAddProductsSlideIn = () => {
    setShowSlideIn?.(true);
  };

  if (currentAccessRole === COMMERCE && productsTab) {
    return (
      <div className="w-11/12 md:block md:flex-col md:w-full border h-40 md:h-auto md:border-none border-elr-green border-opacity-30 mb-2.5 md:mb-1 md:ml-0 max-h-32 md:h-16 rounded-md md:flex mx-auto bg-elr-white-400 px-2 md:px-auto no-scrollbar overflow-x-auto overflow-y-hidden">
        <div className="flex justify-between pt-5 md:pt-0 md:flex-none flex-col-reverse md:flex-row flex-wrap md:items-center w-full gap -2 relative h">
          <div className="flex justify-between md:flex-none flex-wrap items-center w-full md:w-8/12 gap -2 relative">
            <ProductsSort productsSortFilterdata={productsSortdata} />
            <ProductsFiilter productsFilterdata={productsSortdata} />
          </div>
          <div className="md:relative flex items-center justify-between md:justify-center md:top-0">
            <p className="md:hidden">Products</p>
            <ElrButton
              text="+ Add Product"
              className="bg-elr-black text-white h-6 w-40"
              size="sm"
              spinnerColor="White"
              onClick={handleAddProductsSlideIn}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-11/12 md:w-full border md:border-none border-elr-green border-opacity-30 mb-2.5 md:mb-1 md:ml-0 max-h-32 md:h-16 rounded-md md:flex mx-auto bg-elr-white-400 px-2 md:px-auto no-scrollbar overflow-x-auto overflow-y-hidden">
      <div className="flex justify-space md:flex-none flex-wrap">
        {showType && (
          <DispatcherView role={currentRole}>
            <RequestTypeDropdown
              requestType={requestType}
              onChange={onChangeRequestType}
              displayAdditionalFilterOptions={displayAdditionalFilterOptions}
            />
          </DispatcherView>
        )}

        <LocationFilter locationFilterdata={locationFilterdata} />
        {!hideAddressSelect && (
          <AddressFilter addressFilterdata={addressFilterdata} />
        )}
      </div>
    </div>
  );
};

export default FilterInput;
