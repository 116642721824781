import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import BankInfoForm from 'app/components/settings/BankInfoForm';
import { useGetProfile } from 'app/hooks/user';
import React, { useState } from 'react';
import { ElrModal, ElrPageTitle } from 'ui-components';

const PaymentDetail: React.FC = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const {
    accountInformation: {
      bankDetails = {
        bankCode: '',
        bankName: '',
        accountName: '',
        accountNumber: '',
      },
    },
  } = useGetProfile();

  const handleShow = () => setIsShowModal(!isShowModal);

  return (
    <div className="h-full">
      <ElrPageTitle title="Errandlr - Personal Profile" />
      <ProfileNavigationMenu>
        <TopNavigationMenu />
        <div className="mt-8 h-1000 md:h-auto">
          <div className="px-8 md:px-24 pt-10 pb-8 text-xl">
            Payment details
          </div>
          <div className="pb-16 md:pb-28 mx-4 md:mx-24 border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-5 mb-20">
            <div
              className="mt-3.5 w-10 text-sm p-0.5 text-elr-purple bg-opacity-10 bg-elr-purple ml-auto text-center mb-8 cursor-pointer"
              onClick={handleShow}
            >
              Edit
            </div>
            {isShowModal && (
              <ElrModal isOpen={isShowModal} onClose={handleShow}>
                <BankInfoForm stepIndex={0} onChangeStep={() => {}} editMode />
              </ElrModal>
            )}
            <div className="flex flex-col md:flex-row">
              <div className="ml-4 md:ml-9">
                <p className="text-sm text-elr-black mb-2 md:mb-5">Bank name</p>
                <p className="text-sm text-elr-black opacity-60">
                  {bankDetails.bankName}
                </p>
              </div>
              <div className="ml-4 md:ml-36 mt-6 md:mt-0">
                <p className="text-sm text-elr-black mb-2 md:mb-5">
                  Name on account
                </p>
                <p className="text-sm text-elr-black opacity-60">
                  {bankDetails.accountName}
                </p>
              </div>
              <div className="ml-4 md:ml-36 mt-6 md:mt-0">
                <p className="text-sm text-elr-black mb-2 md:mb-5">
                  Account number
                </p>
                <p className="text-sm text-elr-black opacity-60">
                  {bankDetails.accountNumber}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default PaymentDetail;
