import { useQueryClient } from '@tanstack/react-query';
import {
  httpAddAsPartner,
  httpGetAllDispacther,
  httpGetNonPartners,
  httpGetPartners,
  httpRemoveAsPartner,
  httpSearchDispatchers,
} from 'app/api/dispatcher';
import { httpGetEstimate } from 'app/api/estimate';
import { useFetchInfiniteQuery } from 'app/components/dashboard/hooks';
import { ALL, NON_PARTNERS, PARTNERS } from 'app/constants';
import { STATE_DISPATCH_PAYLOAD } from 'app/state/constants';
import {
  AgentPayloadInterface,
  EstimateProps,
  HttpDispatchResponse,
  HttpLocationValues,
} from 'app/utilities/types/shared';
import { useState } from 'react';
import { useGetProfile } from './user';

export const useGetAgents = (): AgentPayloadInterface[] => {
  const queryClient = useQueryClient();
  const agents: AgentPayloadInterface[] =
    queryClient.getQueryData(['agentsList']) || [];
  return agents;
};
export const useGetEstimations = () => {
  const queryClient = useQueryClient();
  const estimateDetailCache: HttpDispatchResponse | undefined =
    queryClient.getQueryData([STATE_DISPATCH_PAYLOAD]);
  const estimateRoutes = estimateDetailCache?.routes && {
    ...estimateDetailCache?.routes,
    parcelCode: estimateDetailCache.parcelCode,
  };
  return [estimateDetailCache, estimateRoutes];
};
export const useGetStructureEstimate = (estimateRoutes?: EstimateProps) => {
  const destinations = estimateRoutes?.dropoffLocations.map(
    ({ destination, duration, distance }: any) => ({
      label: destination.label || '',
      id: destination.id || '',
      distance: distance || 0,
      duration: duration || 0,
    })
  );
  const estimatePayload = {
    parcelCode: estimateRoutes?.parcelCode || '',
    pickupLocation: {
      label: estimateRoutes?.pickupLocation.label || '',
      id: estimateRoutes?.pickupLocation.id || '',
      distance: estimateRoutes?.pickupLocation.distance || 0,
      duration: estimateRoutes?.pickupLocation.duration || 0,
    },
    dropoffLocations: destinations || [],
  };
  return [estimatePayload];
};
export const useFetchEstimateQuery = () => {
  const queryClient = useQueryClient();
  const sendQuery = async (payload: HttpLocationValues) => {
    const data = await queryClient.fetchQuery({
      queryKey: [STATE_DISPATCH_PAYLOAD],
      queryFn: () => httpGetEstimate(payload),
      gcTime: 24000000, // ~40 minutes+
      staleTime: 56000,
    });
    return data;
  };
  return [sendQuery];
};

export type Tabs = typeof ALL | typeof PARTNERS | typeof NON_PARTNERS;

export const useFetchDispatchersAndPartners = (tab: Tabs) => {
  const { id: userId } = useGetProfile();

  const switchTab: { All: string; Partners: string; 'Non-Partners': string } = {
    All: ALL,
    Partners: PARTNERS,
    'Non-Partners': NON_PARTNERS,
  };

  const tabs: string = switchTab[tab];
  const struct: {
    [key: string]: {
      fn: Function;
      payload: {
        userId: string;
      };
    };
  } = {
    All: {
      fn: httpGetAllDispacther,
      payload: {
        userId,
      },
    },
    Partners: {
      fn: httpGetPartners,
      payload: {
        userId,
      },
    },
    'Non-Partners': {
      fn: httpGetNonPartners,
      payload: {
        userId,
      },
    },
  };

  return useFetchInfiniteQuery({
    key: tabs,
    ...struct[tab],
  });
};

type AddRemovePartnersHookType = () => [
  (userId: string, dispatcherId: string) => Promise<void>,
  boolean,
];

export const useAddAsPartner: AddRemovePartnersHookType = () => {
  const [loading, setLoading] = useState(false);

  const addAsPartner = async (userId: string, dispatcherId: string) => {
    setLoading(true);
    try {
      const result = await httpAddAsPartner(userId, dispatcherId);
      setLoading(false);
      return result;
    } catch (err) {
      // no op
    } finally {
      setLoading(false);
    }
    return null;
  };
  return [addAsPartner, loading];
};

export const useRemoveAsPartner: AddRemovePartnersHookType = () => {
  const [loading, setLoading] = useState(false);

  const removeAsPartner = async (userId: string, dispatcherId: string) => {
    setLoading(true);
    try {
      const result = await httpRemoveAsPartner(userId, dispatcherId);
      setLoading(false);
      return result;
    } catch (err) {
      // no op
    } finally {
      setLoading(false);
    }
    return null;
  };
  return [removeAsPartner, loading];
};

type SearchDispatchersHookType = () => [
  (userId: string, email: string) => Promise<void>,
  boolean,
];
export const useSearchDispatchers: SearchDispatchersHookType = () => {
  const [loading, setLoading] = useState(false);
  const searchDispatchers = async (userId: string, email: string) => {
    setLoading(true);
    try {
      const result = await httpSearchDispatchers(userId, email);
      setLoading(false);
      return result;
    } catch (err) {
      // no op
    } finally {
      setLoading(false);
    }
    return null;
  };
  return [searchDispatchers, loading];
};
