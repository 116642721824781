import { ITenantData } from 'app/api/d';
import { DEFAULT_COUNTRY } from './helpers';

export const accessToken = 'access_guid';
export const refreshToken = 'refresh_guid';
export const tenantDataKey = 'tenant_data';
export const currentCountryKey = 'current_country';
const { localStorage } = window;

export const Session = {
  currentUser: () => {
    const accessKey = localStorage.getItem(accessToken);
    return accessKey;
  },

  currentCountry: () =>
    localStorage.getItem(currentCountryKey) || DEFAULT_COUNTRY,

  refreshToken: () => {
    const refreshKey = localStorage.getItem(refreshToken);
    return refreshKey;
  },

  setValue: (key: string, value: string) => {
    const setKey = localStorage.setItem(key, value);
    return setKey;
  },

  getValue: (key: string) => {
    const getKey = localStorage.getItem(key);
    return getKey;
  },

  logout: () => {
    localStorage.removeItem(accessToken);
    localStorage.removeItem(refreshToken);
    return 'ok';
  },
  tenantData: (): ITenantData => {
    const data = localStorage.getItem(tenantDataKey);
    const defaultData = {
      nigeria: {
        active: true,
        states: {
          lagos: {
            active: true,
          },
        },
      },
    };
    return data ? JSON.parse(data) : defaultData;
  },
};
