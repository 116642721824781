import { TopNav } from 'app/components/menu/Navbar';
import { useEffect } from 'react';
import errandlrLogoWall from '../../../assets/images/errandlr-logo-wall.png';
import { ElrPageTitle } from '../../../ui-components';
import { Footer } from '../landing/components/Footer';
import PoliciesAppBanner from '../landing/components/PoliciesAppBanner';

interface BoxProps {
  num: string;
  text: string;
  bgColor: string;
  textColor: string;
  roundedCorners: string;
}

const StatBox: React.FC<BoxProps> = ({
  num,
  text,
  bgColor,
  textColor,
  roundedCorners,
}) => (
  <div
    className={`md:w-[510px] w-auto lg:w-[450px] h-[150px] lg:h-[175px] flex flex-col justify-center items-center align-middle px-8 ${bgColor} ${roundedCorners}`}
  >
    <p className="text-elr-black text-opacity-70 mb-3 text-base md:text-lg">
      {text}
    </p>
    <h2 className={`text-5xl md:text-6xl ${textColor}`}>{num}</h2>
  </div>
);

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const statsData = [
    {
      num: '5',
      text: 'Core Services',
      bgColor: 'bg-[#EBF5F6]',
      textColor: 'text-elr-green',
      roundedCorners:
        'rounded-tl-3xl rounded-bl-none rounded-tr-none rounded-br-none',
    },
    {
      num: '5k+',
      text: 'Active Users',
      bgColor: 'bg-[#ECEBFF]',
      textColor: 'text-elr-purple',
      roundedCorners:
        'rounded-tl-none rounded-bl-3xl rounded-tr-none rounded-br-none',
    },
    {
      num: '20',
      text: 'Team Members',
      bgColor: 'bg-[#F8F1DA]',
      textColor: 'text-elr-yellow',
      roundedCorners:
        'rounded-tl-none rounded-bl-none rounded-tr-3xl rounded-br-none',
    },
    {
      num: '2+',
      text: 'Countries',
      bgColor: 'bg-elr-gray',
      textColor: 'text-elr-green',
      roundedCorners:
        'rounded-tl-none rounded-bl-none rounded-tr-none rounded-br-3xl',
    },
  ];

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - About us" />
      <div className="h-full w-full relative ">
        <TopNav isDefault />
        <div className="md:px-40 px-0 mx-6">
          <div className="w-full pb-24 md:pb-20 justify-center pt-28 text-elr-black flex flex-col gap-2">
            <h1 className="text-4xl leading-10 font-bold text-center pb-6">
              Our Vision
            </h1>
            <p className="text-elr-black text-opacity-60 text-xl text-center md:w-5/12 w-11/12 md:pb-14 pb-8 mx-auto">
              To be Africa&apos;s leading digital platform, revolutionizing how
              people and businesses handle logistics, trade, and financial
              services through innovative technology solutions.
            </p>
            <div className=" mx-auto">
              <img src={errandlrLogoWall} alt="" className="w-full" />
            </div>
          </div>
          <div className="flex flex-col gap-14 md:gap-20 md:pb-40 pb-24">
            <section className="flex-col mb-8 md:flex md:flex-row">
              <p className="md:w-1/2 w-full pb-5 text-4xl text-elr-purple">
                Our Expertise
              </p>
              <p className="md:w-5/12 w-full text-xl text-elr-black-200 text-opacity-75">
                We specialize in providing a unified platform that seamlessly
                integrates logistics, commerce, and financial services. Our
                technology-driven solutions include local and international
                deliveries, secure payment systems, and comprehensive shopping
                services.
                <br />
                <br />
                Through our innovative platform, we&apos;ve simplified complex
                processes, making it easier for both individuals and businesses
                to manage their daily operations efficiently.
              </p>
            </section>
            <section className="flex-col mb-5 md:flex md:flex-row">
              <p className="md:w-1/2 w-full pb-6 text-4xl text-elr-green">
                Our Solutions
              </p>
              <p className="md:w-5/12 w-9/10 text-xl text-elr-black-200 text-opacity-75">
                • Door-to-door deliveries across Nigeria and internationally
                <br />
                • Seamless bank transfers and financial transactions
                <br />
                • International money conversion services
                <br />
                • Mobile data and airtime purchases
                <br />
                • Market and supermarket shopping services
                <br />
                • Restaurant order fulfillment
                <br />• Event ticketing platform
              </p>
            </section>
            <section className="flex-col mb-5 md:flex md:flex-row">
              <p className="md:w-1/2 w-full pb-6 text-4xl text-elr-yellow">
                Contact Info
              </p>
              <p className="md:w-5/12 w-9/10 text-xl text-elr-black-200 text-opacity-75">
                Email: support@errandlr.com
                <br />
              </p>
            </section>
          </div>
          <div className="w-full flex flex-col justify-center">
            <p className=" text-4xl text-center text-elr-black md:w-6/12 w-11/12 mx-auto pb-16">
              Transforming Nigeria&apos;s Digital Commerce Landscape
            </p>
            <div className="flex w-auto mx-auto items-center">
              <div className="flex flex-col">
                {statsData.slice(0, 2).map((stat) => (
                  <StatBox key={stat.text} {...stat} />
                ))}
              </div>
              <div className="flex flex-col">
                {statsData.slice(2).map((stat) => (
                  <StatBox key={stat.text} {...stat} />
                ))}
              </div>
            </div>
          </div>
          <PoliciesAppBanner />
        </div>
        <Footer hideTopFooter />
      </div>
    </div>
  );
};
export default AboutUs;
