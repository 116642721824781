import AgentForm from 'app/modules/onboarding/AgentForm';
import React from 'react';
import { ElrModal } from 'ui-components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCompleted: () => void;
}
const AddAgentModal: React.FC<Props> = ({ isOpen, onClose, onCompleted }) =>
  isOpen ? (
    <ElrModal isOpen={isOpen} onClose={onClose}>
      <AgentForm
        onComplete={onCompleted}
        onClose={onClose}
        profileAddAgentButton
      />
    </ElrModal>
  ) : null;

export default AddAgentModal;
