import {
  useFetchNewRequests,
  useRefetchNewRequests,
  useRefetchUpcomingRequests,
} from 'app/hooks/requests/dashboard';
import { useGetProfile } from 'app/hooks/user';
import RequestItem from 'app/platform/modules/RequestItem';
import { handleScroll } from 'app/platform/modules/utility';
import { SenderView } from 'app/utilities/ComponentView';
import { publicRoutes } from 'app/utilities/routes';
import { Request } from 'app/utilities/types/shared';
import React, { useEffect, useState } from 'react';
import { ElrPillButton } from 'ui-components';

export type SingleDeliveryProp = {
  displayOriginalFees?: boolean;
  containerClassName: string;
  requestType?: any;
};

const SingleDelivery: React.FC<SingleDeliveryProp> = ({
  displayOriginalFees,
  containerClassName,
  requestType = 'marketplace',
}) => {
  const [active, setActive] = useState(0);
  const { role, currentAccessRole } = useGetProfile();

  const {
    hasNextPage,
    fetchNextPage,
    latestData,
    isLoading,
    isFetchingNextPage,
    docsList,
  } = useFetchNewRequests(requestType);

  const refetchNewRequests = useRefetchNewRequests();
  const refetchUpcomingRequests = useRefetchUpcomingRequests();

  const requestDocuments = latestData.docs;
  const requestList = React.useRef(null);
  const currentRole = currentAccessRole || role;

  const updateList = async () => {
    refetchNewRequests();
    refetchUpcomingRequests();
  };

  const _centerLoadingMessage = () => (
    <div className="opacity-60 text-center pt-2"> loading... </div>
  );

  useEffect(() => {
    handleScroll(requestList);
  }, [requestDocuments]);

  const _renderLoadState = () => {
    if (!isLoading && !requestDocuments.length) {
      return (
        <div className="opacity-60 text-center pt-2">
          <p>
            There are no new requests.&nbsp;
            <SenderView role={currentRole}>
              <span>
                Create a&nbsp;
                <a
                  href={publicRoutes.estimate}
                  className="cursor-pointer underline"
                >
                  new order here
                </a>
              </span>
            </SenderView>
          </p>
        </div>
      );
    }
    return _centerLoadingMessage();
  };

  return (
    <div className={containerClassName}>
      {!isLoading ? (
        <>
          {requestDocuments.length
            ? docsList?.map((request: Request, i: number) => (
                <div key={request.id}>
                  <RequestItem
                    request={request}
                    index={i}
                    refetch={updateList}
                    active={active === i}
                    initialPanel
                    displayOriginalFees={displayOriginalFees}
                    onUpdateList={updateList}
                    setActive={setActive}
                  />
                </div>
              ))
            : _renderLoadState()}
          {hasNextPage && (
            <div className="mx-auto text-center mt-5">
              <ElrPillButton
                text="Load more"
                size="lg"
                loading={isFetchingNextPage}
                spinnerColor="White"
                onClick={() => {
                  fetchNextPage();
                }}
                className="bg-opacity-90 bg-elr-black text-elr-white py-1 w-28"
              />
            </div>
          )}
        </>
      ) : (
        _centerLoadingMessage()
      )}
    </div>
  );
};

export default SingleDelivery;
