import styled from 'styled-components';

export const ErrandContainer = styled.div`
  height: 900px;
  @media (min-width: 768px) {
    height: 800px;
  }
`;

export const FooterInput = styled.div`
  input {
    height: 39px;
    background: #151f2f;
  }
`;
