import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpTrackParcel } from 'app/api/trackParcel';
import CancelOrderFlow from 'app/components/CancelOrderFlow';
import DeliveryDetails from 'app/components/DeliveryDetails';
import GenerateInvoice from 'app/components/GenerateInvoice';
import { MobileHeader } from 'app/components/Header';
import Loading from 'app/components/Loading';
import LockerComponent from 'app/components/LockerComponent';
import { getCurrentCountryData } from 'app/utilities/country';
import { currencyFormatter } from 'app/utilities/helpers';
import { Request } from 'app/utilities/types/shared';
import Logo from 'assets/images/logo.svg';
import truncate from 'lodash/truncate';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ElrDropdownIconToggle, ElrPageTitle } from 'ui-components';
import SingleTrack, { SingleTrackStatus } from './Single';

const initialSingleState = {
  open: false,
  pending: false,
  accepted: false,
  collected: false,
  completed: false,
  closed: false,
  booking: false,
};

const TrackParcelUpdate: React.FC = () => {
  const trackingId =
    new URLSearchParams(useLocation().search).get('trackingId') || '';
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const [singleOrderStatus, setSingleOrderStatus] =
    useState<SingleTrackStatus>(initialSingleState);

  const queryClient = useQueryClient();
  const history = useNavigate();

  const parcel: Request | undefined = queryClient.getQueryData(['trackparcel']);
  const { isLoading, isError, isFetched, refetch } = useQuery({
    queryKey: ['trackparcel'],
    queryFn: () => httpTrackParcel(trackingId),
    enabled: !parcel,
    retry: 0,
  });

  const hasError = isError && isFetched;
  const setOrderFn = (update: { [key: string]: boolean }) => {
    setSingleOrderStatus({ ...singleOrderStatus, ...update });
  };

  useEffect(() => {
    if (isLoading) return;
    onLoadedCheck();
  }, [parcel, isLoading]);

  useEffect(() => {
    refetch();
  }, []);

  const onLoadedCheck = useCallback(() => {
    if (!isLoading && !hasError && parcel) {
      const { status } = parcel;

      const switchSingleOrder: { [status: string]: () => void } = {
        open: () => setOrderFn({ open: true }),
        pending: () => setOrderFn({ open: true, pending: true }),
        accepted: () => setOrderFn({ open: true, accepted: true }),
        collected: () =>
          setOrderFn({ open: true, accepted: true, collected: true }),
        completed: () =>
          setOrderFn({
            open: true,
            accepted: true,
            collected: true,
            completed: true,
          }),
        closed: () => setOrderFn({ closed: true }),
        booking: () => setOrderFn({ booking: true }),
        quote: () => setOrderFn({ quote: true }),
      };

      switchSingleOrder[status]();
    } else {
      history('/not-found');
    }
  }, [parcel, isLoading]);

  if (!parcel) {
    return <div />;
  }

  if (isLoading) {
    return <Loading />;
  }
  if (hasError) {
    return <div />;
  }

  return (
    <div className="relative bg-elr-gray-500 h-1000">
      <ElrPageTitle title="Errandlr - Track Parcel" />
      <div className="w-full md:w-11/12 md:mx-auto">
        <Link to="/">
          <img className="hidden pt-10 md:block" src={Logo} alt="logo" />
        </Link>
        <MobileHeader mdHide />
      </div>
      <div className="flex flex-col items-center pb-5">
        {parcel && <CancelOrderFlow request={parcel} onTrackUpdate />}
        <LockerComponent
          className="w-350 md:w-600"
          pickupAddress={parcel.pickupAddress}
        />
        <div className="p-4 mt-4 border rounded-md w-350 md:w-600 bg-elr-white-400 border-opacity-40 border-elr-green">
          <div className="flex justify-between pb-2 border-b border-elr-gray">
            <div className="text-base">Summary</div>
            <div>
              <GenerateInvoice data={parcel} />
            </div>
          </div>
          <div className="flex justify-between pt-3 pb-3 text-sm border-b border-elr-gray opacity-70">
            <span>
              {truncate(parcel.pickupAddress.fullAddress, { length: 42 })}
            </span>
            {!parcel.businessPays && (
              <span>{currencyFormatter(+parcel!.priceEstimate)}</span>
            )}
            <span>
              <ElrDropdownIconToggle
                isToggled={isToggled}
                toggle={setIsToggled}
              />
            </span>
          </div>
          {isToggled && (
            <div>
              <DeliveryDetails details={parcel} />
              <div className="flex justify-between mt-10 mb-2 md:text-base">
                {!parcel.businessPays && (
                  <>
                    <p className="opacity-60"> Total charged: </p>
                    <p className="text-xl">
                      &nbsp;
                      {getCurrentCountryData().currencyCode.toUpperCase()}&nbsp;
                      {parcel.priceEstimate}
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <SingleTrack
          orderStatus={singleOrderStatus}
          parcel={parcel}
          trackingId={trackingId}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default TrackParcelUpdate;
