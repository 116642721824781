import { useGetProfile } from 'app/hooks/user';
import { Steps } from 'app/modules/dispatch/DispatchOrder';
import AuthOptions from 'app/sender/components/AuthOptions';
import CreateAccountSlideIn from 'app/sender/components/CreateAccountSlideIn';
import LoginSlideIn from 'app/sender/components/LoginSlideIn';
import WalletSlideIn from 'app/sender/components/WalletSlideIn';
import React, { useState } from 'react';

export interface AuthModalInterface {
  showSlideIn: boolean;
  setShowSlideIn: (state: boolean) => void;
  onUserSelectCardOption: () => void;
  onUserSelectWalletOption?: () => void;
  getRequestPayload?: () => void;
}

export type PagesState = 'authOptions' | 'login' | 'createAccount' | 'wallet';

const AuthOptionsModal: React.FC<AuthModalInterface> = ({
  showSlideIn,
  setShowSlideIn,
  onUserSelectCardOption,
  onUserSelectWalletOption,
  getRequestPayload,
}) => {
  const { id } = useGetProfile();
  const selectPageState = id ? 'wallet' : 'authOptions';
  const [currentScreen, setCurrentScreen] =
    useState<PagesState>(selectPageState);

  const screens: Steps = {
    authOptions: (
      <AuthOptions
        showSlideIn={showSlideIn}
        setShowSlideIn={setShowSlideIn}
        setCurrentScreen={setCurrentScreen}
        onUserSelectCardOption={onUserSelectCardOption}
        getRequestPayload={getRequestPayload}
      />
    ),

    createAccount: (
      <CreateAccountSlideIn
        showSlideIn={showSlideIn}
        setShowSlideIn={setShowSlideIn}
        setCurrentScreen={setCurrentScreen}
        onUserSelectCardOption={onUserSelectCardOption}
      />
    ),

    login: (
      <LoginSlideIn
        showSlideIn={showSlideIn}
        setShowSlideIn={setShowSlideIn}
        setCurrentScreen={setCurrentScreen}
        onUserSelectCardOption={onUserSelectCardOption}
      />
    ),

    wallet: (
      <WalletSlideIn
        onUserSelectCardOption={onUserSelectCardOption}
        onUserSelectWalletOption={onUserSelectWalletOption}
        showSlideIn={showSlideIn}
        setShowSlideIn={setShowSlideIn}
        getRequestPayload={getRequestPayload}
      />
    ),
  };

  const renderComponent = screens[currentScreen];

  return <div>{renderComponent}</div>;
};

export default AuthOptionsModal;
