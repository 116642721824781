export const switchedOnContainer = (switchedOn: boolean) =>
  `rounded-full px-1 w-11 h-5 items-center flex ${
    switchedOn
      ? 'justify-end bg-elr-purple bg-opacity-60'
      : 'bg-elr-grey border'
  }`;

export const switchedOnButton = (switchedOn: boolean) =>
  `${switchedOn ? 'bg-white' : 'bg-elr-black'} rounded-full h-3.5 w-3.5`;

export const inputClass = () =>
  `h-50 flex items-center justify-between px-2 mb-2.5 bg-elr-gray`;
