import { Roles } from 'app/hooks/user';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { Session } from './Session';
import { COMMERCE, DISPATCHER, SENDER } from './roles';

type Navigation = {
  sender: { title: string; route: string }[];
  dispatcher: { title: string; route: string }[];
  commerce: { title: string; route: string }[];
};

const navigations: Navigation = {
  sender: [
    {
      title: 'Dashboard',
      route: privateRoutes.dashboard(SENDER),
    },
    {
      title: 'Estimate',
      route: publicRoutes.estimate,
    },
    {
      title: 'Profile',
      route: `/sender/${privateRoutes.profile}`,
    },
  ],
  /**
   * deliberately leave out ["company"] route from the navigation list
   */
  dispatcher: [
    {
      title: 'Dashboard',
      route: privateRoutes.dashboard(DISPATCHER),
    },
    {
      title: 'Profile',
      route: `/dispatcher/${privateRoutes.profile}`,
    },
    {
      title: 'Payment',
      route: `/dispatcher/${privateRoutes.payment}`,
    },
    {
      title: 'Agents',
      route: `/dispatcher/${privateRoutes.agents}`,
    },
  ],
  commerce: [
    {
      title: 'Dashboard',
      route: privateRoutes.dashboard(COMMERCE),
    },
    {
      title: 'Account',
      route: `/commerce/${privateRoutes.company}`,
    },
  ],
};

const mappedNavigation = (role: Roles) => {
  const selectedAccountType = Session.getValue('accessUserType');

  return (
    navigations as {
      [key: string]: {
        title: string;
        route: string;
      }[];
    }
  )[selectedAccountType || role];
};

export const RoleHash: { [key: string]: boolean } = {
  sender: true,
  dispatcher: true,
  commerce: true,
};

export default mappedNavigation;
