import { TopNav } from 'app/components/menu/Navbar';
import { Footer } from 'app/modules/landing/components';
import React, { useEffect } from 'react';
import { ElrAccordion, ElrPageTitle } from 'ui-components';
import PurpleVector from 'assets/images/policies-purple-vector.svg';
import YellowVector from 'assets/images/policies-yellow-vector.svg';
import PoliciesAppBanner from 'app/modules/landing/components/PoliciesAppBanner';

const PrivacyPolicy: React.FC = () => {
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<number>(0);
  const showDropdownOption = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const boxes = [1, 2, 3, 4, 5];

  return (
    <div className="bg-white">
      <ElrPageTitle title="Errandlr - Privacy Policy" />
      <TopNav isDefault />
      <div className="mx-5 py-10 md:mx-40">
        <div className="text-xl mt-12 mb-16 bg-elr-gray relative rounded-xl overflow-hidden">
          <img
            src={PurpleVector}
            alt=""
            className="absolute md:bottom-0 w-3/12 md:w-32 -bottom-2 -left-2 md:left-0"
          />
          <img
            src={YellowVector}
            alt=""
            className="absolute md:top-0 -right-2 md:right-0 w-3/12 md:w-32 -top-2"
          />
          <p className="md:text-5xl text-center py-16 text-3xl">
            Privacy Policy
          </p>
        </div>
        <div className="text-base mt-16 mb-10 text-elr-black-300">
          <p>
            Errandlr Technologies Limited and its affiliated entities
            (collectively, &quot;Errandlr&quot;, &quot;us&quot;, or
            &quot;we&quot;) are committed to protecting your privacy. This
            Privacy Statement describes Errandlr’s information practices and the
            choices available to you regarding Errandlr’s use of information
            that can be used to identify you (&quot;Personal Data&quot;). This
            Privacy Statement addresses Errandlr’s use of information collected
            through our website or our software or maintenance products
            (collectively, &quot;Products&quot;). We will only collect and use
            information from you as described in this Privacy Statement. We will
            not sell your data to any third parties. This statement also governs
            Errandlr’s collections of personal data in connection with
            Errandlr’s services. We may collect the contact information of
            individuals or of owners or employees of Errandlr delivery partners.
            We may also collect the personal data of those who start but do not
            complete applications to be riders or delivery persons, those who
            rent motorbikes, scooters or other light electrical devices offered
            by other companies through the Errandlr app, or in connection with
            our mapping technology and features.
          </p>
          <p className="pt-3">
            All those subject to this notice are referred to as ‘users’ in this
            notice.
          </p>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[1]}
            active={boxes[1] === active}
            transformPositionClass=""
            header="1. The Data We Process; Purposes of Processing"
          >
            <div className="mb-5">
              <p>
                Depending on how you interact with us, we process different
                kinds of data and in different ways. Some data is automatically
                processed if you visit our Sites or use our Products. Other data
                is only processed if you actively submit it to us (like using
                our web forms to get in touch with us or uploading content to
                our Sites).
              </p>
              <p className="text-lg font-bold mb-5 mt-5">
                Data we process when you visit our Site:
              </p>
              <span className="text-lg font-bold">Usage data:</span> We collect
              data about how users interact with our services. This includes
              data such as access dates and times, app features or pages viewed,
              app crashes and other system activity, type of browser, and
              third-party sites or services used before interacting with our
              services. In some cases, we collect this data through cookies,
              pixels, tags, and similar tracking technologies that create and
              maintain unique identifiers.
              <span className="text-lg font-bold">
                To learn more about these technologies, please see our Cookie
                Notice.
              </span>
            </div>

            <p className="mb-5">
              Even if you are only a visitor on our site, your computer or
              device automatically sends technical information to our web
              servers that we store in log files, including the following
              <span className="font-bold">{`"Website Usage Data":`}</span>
            </p>

            <div className="mb-5">
              <ul className="list-disc ml-4">
                <li>
                  Date and time of the visit and the duration of use of the
                  Site;
                </li>
                <li>
                  The IP address of your device as well as your internet service
                  provider (ISP);
                </li>
                <li>The referral/exit URL;</li>
                <li>The visited subsites of the Site; Clickstream data; and</li>
                <li>
                  Information about your device (type of device, browser type
                  and version, as well as settings, installed plug-ins,
                  operating system).
                </li>
              </ul>
            </div>

            <p className="mb-5">
              We process Website Usage Data to allow you to surf the Site and to
              ensure its functionality. We also process Website Usage Data to
              perform analyses on the performance of the Site, to continuously
              improve the Site and correct errors, to ensure IT security and
              operation of our systems, as well as to prevent or uncover abuse.
              We further process this data in aggregated or pseudonymized form
              to analyze trends, administer the Sites, track users’ movements
              around the Site, and to gather demographic information about our
              user base as a whole or to improve marketing (including serving
              you personalized, interest-based ads) as well as the user
              experience on our Site and guaranteeing the safety of our IT
              systems.
            </p>
            <p className="mb-5">
              <span className="font-bold">
                Background check and identity verification:
              </span>
              We collect background check and identity verification information
              for riders and delivery persons. This may include information such
              as rider history or criminal record (where permitted by law), and
              right to work. This information may be collected by an authorized
              vendor on Errandlr’s behalf.
            </p>
            <p className="mb-5">
              <span className="font-bold">Customer Support Data:</span> We
              collect the information users submit when they contact Errandlr
              customer support, provide ratings or compliments for other users,
              riders or third party partners, or otherwise contact Errandlr.
              This may include feedback, photographs or other recordings
              collected by users. You can use the contact forms to request
              technical assistance, request information on the status of your
              delivery, or otherwise reach out to our customer support team. In
              order to respond to your request, we will process your IP address
              and contact data as well as the contents of your request. We will
              delete this data as soon as we have complied with your request.
            </p>

            <p className="mb-5">
              <span className="font-bold">Survey/Demographic Data:</span>
              From time to time we may conduct surveys in respect of our
              products and services. Participation in our surveys is optional.
              However, if you respond to one of our surveys, you may provide us
              with personal information about yourself. Unless you otherwise
              consent, we will only use this information to determine the types
              of Products that may be of interest to you and to operate and
              improve our Product offerings.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                Email/Direct Mail Campaign Data:
              </span>
              From time to time, we may contact our customers directly by mail,
              email, or telephone to inform you about upgrades, new products,
              promotions, or special offers that you told us you were interested
              in receiving (including our newsletters). However, we will not
              contact you with any commercial communications that are unrelated
              to the Products that you have purchased from us unless you have
              given us your prior explicit consent to receiving such
              information. Please note that, where you have given us your
              consent to receive marketing communications, you are free to
              withdraw your consent to receiving such communications at any
              point in time. When responding to one of these campaigns, you may
              have the option to provide us with personal information, which we
              will use for the purpose indicated.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                Business Contact or Sales Prospect Data:
              </span>
              If you are a business contact or sales prospect that has provided
              us your Personal Data, we will store your data in our database so
              that we can follow up on previous business conversations we’ve had
              with you, provide you additional information about our Products,
              or assist you in purchasing our Products. We may also share your
              Personal Data with sales representatives employed with our local
              affiliates or resellers so that they can work with you to complete
              any requested purchase of our Products.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                Location data (riders and delivery recipients):
              </span>
              We collect precise or approximate location data from riders’ and
              delivery recipients’ mobile devices if they enable us to do so.
              Errandlr collects this data from the time a delivery is requested
              until it is finished, and any time the app is running in the
              foreground of their mobile device. We use this data to enhance
              your use of our app, including improving pick-up locations,
              enabling safety features, and preventing and detecting fraud.
            </p>

            <p className="mb-5">
              Errandlr collects driver location data, and links location data
              collected during a trip with their rider’s account, even if the
              rider has not enabled us to collect location data from their
              device. This enables us to offer services to the rider like
              receipt generation and customer support.
            </p>

            <p className="mb-5">
              <span className="font-bold">Transaction information:</span>
              We collect transaction information related to the use of our
              services, including the type of services requested or provided,
              order details, delivery information, date and time the service was
              provided, amount charged, distance traveled, and payment method.
              Additionally, if someone uses your promotion code, we may
              associate your name with that person.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                Data from other sources. This includes:
              </span>
            </p>

            <p className="mb-5">
              User feedback, such as ratings, feedback, or compliments.
            </p>

            <p className="mb-5">
              Users participating in our referral programs. For example, when a
              user refers another person, we receive the referred person’s
              personal data from that user.
            </p>

            <p className="mb-5">
              Users or others providing information in connection with claims or
              disputes.
            </p>

            <p className="mb-5">
              Vendors who help us verify users’ identity, background
              information, and eligibility to work, for regulatory, safety, and
              security purposes.
            </p>

            <p className="mb-5">
              Insurance, vehicle, or financial services providers for riders
              and/or delivery persons.
            </p>

            <p className="mb-5">
              Partner transportation companies (for riders or delivery persons
              who use our services through an account associated with such a
              company).
            </p>

            <p className="mb-5">Publicly available sources.</p>

            <p className="mb-5">Marketing service providers.</p>

            <p className="mb-5">
              Errandlr may combine the data collected from these sources with
              other data in its possession.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="2. How we use personal data"
          >
            <p className="mb-5">
              Errandlr collects and uses data to enable reliable and convenient
              transportation, delivery, and other products and services. We also
              use the data we collect:
            </p>

            <p className="mb-5">
              To enhance the safety and security of our users and services
            </p>

            <p className="mb-5">For customer support</p>

            <p className="mb-5">For research and development</p>

            <p className="mb-5">To enable communications between users</p>

            <p className="mb-5">
              To send marketing and non-marketing communications to users
            </p>

            <p className="mb-5">In connection with legal proceedings</p>

            <p className="mb-5">
              Errandlr does not sell or share user personal data with third
              parties for their direct marketing, except with users’ consent.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                Providing services and features:
              </span>
              Errandlr uses the data we collect to provide, personalize,
              maintain, and improve our products and services.
            </p>

            <p className="mb-5">This includes using the data to:</p>

            <p className="mb-5">Create and update users’ accounts.</p>

            <p className="mb-5">
              Verify riders’ and delivery persons’ identity, background history,
              and eligibility to work.
            </p>

            <p className="mb-5">
              Enable transportation, deliveries, and other services.
            </p>

            <p className="mb-5">
              Offer, process, or facilitate payments for our services.
            </p>

            <p className="mb-5">
              Offer, obtain, provide, or facilitate insurance, invoicing, or
              financing solutions in connection with our services.
            </p>

            <p className="mb-5">
              Track and share the progress of rides or deliveries.
            </p>

            <p className="mb-5">
              Enable features that allow users to share information with other
              people, such as when riders submit a compliment about a driver,
              when delivery recipients provide feedback for a delivery person,
              refer a friend to Errandlr, or share ETA and location with their
              contacts.
            </p>

            <p className="mb-5">
              Perform internal operations necessary to provide our services,
              including to troubleshoot software bugs and operational problems;
              to conduct data analysis, testing, and research; and to monitor
              and analyze usage and activity trends.
            </p>

            <p>
              <span className="font-bold">Safety and security:</span> We use
              personal data to help maintain the safety, security, and integrity
              of our services and users. This includes:
            </p>

            <p className="mb-5">
              Screening riders and delivery persons before enabling their use of
              our services and at subsequent intervals, including through
              reviews of background checks, where permitted by law, to help
              prevent use of our services by unsafe riders.
            </p>

            <p className="mb-5">
              Using data from delivery persons’ devices to help identify unsafe
              riding behavior and to inform them of safer riding practices.
            </p>

            <p className="mb-5">
              Using user ratings and feedback to encourage compliance with our
              Community Guidelines and as grounds for deactivating riders and
              delivery persons with low ratings or who otherwise violated such
              guidelines.
            </p>

            <p>
              <span className="font-bold">Customer support:</span> Errandlr uses
              the information we collect (including recordings of customer
              support calls with notice to and the consent of the user) to
              provide customer support, including to:
            </p>

            <p className="mb-5">
              Direct questions to the appropriate customer support person
            </p>

            <p className="mb-5">Investigate and address user concerns</p>

            <p className="mb-5">
              Monitor and improve our customer support responses and processes.
            </p>

            <p className="mb-5">
              <span className="font-bold">Research and development:</span> We
              may use the data we collect for testing, research, analysis,
              product development, and machine learning to improve the user
              experience. This helps us to improve and enhance the safety and
              security of our services, improve our ability to prevent the use
              of our services for illegal or improper purposes, develop new
              features and products, and facilitate insurance and finance
              solutions in connection with our services.
            </p>

            <p className="mb-5">
              <span className="font-bold">Marketing:</span> Errandlr may use the
              data we collect to market our services to our users. This includes
              sending users communications about Errandlr services, features,
              promotions, sweepstakes, studies, surveys, news, updates, and
              events.
            </p>

            <p className="mb-5">
              We may also send communications to our users about products and
              services offered by Errandlr partners. Although we may send users
              communications about Errandlr partners’ products and services, we
              do not sell users’ personal data to, or share it with, such
              partners or others for purposes of their own direct marketing or
              advertising, except with users’ consent.
            </p>

            <p className="mb-5">
              We may use the data we collect to personalize the marketing
              communications (including advertisements) that we send, including
              based on user location, past use of Errandlr’s services, and user
              preferences and settings.
            </p>

            <p className="mb-5">
              <span className="font-bold">Non-marketing communications:</span>
              Errandlr may use the data we collect to generate and provide users
              with receipts; inform them of changes to our terms, services, or
              policies; or send other communications that aren’t for the purpose
              of marketing the services or products of Errandlr or its partners.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                Legal proceedings and requirements:
              </span>
              We may use the personal data we collect to investigate or address
              claims or disputes relating to use of Errandlr services, or as
              otherwise allowed by applicable law, or as requested by
              regulators, government entities, and official inquiries.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[3]}
            active={boxes[3] === active}
            transformPositionClass=""
            header="3. Data retention and deletion"
          >
            <p className="mb-5">
              We process and store your Personal Data as long as necessary for
              the fulfillment of our contractual or legal obligations. Thus, we
              store the data as long as our contractual relationship with you as
              our customer obliges us to do so. After termination of our
              contract, we will store Personal Data only to the extent and for
              as long as applicable law requires. All other data will be deleted
              immediately when you, as a customer, unsubscribe from our Products
              or other services. If the remaining data is no longer required for
              the fulfillment of legal obligations, it will be regularly
              deleted, unless its further processing is necessary for the
              preservation of evidence or the prevention of legal claims from
              becoming time-barred.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[4]}
            active={boxes[4] === active}
            transformPositionClass=""
            header="4. Grounds for processing"
          >
            <p className="mb-5">
              We only collect and use personal data where we have lawful grounds
              to do so. These include processing user personal data to provide
              requested services and features, for purposes of Errandlr’s
              legitimate interests or those of other parties, to fulfill our
              legal obligations, or based on consent.
            </p>

            <p className="mb-5">
              We collect and use personal data only where we have one or more
              lawful grounds for doing so. Such grounds may vary depending on
              where our users are located, but generally include processing
              personal data:
            </p>

            <p className="mb-5">
              <span className="font-bold">
                To provide requested services and features:
              </span>
              In order to provide our services, we must collect and use certain
              personal data.
            </p>

            <p className="mb-5">
              This includes: User profile data, which we use to establish and
              maintain user accounts; verify user identity; communicate with
              users about their orders, deliveries and accounts; and enable
              users to make payments or receive earnings.
            </p>

            <p className="mb-5">
              Background trip information, which is used to verify an
              applicant’s eligibility to be a delivery person
            </p>

            <p className="mb-5">
              Delivery person location data, which we use to track trips and
              assist with navigation
            </p>

            <p className="mb-5">
              Usage data, which is necessary to maintain, optimize, and enhance
              Errandlr services, including determining incentives, connecting
              delivery persons and vendors/customers, and calculating costs of
              trips and rider earnings.
            </p>

            <p className="mb-5">
              Transaction information. Information relating to customer support.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                For purposes of the legitimate interests of Errandlr or other
                parties:
              </span>
              This includes using personal data to maintain and enhance our
              users’ safety and security. For example, we use personal data to
              prevent use of our services by users who have engaged in
              inappropriate or dangerous behavior, such as by retaining data of
              banned users to prevent their use of Errandlr apps.
            </p>

            <p className="mb-5">
              This also includes purposes such as combating fraud; improving our
              services, direct marketing, research, and development; and
              enforcing Errandlr Terms of Service.
            </p>

            <p className="mb-5">
              In addition, it includes using personal data to the extent
              necessary for the interests of other people or the general public,
              such as in connection with legal or insurance claims, and to
              protect the rights and safety of others.
            </p>

            <p className="mb-5">
              <span className="font-bold">
                To fulfill Errandlr’s legal obligations:
              </span>
              Errandlr is subject to laws and regulations that require it to
              collect and retain data about our users’ deliveries, and to
              provide copies of such data to the government or other
              authorities. We collect and use personal data to comply with such
              laws.
            </p>

            <p className="mb-5">
              Errandlr may also share data with law enforcement regarding
              criminal acts or threats to public safety, or requests by third
              parties pursuant to legal processes.
            </p>

            <p className="mb-5">
              <span className="font-bold">With consent:</span> Errandlr may
              collect and use personal data based on the user’s consent. For
              example, we may collect personal data through voluntary surveys.
              Responses to such surveys are collected on the basis of consent
              and will be deleted once no longer necessary for the purposes
              collected.
            </p>
          </ElrAccordion>
        </div>

        <div onClick={showDropdownOption} className="pb-8">
          <ElrAccordion
            setActive={setActive}
            index={boxes[5]}
            active={boxes[5] === active}
            transformPositionClass=""
            header="5. Updates to this notice"
          >
            <p>
              Errandlr reserves the right to change this Privacy Statement. We
              will provide notification of the material changes to this Privacy
              Statement through our Sites at least 30 days prior to the change
              taking effect.
            </p>
          </ElrAccordion>
        </div>
        <PoliciesAppBanner />
      </div>
      <Footer hideTopFooter />
    </div>
  );
};

export default PrivacyPolicy;
