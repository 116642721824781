import { MobileHeader } from 'app/components/Header';
import { publicRoutes } from 'app/utilities/routes';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const PoliciesNavigationMenu: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();

  const locateActiveRoute = (route: keyof typeof publicRoutes) =>
    publicRoutes[route] === location.pathname;

  return (
    <div className="flex flex-col md:h-full">
      <div className="w-full md:w-72 h-44 md:h-full overflow-x-hidden md:fixed bg-elr-gray">
        <div className="md:w-3/5 md:max-w-6xl md:mx-auto">
          <MobileHeader />
          <div>
            <div className="flex w-11/12 mx-auto md:w-full md:flex-col mt-4 md:mt-0 justify-between">
              <NavLink
                to={publicRoutes.termsAndCondition}
                className={(navData) =>
                  `h-20 w-full md:h-14 mr-3 md:text-center md:mr-0 flex-col flex md:flex-row justify-center md:justify-start items-center hover:bg-elr-green hover:bg-opacity-10 md:hover:bg-elr-gray md:hover:text-elr-green ${
                    locateActiveRoute('termsAndCondition')
                      ? 'bg-elr-green bg-opacity-10 md:bg-elr-gray'
                      : ''
                  } ${navData.isActive ? 'text-elr-green text-opacity-90' : ''}`
                }
              >
                <p className="hidden md:block text-xs md:text-base">
                  Terms And Condition
                </p>
                <p className="md:hidden text-xs md:text-base">Terms</p>
              </NavLink>

              <NavLink
                to={publicRoutes.privacyPolicy}
                className={(navData) =>
                  `flex flex-col md:flex-row justify-center md:text-center md:justify-start items-center md:w-full -mr-3 md:mr-0 md:h-14 h-20 w-full hover:bg-elr-green hover:bg-opacity-10 md:hover:bg-elr-gray md:hover:text-elr-green ${
                    locateActiveRoute('privacyPolicy')
                      ? 'bg-elr-green bg-opacity-10 md:bg-elr-gray'
                      : ''
                  } ${navData.isActive ? 'text-elr-green text-opacity-90' : ''}`
                }
              >
                <p>Privacy Policy</p>
              </NavLink>

              <NavLink
                to={publicRoutes.faq}
                className={(navData) =>
                  `flex flex-col md:flex-row justify-center md:justify-start md:text-center items-center md:w-full mr-0 md:mr-0 md:h-14 h-20 w-full hover:bg-elr-green hover:bg-opacity-10 md:hover:bg-elr-gray md:hover:text-elr-green ${
                    locateActiveRoute('faq')
                      ? 'bg-elr-green bg-opacity-10 md:bg-elr-gray'
                      : ''
                  } ${navData.isActive ? 'text-elr-green text-opacity-90' : ''}`
                }
              >
                <p>FAQ</p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="h-full md:ml-72 bg-white">{children}</div>
    </div>
  );
};

export default PoliciesNavigationMenu;
