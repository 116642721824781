import { capitalize } from '@material-ui/core';
import BankDetailsInput from 'app/components/settings/BankDetailsInput';
import { useSubmitAgentForm } from 'app/hooks/requests/agent';
import { useGetProfile } from 'app/hooks/user';
import { Session } from 'app/utilities/Session';
import { getCurrentCountry, getDefaultState } from 'app/utilities/country';
import { BankDetails } from 'app/utilities/types/dispatcherTypes';
import { AgentFormInterface, AgentInterface } from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrMainSelect,
  ElrPhoneInput,
  ElrStateSelect,
} from 'ui-components';
import { AgentFormSchema } from './FormSchemaValidation';

type FormValue = AgentFormInterface;
type PhoneValue = {
  isValid: boolean;
  mobile: string;
  formattedNumber: string;
};

interface IOptions {
  value: any;
  label: string;
  id: number;
}

interface Props {
  onComplete: () => void;
  profileAddAgentButton?: boolean;
  onClose: () => void;
  editAgent?: boolean;
  agent?: AgentInterface;
}

const defaultPhoneInput = (
  agentPhoneNumbers?: string,
  alternate: boolean = false
) => {
  if (!agentPhoneNumbers) {
    return { isValid: false, mobile: '', formattedNumber: '' };
  }

  const phoneNumbers = agentPhoneNumbers.split(',');

  const selectedNumber =
    phoneNumbers.find((phoneNumber) =>
      alternate
        ? phoneNumber.includes('alternate')
        : !phoneNumber.includes('alternate')
    ) || '';
  return {
    isValid: true,
    mobile: selectedNumber,
    formattedNumber: selectedNumber,
  };
};

const AgentForm: React.FC<Props> = ({
  onComplete,
  profileAddAgentButton,
  onClose,
  agent,
  editAgent,
}) => {
  const [phone, setPhoneValue] = React.useState<PhoneValue>(
    defaultPhoneInput(agent?.phone)
  );
  const [loading, setLoading] = useState(false);
  const [alternativePhone, setAltPhoneValue] = React.useState<PhoneValue>(
    defaultPhoneInput(agent?.phone, true)
  );

  const [sendCompleteForm] = useSubmitAgentForm(onComplete, onClose);

  const [countryOptions, setCountryOptions] = useState<IOptions>(
    agent?.country
      ? {
          id: 0,
          label: capitalize(agent?.country),
          value: agent?.country,
        }
      : {
          id: 0,
          label: capitalize(getCurrentCountry()),
          value: getCurrentCountry(),
        }
  );

  const [stateOptions, setStateOptions] = useState(
    agent?.state
      ? {
          id: 0,
          label: capitalize(agent?.state),
          value: agent?.state,
        }
      : {
          id: 0,
          label: capitalize(getDefaultState()),
          value: getDefaultState(),
        }
  );

  const [stateBankDetails, setBankDetails] = useState<BankDetails>({
    bankCode: agent?.bankDetails?.bankCode || '',
    bankName: agent?.bankDetails?.bankName || '',
    accountName: agent?.bankDetails?.accountName || '',
    accountNumber: agent?.bankDetails?.accountNumber || '',
  });

  const profile = useGetProfile();

  const tenantData = Session.tenantData();

  const onPhoneInputChange = (
    payload: PhoneValue,
    type: 'phone' | 'alternativePhone'
  ) => {
    const setStateOnType = {
      phone: setPhoneValue,
      alternativePhone: setAltPhoneValue,
    };
    setFieldValue(type, payload.formattedNumber);
    setStateOnType[type]((prev) => ({ ...prev, ...payload }));
  };

  const initialValues = {
    firstname: '',
    lastname: '',
    phone: '',
    alternativePhone: '',
    country: getCurrentCountry(),
    state: getDefaultState(),
    vehiclePlateNumber: '',
    driversLicense: '',
    additionalId: undefined,
    vehicleRegistrationDocument: undefined,
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik<FormValue>({
      initialValues: editAgent
        ? {
            ...initialValues,
            ...agent,
            phone: defaultPhoneInput(agent?.phone).formattedNumber,
            alternativePhone: defaultPhoneInput(agent?.phone, true)
              .formattedNumber,
            driversLicense: agent?.driversLicense,
          }
        : initialValues,
      validationSchema: AgentFormSchema,
      onSubmit: (input) => {
        const formData = {
          ...input,
          ...(Object.keys(stateBankDetails).length && {
            bankDetails: stateBankDetails,
          }),
        };

        setLoading(true);
        onSubmitCompleteForm(formData).then(() => {
          setLoading(false);
        });
      },
    });

  const onSubmitCompleteForm = async (input: FormValue) => {
    await sendCompleteForm({
      profileId: profile.id,
      dispatcherId: profile.accountInformation.id,
      agentId: agent?.id,
      agentPhoneNumber: phone.formattedNumber,
      alternativeAgentPhoneNumber:
        (alternativePhone.isValid && alternativePhone.formattedNumber) || '',
      editAgent,
      profileAddAgentButton,
      input,
      onCloseCallback: onClose,
    });
  };

  const onSelectChange = (selectedOption: any) => {
    const { value = '', label = '' } = selectedOption || {};

    if (Object.keys(tenantData).includes(value)) {
      setCountryOptions({ value, id: 0, label });
      setFieldValue('country', value);
      setStateOptions({ value: '', id: 0, label: '' });
      setFieldValue('state', '');
      return;
    }

    const [state] = value.split('|');
    const lowerCaseState = state.toLowerCase();
    setStateOptions({ value: lowerCaseState, id: 0, label });
    setFieldValue('state', lowerCaseState);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-12">
        <div className="px-8 pb-3 text-xl text-center">
          {editAgent ? 'Edit Agent' : 'Add Riders'}
        </div>
        <div className="flex justify-start mt-6 mb-3 text-elr-black opacity-40">
          Unique ID : {agent?.agentUUID ? agent.agentUUID : '012345'}
        </div>
        <div>
          <div className="mb-14 md:mb-16">
            {errors?.firstname && (
              <span className="text-xs text-elr-error">
                {errors?.firstname}
              </span>
            )}
            <ElrInput
              className="mb-3 border-none rounded-none bg-elr-gray"
              placeholder="First Name*"
              name="firstname"
              onChange={handleChange}
              value={values.firstname}
            />
            {errors?.lastname && (
              <span className="text-xs text-elr-error">{errors?.lastname}</span>
            )}
            <ElrInput
              className="mb-3 border-none rounded-none bg-elr-gray"
              placeholder="Last Name*"
              name="lastname"
              onChange={handleChange}
              value={values.lastname}
            />
          </div>
          <div className="mb-14 md:mb-16">
            {
              <span className="text-xs text-elr-error">
                {(!phone.isValid && phone.mobile) || errors.phone
                  ? 'Invalid phone number'
                  : null}
              </span>
            }
            <ElrPhoneInput
              containerClass="w-full mb-3 flex justify-between"
              value={phone.mobile}
              onChange={(change) => onPhoneInputChange(change, 'phone')}
              placeholder="Phone Number*"
            />
            {
              <span className="text-xs text-elr-error">
                {(!alternativePhone.isValid && alternativePhone.mobile) ||
                errors.alternativePhone
                  ? 'Invalid phone number'
                  : null}
              </span>
            }
            <ElrPhoneInput
              containerClass="w-full mb-3 flex justify-between"
              value={alternativePhone.mobile}
              onChange={(change) =>
                onPhoneInputChange(change, 'alternativePhone')
              }
              placeholder="Alternate Phone Number"
            />
          </div>
          <div className="mb-14 md:mb-16">
            {errors?.country && (
              <span className="text-xs text-elr-error">{errors?.country}</span>
            )}
            <ElrMainSelect
              placeholder="Country of operation*"
              onChange={onSelectChange}
              currentValue={countryOptions.label}
              value={countryOptions}
              data={Object.keys(tenantData)}
            />
            {errors?.state && (
              <span className="text-xs text-elr-error">{errors?.state}</span>
            )}
            <ElrStateSelect
              placeholder="State of operation*"
              onChange={onSelectChange}
              countries={[countryOptions.value]}
              currentValue={stateOptions.label}
              value={stateOptions}
            />

            {errors?.vehiclePlateNumber && (
              <span className="text-xs text-elr-error">
                {errors?.vehiclePlateNumber}
              </span>
            )}
            <ElrInput
              className="mb-3 border-none rounded-none bg-elr-gray"
              placeholder="Vehicle plate number*"
              name="vehiclePlateNumber"
              onChange={handleChange}
              value={values.vehiclePlateNumber}
            />
            {errors?.driversLicense && (
              <span className="text-xs text-elr-error">Required</span>
            )}
          </div>

          <p className="text-elr-black">
            Adding a bank account information for this driver will automatically
            payout to the driver when a trip is completed.
          </p>
          <div className="mb-14 md:mb-16">
            <BankDetailsInput
              bankDetails={stateBankDetails}
              setBankDetails={setBankDetails}
            />
          </div>
        </div>
      </div>

      {profileAddAgentButton || editAgent ? (
        <div className="flex justify-end">
          <ElrButton
            text={editAgent ? 'Edit Agent' : 'Add'}
            size="lg"
            submit
            loading={loading}
            disabled={loading}
            spinnerColor="White"
            className="float-right w-40 mt-3 text-lg text-white bg-elr-black mb-7"
          />
        </div>
      ) : (
        <div className="mb-14">
          <div className="flex justify-between mb-8 md:mb-14">
            <ElrButton
              text="Complete"
              size="sm"
              disabled={loading}
              submit
              loading={loading}
              className="w-10 bg-elr-purple h-7 text-elr-white"
            />
            <ElrButton
              text="save for later"
              size="sm"
              submit
              className="w-12 bg-elr-white-400 h-7 text-elr-purple opacity-60"
            />
          </div>
        </div>
      )}
    </form>
  );
};
export default AgentForm;
