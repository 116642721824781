import { CopyDoubleIcon } from 'app/components/SvgAssets';
import copyToClipboard from 'app/utilities/copyToClipboard';
import { toast } from 'react-toastify';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import { useFormik } from 'formik';
import { useGetProfile } from 'app/hooks/user';
import { ERRANDLR_REDIRECT_URL } from 'app/constants';
import validationSchema from './validationSchema';
import { SettingsValue } from '.';

interface DirectBookingLinkProps {
  updateSettings: (settings: SettingsValue) => void;
  handlePlan: (
    setFieldValue: (field: string, value: boolean) => void,
    option: boolean,
    field: string,
    values: SettingsValue,
    updateSettings: (settings: SettingsValue) => void
  ) => void;
}

const DirectBookingLink = ({
  handlePlan,
  updateSettings,
}: DirectBookingLinkProps) => {
  const { accountInformation } = useGetProfile();

  const {
    accountInformation: { slug = '' },
  } = useGetProfile();
  const directBookingLink = `${ERRANDLR_REDIRECT_URL}/${slug}`;

  const { values, setFieldValue } = useFormik({
    initialValues: {
      directLinkSettings: {
        businessPays:
          accountInformation?.directLinkSettings?.businessPays ?? true,
      },
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const handleCopy = () => {
    copyToClipboard(directBookingLink);
    toast.success('Direct Booking link copied to clipboard');
  };

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <p className="text-xl pb-1">Direct Booking Link</p>
      <div className="flex md:flex-row flex-col gap-3">
        <div className="bg-elr-gray py-3 px-5">
          <p className="text-elr-purple">{directBookingLink}</p>
        </div>
        <div
          className="flex bg-elr-gray py-3 px-6 gap-2 cursor-pointer justify-center align-middle"
          onClick={handleCopy}
        >
          <CopyDoubleIcon fill="#081120" />
          <p className="text-sm text-elr-black">Copy Link</p>
        </div>
      </div>
      <div className="mt-6 flex flex-col gap-1.5">
        <h3 className="text-base pb-1 text-elr-black-400">
          Who pays for delivery?
        </h3>
        <div className="flex gap gap-x-6 text-elr-elr-black-300">
          <div className="flex gap gap-x-6 text-elr-black-300">
            <ElrRadio
              checked={!values.directLinkSettings.businessPays}
              onChange={() =>
                handlePlan(
                  setFieldValue,
                  false,
                  'directLinkSettings.businessPays',
                  values as SettingsValue,
                  updateSettings
                )
              }
              radioClassName="accent-elr-black"
              label="Customer pays"
              labelClassName="text-elr-black text-base before:align-text-top gap-2"
            />
            <ElrRadio
              checked={values.directLinkSettings.businessPays}
              onChange={() =>
                handlePlan(
                  setFieldValue,
                  true,
                  'directLinkSettings.businessPays',
                  values as SettingsValue,
                  updateSettings
                )
              }
              radioClassName="accent-elr-black"
              label="I pay"
              labelClassName="text-elr-black text-base before:align-text-top gap-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectBookingLink;
