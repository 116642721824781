/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from '@tanstack/react-query';
import { httpGetAvailableAgents, httpReAssignAgents } from 'app/api/agent';
import {
  httpAcceptRequest,
  httpMarkDeliveryAsCompleted,
  httpOutsourceRequest,
} from 'app/api/requests';
import DeliveryDetails from 'app/components/DeliveryDetails';
import {
  CommerceRequestAssigned,
  CommerceRequestUnAssigned,
} from 'app/developer/modules/components/CancelDeliverySection';
import { useGetProfile } from 'app/hooks/user';
import { getDispatchedRequestDuration } from 'app/modules/dispatch/components/utils';
import { CommerceView, DispatcherView } from 'app/utilities/ComponentView';
import { Session } from 'app/utilities/Session';
import {
  currencyFormatter,
  isCommerce,
  isDispatcher,
  isDispatcherPlatform,
  isSenderPlatform,
} from 'app/utilities/helpers';
import {
  AgentPayloadInterface,
  Request,
  RequestTypes,
} from 'app/utilities/types/shared';
import truncate from 'lodash/truncate';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFetchPackageType } from 'app/hooks/utils';
import * as styles from '../../dispatcher/modules/components/ClassUtils';
import {
  DispatchRequestAssigned,
  DispatchRequestUnAssigned,
} from '../../dispatcher/modules/components/requestItem/DispatcherSection';
import {
  SenderRequestAssigned,
  SenderRequestUnAssigned,
} from '../../sender/components/SenderSection';
import { handleScroll } from './utility';

interface RequestItemProp {
  request: Request;
  index: number;
  active: boolean;
  initialPanel?: boolean;
  displayOriginalFees?: boolean;
  setActive: Function;
  onUpdateList: (index?: number, selectedRiderId?: string) => void;
  isAssigned?: boolean;
  pendingRequest?: boolean;
  refetch: Function;
  onRemoveListing?: (trackingId: string) => void;
}

interface SelectedRider {
  id: string;
  name: string;
}

const RequestItem: React.FC<RequestItemProp> = ({
  request,
  index,
  setActive,
  active,
  isAssigned = false,
  pendingRequest = false,
  initialPanel,
  onUpdateList,
  refetch,
  displayOriginalFees,
  onRemoveListing,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);
  const [availableAgents, setAvailableAgents] = useState<
    AgentPayloadInterface[]
  >([]);

  const requestRef = useRef<HTMLDivElement>(null);
  const { id, accountInformation, currentAccessRole } = useGetProfile();
  const [selectedRider, setSelectedRider] = useState<SelectedRider>({
    id: '',
    name: '',
  });
  const { country, state } = request;
  const isSenderPlatformOption = isSenderPlatform(
    window.location.pathname,
    currentAccessRole
  );

  const [showOutsourceModal, setShowOutsourceModal] = useState(false);
  const [outsourcedSuccessfully, setOutsourcedSuccessfully] = useState(false);
  const [itemTypes] = useFetchPackageType();

  const fetchAvailableAgentOnListOpen = useCallback(() => {
    if (showDetails && active) {
      httpGetAvailableAgents(id, {
        dispatcherId: accountInformation.id,
        country,
        state: request.state,
        limit: 150,
      })
        .then((response) => {
          const { docs = [] } = response;
          setAvailableAgents(docs);
          queryClient.setQueryData(['availableAgents'], () => docs);
        })
        .catch(() => {
          /** no op */
        });
    }
  }, [showDetails, initialPanel, active]);

  useEffect(() => {
    handleScroll(requestRef);
    if (isDispatcherPlatform(currentAccessRole)) {
      fetchAvailableAgentOnListOpen();
    }
  }, [fetchAvailableAgentOnListOpen]);

  const showItems = (openIndex: number) => {
    setShowDetails(!showDetails);
    setActive(openIndex);
  };

  const tenantData = Session.tenantData();

  const onAssignedRiderOptionChange = (
    option: AgentPayloadInterface | any,
    submitDirectly: boolean = false
  ) => {
    const riderName =
      option?.name || option?.label || `${option.firstname} ${option.lastname}`;
    const riderId = option?.value || option.id;
    setSelectedRider({ name: riderName, id: riderId });
    submitDirectly && onSubmit({ name: riderName, id: riderId });
  };

  const updateCacheList = () => {
    showItems(index + 1);
    onUpdateList(index, selectedRider.id);
  };

  const onSubmit = async (rider: SelectedRider = selectedRider) => {
    try {
      setLoading(true);
      const payload = {
        agentId: rider.id as string,
        dispatcherId: accountInformation.id,
        userId: id,
        requestId: request.id,
      };
      await httpAcceptRequest(payload);
      updateCacheList();
      return setLoading(false);
    } catch (error: any) {
      /** no op */
      if (error.message !== 'Selected Agent is not available') {
        updateCacheList();
      }
      return setLoading(false);
    }
  };

  const onReassignRequest = async (rider: SelectedRider = selectedRider) => {
    try {
      setLoading(true);
      const payload = {
        agentId: rider.id as string,
        dispatcherId: accountInformation.id,
        requestId: request.id,
      };
      await httpReAssignAgents(id, payload);
      updateCacheList();
      return setLoading(false);
    } catch (error: any) {
      /** no op */
      if (error.message !== 'Selected Agent is not available') {
        updateCacheList();
      }
      return setLoading(false);
    }
  };

  const onOutsourceRequest = async () => {
    try {
      setLoading(true);
      const payload = {
        userId: id,
        requestId: request.id,
      };
      await httpOutsourceRequest(payload);
      setShowOutsourceModal(false);
      setOutsourcedSuccessfully(true);
      return setLoading(false);
    } catch (error: any) {
      /** no op */
      return setLoading(false);
    }
  };

  const onCompleteRequest = async () => {
    try {
      setLoading(true);
      const params = {
        userId: id,
        trackingId: request.trackingId,
      };
      await httpMarkDeliveryAsCompleted(params);
      setLoading(false);
      refetch();
    } catch (err) {
      setLoading(false);
    }
  };

  // @ts-ignore
  const { trackRiderEnabled } = tenantData[country].states[state];

  const _renderUnAssigned = () => {
    <CommerceView role={currentAccessRole}>
      <CommerceRequestUnAssigned request={request} refetch={refetch} />
    </CommerceView>;

    if (isSenderPlatformOption) {
      return <SenderRequestUnAssigned request={request} />;
    }

    return (
      <DispatchRequestUnAssigned
        availableAgents={availableAgents}
        selectedRider={selectedRider}
        isLoading={isLoading}
        onAssignedRiderOptionChange={onAssignedRiderOptionChange}
        onSubmit={onSubmit}
        onOutsourceRequest={onOutsourceRequest}
        outsourcedSuccessfully={outsourcedSuccessfully}
        setShowOutsourceModal={setShowOutsourceModal}
        showOutsourceModal={showOutsourceModal}
        trackRiderEnabled={trackRiderEnabled}
        pickupAddress={request.pickupAddress}
        request={request}
      />
    );
  };

  const _renderAssigned = () => {
    if (isSenderPlatformOption) {
      return <SenderRequestAssigned request={request} />;
    }

    if (isCommerce(currentAccessRole)) {
      return <CommerceRequestAssigned request={request} refetch={refetch} />;
    }

    return (
      <DispatchRequestAssigned
        request={request}
        pendingRequest={pendingRequest}
        availableAgents={availableAgents}
        trackRiderEnabled={trackRiderEnabled}
        pickupAddress={request.pickupAddress}
        isLoading={isLoading}
        onReassignRequest={onReassignRequest}
        onCompleteRequest={onCompleteRequest}
      />
    );
  };

  const {
    afterChargeEstimate,
    pickupAddress,
    deliverToInformation,
    priceEstimate,
    promoOriginalPrice,
    tag = 'ET',
    parcelCode,
    status,
    createdAt,
  } = request;

  const fees = displayOriginalFees ? +priceEstimate : +afterChargeEstimate;

  const createdTimeStamp = Date.parse(createdAt);
  const currentTimeStamp = Date.now();
  const TimeDifference = currentTimeStamp - createdTimeStamp;
  const hoursDifference = TimeDifference / (1000 * 60 * 60);

  const showPastDueTag =
    status !== RequestTypes.completed &&
    hoursDifference >= 4 &&
    isDispatcher(currentAccessRole);

  return (
    <>
      <div
        onClick={() => showItems(index)}
        className="flex justify-between py-4 text-sm border-b cursor-pointer md:text-base border-elr-gray-400 border-opacity-20"
      >
        <div
          className="self-center w-6/12 opacity-60"
          title={pickupAddress?.fullAddress}
        >
          {truncate(pickupAddress?.fullAddress, { length: 39 })}
        </div>
        <DispatcherView role={currentAccessRole}>
          <div className="w-2/12">
            <span className={styles.requestTypeBadge()} title={tag}>
              {tag}
            </span>
          </div>
        </DispatcherView>
        <div className="flex flex-col items-center justify-between md:w-5/12 md:flex-row">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <span className="opacity-60">{currencyFormatter(+fees)}</span>
            {promoOriginalPrice && (
              <DispatcherView role={currentAccessRole}>
                <div className="flex items-center justify-center h-5 bg-elr-black w-9 rounded-5">
                  <p className="text-xs text-elr-white">{priceEstimate}</p>
                </div>
              </DispatcherView>
            )}
          </div>
          <span>
            {itemTypes.map((item) => {
              if (parcelCode === item.code) {
                return <img key={item.code} src={item.imageSrc} alt="imgSrc" />;
              }
              return null;
            })}
          </span>
          {showPastDueTag ? (
            <div className="rounded-2xl text-elr-error border border-elr-error text-xs p-1 bg-elr-orange-900">
              Past Due
            </div>
          ) : (
            <span className="hidden text-xs opacity-60 md:block">
              {getDispatchedRequestDuration(deliverToInformation)}
            </span>
          )}
        </div>
      </div>
      {showDetails && active && (
        <div ref={requestRef}>
          <DeliveryDetails
            details={request}
            onRemoveListing={onRemoveListing}
          />
          {isAssigned ? _renderAssigned() : _renderUnAssigned()}
          <div className="h-1 border-b border-elr-gray-400 border-opacity-20" />
        </div>
      )}
    </>
  );
};

export default RequestItem;
