import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';

elementScrollIntoViewPolyfill();

export const handleScroll = (element: React.RefObject<HTMLDivElement>) => {
  if (element.current) {
    element.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const requestTypeTag = {
  MP: 'Marketplace request',
  QP: 'Quote request',
};

export const QUOTE_STATUS = 'quote';
