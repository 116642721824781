import countries, { CountryProperty } from 'country-codes-list';
import { capitalize } from 'lodash';
import { Session } from './Session';

export const getCurrentCountry = () => Session.currentCountry();

export const getDefaultState = () => {
  const DEFAULT_STATE = 'lagos';
  const country = getCurrentCountry();
  const tenantData = Session.tenantData();
  const states = tenantData[country]
    ? Object.keys(tenantData[country].states)
    : [];
  return states?.[0] || DEFAULT_STATE;
};

export const getCurrentCountryData = () =>
  getCountryByName(Session.currentCountry());

export const getCountryByCode = (code: string) =>
  countries.findOne('countryCode' as CountryProperty, code.toUpperCase());

export const getCountryByName = (name: string) =>
  countries.findOne(
    'countryNameEn' as CountryProperty,
    capitalize(name.toLowerCase())
  );
