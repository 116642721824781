import buyItems from 'assets/images/market-store.svg';
import quickSupport from 'assets/images/customer-support.svg';
import realTimeTracking from 'assets/images/real-time-tracking.svg';
import sendParcel from 'assets/images/deliveries.svg';
import React from 'react';

interface BoxProps {
  title: string;
  paragraph: string;
  img: string;
  imgAlt: string;
  reverse?: boolean;
  imageStyle?: string;
  color?: string;
}

const FeaturesBox: React.FC<BoxProps> = ({
  title,
  paragraph,
  img,
  imgAlt,
  reverse,
  color,
  imageStyle,
}) => (
  <div
    className={`md:px-9 px-2 md:py-12 py-12 flex justify-center md:justify-between items-center flex-wrap md:flex-nowrap gap-6 ${
      reverse ? 'flex-row-reverse' : 'flex-row'
    }`}
  >
    <div className="lg:w-full lg:h-full px-4 pt-6 pb-0 w-full">
      <img src={img} alt={imgAlt} className={`${imageStyle}`} />
    </div>
    <div className="text-left w-full">
      <h3
        className="text-24 md:text-4xl leading-tight md:leading-10 pb-3 md:pb-4 md:w-9/12 w-11/12 pt-6 md:pt-0"
        style={{ color: color || 'inherit' }}
      >
        {title}
      </h3>
      <p className="text-16 md:text-xl text-elr-black-300 md:w-9/12 w-11/12 leading-snug">
        {paragraph}
      </p>
    </div>
  </div>
);

export const Features: React.FC = () => (
  <div className="pt-24 pb-20 md:pt-28 md:py-24 px-4 lg:px-32 md:px-24 text-center">
    <h1 className="text-14 md:text-2xl leading-5 text-elr-green">
      Complete Solution Suite
    </h1>
    <h2 className="text-28 md:text-40 leading-tight font-bold">
      Everything You Need, All in One Place
    </h2>
    <div className="flex flex-col md:gap-2">
      <FeaturesBox
        title="Local & International Deliveries"
        paragraph="Send packages anywhere in Nigeria or internationally. Our reliable logistics network ensures your items reach their destination safely and on time."
        img={sendParcel}
        imgAlt="send-parcel"
        color="#5A4AF2"
      />
      <FeaturesBox
        title="Shopping & Payments Made Easy"
        paragraph="Shop from local markets, order from restaurants, buy airtime, and make instant bank transfers - all from one convenient platform."
        img={buyItems}
        imgAlt="buy-items"
        reverse
        color="#054853"
      />
      <FeaturesBox
        title="Real-time Tracking"
        paragraph="Track your deliveries in real-time and enjoy secure financial transactions with our integrated payment system and in-app wallet."
        img={realTimeTracking}
        imgAlt="real-time-tracking"
        color="#E7BC2F"
      />
      <FeaturesBox
        title="24/7 Customer Support"
        paragraph="Our dedicated support team is always ready to assist you with any questions about deliveries, payments, or shopping services."
        img={quickSupport}
        imgAlt="quick-support"
        reverse
        color="#081120"
      />
    </div>
  </div>
);
