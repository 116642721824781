import { httpCancelRequest } from 'app/api/requests';
import CancelDeliveryModal from 'app/components/CancelDeliveryModal';
import { AssignedDriver } from 'app/dispatcher/modules/components/requestItem/AgentSection';
import { useGetProfile } from 'app/hooks/user';
import { isRequestCollected } from 'app/utilities/helpers';
import { Batch, Request } from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { ElrPillButton } from 'ui-components';

export const CancelRequest: React.FC<{
  trackingId: string;
  status: string;
  refetch: Function;
}> = ({ trackingId, status, refetch }) => {
  const { id: userId, accountInformation } = useGetProfile();
  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const onCancelRequest: () => Promise<void> = async () => {
    setLoading(true);
    try {
      await httpCancelRequest(
        trackingId,
        accountInformation.id,
        userId,
        cancelReason
      );
      setLoading(false);
      setShowCancelModal(false);
      refetch();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex w-full flex-col-reverse md:flex-row justify-between mb-5">
        <div>
          {isRequestCollected(status) && (
            <ElrPillButton
              size="lg"
              text="Parcel has been picked up"
              className="w-full bg-elr-purple bg-opacity-10 md:bg-elr-gray-400 md:bg-opacity-30 text-elr-black md:text-sm px-2 md:ml-4 mt-2 md:mt-0 py-1 opacity-60 cursor-not-allowed"
            />
          )}
        </div>
        <ElrPillButton
          size="lg"
          text="Cancel delivery"
          onClick={() => setShowCancelModal(true)}
          className="w-full bg-elr-purple bg-opacity-10 text-elr-purple md:w-[115px] md:text-sm py-1"
        />
      </div>
      <CancelDeliveryModal
        onCancelDelivery={onCancelRequest}
        text="Are you sure you want to cancel delivery?"
        onClose={() => setShowCancelModal(false)}
        isOpen={showCancelModal}
        loading={loading}
        reason={cancelReason}
        setReason={setCancelReason}
      />
    </>
  );
};

type RequestAssignedProps = {
  request: Request | Batch;
  refetch: Function;
};

export const CommerceRequestAssigned: React.FC<RequestAssignedProps> = ({
  request,
  refetch,
}) => {
  // because we're displaying the operators number to the API user, so use that information instead
  // create an operator structure like the agent, to fit the agent structure in the component.
  const operatorStructure = {
    phone: request?.acceptedBy?.account?.phone,
    firstname: request?.acceptedBy?.account?.firstname,
    lastname: request?.acceptedBy?.account?.lastname,
    trips: '~',
    agentUUID: '~',
  };
  return (
    <>
      {request.acceptedBy && (
        <AssignedDriver agent={operatorStructure as any} isOnCommercePlatform />
      )}
      <CancelRequest
        trackingId={request.trackingId}
        status={request.status}
        refetch={refetch}
      />
    </>
  );
};

type CommerceRequestUnAssignedProps = {
  request: Request;
  refetch: Function;
};

export const CommerceRequestUnAssigned: React.FC<
  CommerceRequestUnAssignedProps
> = ({ request, refetch }) => (
  <div className="flex items-center justify-between w-full pt-5">
    <p className="py-1 mb-5 text-elr-black text-opacity-60 md:text-sm">
      Unassigned
    </p>
    <CancelRequest
      status={request.status}
      trackingId={request.trackingId}
      refetch={refetch}
    />
  </div>
);
