import copyToClipboard from 'app/utilities/copyToClipboard';
import { publicRoutes } from 'app/utilities/routes';
import appleStoreBtn from 'assets/images/app-store-btn-gray.svg';
import googlePlay from 'assets/images/google-play-btn-gray.svg';
import logoWhite from 'assets/images/logo-white-yellow.svg';
import logo from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CopyLinkIcon } from '../SvgAssets';

export const Header: React.FC<{ eventId: string }> = ({ eventId }) => {
  const eventLink = `${window.location.origin}${publicRoutes.event}/${eventId}`;

  const handleCopy = () => {
    copyToClipboard(eventLink);
    toast.success('Event link copied to clipboard');
  };

  return (
    <header className="flex items-center justify-between px-4 py-5 xl:px-48 lg:px-32 md:px-20">
      <Link to="/">
        <img src={logo} alt="Errandlr logo" />
      </Link>
      <div className="">
        <button
          onClick={handleCopy}
          type="button"
          className="flex items-center gap-1.5 md:gap-2.5 px-5 md:px-8 py-2.5 md:py-3 bg-white rounded"
        >
          <CopyLinkIcon />
          <span>Copy Event Link</span>
        </button>
      </div>
    </header>
  );
};

export const Footer: React.FC = () => (
  <footer className="flex flex-col-reverse justify-center px-4 py-10 md:flex-row md:items-center md:justify-between bg-elr-black xl:px-48 lg:px-32 md:px-20 gap-y-10">
    <div>
      <Link to="/">
        <img className="w-24 md:w-auto" src={logoWhite} alt="Errandlr logo" />
      </Link>
      <p className="mt-2.5 text-white text-opacity-90">
        <span className="text-elr-yellow">&copy;</span>{' '}
        {new Date().getFullYear()}. All Rights Reserved
      </p>
    </div>

    <div>
      <h4 className="mb-3 text-xl font-medium text-white">Mobile App</h4>
      <div className="relative flex items-center gap-6 md:justify-center">
        <a
          target="_blank"
          href="https://apps.apple.com/app/errandlr/id6499235011"
          rel="noreferrer"
        >
          <img
            src={appleStoreBtn}
            alt=""
            className="object-contain w-40 cursor-pointer"
          />
        </a>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share"
          rel="noreferrer"
        >
          <img
            src={googlePlay}
            alt=""
            className="object-contain w-40 cursor-pointer"
          />
        </a>
      </div>
    </div>
  </footer>
);

const EVENT_SESSION_KEY = 'elr_event_session';

export const EventSession = (eventId: string) => ({
  getSession: () => {
    const session = sessionStorage.getItem(`${EVENT_SESSION_KEY}_${eventId}`);
    if (session) return JSON.parse(session);
    return {};
  },
  setSession: (data: any) => {
    const sessionStr = sessionStorage.getItem(
      `${EVENT_SESSION_KEY}_${eventId}`
    );
    const session = sessionStr ? JSON.parse(sessionStr) : {};
    sessionStorage.setItem(
      `${EVENT_SESSION_KEY}_${eventId}`,
      JSON.stringify({ ...session, ...data })
    );
  },
  clearSession: () =>
    sessionStorage.setItem(`${EVENT_SESSION_KEY}_${eventId}`, ''),
});
