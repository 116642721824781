import { capitalize } from '@material-ui/core';
import { InfiniteData } from '@tanstack/react-query';
import { httpAssignUnmanagedAgent } from 'app/api/agent';
import { GetAgentsInterface } from 'app/api/d';
import { useGetProfile } from 'app/hooks/user';
import { AgentPayloadInterface } from 'app/utilities/types/shared';
import agentIcon from 'assets/images/defaultUser.svg';
import mapIcon from 'assets/images/mapMarker.svg';
import phoneIcon from 'assets/images/phoneIcon.svg';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ElrModal, ElrPillButton } from 'ui-components';

interface UnassignedAgentsProps {
  refetchUnassignedAgents: () => void;
  unassignedAgentsData: InfiniteData<GetAgentsInterface> | undefined;
  paginationProps: {
    isFetching: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
  };
}
interface EachRiderInterface {
  rider: AgentPayloadInterface;
  assign: (rider: AgentPayloadInterface) => void;
  loading: boolean;
}

const UnassignedAgents: React.FC<UnassignedAgentsProps> = ({
  refetchUnassignedAgents,
  unassignedAgentsData,
  paginationProps: {
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  },
}) => {
  const {
    id,
    accountInformation: { id: dispatcherId },
  } = useGetProfile();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAgent, setSelectedAgent] =
    useState<AgentPayloadInterface | null>(null);

  const assign = (rider: AgentPayloadInterface) => {
    setSelectedAgent(rider);
    setShowModal(true);
  };

  const cancel = () => {
    setShowModal(false);
    setSelectedAgent(null);
  };

  const confirm = async () => {
    if (!selectedAgent) return;
    setLoading(true);

    const res = await httpAssignUnmanagedAgent(id, {
      agentId: selectedAgent.id,
      dispatcherId,
    });
    if (res) {
      toast.success(res.message);
      refetchUnassignedAgents();
    }
    setShowModal(false);
    setSelectedAgent(null);
    setLoading(false);
  };

  return (
    <div className="w-full md:w-1/2">
      <div className="w-11/12 md:w-full md:ml-5 mx-auto border border-elr-green border-opacity-30 h-450 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md">
        <div>
          <div className="flex md:justify-between items-center pt-5">
            <div className="md:flex text-lg text-elr-black">
              List of new Drivers
            </div>
          </div>
        </div>
        <ElrModal
          height="h-2/6"
          width="sm:w-430 w-85"
          onClose={cancel}
          isOpen={showModal}
        >
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-center mt-5">
              <span className="text-elr-black text-opacity-60 text-sm">
                Are you sure you want to add
              </span>
              <br />
              <b className="text-sm">{`${selectedAgent?.firstname || ''} ${
                selectedAgent?.lastname || ''
              }`}</b>
              ?
            </p>
            <div className="flex justify-between mt-8 w-full px-5">
              <ElrPillButton
                size="sm"
                onClick={cancel}
                spinnerColor="White"
                text="Cancel"
                className="h-8 w-24 md:w-28 text-elr-black text-opacity-60 text-base flex items-center justify-center"
              />

              <ElrPillButton
                size="sm"
                loading={loading}
                onClick={confirm}
                spinnerColor="White"
                text="Yes, add Agent"
                className="bg-elr-black h-9 w-32 text-white text-base flex items-center justify-center"
              />
            </div>
          </div>
        </ElrModal>
        <div className="h-690 md:h-4/5 overflow-y-scroll no-scrollbar pt-5">
          {unassignedAgentsData?.pages.map((page, _) => (
            <div key={String(page.page)}>
              {page.docs.map((rider) => (
                <EachRider
                  key={rider.id}
                  loading={loading}
                  rider={rider}
                  assign={assign}
                />
              ))}
            </div>
          ))}

          <div className="text-center">
            <button
              type="button"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage && (
                <>
                  <br /> loading more...
                </>
              )}
              {hasNextPage ? 'Load More' : 'Nothing more to load'}
            </button>
          </div>
          <div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
        </div>
      </div>
    </div>
  );
};

const EachRider: React.FC<EachRiderInterface> = ({
  rider,
  loading,
  assign,
}) => (
  <div key={rider.id} className="flex justify-between mb-8">
    <div className="flex flex-col w-4/6">
      <div className="flex">
        <img src={agentIcon} alt="agent" className="w-8 h-5 mr-1" />
        <span className="text-elr-black text-opacity-80 text-sm self-center">{`${rider.firstname} ${rider.lastname}`}</span>
      </div>

      <div className="ml-8">
        <p className="flex my-2 break-words text-ellipsis">
          <img src={mapIcon} alt="phone" className="w-4 h-4 mr-2" />
          <span className="text-black opacity-60 text-sm">
            {rider.mainAddress.addressLine1},&nbsp;
            {capitalize(rider.mainAddress?.city || '')}.
          </span>
        </p>

        <p className="flex">
          <img
            src={phoneIcon}
            alt="phone"
            className="w-4 h-4 mr-2 opacity-40"
          />
          <span className="text-black opacity-60 text-sm">
            <a href={`tel:${rider.phone}`}>{rider.phone}</a>
          </span>
        </p>
      </div>
    </div>
    <div className="w-2/6 flex justify-end pr-1">
      <ElrPillButton
        size="sm"
        loading={loading}
        onClick={() => assign(rider)}
        spinnerColor="White"
        text="Add Agent"
        className="bg-elr-purple h-8 w-24 md:w-28 text-white text-base flex items-center justify-center"
      />
    </div>
  </div>
);

export default UnassignedAgents;
