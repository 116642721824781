/* eslint-disable no-param-reassign */
import blankStar from 'assets/images/blank-star.svg';
import blackStar from 'assets/images/black-star.svg';
import { getRandomNumber } from 'app/modules/dispatch/components/utils';
import star from 'assets/images/star.svg';
import emptyStar from 'assets/images/emptyStar.svg';

interface Props {
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number>>;
}

export const ElrStarRating = ({ rating, setRating }: Props) => {
  const genUUID = () => getRandomNumber(1, 3200);

  return (
    <>
      {[...Array(5)].map((_star, index: number) => {
        index += 1;
        return (
          <button
            key={genUUID()}
            type="button"
            onClick={() => setRating(index)}
          >
            {index <= rating ? (
              <img src={blackStar} alt="" />
            ) : (
              <img src={blankStar} alt="" />
            )}
          </button>
        );
      })}
    </>
  );
};

export const ElrRatingDisplay = ({
  rating,
  starStyle,
}: {
  rating: 1 | 2 | 3 | 4 | 5;
  starStyle: string;
}) => {
  const stars = [];
  for (let i = 1; i <= rating; i += 1) {
    stars.push(star);
  }
  for (let i = 1; i <= 5 - rating; i += 1) {
    stars.push(emptyStar);
  }

  return (
    <>
      {stars.map((item: string) => (
        <img src={item} className={starStyle} alt="star" />
      ))}
    </>
  );
};
