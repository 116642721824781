import { useState } from 'react';
import { ElrInput } from 'ui-components';
import EyeOpenIcon from '../../assets/images/eye-line.svg';
import EyeLockIcon from '../../assets/images/eye-off-line.svg';

interface Props {
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  passwordValue: string;
  placeHolder: string;
  name: string;
}

export const PasswordInput: React.FC<Props> = ({
  onInputChange,
  passwordValue,
  placeHolder,
  name,
}) => {
  const [passwordIconToggle, setPasswordIconToggle] = useState<boolean>(false);

  const onPasswordIconChange = () => {
    setPasswordIconToggle(!passwordIconToggle);
  };

  const passwordIconVisibleIcon = passwordIconToggle
    ? EyeLockIcon
    : EyeOpenIcon;

  return (
    <div className="relative">
      <ElrInput
        onChange={onInputChange}
        type={passwordIconToggle ? 'text' : 'password'}
        required
        placeholder={placeHolder}
        name={name}
        className="rounded-none pl-3 pr-10 focus:outline-none bg-elr-gray text-sm"
        value={passwordValue}
      />
      <button
        type="button"
        onClick={onPasswordIconChange}
        className="absolute right-3 top-1/2 transform -translate-y-1/2 opacity-40 focus:outline-none"
        aria-label={passwordIconToggle ? 'Hide password' : 'Show password'}
      >
        <img
          src={passwordIconVisibleIcon}
          alt={passwordIconToggle ? 'Hide password' : 'Show password'}
        />
      </button>
    </div>
  );
};
