/* eslint-disable react-hooks/exhaustive-deps */
import BatchDeliveryDetailList from 'app/components/batch/BatchDeliveryDetailList';
import { useGetProfile } from 'app/hooks/user';
import {
  CustomAccessView,
  DispatcherOnlyView,
} from 'app/utilities/ComponentView';
import { isDispatcherOnly } from 'app/utilities/helpers';
import { COMMERCE, OPERATOR, SENDER } from 'app/utilities/roles';
import React, { useEffect, useRef, useState } from 'react';

import {
  BatchItemAltContent,
  BatchItemDefaultContent,
} from 'app/platform/components/BatchItemContent';
import { handleScroll } from 'app/platform/modules/utility';

interface RequestItemProp {
  request: any;
  index: number;
  active: boolean;
  setActive: Function;
  batchFee: number;
}

const BatchRequestItem: React.FC<RequestItemProp> = ({
  request,
  index,
  setActive,
  active,
  batchFee,
}) => {
  const { isAnOperator, currentAccessRole, access } = useGetProfile();
  const [showDetails, setShowDetails] = useState(false);

  const requestRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    handleScroll(requestRef);
  }, []);

  const showItems = (openIndex: number) => {
    setShowDetails(!showDetails);
    setActive(openIndex);
  };

  const { pickupAddress, deliverToInformation, parcelCode } = request;

  const amountPerItem = () => {
    if (isAnOperator) {
      return batchFee;
    }
    if (isDispatcherOnly(access, currentAccessRole)) {
      return batchFee;
    }
    return batchFee;
  };

  const onClickItem = () => showItems(index);

  return (
    <>
      <CustomAccessView showFor={[SENDER, OPERATOR, COMMERCE]}>
        <BatchItemDefaultContent
          amountPerItem={amountPerItem()}
          pickupAddress={pickupAddress}
          onClickItem={onClickItem}
          parcelCode={parcelCode}
        />
      </CustomAccessView>
      <DispatcherOnlyView role={currentAccessRole} access={access}>
        <BatchItemAltContent
          deliverToInformation={deliverToInformation}
          trackingId={request.trackingId}
          onClickItem={onClickItem}
          active={showDetails}
        />
      </DispatcherOnlyView>
      {showDetails && active && (
        <div ref={requestRef}>
          <BatchDeliveryDetailList details={request} />
          <div className="h-1 border-b border-elr-gray-400 border-opacity-20" />
        </div>
      )}
    </>
  );
};

export default BatchRequestItem;
