import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { DebitIcon, MoneyIcon } from 'app/components/SvgAssets';
import { currencyFormatter } from 'app/utilities/helpers';
import {
  useFetchBillingHistory,
  useGetBillingRecord,
  useGetWalletBalance,
} from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ElrButton, ElrDropdownButton, ElrPageTitle } from 'ui-components';
import { TBillingHistory } from 'app/api/types';
import { DateTime } from 'luxon';
import FundWallet from 'app/sender/components/FundWallet';
import WalletCreditIcon from '../../../../assets/images/wallet-credit.svg';
import WalletDebitIcon from '../../../../assets/images/wallet-withdrawal.svg';

type Record = {
  type: string;
  createdAt: string;
  amount: number;
};

type BillingData = {
  totalDebit: number;
  totalCredit: number;
  date: string;
  records: Record[];
};

const BillingRecord: React.FC = () => {
  const {
    id: userId,
    accountInformation: { id: roleId },
    role,

    currentAccessRole,
  } = useGetProfile();
  const [walletBalance, setWalletBalance] = useState(0);
  const [showFundModal, setShowFundModal] = useState(false);
  const [activeTab, setActiveTab] = useState('All');
  const [transferDetails, setTransferDetails] = useState({
    paystackAccountName: '',
    paystackAccountNumber: '',
  });
  const [underlinePosition, setUnderlinePosition] = useState({
    left: 0,
    width: 0,
  });
  const currentRole = currentAccessRole || role;

  const [getWalletBalance, wallet] = useGetWalletBalance(
    userId,
    roleId,
    currentRole
  );

  const { totalDebit } = useGetBillingRecord(userId, roleId, currentRole) as {
    docsList: BillingData[];
    totalDebit: number;
  };
  const [billRecords, loading] = useFetchBillingHistory(10, currentRole);

  const getBalance = useCallback(async () => {
    const account: any = await getWalletBalance();
    if (account.data) {
      const { balance, paystackAccountName, paystackAccountNumber } =
        account.data;
      setWalletBalance(balance);
      paystackAccountName &&
        setTransferDetails({ paystackAccountName, paystackAccountNumber });
    }
  }, [wallet?.balance]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  const allTabRef = useRef<HTMLButtonElement | null>(null);
  const fundingTabRef = useRef<HTMLButtonElement | null>(null);
  const debitsTabRef = useRef<HTMLButtonElement | null>(null);

  const tabs = [
    { name: 'All', ref: allTabRef },
    { name: 'Funding', ref: fundingTabRef },
    { name: 'Debits', ref: debitsTabRef },
  ];

  useEffect(() => {
    const activeTabRef = tabs.find((tab) => tab.name === activeTab)?.ref
      .current;
    if (activeTabRef) {
      const activeTabWidth = activeTabRef.offsetWidth;
      setUnderlinePosition({
        left: activeTabRef.offsetLeft + (activeTabWidth - 92) / 2,
        width: 92,
      });
    }
  }, [activeTab]);

  const getFilteredRecords = () => {
    if (activeTab === 'Funding') {
      return billRecords.filter((record) => record.type === 'credit');
    }
    if (activeTab === 'Debits') {
      return billRecords.filter((record) => record.type === 'debit');
    }
    return billRecords;
  };
  const filteredRecords = getFilteredRecords();

  const alertDescription = (type: string) => {
    const alert: { [key: string]: { [key: string]: string | JSX.Element } } = {
      debit: {
        text: 'Wallet Debited',
        icon: <img src={WalletDebitIcon} alt="Wallet Credit" />,
      },
      credit: {
        text: 'Wallet Credited',
        icon: <img src={WalletCreditIcon} alt="Wallet Debit" />,
      },
    };
    return (
      <div className="flex flex-row gap-4 align-middle items-center">
        {alert[type].icon}
        <div>
          <p>{alert[type].text}</p>
        </div>
      </div>
    );
  };

  const _renderLoadState = () => {
    if (loading) {
      return (
        <tr>
          <td
            colSpan={4}
            className="text-center italic py-8 text-elr-black-300"
          >
            Loading transactions...
          </td>
        </tr>
      );
    }
    if (!filteredRecords.length) {
      return (
        <tr>
          <td
            colSpan={4}
            className="text-center italic py-8 text-elr-black-300"
          >
            No transactions at the moment...
          </td>
        </tr>
      );
    }
    return null;
  };

  const formatDateTime = (createdAt: string) => {
    const dateTime = DateTime.fromISO(createdAt);
    return dateTime.toFormat('yyyy-LL-dd hh:mm a');
  };

  return (
    <div>
      <ElrPageTitle title="Errandlr - Transactions" />
      <ProfileNavigationMenu>
        <div className="justify-center">
          <TopNavigationMenu />
          {showFundModal && (
            <FundWallet
              amount={0}
              skipOrderMethod={!!transferDetails.paystackAccountName}
              close={() => setShowFundModal(false)}
            />
          )}
          <div className="bg-elr-gray flex flex-col pt-8 px-7 md:pt-3 md:px-20">
            <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
              <h3 className="pt-6 text-xl mb-2">Transaction</h3>
              <p className="text-elr-black-300 text-base">
                View and follow up with your events here.
              </p>
            </div>
            <div>
              <section className="grid md:grid-cols-column-two grid-cols-column gap-4 mb-5">
                <div className="bg-elr-white-400 py-5 px-6">
                  <div>
                    <div className="float-end">
                      <ElrButton
                        text={'Top Up Wallet'}
                        className="bg-elr-black text-elr-white-400 rounded-md py-3 px-5"
                        onClick={() => {
                          setShowFundModal(true);
                        }}
                      />
                    </div>
                    <MoneyIcon fill="#EFEDFE" bgFill="#5A4AF2" />
                    <div className="mb-5" />
                    <p className="text-lg text-elr-black-300 leading-5 mb-3">
                      Wallet Balance
                    </p>
                    <p className="text-3xl text-elr-black">
                      {currencyFormatter(walletBalance)}
                    </p>
                  </div>
                </div>
                <div className="bg-elr-white-400 py-5 px-6">
                  <div>
                    <DebitIcon />
                    <div className="mb-5" />
                    <p className="text-lg text-elr-black-300 leading-5 mb-3">
                      Total Debit
                    </p>
                    <p className="text-3xl text-elr-black">
                      {currencyFormatter(totalDebit)}
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <section className="bg-elr-white-400 mb-8">
              <div className="py-4 px-8 md:flex justify-between border-b-2 border-elr-gray">
                <div className="flex self-center text-lg gap-16 border-elr-black-200 relative ml-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      type="button"
                      ref={tab.ref}
                      className={`cursor-pointer text-elr-black ${
                        activeTab === tab.name
                          ? 'text-opacity-100'
                          : 'text-opacity-40'
                      }`}
                      onClick={() => setActiveTab(tab.name)}
                    >
                      {tab.name}
                    </button>
                  ))}
                  <div
                    className={`rounded-t-lg h-1 bg-elr-black absolute transition-all duration-300 ease-in-out`}
                    style={{
                      width: `${underlinePosition.width}px`,
                      left: `${underlinePosition.left}px`,
                      bottom: '-24px',
                    }}
                  />
                </div>
                <div className="flex gap-2">
                  <div>
                    <ElrDropdownButton
                      text="This Month"
                      isToggled={false}
                      className="bg-elr-gray rounded-sm"
                      size="lg"
                    />
                  </div>
                  <ElrButton
                    text="Export Billing Report"
                    className="bg-elr-gray text-elr-black px-12 rounded-sm"
                  />
                </div>
              </div>
              <div className="pl-14">
                <table className="table-auto w-full">
                  <thead>
                    <tr className="border-b-2 border-elr-gray text-elr-black-300">
                      <th className="font-normal py-7 pl-7 text-left">
                        Transaction Type
                      </th>
                      <th className="font-normal py-7 text-center">Amount</th>
                      <th className="font-normal py-7 text-center">
                        Date & Time
                      </th>
                      <th className="font-normal py-7 text-center">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {_renderLoadState()}
                    {filteredRecords.map((transaction: TBillingHistory) => (
                      <tr
                        key={transaction.createdAt}
                        className="text-center border border-x-0 border-elr-gray"
                      >
                        <td className="text-left py-5">
                          {alertDescription(transaction.type)}
                        </td>
                        <td className="text-base py-5 text-center text-opacity-60 text-elr-black">
                          {transaction.amount}
                        </td>
                        <td className="text-base py-5 text-center text-opacity-60 text-elr-black">
                          {formatDateTime(transaction.createdAt)}
                        </td>
                        <td className="text-base my-5 px-8 text-center py-2 rounded-full inline-block bg-elr-purple-200 text-elr-purple bg-opacity-40">
                          {transaction.status || 'Successful'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default BillingRecord;
