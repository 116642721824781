import { CalendarIconWonWithBG, PlusIcon } from 'app/components/SvgAssets';
import { ElrButton } from 'ui-components';
import { useNavigate } from 'react-router';
import { privateRoutes } from 'app/utilities/routes';
import { COMMERCE } from 'app/utilities/roles';
import { useFetchEventList } from 'app/hooks/requests/commerce';
import { DateTime } from 'luxon';
import ArrowForward from '../../../../assets/images/arrowForward.svg';

export type EventType = {
  title: string;
  createdDate: string;
  eventUUId: string;
  isLastItem?: boolean;
};

const EventItem = ({
  title,
  createdDate,
  eventUUId,
  isLastItem,
}: EventType) => {
  const navigate = useNavigate();

  const formattedDate = DateTime.fromISO(createdDate).toFormat('dd-MM-yyyy');

  const handleEventClick = () => {
    navigate(
      `/${COMMERCE}/${privateRoutes.ecommerce}/${eventUUId}/${privateRoutes.eventsDashboard}`,
      {
        state: {
          createdDate,
        },
      }
    );
  };

  return (
    <>
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={handleEventClick}
      >
        <div className="flex flex-row gap-4">
          <CalendarIconWonWithBG className="rounded-full w-16 h-16 bg-elr-gray-neutral-200 p-1.5 opacity-80" />
          <div className="flex flex-col gap-1">
            <p className="text-lg text-elr-black">{title}</p>
            <p className="text-base text-elr-black-400">
              Created {formattedDate}
            </p>
          </div>
        </div>
        <img
          className="w-8 h-8 p-1.5 opacity-50"
          src={ArrowForward}
          alt="arrow forward icon"
        />
      </div>
      {!isLastItem && (
        <div className="bg-elr-gray-neutral-200 border border-elr-gray-neutral-200 my-6" />
      )}
    </>
  );
};

const EventList = () => {
  const navigate = useNavigate();
  const { eventListData: events, loading } = useFetchEventList();

  const handleNewEvent = () => {
    navigate(`/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.events}`);
  };

  return (
    <div className="pt-16 flex justify-center min-h-screen">
      <div className="flex flex-col w-full md:w-8/12 px-4 md:px-0">
        <div className="mt-12 p-10 w-full bg-white">
          <div className="flex flex-col gap-1">
            <p className="text-2xl">List of Events</p>
            <p className="text-lg text-elr-black-400">
              The events you have created so far
            </p>
          </div>

          <div className="py-10">
            {loading && <p>Loading...</p>}
            {!loading &&
              events &&
              events.length > 0 &&
              events.map((event, index) => (
                <EventItem
                  key={event.eventUUId}
                  title={event.title}
                  createdDate={event.createdDate}
                  eventUUId={event.eventUUId}
                  isLastItem={index === events.length - 1}
                />
              ))}
            {!loading && (!events || events.length === 0) && (
              <p className="text-center text-elr-black-400">
                No Events at the moment, Create a new event.
              </p>
            )}
          </div>

          <ElrButton
            text="Create New Event"
            className="w-full bg-elr-black text-elr-white rounded-md gap-3"
            imageSrc={<PlusIcon fill="#fff" />}
            imagePosition="Left"
            onClick={handleNewEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default EventList;
