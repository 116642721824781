import { PAYSTACK_PUBLIC_KEY } from 'app/constants';
import { useGetProfile } from 'app/hooks/user';
import { calculatePaymentFee } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import React, { useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { useNavigate } from 'react-router-dom';
import { ElrButton, ElrInput, ElrModal } from 'ui-components';

interface IFundWallet {
  amount: number;
  close: () => void;
  askForAmount?: boolean;
  request?: string; // used for shared payment, to keep track of what request the user is paying for
  trackingId?: string;
  phoneNumber?: string;
  skipOrderMethod?: boolean;
}

const FundWallet: React.FC<IFundWallet> = ({
  amount = 0,
  close,
  askForAmount = false,
  request,
  trackingId,
  phoneNumber,
  skipOrderMethod = false,
}) => (
  <FundModal
    close={close}
    request={request}
    amount={amount}
    trackingId={trackingId}
    askForAmount={askForAmount || !amount}
    phoneNumber={phoneNumber}
    skipOrderMethod={skipOrderMethod}
  />
);

export const FundModal = ({
  amount,
  close,
  askForAmount,
  request,
  trackingId,
  phoneNumber,
  skipOrderMethod = false,
}: {
  amount: number;
  close: () => void;
  askForAmount: boolean;
  request?: string;
  trackingId?: string;
  phoneNumber?: string;
  skipOrderMethod?: boolean;
}) => {
  const {
    accountInformation: { id: roleId },
    email,
  } = useGetProfile();
  const history = useNavigate();
  const [showAmountModal, setShowAmountModal] = useState(skipOrderMethod);
  const [amt, setAmt] = useState(String(amount));

  const amountInNaira = Number(amt);
  const totalAmountWithFeeInKobo = calculatePaymentFee(amountInNaira) * 100;

  const config = {
    email: email || `${phoneNumber}@errandlr.com`,
    amount: totalAmountWithFeeInKobo,
    publicKey: PAYSTACK_PUBLIC_KEY,
    channels: ['card', 'ussd', 'bank_transfer'],
    metadata: {
      senderId: roleId,
      request,
    },
  };

  const initializePayment = usePaystackPayment(config as any);

  const onSuccess = () => {
    close();
    if (request) {
      history(`${publicRoutes.dispatchDetails}/${request}?track=${trackingId}`);
    }
  };

  const onClose = () => {};

  const goToPaystack = () => {
    const MINIMUM_PAYSTACK = 50;
    Number(amt) >= MINIMUM_PAYSTACK &&
      initializePayment({ onSuccess, onClose });
    setShowAmountModal(false);
  };

  const handleTransfer = () => {
    if (askForAmount) {
      setShowAmountModal(true);
    } else {
      goToPaystack();
    }
  };

  return (
    <ElrModal
      height="h-72"
      width="w-11/12 md:w-430"
      isOpen
      appendModal={<div />}
      onClose={close}
    >
      {showAmountModal ? (
        <div>
          <p className="mt-5 mb-5 text-xl text-center">Enter Amount</p>

          <div className="md:mx-5">
            <ElrInput
              className="border-none rounded-none bg-elr-gray"
              value={amt}
              type="number"
              onChange={(e) => setAmt(e.target.value)}
            />
          </div>
          <div className="text-center">
            <ElrButton
              text="Continue"
              size="sm"
              onClick={goToPaystack}
              className="self-center px-10 py-2 mt-8 bg-elr-black text-elr-white-400 md:text-base"
            />
          </div>
          <p className="mt-10 text-sm text-center text-elr-black text-opacity-60">
            <span
              className="cursor-pointer"
              onClick={() => setShowAmountModal(false)}
            >
              Go back
            </span>
          </p>
        </div>
      ) : (
        <div>
          <p className="mt-5 mb-5 text-xl text-center">Place order via</p>

          <div>
            <div
              onClick={handleTransfer}
              className="w-full cursor-pointer h-[45px] flex pl-2 md:pl-4 items-center mb-3 rounded-5 border-03 border-elr-green border-opacity-60 justify-between"
            >
              <p>Bank Transfer</p>
              <small className="text-xs px-1">Payment Charges Apply</small>
            </div>
          </div>
          <p className="mt-5 text-sm text-center text-elr-black text-opacity-60">
            <span className="cursor-pointer" onClick={close}>
              Back to system
            </span>
          </p>
        </div>
      )}
    </ElrModal>
  );
};

export default FundWallet;
