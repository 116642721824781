import React from 'react';

interface Props {
  checked: boolean;
  onChange?: (e: any) => void;
}

export const ElrCheckboxToggle: React.FC<Props> = ({ onChange, checked }) => (
  <div className="checkbox-container-style bg-elr-gray rounded border-elr-purple border-05 border-opacity-60 w-6 h-6 flex shrink-0 justify-center items-center mr-2 focus-within:border-elr-purple focus-within:border-opacity-60 cursor-pointer">
    <input
      type="checkbox"
      className="opacity-0 absolute cursor-pointer"
      onChange={onChange}
      checked={checked}
    />
    <svg
      className="fill-current hidden w-5 h-4 text-elr-purple"
      viewBox="0 0 20 20"
    >
      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
    </svg>
  </div>
);
