import { httpGetPlaceDetailsByID } from 'app/api/estimate';
import { mapStyle } from 'app/api/mapStyle';
import {
  GeoLocationData,
  LocationWrapperValues,
} from 'app/utilities/types/shared';
import destinationIconMapMarker from 'assets/images/map_destination_icon.png';
import originIconMapMarker from 'assets/images/map_origin_icon.png';
import memoizeOne from 'memoize-one';
import equals from 'ramda/src/equals';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

interface MapProps {
  userRouteAddresses?: LocationWrapperValues;
  onSetPickupGeometry: (response: GeoLocationData) => void;
}

type routeGeometry = {
  lat?: number;
  lng?: number;
  icon: string;
};

const DynamicMap: React.FC<MapProps> = ({
  userRouteAddresses = { pickupLocation: { id: '' }, dropoffLocations: [] },
  onSetPickupGeometry,
}) => {
  const {
    pickupLocation = {
      id: '',
    },
    dropoffLocations = [],
  } = userRouteAddresses;
  const google: any = window?.google;

  const dropOffPlaceIds =
    (dropoffLocations.length &&
      dropoffLocations.map((dropoff) => dropoff?.id).filter(Boolean)) ||
    [];

  const [pickupLocationId, setPickupLocationId] = useState<string>(
    pickupLocation.id
  );
  const [dropOffIds, setDropOffIds] = useState<string[]>(dropOffPlaceIds);

  const [pickupLocationCoordinate, setPickuplocationCoordinate] = useState<{
    lat: number;
    lng: number;
    icon: string;
  }>({ lat: 0, lng: 0, icon: originIconMapMarker });
  const [destinationLocationCoordinate, setDestinationlocationCoordinate] =
    useState<{ lat?: number; lng?: number; icon: string }[]>([]);
  const homeUrlReference =
    new URLSearchParams(useLocation().search).get('utm') || null;
  const [homeReference, setHomeRef] = useState(homeUrlReference);

  const initialize = (
    pickupRoute: routeGeometry,
    destinationRoute: routeGeometry[]
  ): void => {
    if (!destinationRoute.length && pickupRoute) return;

    const center = new google.maps.LatLng(pickupRoute?.lat, pickupRoute?.lng);
    const mapOptions = {
      center,
      zoom: 10,
      styles: mapStyle,
    };
    const map = new google.maps.Map(document.getElementById('map'), mapOptions);
    const infoWindow = new google.maps.InfoWindow();
    // eslint-disable-next-line camelcase
    const latLng = [];
    const geometry = [pickupRoute, ...destinationRoute];
    const latlngbounds = new google.maps.LatLngBounds();
    for (let i = 0; i < geometry.length; i += 1) {
      const data = geometry[i];
      const myLatlng = new google.maps.LatLng(data?.lat, data?.lng);
      latLng.push(myLatlng);

      const marker = new google.maps.Marker({
        position: myLatlng,
        map,
        icon: data?.icon,
        // title: data.title,
      });

      latlngbounds.extend(marker.position);
      ((markerf, _) => {
        google.maps.event.addListener(markerf, 'click', () => {
          // infoWindow.setContent(dataf.description);
          infoWindow.open(map, markerf);
        });
      })(marker, data);
    }
    map.setCenter(latlngbounds.getCenter());
    map.fitBounds(latlngbounds);

    //* **********ROUTING****************//

    // Intialize the Direction Service
    const service = new google.maps.DirectionsService();

    // Loop and Draw Path Route between the Points on MAP
    for (let i = 0; i < latLng.length; i += 1) {
      if (i + 1 < latLng.length) {
        const src = latLng[i];
        const des = latLng[i + 1];
        service.route(
          {
            origin: src,
            destination: des,
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
          },
          // eslint-disable-next-line camelcase
          (result: { routes: { overview_path: any[] }[] }, status: any) => {
            if (status === google.maps.DirectionsStatus.OK) {
              // Set the Path Stroke Color
              const poly = new google.maps.Polyline({
                map,
                strokeColor: '#555',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              });
              // Intialize the Path Array
              const path = new google.maps.MVCArray();
              for (
                let iv = 0, len = result.routes[0].overview_path.length;
                iv < len;
                iv += 1
              ) {
                path.push(result.routes[0].overview_path[iv]);
              }
              poly.setPath(path);
            }
          }
        );
      }
    }
  };

  const getPickupGeometry = async () => {
    try {
      const memoizePlaceCall = await memoizeOne(httpGetPlaceDetailsByID);
      const response = await memoizePlaceCall(pickupLocation.id);

      const pickupRoute = {
        lat: response.geolocation.lat,
        lng: response.geolocation.lng,
        icon: originIconMapMarker,
      };
      onSetPickupGeometry(response);
      setPickuplocationCoordinate(pickupRoute);
      const initMemoize = memoizeOne(initialize);
      initMemoize(pickupRoute, destinationLocationCoordinate);
      return pickupRoute;
    } catch (error: any) {
      /** noop */
      return error;
    }
  };

  const getDropOffGeometry = async (route?: routeGeometry) => {
    const response = await Promise.allSettled(
      dropOffPlaceIds.map(async (id: string) => {
        try {
          const memoizePlaceCall = await memoizeOne(httpGetPlaceDetailsByID);
          return await memoizePlaceCall(id);
        } catch {
          /** no op, it's a minor eye bug */
          return null;
        }
      })
    );

    // a bug might happen here, just incase
    // add this resp.status === fulfilled in the map function.
    const result =
      response
        .map((resp: { status: string; value?: GeoLocationData | null }) => ({
          ...resp?.value?.geolocation,
          icon: destinationIconMapMarker,
        }))
        .filter(Boolean) || [];
    setDestinationlocationCoordinate(result);

    const initMemoize = memoizeOne(initialize);
    const pickupCoords = route || pickupLocationCoordinate;
    initMemoize(pickupCoords, result);
    return result;
  };

  if (dropOffPlaceIds.length && pickupLocation.id && homeReference) {
    setHomeRef(null);
    getPickupGeometry().then((route) => {
      getDropOffGeometry(route);
    });
  }

  if (pickupLocation.id && pickupLocation.id !== pickupLocationId) {
    setPickupLocationId(pickupLocation.id);
    getPickupGeometry();
  }

  if (dropOffPlaceIds.length && !equals(dropOffIds, dropOffPlaceIds)) {
    setDropOffIds(dropOffPlaceIds);
    getDropOffGeometry();
  }

  return (
    <div className="md:block w-full h-96 md:h-screen">
      <div id="map" className="h-full blank-map-layover" />
    </div>
  );
};

export default React.memo(DynamicMap);
