import React, { useState, useRef, useEffect } from 'react';
import Dropdown from '../../../../assets/images/dropdown.svg';

interface DropdownProps {
  options: { value: string; label: string; icon?: JSX.Element }[];
  onSelect: (value: string) => void;
  buttonLabel: string | JSX.Element;
  buttonIcon?: JSX.Element;
  dropdownPosition?: 'right' | 'left';
  className?: string;
  showDropdownIcon?: boolean;
}

export const EventDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  buttonLabel,
  buttonIcon,
  dropdownPosition = 'right',
  className,
  showDropdownIcon = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between align-middle cursor-pointer gap-2"
      >
        {buttonIcon && <span>{buttonIcon}</span>}
        <span>{buttonLabel}</span>
        {showDropdownIcon && <img src={Dropdown} alt="" className="w-4 ml-2" />}
      </div>
      {isOpen && (
        <div
          className={`dropdown-menu ${dropdownPosition === 'right' ? 'right-0' : 'left-0'}`}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-option flex items-center gap-3"
              onClick={() => {
                onSelect(option.value);
                setIsOpen(false);
              }}
            >
              {option.icon && <span className="icon">{option.icon}</span>}
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventDropdown;
