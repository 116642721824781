import { verifyBankInformation } from 'app/api/payment';
import { BankDetails } from 'app/utilities/types/dispatcherTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { ElrBankSelect, ElrInput } from 'ui-components';

interface BankDetailsInputInterface {
  bankDetails: BankDetails;
  setBankDetails: (bd: any) => void;
}

const BankDetailsInput: React.FC<BankDetailsInputInterface> = ({
  bankDetails,
  setBankDetails,
}) => {
  const [isVerifying, setVerifying] = useState<boolean>(false);
  const onBankNameChange = (selectedOption: any): void => {
    const { value = '', code = '' } = selectedOption || {};
    setBankDetails((bd: any) => ({
      ...bd,
      bankName: value,
      bankCode: code,
    }));
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankDetails((bd: any) => ({
      ...bd,
      [event.target.name]: event.target.value,
    }));
  };

  const verifyAccountNumber = useCallback(
    async (accountNumber: string, bankCode: string) => {
      try {
        setVerifying(true);
        const data = await verifyBankInformation({ accountNumber, bankCode });
        setBankDetails((bd: any) => ({
          ...bd,
          accountName: data.account_name,
        }));
        setVerifying(false);
      } catch (error) {
        setVerifying(false);
        setBankDetails((bd: any) => ({
          ...bd,
          accountName: '',
        }));
        // no op
      }
    },
    [setBankDetails]
  );

  useEffect(() => {
    const NGN_BANK_ACCOUNT_LENGTH = 10;
    if (
      bankDetails.accountNumber?.length === NGN_BANK_ACCOUNT_LENGTH &&
      bankDetails.bankCode
    ) {
      verifyAccountNumber(bankDetails.accountNumber, bankDetails.bankCode);
    }
  }, [bankDetails.accountNumber, bankDetails.bankCode, verifyAccountNumber]);

  return (
    <div>
      <ElrBankSelect
        placeholder="Bank name"
        onChange={onBankNameChange}
        currentValue={bankDetails.bankName}
      />
      <ElrInput
        className="bg-elr-gray rounded-none border-none mb-3"
        onChange={onChange}
        name="accountNumber"
        value={bankDetails.accountNumber}
        placeholder="Account Number"
      />
      <ElrInput
        className="bg-elr-gray rounded-none border-none mb-3"
        onChange={() => {}}
        name="accountName"
        value={bankDetails.accountName}
        disabled
        placeholder="Your Name Will Appear Here"
      />
      {isVerifying && (
        <strong className="text-center pt-2">
          PLEASE WAIT, VERIFYING ACCOUNT NUMBER
        </strong>
      )}
      {!isVerifying && bankDetails.accountName && (
        <p className="text-elr-green text-center pt-2">
          {bankDetails.accountName}
        </p>
      )}
    </div>
  );
};

export default BankDetailsInput;
