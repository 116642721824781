import React, { useState } from 'react';

interface Props {
  containerStyles?: string;
  transformPositionClass?: string;
  iconClass?: string;
  children: React.ReactNode;
  IconComponent?: React.ReactNode;
}

const SameIconDropDown: React.FC<Props> = ({
  children,
  transformPositionClass = '-translate-x-25',
  containerStyles,
  iconClass = 'text-elr-black opacity-60',
  IconComponent,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const onClickIcon = () => {
    setDropDown(!dropDown);
  };
  return (
    <div>
      <div>
        <div className="flex justify-between w-auto">
          <div className={`${iconClass} flex-end`} onClick={onClickIcon}>
            {IconComponent}
          </div>
        </div>
      </div>
      {dropDown && (
        <div
          className={`${transformPositionClass} absolute mt-2 rounded-md ${containerStyles}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default SameIconDropDown;
