import logo from 'assets/images/logo.svg';

const Loading = () => (
  <div>
    <img
      className="fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
      src={logo}
      alt="loading"
      title="loading"
    />
  </div>
);

export default Loading;
