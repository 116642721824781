import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import SameIconDropDown from './SameIconDropDown';

interface Props {
  containerClass?: string;
}

const Notification: React.FC<Props> = ({ containerClass = '' }) => (
  <div className={`${containerClass}`}>
    <SameIconDropDown
      IconComponent={<HelpOutlineIcon />}
      containerStyles="w-screen left-0 md:left-auto h-full mb-12 md:-ml-40 md:w-406 no-scrollbar md:h-60 md:m-auto bg-elr-white-400 md:border-opacity-30 md:border md:border-elr-green p-2 overflow-hidden md:overflow-y-scroll z-50"
      transformPositionClass=""
    >
      <div className="flex justify-between hover:bg-elr-gray cursor-pointer w-full md:text-sm p-2 text-elr-green text-opacity-60">
        <div className="div">Complete your document documentation</div>
        <div className="">now</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer md:text-sm text-elr-green text-opacity-60">
        <div className="div">Add a bank accout to your profile</div>
        <div className="">2 mins</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer md:text-sm text-elr-green text-opacity-60">
        <div className="">
          To have a new request,
          <span className="text-elr-green pl-2">view</span>
        </div>
        <div className="">6 mins</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray md:text-sm cursor-pointer text-elr-gray-400">
        <div className="div">
          You have been paid
          <span className="text-elr-black pl-2">view</span>
        </div>
        <div className="">20 hrs</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer text-elr-gray-400">
        <div className="div">
          Your delivery has been confirmed.
          <span className="text-elr-black pl-2">view</span>
        </div>
        <div className="">1 hrs</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer text-elr-gray-400">
        <div className="div">
          Incomplete driver information.
          <span className="text-elr-black pl-2">view</span>
        </div>
        <div className="">18 hrs</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer text-elr-gray-400">
        <div className="div">
          You have been paid
          <span className="text-elr-black pl-2">view</span>
        </div>
        <div className="">20 hrs</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer text-elr-gray-400">
        <div className="div">
          Your delivery has been confirmed.
          <span className="text-elr-black pl-2">view</span>
        </div>
        <div className="">1 hrs</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer text-elr-gray-400">
        <div className="div">
          Incomplete driver information.
          <span className="text-elr-black pl-2">view</span>
        </div>
        <div className="">18 hrs</div>
      </div>
      <div className="flex justify-between w-full p-2 hover:bg-elr-gray cursor-pointer text-elr-green text-opacity-60">
        <div className="div">
          Update your bank details
          <span className="text-elr-green pl-2">view</span>
        </div>
        <div className="">2 days</div>
      </div>
      <div className="text-center invisible md:visible pt-6 text-elr-gray-400">
        See all
      </div>
    </SameIconDropDown>
  </div>
);

export default Notification;
