import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import { ElrPageTitle } from 'ui-components';
import BusinessDetails from './BusinessDetails';
import PersonalDetails from './PersonalDetails';
import BankDetails from './BankDetails';

const CompanySetting = () => {
  const profile = useGetProfile();
  const { businessName } = profile.accountInformation;

  return (
    <div>
      <ElrPageTitle title="Errandlr - Company" />
      <ProfileNavigationMenu>
        <div className="justify-center">
          <TopNavigationMenu />
          <div className="bg-elr-gray flex flex-col py-8 px-7 md:pt-3 md:px-20">
            <div className="mb-6 text-elr-black font-normal font-serif leading-tight">
              <h3 className="pt-6 text-xl mb-2">Account</h3>
              <p className="text-elr-black-300 text-base">
                Manage your account information
              </p>
            </div>
            <div className="md:w-10/12 w-full flex flex-col gap-5 md:justify-start">
              <BusinessDetails accountName={businessName} />
              <PersonalDetails />
              <BankDetails />
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default CompanySetting;
