import { InputProps } from 'app/utilities/types/dispatcherTypes';
import React, { InputHTMLAttributes } from 'react';
import { inputStyle } from './ClassUtils';

const InputVar2: React.FC<
  InputHTMLAttributes<HTMLInputElement> & InputProps
> = ({
  label,
  field,
  form,
  preComponent,
  type,
  optional,
  inputPaddingY = 3,
  required,
  className,
  focus,
  ...other
}) => {
  const isRequired = required;

  return (
    <>
      <div className="text-base text-elr-black">{label}</div>
      <div className={inputStyle(focus, className)}>
        {preComponent && <div className="mr-2 text-lg">{preComponent}</div>}

        <input
          {...other}
          {...field}
          onChange={(event) =>
            form.setFieldValue(
              event.target.name,
              event.target.value.trimStart()
            )
          }
          onBlur={(event) =>
            form.setFieldValue(event.target.name, event.target.value.trimEnd())
          }
          value={field.value || ''}
          className="box-border h-full flex-grow w-full focus:outline-none"
          type={type || 'text'}
        />
      </div>
      {isRequired && !field.value && (
        <div className="text-xs mt-2 text-elr-error text-right">
          Required Field
        </div>
      )}
    </>
  );
};

export default InputVar2;
