import { CenterLogoHeader } from 'app/components/Header';
import { useGetProfile } from 'app/hooks/user';
import { checkCompanyProfile } from 'app/utilities/helpers';
import { DISPATCHER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import ErrorOutline from 'assets/images/ErrorOutline.svg';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ElrButton, ElrPageTitle } from 'ui-components';

const Requirements: React.FC = () => {
  const { accountInformation } = useGetProfile();

  const searchFields = useMemo(
    () => ({
      Certificate_Of_Incorporation:
        accountInformation.certificateOfIncorporation,
    }),
    [accountInformation.certificateOfIncorporation]
  );

  const { isNotEmpty } = checkCompanyProfile(searchFields);
  const emptyFields = Array.isArray(isNotEmpty) && isNotEmpty;

  return (
    <div>
      <ElrPageTitle title="Errandlr - Bank-Information" />
      <div className="flex flex-col items-center">
        <CenterLogoHeader />
        <div className="px-5 md:px-14 h-full w-11/12 md:w-560 bg-elr-white-400 rounded-2xl flex flex-col mb-12">
          <p className="mt-12 mb-12 md:mb-14 text-xl text-center">
            Complete the requirements
          </p>
          <div>
            {emptyFields &&
              emptyFields.map((field, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="w-full h-50 bg-elr-gray flex justify-between items-center px-2 md:px-5 mb-3"
                >
                  <p className="flex">
                    <img
                      className="pr-1.5 md:pr-3 -mt-1"
                      src={ErrorOutline}
                      alt="error"
                    />
                    <span className="opacity-40 text-sm xs:text-base md:text-lg">
                      {field.split('_').join(' ')}
                    </span>
                  </p>
                  <p className="opacity-40 text-sm xs:text-base md:text-lg">
                    Pending
                  </p>
                </div>
              ))}
          </div>
          <Link to={`${privateRoutes.onboarding(DISPATCHER)}?pending=true`}>
            <div className="flex justify-end">
              <ElrButton
                text="Continue"
                className="bg-elr-black text-white text-lg w-40 mt-12 mb-7 md:mb-10"
              />
            </div>
            <div className="w-full border-b border-elr-gray-600 mb-10 md:mb-14 opacity-40" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Requirements;
