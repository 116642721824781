import { Field } from 'formik';
import React from 'react';
import TextAreaInput from '../TextAreaInput';

interface Props {
  name: string;
}

export const AdditionalInfo: React.FC<Props> = ({ name }) => (
  <div>
    <p className="opacity-60 my-4">Additional Delivery Information</p>
    <div>
      <p className="text-elr-green text-10 mb-3 opacity-60">
        Any information to deliver the parcel safely, e.g time to be delivered.
      </p>
      <Field
        validateOnChange={false}
        name={name}
        placeholder="Add any other information, please avoid adding sensitive information like phone numbers"
        component={TextAreaInput}
      />
      <p className="text-elr-green text-10 mb-3 opacity-60">
        MAX: 100 Characters
      </p>
    </div>
  </div>
);
