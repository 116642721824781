import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ElrButton } from 'ui-components';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useState } from 'react';
import { PasswordInput } from 'app/components/PasswordInputField';
import arrowRight from '../../../../assets/images/arrowForward.svg';

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters long'),
  confirmNewPassword: Yup.string().required('Please confirm your new password'),
});

interface ChangePasswordProps {
  handleChangePassowrd: (settings: {
    currentPassword: string;
    newPassword: string;
  }) => void;
}

const ChangePassword = ({ handleChangePassowrd }: ChangePasswordProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [matchPassword, setMatchPassword] = useState(false);
  const { values, handleSubmit, handleChange, isValid } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (formValues) => {
      if (formValues.newPassword !== formValues.confirmNewPassword) {
        setMatchPassword(true);
        return;
      }
      setIsModalOpen(false);
      handleChangePassowrd(formValues);
    },
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setMatchPassword(false);
  };

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Change Password</p>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-7 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">
              Current Password
            </p>
            <PasswordInput
              placeHolder="Enter Password"
              onInputChange={handleChange}
              passwordValue={values.currentPassword}
              name="currentPassword"
            />
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">New Password</p>
            <PasswordInput
              placeHolder="Enter Password"
              onInputChange={onInputChange}
              passwordValue={values.newPassword}
              name="newPassword"
            />
          </div>
          <div className="w-full">
            <p className="text-base pb-1.5 text-elr-black-400">
              Confirm New Password
            </p>
            <PasswordInput
              placeHolder="Enter Password"
              onInputChange={onInputChange}
              passwordValue={values.confirmNewPassword}
              name="confirmNewPassword"
            />
            {matchPassword && (
              <p className="text-elr-error pt-0.5 text-sm">
                Password do not match
              </p>
            )}
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled={!isValid}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      {isModalOpen && renderEditInfo}
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <div>
          <p className="text-xl pb-2">Change Password</p>
        </div>
        <div className="flex items-center gap-2">
          <img src={arrowRight} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
