import { useQuery, useQueryClient } from '@tanstack/react-query';
import { httpUserProfile } from 'app/api/user';
import { STATE_USER } from 'app/state/constants';
import { Session } from 'app/utilities/Session';
import {
  COMMERCE,
  DISPATCHER,
  OPERATOR,
  RIDER,
  SENDER,
  SUPPORT,
} from 'app/utilities/roles';
import { AccountType } from 'app/utilities/types/dispatcherTypes';
import { isEmpty } from 'ramda';

export const useFetchUser = (
  restrictProfileFetching: string | null | Function,
  access: Roles = SENDER
) => {
  const userAccessType =
    (Session.getValue('accessUserType') as Roles) || access;

  return useQuery({
    queryKey: [STATE_USER],
    queryFn: () => {
      if (!Session.currentUser() || !restrictProfileFetching || !userAccessType)
        return {};
      return httpUserProfile(userAccessType);
    },
    retryOnMount: false,
    refetchOnMount: false,
    notifyOnChangeProps: ['data'],
    retry: false,
    enabled: true,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

type Phone = string;

export type Roles =
  | typeof SENDER
  | typeof DISPATCHER
  | typeof COMMERCE
  | typeof SUPPORT
  | typeof RIDER
  | typeof OPERATOR;

export const roles = [SENDER, DISPATCHER, COMMERCE, SUPPORT, RIDER, OPERATOR];

export type Profile = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: Phone;
  accountInformation: AccountType;
  role: Roles;
  currentAccessRole: Roles;
  access: Roles[];
  isAnOperator: boolean;
};

export const defaultProfileEntry: Profile = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  accountInformation: {
    id: '',
    companyName: '',
    businessName: '',
    country: [],
    state: [],
    mainAddress: {
      addressNumber: '',
      localGovtArea: '',
      registeredCity: '',
      streetName: '',

      fullAddress: '',
      localGovt: '',
      city: '',
      state: '',
      placeId: '',
      country: '',
    },
    bankDetails: {
      accountNumber: '',
      accountName: '',
      bankName: '',
      bankCode: '',
    },
    referralCode: '',
  },
  role: DISPATCHER,
  currentAccessRole: SENDER, // the currently fetched access role for the user
  access: [],
  isAnOperator: false,
};

const isOperatorAccess = (profile: Profile) =>
  !isEmpty(profile) ? profile.access.includes(OPERATOR) : false;

export const useGetProfile = (): Profile => {
  const queryClient = useQueryClient();

  const profile: Profile =
    queryClient.getQueryData(['user']) || defaultProfileEntry;

  const profileData = { ...profile, isAnOperator: isOperatorAccess(profile) };

  return !isEmpty(profile) ? profileData : defaultProfileEntry;
};

export type MapRoles = {
  [key in Roles]: Function;
};
