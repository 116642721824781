import { ElrModal } from 'ui-components';
import TipRiderDetails from './TipRiderDetails';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  trackingId: string;
}

const TipModal: React.FC<Props> = ({ isOpen, onClose, trackingId }) =>
  isOpen ? (
    <ElrModal
      width="w-10/12 md:w-480 px-4"
      height="h-7/12 md:h-fit"
      isOpen={isOpen}
      onClose={onClose}
    >
      <TipRiderDetails trackingId={trackingId} onClose={onClose} />
    </ElrModal>
  ) : null;

export default TipModal;
