import GenerateInvoice from 'app/components/GenerateInvoice';
import { useGetProfile } from 'app/hooks/user';
import { renderVerificationCode } from 'app/utilities/renderVerificationCode';
import { publicRoutes } from 'app/utilities/routes';
import { Request } from 'app/utilities/types/shared';
import CheckCircle from 'assets/images/CheckCircle.svg';
import CheckCircleYellow from 'assets/images/CircleCheckYellow.svg';
import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import * as styles from './ClassUtils';

export interface SingleTrackStatus {
  open: boolean;
  pending: boolean;
  accepted: boolean;
  collected: boolean;
  completed: boolean;
  closed: boolean;
  booking: boolean;
}

const SingleTrack: React.FC<{
  orderStatus: SingleTrackStatus;
  parcel: Request;
  trackingId: string;
  refetch: Function;
}> = ({ orderStatus, parcel, trackingId }) => {
  const { id: userId } = useGetProfile();

  const canSeeVerificationCode =
    parcel.deliverToInformation.find(
      (deliverTo) => deliverTo.trackingId === trackingId
    )?.requestOpenedBy == userId;

  const inQueueContent = (
    <>
      <div className="flex justify-between">
        <span className="text-base">In Queue</span>
        <span>
          <img src={CheckCircle} alt="check-circle" />
        </span>
      </div>
      <div className="flex flex-col mt-3 md:flex-row opacity-60">
        <p className="text-xs md:text-sm">Waiting to be assigned to Rider </p>
        <span className="text-xs cursor-pointer md:text-sm text-elr-green">
          &nbsp;(We&apos;ll notify you when its been assigned)
        </span>
      </div>
    </>
  );

  return (
    <>
      {(orderStatus.closed && (
        <div className={styles.ParcelClosed(orderStatus.closed)}>
          {inQueueContent}
        </div>
      )) ||
        (!orderStatus.accepted && (
          <div className={styles.ParcelClosed(orderStatus.closed)}>
            {inQueueContent}
          </div>
        ))}

      {canSeeVerificationCode && parcel.verificationCodes?.pickups ? (
        <div className={styles.ParcelClosed(orderStatus.accepted)}>
          <DeliveryVerificationCode
            title="Please provide the CODE to the rider on pickup"
            code={parcel.verificationCodes.pickups[trackingId]}
          />
        </div>
      ) : null}

      <div className={styles.OperatorDetailsContainer(orderStatus.accepted)}>
        <div className="flex justify-between">
          <span className="text-base">Rider&apos;s Details</span>
          {orderStatus.accepted && (
            <span>
              <img src={CheckCircle} alt="check-circle" />
            </span>
          )}
        </div>
        <div className="flex flex-col">
          {orderStatus.accepted ? (
            <>
              <div className="flex justify-between pt-4 text-sm opacity-60">
                <p>{parcel.designatedAgent?.firstname}</p>
              </div>

              {orderStatus.collected && (
                <div className="my-3 w-full bg-[#E6E7E9] bg-opacity-50 rounded py-3 px-3">
                  <p className="text-black opacity-60">
                    Your parcel has been picked up by the rider
                  </p>
                </div>
              )}
            </>
          ) : (
            <div className="text-sm text-elr-gray-400">
              Rider details will appear here
            </div>
          )}
        </div>
      </div>
      <div className={styles.IntransitContainer(orderStatus.collected)}>
        <div className="flex justify-between">
          <span className="text-base">In Transit</span>
          {orderStatus.collected && (
            <span>
              <img src={CheckCircle} alt="check-circle" />
            </span>
          )}
        </div>
        <div className="pt-4">
          {orderStatus.collected ? (
            <div className="text-sm opacity-60">
              Your parcel is on its way and should be delivered soon.
            </div>
          ) : (
            <div className="text-sm text-elr-gray-400">
              Transit information will appear here
            </div>
          )}
        </div>
      </div>
      {canSeeVerificationCode &&
      orderStatus.collected &&
      parcel.verificationCodes?.destinations ? (
        <div className={styles.ParcelClosed(orderStatus.completed)}>
          <DeliveryVerificationCode
            title="Please provide the CODE to the rider on delivery."
            code={parcel.verificationCodes.destinations[trackingId]}
          />
        </div>
      ) : null}
      <div className={styles.ParcelCompleted(orderStatus.completed)}>
        <div className="flex justify-between">
          <span
            className={`text-base ${
              !orderStatus.completed && 'text-elr-gray-500'
            }`}
          >
            Parcel Delivered !
          </span>
          {orderStatus.completed && (
            <div className="flex items-center">
              <span className="mr-5">
                <img src={CheckCircleYellow} alt="check-circle" />
              </span>
              <GenerateInvoice data={parcel} />
            </div>
          )}
        </div>
        <div className="pt-4">
          {orderStatus.completed ? (
            <>
              <div className="text-sm opacity-60">
                Your parcel has been delivered.
              </div>
              <div className="flex pt-4">
                <div className="mr-3">
                  <Link to={`${publicRoutes.rate}/${trackingId}`}>
                    <ElrButton
                      text="Rate your experience"
                      size="sm"
                      className="px-2 h-7 text-elr-black bg-elr-white-400"
                    />
                  </Link>
                </div>
                <div>
                  <ElrButton
                    text="Any Issues?"
                    size="sm"
                    className="w-20 h-6 md:w-36 md:h-7 text-elr-black bg-elr-white-400"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="text-sm text-elr-gray-400">Details to follow</div>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleTrack;

const DeliveryVerificationCode: React.FC<{ title: string; code: string }> = ({
  title,
  code,
}) => (
  <>
    <div className="flex justify-between">
      <span className="text-base">{title}</span>
    </div>
    <div className="flex items-center justify-center mt-3 gap-y-3">
      {renderVerificationCode(code)}
    </div>
  </>
);
