import PurpleVector from 'assets/images/policies-purple-vector.svg';
import YellowVector from 'assets/images/policies-yellow-vector.svg';

const Header = () => (
  <div className="">
    <div className="text-xl mt-12 mb-16 bg-elr-gray relative rounded-xl overflow-hidden">
      <img
        src={PurpleVector}
        alt=""
        className="absolute md:bottom-0 w-3/12 md:w-32 -bottom-2 -left-2 md:left-0"
      />
      <img
        src={YellowVector}
        alt=""
        className="absolute md:top-0 -right-2 md:right-0 w-3/12 md:w-32 -top-2"
      />
      <p className="md:text-5xl text-center py-16 text-3xl">
        {' '}
        Errandlr Delivery Policy
      </p>
    </div>
    <div className="text-base mt-16 text-elr-black-300">
      <p>
        Welcome to Errandlr! This Delivery Policy outlines the terms and
        conditions governing the delivery of goods and services you purchase
        through the Errandlr platform.
      </p>
    </div>
  </div>
);

export default Header;
