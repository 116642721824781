import { httpAcceptRequest } from 'app/api/requests';
import { InModalAssignRiderDisplay } from 'app/dispatcher/modules/components/requestItem/AgentSection';
import { useFetchInterestedRiders } from 'app/hooks/requests/agent';
import { useFetchRequestLocality } from 'app/hooks/requests/batch';
import { useGetProfile } from 'app/hooks/user';
import {
  Batch,
  NearbyAgentsInterface,
  PartnerDropdownOptions,
} from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { ElrModal, ElrPillButton } from 'ui-components';
import BatchRequestLocalityDisplay from './BatchRequestLocalityDisplay';
import { useGetBatchRequestLocalities } from './hooks';

interface BatchGetLocalityInterface {
  item: Batch;
  isAssignLoading: boolean;
  closeSideIn: () => void;
  refetch: () => void;
  refetchUpcomingBatchRequests: Function;
}

type areaInterface = {
  id: string;
  trackingId: string;
  pickupArea: { area: string };
  destinationArea: { area: string };
  agentsWithInterest: [];
  totalPickupAddresses: number;
  totalDeliverToInformation: number;
};

const BatchGetLocality: React.FC<BatchGetLocalityInterface> = ({
  item,
  isAssignLoading,
  closeSideIn,
  refetch,
  refetchUpcomingBatchRequests,
}) => {
  const requestData: any = useGetBatchRequestLocalities();
  const [getBatchLocalities, isLoadingLocality] = useFetchRequestLocality();
  const { id: userId, accountInformation } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoadingAssignRider, setIsLoadingAssignRider] =
    useState<boolean>(false);

  const normalizeData = (d: areaInterface) => ({
    id: d.id,
    name: `${d.pickupArea.area} to ${d.destinationArea.area}`,
    location: `${d.totalPickupAddresses} Pickups & ${d.totalDeliverToInformation} Dropoffs - ${d.agentsWithInterest} Interest`,
    disabled: false,
    trackingId: d.trackingId,
  });

  const getOptions = (payload: areaInterface[]) =>
    payload ? payload.map((p) => normalizeData(p)) : [];

  const options: PartnerDropdownOptions[] = [
    {
      id: '',
      name: 'Localities',
      disabled: true,
      location: '',
    },
    ...getOptions(requestData),
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const { data: agentsWithInterest } = useFetchInterestedRiders(
    userId,
    selectedOption.id,
    !!isModalOpen
  );

  const handleSelectOption = (option: any) => {
    if (option.disabled) return;
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const onTriggerModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClickDropdown = async () => {
    await getBatchLocalities({
      userId,
      pickupArea: item.area,
    });
  };

  const assignRequestToRider = async (rider: { id: string; name: string }) => {
    try {
      setIsLoadingAssignRider(true);
      const payload = {
        agentId: rider.id as string,
        dispatcherId: accountInformation.id,
        userId,
        requestId: selectedOption.id,
      };
      await httpAcceptRequest(payload);
      setIsLoadingAssignRider(false);
      setIsModalOpen(false);
      closeSideIn();
      refetch();
      return refetchUpcomingBatchRequests();
    } catch (error: any) {
      return setIsLoadingAssignRider(false);
    }
  };

  const renderInterestedRiderModal = (
    <ElrModal
      onClose={onTriggerModal}
      isOpen={isModalOpen}
      height="h-3/6"
      width="sm:w-430 w-85"
    >
      <p className="text-elr-black mt-4 mb-2">Interested riders</p>
      {agentsWithInterest.map((agent: NearbyAgentsInterface) => (
        <InModalAssignRiderDisplay
          key={agent.id}
          rider={agent}
          assignRequestToRider={assignRequestToRider}
          isBooking={!!isLoadingAssignRider}
          currentlyAssignedRider={''}
        />
      ))}
    </ElrModal>
  );

  const disableAssign = selectedOption.disabled;

  return (
    <div className="w-full flex flex-col md:flex-row my-7 justify-between items-center">
      {renderInterestedRiderModal}
      <div className="flex w-full md:w-8/12 items-center">
        <div className="w-24">Assign to</div>
        <BatchRequestLocalityDisplay
          dropdownOpen={dropdownOpen}
          selectedOption={selectedOption}
          handleSelectOption={handleSelectOption}
          onClickDropdown={onClickDropdown}
          isFetchingCompany={isLoadingLocality}
          setDropdownOpen={setDropdownOpen}
          options={options}
        />
      </div>
      <div className="w-full md:w-28 mt-5 md:mt-0">
        <ElrPillButton
          onClick={onTriggerModal}
          size="lg"
          spinnerColor="White"
          disabled={disableAssign}
          loading={isAssignLoading}
          text="Assign"
          className="bg-elr-black h-8 w-full text-white text-base flex items-center justify-center"
        />
      </div>
    </div>
  );
};

export default BatchGetLocality;
