import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios } from 'app/utilities/axios';
import { batchRequestTabs } from 'app/utilities/helpers';
import {
  PartnerInterface,
  Request,
  RequestTypes,
} from 'app/utilities/types/shared';
import { toast } from 'react-toastify';
import { BatchDataInterface } from './d';
import { AcceptRequestResponse } from './types';

interface RequestParams {
  userId: string;
  nextPage: number;
  currentRole?: string;
  roleId?: string;
}

export const httpGetNewCommerceBatches = async ({
  userId,
  nextPage,
}: RequestParams): Promise<BatchDataInterface> => {
  try {
    const response = await axios.get(`/commerce/${userId}/batch`, {
      params: { page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

interface InTransitParams {
  userId: string;
  nextPage: number;
}

export const httpGetBatchesInTransit = async ({
  userId,
  nextPage,
}: InTransitParams): Promise<BatchDataInterface> => {
  try {
    const response = await axios.get(
      `/commerce/${userId}/batch-upcoming-deliveries`,
      {
        params: { page: nextPage },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetPartnerBatchUpcomingDeliveries = async ({
  userId,
  dispatcherId,
  nextPage,
  batchType,
}: {
  userId: string;
  dispatcherId: string;
  nextPage: number;
  batchType: string;
}): Promise<BatchDataInterface> => {
  try {
    let link = '';
    if (batchType === batchRequestTabs[0].id) {
      link = 'sorted/batches';
    } else {
      link = 'partner/destination-deliveries'; // batchRequestTabs[2].id;
    }
    const response = await axios.get(`/batch/${userId}/${link}`, {
      params: { page: nextPage, dispatcherId },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetSenderBatchUpcomingDeliveries = async ({
  userId,
  nextPage,
}: {
  userId: string;
  dispatcherId: string;
  nextPage: number;
  batchType: string;
}): Promise<BatchDataInterface> => {
  try {
    const response = await axios.get(`/sender/${userId}/batch`, {
      params: { page: nextPage, status: RequestTypes.sorted },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

// mark as completed
export const httpMarkBatchDeliveryAsCompleted = async ({
  userId,
  trackingId,
}: {
  userId: string;
  trackingId: string;
}) => {
  try {
    const response = await axios.put(
      `/batch/${userId}/delivered`,
      {
        trackingId,
      },
      SupportHeader()
    );
    toast.success('Delivery marked as completed', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpProcessBatchRequest = async ({
  userId,
  roleId,
  batchId,
}: {
  userId: string;
  roleId: string;
  batchId: string;
}) => {
  try {
    const response = await axios.post<
      {
        roleId: string;
        batchId: string;
        agentId: string;
      },
      AcceptRequestResponse
    >(
      `/batch/${userId}/manual-processing?roleId=${roleId}`,
      {
        batchId,
      },
      SupportHeader()
    );
    toast.success('Batch processed successfully 🥳.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpProcessAPIBatch = async ({
  userId,
  identifier,
  roleId,
}: {
  userId: string;
  identifier: string;
  roleId: string;
}) => {
  try {
    const response = await axios.put(
      `/batch/${userId}/sv-batch?identifier=${identifier}&roleId=${roleId}`,
      {},
      SupportHeader()
    );
    toast.success('Batch processed successfully 🥳.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchCompletedBatches = async ({
  userId,
  role,
  roleId,
  nextPage,
}: {
  userId: string;
  role: string;
  roleId: string;
  nextPage: number;
}): Promise<BatchDataInterface> => {
  try {
    const response = await axios.get(`/batch/${userId}/batches`, {
      params: { status: 'completed', role, roleId, page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

// mark batch as completed by Operator
export const httpOperatorMarkBatchAsCompleted = async ({
  userId,
  batchId,
  agentId,
}: {
  userId: string;
  batchId: string;
  agentId: string;
}) => {
  try {
    const response = await axios.patch<
      { batchId: string },
      AcceptRequestResponse
    >(
      `/batch/${userId}/operator/batches`,
      {
        batchId,
        agentId,
      },
      SupportHeader()
    );
    toast.success('Batch has been marked as complete 🥳.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpFetchOperatorBatches = async ({
  userId,
  nextPage,
  batchType,
}: {
  userId: string;
  nextPage: number;
  batchType?: string;
}): Promise<BatchDataInterface> => {
  const switchBatchPayload: any = {
    [batchRequestTabs[0].id]: {
      page: nextPage,
      status: RequestTypes.accepted,
    },
    [batchRequestTabs[1].id]: {
      page: nextPage,
      status: RequestTypes.accepted,
      getDestinationBatches: true,
    },
  };

  const defaultParams = {
    page: nextPage,
    status: 'open',
  };

  const dynamicParams = batchType
    ? switchBatchPayload[batchType]
    : defaultParams;

  try {
    const response = await axios.get(`/batch/${userId}/operator/batches`, {
      params: dynamicParams,
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export interface PaginatedPartnerInterface {
  docs: Array<PartnerInterface>;
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}

export const httpFetchPartners = async ({
  userId,
  state,
  country,
  nextPage,
  location,
}: any): Promise<PaginatedPartnerInterface> => {
  try {
    const response = await axios.get(`/operator/${userId}/fetch-partners`, {
      params: {
        state,
        country,
        page: nextPage,
        location,
      },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpFetchRequestLocalities = async ({
  userId,
  pickupArea,
}: {
  userId: string;
  pickupArea: string;
}): Promise<Request[]> => {
  try {
    const response = await axios.get(`/request/${userId}/batch-localities`, {
      params: {
        pickupArea,
      },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpFetchActivePartners = async ({
  userId,
}: {
  userId: string;
}): Promise<any> => {
  try {
    const response = await axios.get(`/batch/${userId}/get-partner-counts`, {
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

// Operator Assign batch request to partner
export const httpOperatorAssignBatchRequest = async ({
  userId,
  batchId,
  dispatcherId,
}: {
  userId: string;
  batchId: string;
  dispatcherId: string;
}): Promise<any> => {
  try {
    const response = await axios.post(
      `/batch/${userId}/operator/batches`,
      {
        batchId,
        dispatcherId,
      },
      SupportHeader()
    );
    toast.success('Batch was assigned successfuly 🥳.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
export const httpOperatorAssignRiderBatchRequest = async ({
  userId,
  batchId,
  dispatcherId,
}: {
  userId: string;
  batchId: string;
  dispatcherId: string;
}): Promise<any> => {
  try {
    const response = await axios.put(
      `/batch/${userId}/operator/batches`,
      {
        batchId,
        dispatcherId,
      },
      SupportHeader()
    );
    toast.success('Batch assignment successful 🥳.', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpMarkDestinationBatchAsCompleted = async ({
  userId,
  batchId,
  dispatcherId,
}: {
  userId: string;
  batchId: string;
  dispatcherId: string;
}) => {
  try {
    const response = await axios.patch<
      {
        batchId: string;
      },
      AcceptRequestResponse
    >(
      `/batch/${userId}/partner/destination-deliveries`,
      {
        batchId,
        dispatcherId,
      },
      SupportHeader()
    );
    toast.success('Batch marked as completed successfully 🥳.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpMarkSortedBatchAsCompleted = async ({
  userId,
  batchId,
  dispatcherId,
}: {
  userId: string;
  batchId: string;
  dispatcherId: string;
}) => {
  try {
    const response = await axios.put<
      {
        batchId: string;
      },
      AcceptRequestResponse
    >(
      `/batch/${userId}/sorted/batches`,
      {
        batchId,
        dispatcherId,
      },
      SupportHeader()
    );
    toast.success('Batch marked as completed successfully 🥳.', {
      position: 'top-right',
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};
