import React from 'react';
import Modal from 'react-modal';
import { modalContainerClass } from './ClassUtils';

export const customStyles = {
  content: {
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '15px',
  },
};

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  height?: string;
  width?: string;
  appendModal?: JSX.Element;
  onClose: () => void;
  children: React.ReactNode;
}

export const ElrModal: React.FC<ModalProps> = ({
  isOpen,
  height,
  width,
  appendModal,
  onClose,
  children,
}) =>
  isOpen ? (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      className={modalContainerClass(height, width)}
      overlayClassName="bg-elr-black bg-opacity-60 overflow-y-scroll fixed inset-0 z-100"
    >
      <div
        className="cursor-pointer -mt-10 mr-4 sm:-mr-5 text-elr-white font-bold rounded w-50 absolute right-0"
        onClick={onClose}
      >
        x
      </div>
      <div className="h-full px-7 md:px-8 overflow-auto no-scrollbar">
        {children}
      </div>
      {appendModal}
    </Modal>
  ) : null;
