const {
  VITE_APP_GOOGLE_API_KEY: GOOGLE_API_KEY,
  VITE_APP_API_URL: API_URL,
  VITE_APP_ENV: ENV,
  VITE_APP_SENTRY_DSN: SENTRY_DSN,
  VITE_APP_RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY,
  VITE_APP_ERRANDLR_REDIRECT_URL: ERRANDLR_REDIRECT_URL,
  VITE_APP_PAYSTACK_PUBLIC_KEY: PAYSTACK_PUBLIC_KEY,
} = import.meta.env;

export {
  API_URL,
  ENV,
  ERRANDLR_REDIRECT_URL,
  GOOGLE_API_KEY,
  PAYSTACK_PUBLIC_KEY,
  RECAPTCHA_SITE_KEY,
  SENTRY_DSN,
};

export const staticLocationTypes: { [key: string]: string } = {
  pickupLocation: 'pickupLocation',
  dropoffLocations: 'dropoffLocations',
};

export const MAX_ORDER_BULK_ESTIMATE = 4;
export const MAX_BULK_ORDER = 10;

export const SINGLEID = 'single';
export const BATCHID = 'batch';

export const BOOKING = 'booking';

export const DESTINATION = 'destination';

export const BATCH_ESTIMATE_KEY = 'batchEstimate';
export const ESTIMATE_KEY = 'estimate';

export const TAG_PT = 'PT';
export const TAG_LT = 'LT';

export const AVAILABLE_RIDERS = 'Available Riders';
export const YOUR_RIDERS = 'Your Riders';
export const ALL = 'All';
export const ADD = 'add';
export const NON_PARTNERS = 'Non-Partners';
export const PARTNERS = 'Partners';

export const SORTING_STATION = 'sorting_station';
export const FULFILMENT_CENTER = 'fulfilment_center';

export const PackageTypeCode = {
  DEFAULT: '000',
  SMALL_PARCEL: '001',
  LARGE_PARCEL: '002',
  DOCUMENT: '003',
  FOOD: '004',
};

export const PackageTypeLabel = {
  DEFAULT: 'Default',
  SMALL_PARCEL: 'Small Parcel',
  LARGE_PARCEL: 'Large Parcel',
  DOCUMENT: 'Document',
  FOOD: 'Food',
};

export const LockerSizes = [
  {
    sizeid: '1',
    sizename: 'Small',
    description: 'Phones, Envelopes etc',
    widthcm: '35',
    heightcm: '10',
    depthcm: '49',
  },
  {
    sizeid: '2',
    sizename: 'Medium',
    description: 'Shoes, Nestle pack 60cl etc',
    widthcm: '35',
    heightcm: '20',
    depthcm: '49',
  },
  {
    sizeid: '3',
    sizename: 'Large',
    description: 'Noodle Carton etc',
    widthcm: '35',
    heightcm: '30',
    depthcm: '49',
  },
  {
    sizeid: '4',
    sizename: 'XLarge',
    description: 'Printer, Microwave etc',
    widthcm: '55',
    heightcm: '55',
    depthcm: '49',
  },
];
