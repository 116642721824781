import { ElrTextAreaInput } from 'ui-components';
import { ElrPillButton } from 'ui-components/components/ElrButton';

export const CloseRequest = () => (
  <div className="flex flex-col gap-5 pt-3">
    <h1 className="text-16 leading-5">Reason for closing delivery</h1>
    <ElrTextAreaInput
      placeholder="Reason for closing request"
      className="py-2 px-4 rounded-xl"
      onChange={() => {}}
    />
    <ElrPillButton
      text="Close Request"
      className="w-full bg-elr-black text-white h-7"
    />
  </div>
);
