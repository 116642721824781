import { CenterLogoHeader } from 'app/components/Header';
import BankInfoForm from 'app/components/settings/BankInfoForm';
import React from 'react';
import { ElrPageTitle } from 'ui-components';

interface Props {
  stepIndex: number;
  onChangeStep: (index: number) => void;
}

const BankInfo: React.FC<Props> = ({ stepIndex, onChangeStep }) => (
  <div>
    <ElrPageTitle title="Errandlr - Bank-Information" />
    <div className="flex flex-col items-center">
      <CenterLogoHeader />
      <div className="px-7 md:px-10 h-full w-11/12 md:w-430 bg-elr-white-400 rounded-2xl flex flex-col mb-12">
        <BankInfoForm onChangeStep={onChangeStep} stepIndex={stepIndex} />
        <div className="mb-10 md:mb-12" />
      </div>
    </div>
  </div>
);

export default BankInfo;
