import SlideIn from 'app/components/SlideIn/SlideIn';
import { PencilIcon } from 'app/components/SvgAssets';
import { useGetProfile } from 'app/hooks/user';
import { COMMERCE } from 'app/utilities/roles';
import { useState } from 'react';
import { ElrButton, ElrLocationSelect, ElrPhoneInputFlat } from 'ui-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  phone: Yup.string().required('Phone is required'),
  fullAddress: Yup.object({
    label: Yup.string().required('Location is required'),
  }),
});

const BusinessDetails: React.FC<{ accountName: string | undefined }> = ({
  accountName,
}) => {
  const {
    accountInformation: { mainAddress },
    email,
    phone,
    currentAccessRole,
    firstname,
  } = useGetProfile();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [telephone, setTelephone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });

  const { handleSubmit, values, setFieldValue, isValid } = useFormik({
    initialValues: {
      phone: phone || '',
      fullAddress: {
        id: mainAddress?.placeId || '',
        label: mainAddress?.fullAddress || '',
        value: mainAddress?.placeId || '',
      },
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      setIsModalOpen(false);
    },
  });

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setTelephone((prev) => ({ ...prev, ...payload }));
    setFieldValue('phone', payload.formattedNumber);
  };

  const renderEditInfo = (
    <SlideIn
      close={() => setIsModalOpen(false)}
      shown={isModalOpen}
      leftMarginOffset="md:ml-[60%]"
    >
      <p className="text-center pb-6 text-xl">Business Information</p>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-col gap-5 py-8 mx-auto mb-10 bg-elr-white-400">
          <div className="w-full">
            <p className="text-base pb-2 text-elr-black-400">
              Business Phone Number
            </p>
            <ElrPhoneInputFlat
              containerClass="w-full flex"
              className="border-none bg-elr-gray h-12"
              value={telephone.mobile}
              onChange={onPhoneInputChange}
              placeholder="Phone Number"
            />
          </div>
          <div>
            <p className="text-base pb-2 text-elr-black-400">
              Business Address
            </p>
            <ElrLocationSelect
              className="bg-white pr-2 !h-12 !mb-3"
              currentValue={values.fullAddress?.label}
              onChange={(value) => setFieldValue('fullAddress', value)}
              placeholder="Business Location"
            />
          </div>
        </div>
        <ElrButton
          text="Save Changes"
          className="w-full bg-elr-black text-white rounded-md text-base mt-24"
          submit
          disabled={!isValid}
        />
      </form>
    </SlideIn>
  );

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <div
        className="absolute right-5 top-6 text-base px-8 py-3 text-elr-black flex gap-3 rounded-md items-center justify-center cursor-pointer bg-elr-gray text-center"
        onClick={() => setIsModalOpen(true)}
      >
        <p>Edit</p>
        <PencilIcon />
      </div>

      {isModalOpen && renderEditInfo}

      <p className="text-xl pb-7">Business Information</p>
      <div className="grid grid-cols-1 md:grid-rows-3 md:grid-flow-col gap-7 md:gap-0">
        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Business Name</p>
          <p className="text-elr-black text-base">{accountName}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-base text-elr-black-400">Business Email Address</p>
          <p className="text-elr-black pt-2 text-base">{email}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-base pb-2 text-elr-black-400">Business Address</p>
          <div className="text-elr-black text-base">
            {mainAddress?.addressNumber}{' '}
            {mainAddress?.fullAddress || mainAddress?.streetName}
            <br />
            {mainAddress?.localGovt || mainAddress?.localGovtArea}{' '}
            {mainAddress?.city || mainAddress?.registeredCity}
            <br />
            {mainAddress?.state} {mainAddress?.country}
          </div>
        </div>

        <div className="flex flex-col">
          <p className="text-base text-elr-black-400">Business Phone numbers</p>
          <div className="flex pt-2 pb-2 text-base">
            <p className="pr-4 pt-1 text-elr-black text-base">{phone}</p>
          </div>
        </div>
        {currentAccessRole === COMMERCE && (
          <div className="text-base text-elr-black-400">
            <h3>Representative Name</h3>
            <p className="text-elr-black text-base pt-2">{firstname}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessDetails;
