import React from 'react';
import { Link } from 'react-router-dom';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
// import { useLogout } from 'app/hooks/auth';
// import { useGetProfile } from 'app/hooks/user';
// import { RenderUnAuthMenuListLinks } from './Navbar';

interface IProps {
  onClose: () => void;
  topPositionClassName?: string;
  navLinks: { path: string; label: string }[];
}
export const HamburgerMenu: React.FC<IProps> = ({
  onClose,
  topPositionClassName = 'top-8',
  navLinks,
}) => (
  // const user = useGetProfile();
  // const [sendLogout] = useLogout();

  <div
    className={`p-4 rounded-xl bg-white z-10 absolute right-2 left-2 mx-2 ${topPositionClassName} shadow-md`}
  >
    <div className="relative flex justify-end">
      <ClearRoundedIcon fontSize="medium" onClick={onClose} />
    </div>
    <div className="mt-4 flex-col flex align-middle items-center">
      {navLinks.map((link) => (
        <div key={link.path} className="py-3 px-1 mb-1 cursor-pointer">
          <Link to={link.path}>
            <p>{link.label}</p>
          </Link>
        </div>
      ))}
    </div>
    <div className="justify-center flex gap-3 mt-12 mb-8">
      <button
        className="bg-elr-gray px-8 py-2 flex cursor-pointer items-center rounded-full"
        type="button"
      >
        Log In
      </button>
      <button
        className="bg-elr-black cursor-pointer text-white px-8 py-2 flex items-center rounded-full"
        type="button"
      >
        Sign Up
      </button>
    </div>
  </div>
);
