import AnalyticsOutlined from '@material-ui/icons/BarChartOutlined';
import EventAvailableOutlined from '@material-ui/icons/EventAvailableOutlined';
import { MobileHeader } from 'app/components/Header';
import { useGetProfile } from 'app/hooks/user';
import { OperatorView } from 'app/utilities/ComponentView';
import { COMMERCE, DISPATCHER, SENDER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import account from 'assets/images/accountIcon.svg';
import agentIcon from 'assets/images/agentsIcon.svg';
import apiIcon from 'assets/images/apiIcon.svg';
import billingInformationIcon from 'assets/images/billingInformationIcon.svg';
import companyIcon from 'assets/images/companyIcon.svg';
import paymentIcon from 'assets/images/moneyIcon.svg';
import twoPeopleIcon from 'assets/images/two-people-icon.svg';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  ECommerceIcon,
  LogisticsBike,
  ProfileOutline,
  SettingsOutline,
  StackedOutline,
  WalletOutline,
} from '../SvgAssets';
import * as styles from './ClassUtils';

const ProfileNavigationMenu: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isAnOperator, currentAccessRole, role } = useGetProfile();
  const currentRole = currentAccessRole || role;
  const navigation: { [key: string]: JSX.Element } = {
    commerce: (
      <>
        <NavLink to={`${privateRoutes.dashboard(COMMERCE)}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <LogisticsBike fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Logistics
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${COMMERCE}/${privateRoutes.ecommerce}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-3 gap">
                <ECommerceIcon fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  E-Commerce
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${COMMERCE}/bulk-estimate`}>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <StackedOutline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Bulk Order
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${COMMERCE}/${privateRoutes.billingRecord}`}>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <WalletOutline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Transactions
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${COMMERCE}/${privateRoutes.company}`}>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <ProfileOutline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Account
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${COMMERCE}/${privateRoutes.commerceSettings}`} replace>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <SettingsOutline fill={isActive ? '#000000' : '#8E8E93'} />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  Settings
                </p>
              </div>
            </div>
          )}
        </NavLink>

        <NavLink to={`/${COMMERCE}/${privateRoutes.developer}`}>
          {({ isActive }) => (
            <div className={styles.navlinkStyle(isActive)}>
              <div className="flex gap-x-4 gap">
                <img src={apiIcon} alt="icon" />
                <p
                  className={`content-center text-lg leading-5 ${isActive ? 'text-black' : 'text-elr-black-400'}`}
                >
                  API
                </p>
              </div>
            </div>
          )}
        </NavLink>
      </>
    ),

    dispatcher: (
      <>
        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.profile}`}
          replace
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img src={account} alt="icon" />
          </span>
          <p>Profile</p>
        </NavLink>

        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.company}`}
          replace
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img src={companyIcon} alt="icon" />
          </span>
          <p>Company</p>
        </NavLink>

        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.payment}`}
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-4 md:mb-0 md:mr-3">
            <img
              src={paymentIcon}
              alt="icon"
              className="text-elr-green text-opacity-90"
            />
          </span>
          <p>Payments</p>
        </NavLink>

        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.agents}`}
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img
              src={agentIcon}
              alt="icon"
              className="text-elr-green text-opacity-90"
            />
          </span>
          <p>Agents</p>
        </NavLink>

        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.availability}`}
          className={(navData) => styles.routeContainer(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-2">
            {/* <img
              src={agentIcon}
              alt="icon"
              className="text-elr-green text-opacity-90"
            /> */}
            <EventAvailableOutlined
              fontSize="small"
              className="text-elr-green text-opacity-90"
            />
          </span>
          <p>Availability</p>
        </NavLink>
        <NavLink
          to={`/${DISPATCHER}/${privateRoutes.analytics}`}
          className={(navData) => styles.routeContainer(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-2">
            <AnalyticsOutlined
              className="text-elr-green text-opacity-90"
              fontSize="small"
            />
          </span>
          <p>Analytics</p>
        </NavLink>
        <NavLink
          to={`/${DISPATCHER}/bulk-estimate`}
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img src={account} alt="icon" />
          </span>
          <p>Bulk Order</p>
        </NavLink>
        <OperatorView isOperator={isAnOperator}>
          <NavLink
            to={`/${DISPATCHER}/${privateRoutes.partners}`}
            className={(navData) => styles.routeContainer(navData.isActive)}
          >
            <span className="mb-2.5 md:mb-0 md:mr-3">
              <img
                src={twoPeopleIcon}
                alt="icon"
                className="w-4 text-elr-green text-opacity-90"
              />
            </span>
            <p>Dispatchers and Partners</p>
          </NavLink>
        </OperatorView>
      </>
    ),

    sender: (
      <>
        <NavLink
          to={`/${SENDER}/${privateRoutes.profile}`}
          replace
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img src={account} alt="icon" />
          </span>
          <p>Profile</p>
        </NavLink>
        <NavLink
          to={`/${SENDER}/bulk-estimate`}
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img src={account} alt="icon" />
          </span>
          <p>Bulk Order</p>
        </NavLink>
        <NavLink
          to={`/${SENDER}/${privateRoutes.billingRecord}`}
          className={(navData) => styles.navlinkStyle(navData.isActive)}
        >
          <span className="mb-2.5 md:mb-0 md:mr-3">
            <img src={billingInformationIcon} alt="icon" />
          </span>
          <p>Billing information</p>
        </NavLink>
      </>
    ),
  };

  return (
    <div className="flex flex-col md:h-full">
      <div className="w-full overflow-x-hidden md:w-72 md:h-full md:fixed bg-elr-white-400">
        <div className="md:mx-auto">
          <MobileHeader
            linkTo={privateRoutes.dashboard(currentRole)}
            className="!justify-center"
          />
          <div>
            <div className="justify-between hidden w-11/12 mx-auto mt-4 md:flex md:w-full md:flex-col md:mt-0 md:px-7">
              {navigation[currentRole]}
            </div>
          </div>
        </div>
      </div>
      <div className="h-full overflow-y-auto bg-white md:ml-72">{children}</div>
    </div>
  );
};

export default ProfileNavigationMenu;
