import Logo from 'assets/images/logo.svg';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  linkTo: string;
}

const EstimatesLogo: React.FC<IProps> = ({ linkTo }) => (
  <div className="pl-16 pt-10 pb-14 hidden md:inline-block">
    <Link to={linkTo}>
      <img className="md:inline" src={Logo} alt="logo" />
    </Link>
  </div>
);

export default EstimatesLogo;
