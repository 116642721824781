import { httpVerifyRequestTransaction } from 'app/api/requests';
import { publicRoutes } from 'app/utilities/routes';
import logo from 'assets/images/logo.svg';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

const VerifyBilling: React.FC = () => {
  const location = useLocation();
  const history = useNavigate();

  const search = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const payload = {
    reqRef: search.reference as string,
  };

  const makeRequest = async () => {
    try {
      const response = await httpVerifyRequestTransaction(payload);
      if (response.status) {
        // it passed, redirect to success page,
        const redirectUrl = `${publicRoutes.dispatchDetails}/${search.request}?track=${search.reference}`;
        history(redirectUrl);
      } else {
        // it failed, redirect to failed page
        history(publicRoutes.failedBilling);
      }
    } catch (error) {
      /** no op - but important to log this error to slack or anywhere as to why it failed */
      history(publicRoutes.failedBilling);
    }
  };
  useEffect(() => {
    if (payload.reqRef) {
      makeRequest();
    } else {
      history('/notfound');
    }
  });

  return (
    <div>
      <img
        className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
        src={logo}
        alt="loading"
        title="loading"
      />
      <p className="fixed top-2/3 left-1/2 -translate-y-1/2 -translate-x-1/2">
        Verifying Transaction...please wait
      </p>
    </div>
  );
};

export default VerifyBilling;
