import React, { useState } from 'react';
import { onPhoneInput } from 'ui-components/index';
import { InputDestinationProps } from 'app/utilities/types/dispatcherTypes';

export const ElrDestinationPhoneInput: React.FC<InputDestinationProps> = ({
  error,
  numberValid,
  value,
  onChange,
  disabled,
  className = '',
}) => {
  const [selectValue, setSelectValue] = useState('+234');

  const onChangeSelect = (val: string) => {
    onPhoneInput('', onChange, val);
    setSelectValue(val);
  };

  return (
    <div
      className={`w-full mb-3 rounded border flex p-2 ${
        error ||
        (value && !numberValid ? 'border-elr-error' : 'border-elr-border')
      }`}
    >
      <select
        value={selectValue}
        onChange={(e) => onChangeSelect(e.target.value)}
        className={`${className} w-0.53 focus:outline-none  text-elr-black pl-1.5 text-lg text-opacity-40 font-medium`}
      >
        <option value="+234">+234</option>
      </select>
      <input
        type="number"
        value={value}
        onChange={(e) => onPhoneInput(e.target.value, onChange, selectValue)}
        placeholder="80 (670) 000 00"
        disabled={disabled}
        className={`${className} h-10 w-full bg-none outline-none border-0 ml-2 pl-2 border-solid border-elr-black border-opacity-40  text-lg`}
      />
    </div>
  );
};
