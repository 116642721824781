import { httpGetDirectProductsList } from 'app/api/sender';
import { ProductsListData } from 'app/utilities/types/shared';
import LocationIcon from 'assets/images/location.svg';
import Logo from 'assets/images/logo.svg';
import NikeShoe from 'assets/images/nikeShoes.svg';
import SearchIcon from 'assets/images/search.svg';
import ShopLogo from 'assets/images/shop.svg';

import React, { useCallback, useEffect, useState } from 'react';
import {
  DropdownIndicatorProps,
  GroupBase,
  NoticeProps,
  OptionProps,
  Props,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';

export const CustomerEstimateHeader: React.FC<{
  companyName: string;
  companyAddress: string;
}> = ({ companyAddress, companyName }) => (
  <div className="flex flex-col items-center gap-y-3">
    <div className="p-2 border rounded-full border-elr-black">
      <img src={ShopLogo} alt="shop logo" className="w-9" />
    </div>
    <h2 className="text-2xl font-medium">{companyName}</h2>
    <p className="flex flex-row items-center justify-center text-sm gap-x-1">
      <img src={LocationIcon} alt="map icon" className="w-3.5" />
      <span className="opacity-60">{companyAddress}</span>
    </p>
  </div>
);

export const CustomerEstimateFooter: React.FC = () => (
  <div className="relative bottom-0 flex flex-col items-center gap-y-1 top-10 opacity-1">
    <p className="text-sm font-medium opacity-40"> Powered by</p>
    <img src={Logo} alt="errandlr logo" className="w-28 opacity-40" />
  </div>
);

const IndicatorSeparator = () => null;

const DropdownIndicator = (
  props: JSX.IntrinsicAttributes &
    DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => {
  const { hasValue } = props;
  if (hasValue) {
    return null;
  }

  return (
    <components.DropdownIndicator {...props}>
      <div className="text-sm">
        <img alt="search icon" src={SearchIcon} className="w-5" />
      </div>
    </components.DropdownIndicator>
  );
};

const Option = ({ children, ...props }: OptionProps<any, any>) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between mb-2 cursor-pointer">
        <div className="flex items-center gap-x-4">
          <img alt="product" src={NikeShoe} />
          <div>
            <h4 className="text-elr-black text-opacity-70">{data.label}</h4>
            <p className="text-elr-purple">#{data.id}</p>
          </div>
        </div>
        <p>NGN 25,000</p>
      </div>
    </components.Option>
  );
};

const NoOptionsMessage = (props: NoticeProps) => {
  const {
    selectProps: { inputValue },
  } = props;
  if (inputValue.length >= 1) {
    return (
      <components.NoOptionsMessage {...props}>
        Keep Typing...
      </components.NoOptionsMessage>
    );
  }
  return (
    <components.NoOptionsMessage {...props}>
      Start Searching
    </components.NoOptionsMessage>
  );
};

interface ProductProps {
  currentValue?: string;
}

export const ElrProductSelect: React.FC<Props & ProductProps> = ({
  className,
  currentValue,
  ...other
}) => {
  const value = currentValue
    ? { label: currentValue, value: currentValue }
    : null;

  const labelID = `label-for-${other.placeholder}`;

  return (
    <AsyncSelect
      {...other}
      value={value}
      className={`${className} mb-4`}
      isClearable
      aria-labelledby={labelID}
      cacheOptions
      loadOptions={fetchItems}
      components={{
        IndicatorSeparator,
        DropdownIndicator,
        Option,
        NoOptionsMessage,
      }}
      styles={{
        container: (base) => ({
          ...base,
          backgroundColor: '#F7F7F7',
        }),
        control: (base) => ({
          ...base,
          backgroundColor: '#F7F7F7',
          border: 'none',
          boxShadow: 'none',
          height: 50,
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: '#F7F7F7',
          border: 'none',
          boxShadow: 'none',
          marginTop: 0,
          borderTop: '0.5px solid rgba(196, 196, 196, 0.3)',
        }),
        option: (base) => ({
          ...base,
          color: '#081120',
          backgroundColor: '#F7F7F7',
          ':hover': { backgroundColor: '#F1F1F1' },
          textAlign: 'left',
        }),
      }}
    />
  );
};

const fetchItems = (
  input: string
): Promise<{ label: string; value: string; id: string }[]> =>
  new Promise((resolve) => {
    console.log(`fetching items for ${input}`);
    setTimeout(() => {
      resolve([
        { value: 'one', label: 'Nike Dun Shoe', id: '1676900LR' },
        { value: 'two', label: 'Nike Dun Shoe', id: '2676900LR' },
        { value: 'three', label: 'Nike Dun Shoe', id: '4676900LR' },
        { value: 'four', label: 'Nike Dun Shoe', id: '5676900LR' },
        { value: 'five', label: 'Nike Dun Shoe', id: '6676900LR' },
      ]);
    }, 1000);
  });

export const useFetchDirectProductsList = (userId: string) => {
  const [productsListData, setProductsListData] = useState<
    ProductsListData | undefined
  >();

  const fetchProductList = useCallback(
    async (nextPage: number) => {
      try {
        const result = await httpGetDirectProductsList({
          userId,
          nextPage,
        });
        setProductsListData(result);
      } catch (err: any) {
        /** no op */
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchProductList(1);
  }, [fetchProductList]);

  return { ...productsListData, fetchProductList };
};
