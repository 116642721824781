import SlideIn from 'app/components/SlideIn/SlideIn';
import Login from 'app/modules/login/Login';
import { AuthModalInterface, PagesState } from '../modules/authOptionsModal';

interface LoginInterface extends AuthModalInterface {
  setCurrentScreen?: (state: PagesState) => void;
  showSlideIn: boolean;
  setShowSlideIn: (state: boolean) => void;
}

const LoginSlideIn: React.FC<LoginInterface> = ({
  showSlideIn,
  setShowSlideIn,
  setCurrentScreen,
}) => (
  <SlideIn shown={showSlideIn} close={() => setShowSlideIn(!showSlideIn)}>
    <Login slideInMode setCurrentScreen={setCurrentScreen} />
  </SlideIn>
);

export default LoginSlideIn;
