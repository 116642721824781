import SlideIn from 'app/components/SlideIn/SlideIn';
import CreateAccount from 'app/modules/registration';
import { AuthModalInterface, PagesState } from '../modules/authOptionsModal';

interface CreateAccountInterface extends AuthModalInterface {
  setCurrentScreen?: (state: PagesState) => void;
}

const CreateAccountSlideIn: React.FC<CreateAccountInterface> = ({
  showSlideIn,
  setShowSlideIn,
  setCurrentScreen,
}) => (
  <SlideIn
    shown={showSlideIn}
    close={() => setShowSlideIn(!showSlideIn)}
    overflow
  >
    <CreateAccount slideInMode setCurrentScreen={setCurrentScreen} />
  </SlideIn>
);

export default CreateAccountSlideIn;
