import { Divider } from '@material-ui/core';
import { httpCreateMarketProducts } from 'app/api/commerce';
import {
  httpGetBatchEstimate,
  httpProcessBulkEstimate,
} from 'app/api/estimate';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import { currencyFormatter } from 'app/utilities/helpers';
import { mobileNumberFormatter } from 'app/utilities/phoneUtils';
import CloudUpload from 'assets/images/CloudUpload.svg';
import phone from 'phone';
import { useState } from 'react';
import {
  formatFileSize,
  useCSVDownloader,
  useCSVReader,
} from 'react-papaparse';
import { toast } from 'react-toastify';
import { ElrButton, ElrModal, ElrPageTitle } from 'ui-components';
import Preview from './Preview';
import { DEFAULT_REMOVE_HOVER_COLOR, dataKeys, sampleData } from './utils';

interface CreateMarketProducts {
  childItemRequired: any;
  childItemQuantity: any;
  childItemPrice: any;
  childItemInStock: any;
  cuisine: string;
  categoryName: string;
  categoryDescription: string;
  categoryImage: string;
  itemName: string;
  itemPrice: number;
  itemDescription: string;
  itemImage: string;
  subItemName: string;
  subItemImage: string;
  subItemDescription: string;
  subItemQuantity: number;
  subItemPrice: number;
  subItemInStock: boolean;
}

const BulkEstimate: React.FC = () => {
  const {
    id: userId,
    role,
    currentAccessRole,
    accountInformation: { id: roleId = '' } = {},
  } = useGetProfile();
  const [isPreview, setIsPreview] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDone, setIsDone] = useState(false);
  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [processedData, setProcessedData] = useState<{
    [key: string]: { [key: string]: string }[];
  } | null>(null);
  const [rawData, setRawData] = useState<{ [key: string]: string }[] | null>(
    null
  );
  const [estimateData, setEstimateData] = useState<{
    estimate: number;
    geoId: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const currentRole = currentAccessRole || role;

  const closeModal = () => {
    setUploadModal(false);
    setErrorMessage('');
    setCount(0);
  };

  const incrementCount = () => {
    setCount((c) => {
      if (c < numberOfRecords - 1) {
        return c + 1;
      }
      return c + 1;
    });
  };

  const onUploaded = (
    payload: { data: { [key: string]: string }[]; errors: string[] },
    _: File
  ) => {
    setNumberOfRecords(payload.data.length);
    setUploadModal(true);
    processData(payload.data);
  };

  const onMarketRunsCSVUploaded = (
    payload: { data: CreateMarketProducts[]; errors: string[] },
    _: File
  ) => {
    processMarketRunsData(payload.data);
  };

  const processMarketRunsData = async (
    uploadedData: CreateMarketProducts[]
  ) => {
    console.log(uploadedData);
    const data = uploadedData.map((payload: CreateMarketProducts) => {
      // @todo perform validation on keys here
      console.log(payload);

      return {
        ...payload,
        itemPrice: Number(payload.itemPrice),
        subItemPrice: Number(payload.subItemPrice),
        subItemInStock: Boolean(payload.subItemInStock),
        childItemRequired: Boolean(payload.childItemRequired),
        childItemInStock: Boolean(payload.childItemInStock),
        childItemPrice: Number(payload.childItemPrice),
        childItemQuantity: Number(payload.childItemQuantity),
        subItemQuantity: Number(payload.subItemQuantity),
      };
    });
    httpCreateMarketProducts({ payload: data, userId, roleId });
    // for (const obj of uploadedData) {
    // }
  };

  const processData = async (uploadedData: { [key: string]: string }[]) => {
    let hasError = false;
    for (const obj of uploadedData) {
      if (obj) {
        for (const key of dataKeys) {
          if (key in obj) {
            if (!obj[key]) {
              setErrorMessage(
                `Row ${count + 1} does not contain a valid value for ${key}`
              );
              hasError = true;
              break;
            }
          } else {
            setErrorMessage(
              `Row ${count + 1} does not contain the field ${key}`
            );
            hasError = true;
            break;
          }
        }
        if (hasError) break;
        incrementCount();
      }
    }
    if (!hasError) {
      const res = await httpGetBatchEstimate({
        pickupLocation: uploadedData[0].PickupAddress,
        dropoffLocations: [...uploadedData.map((d) => d.ReceiverAddress)],
      });
      setEstimateData(res);
      groupByDestination(uploadedData);
      setRawData(uploadedData);
    }
  };

  const groupByDestination = (uploadedData: { [key: string]: string }[]) => {
    if (uploadedData.length) {
      const data: any = uploadedData.reduce(
        (hash: { [key: string]: { [key: string]: string }[] }, obj) => ({
          ...hash,
          [obj.ReceiverAddress]: (hash[obj.ReceiverAddress] || []).concat(obj),
        }),
        {}
      );

      setProcessedData(data);
      setIsDone(true);
    }
  };

  const showPreview = async () => {
    setUploadModal(false);
    setErrorMessage('');
    setIsPreview(true);
  };
  const clearData = () => {
    setIsPreview(false);
    setUploadModal(false);
    setErrorMessage('');
    setCount(0);
    setEstimateData(null);
    setProcessedData(null);
    setRawData(null);
  };

  const proceed = async () => {
    if (!rawData) return;
    try {
      const firstData = rawData[0];
      setLoading(true);
      const phoneValid = (value: string) => phone(value);
      await httpProcessBulkEstimate({
        role: currentRole,
        roleId,
        userId,
        payload: {
          geoId: estimateData?.geoId || '',
          name: firstData.SenderName,
          email: firstData.SenderEmail,
          phone:
            mobileNumberFormatter(
              firstData.SenderPhone,
              phoneValid(firstData.SenderPhone).countryIso2 || 'NG'
            ) || firstData.SenderPhone,
          state: firstData.SenderState,
          country: firstData.SenderCountry,
          deliverToInformation: rawData.map((r, index) => ({
            order: index,
            name: r.ReceiverName,
            packageValue: r.PackageValue,
            packageType: r.PackageType,
            phone:
              mobileNumberFormatter(
                r.ReceiverPhone,
                phoneValid(r.ReceiverPhone).countryIso2 || 'NG'
              ) || r.ReceiverPhone,
            country: r.ReceiverCountry,
            state: r.ReceiverState,
          })),
          batch: true,
        },
      });
      setLoading(false);
      clearData();
      toast.success('Bulk estimate successfully sent');
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div>
      <ElrPageTitle title="Errandlr - Bulk Estimate" />
      <ProfileNavigationMenu>
        <div className="h-1000">
          <TopNavigationMenu />
          <div className="flex flex-col pt-8 px-7 md:pt-3 md:px-20">
            <div className="pt-6 text-xl mb-7">Bulk estimate</div>
            <Divider />
            <div className="flex flex-col mt-3 mb-3 md:items-center md:flex-row gap-x-10">
              <p className="my-5">
                Number of record: <span>{numberOfRecords}</span>
              </p>
              {isPreview ? (
                <div className="flex flex-col items-start gap-y-5 md:items-center md:flex-row gap-x-7">
                  <div>
                    <span className="mr-2 opacity-60">Total estimate:</span>
                    <span>
                      {currencyFormatter(estimateData?.estimate || 0)}
                    </span>
                  </div>

                  <div className="flex items-center gap-x-7">
                    <button
                      type="button"
                      onClick={clearData}
                      className="text-elr-error"
                    >
                      Cancel CSV
                    </button>
                    <ElrButton
                      loading={loading}
                      disabled={loading}
                      className="px-5 cursor-pointer rounded-3xl bg-elr-black text-elr-white"
                      size="sm"
                      text="Proceed delivery"
                      onClick={proceed}
                      spinnerColor="White"
                    />
                  </div>
                </div>
              ) : (
                <CSVDownloader
                  bom
                  filename={'Sample Bulk Estimate'}
                  data={() => sampleData}
                  className="px-10 py-2.5 rounded-3xl bg-elr-black text-elr-white cursor-pointer"
                >
                  Download Sample
                </CSVDownloader>
              )}
            </div>
            <Divider />

            {isPreview ? (
              <Preview data={processedData} />
            ) : (
              <div className="mt-10">
                <div className="flex flex-col items-center mt-16 md:mt-20">
                  <p className="mb-3">Upload multiple delivery at a time</p>

                  <CSVReader
                    onUploadAccepted={onUploaded}
                    onDragOver={(event: DragEvent) => {
                      event.preventDefault();
                    }}
                    onDragLeave={(event: DragEvent) => {
                      event.preventDefault();
                    }}
                    config={{
                      header: true,
                      skipEmptyLines: true,
                    }}
                  >
                    {({
                      getRootProps,
                      acceptedFile,
                      ProgressBar,
                      getRemoveFileProps,
                      Remove,
                    }: any) => (
                      <div
                        className="bg-elr-gray flex-col justify-center items-center flex overflow-hidden p-2 gap-x-3.5 w-72 mb-3 h-48 border border-elr-purple border-dashed cursor-pointer"
                        {...getRootProps()}
                      >
                        {acceptedFile ? (
                          <>
                            <ProgressBar />
                            <div className="flex flex-col items-center">
                              <h3 className="text-lg">
                                {acceptedFile.name}(
                                {formatFileSize(acceptedFile.size)})
                              </h3>
                              <span {...getRemoveFileProps()}>
                                <Remove color={DEFAULT_REMOVE_HOVER_COLOR} />
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={CloudUpload} alt="cloud-upload" />
                            <h3 className="text-lg">Bulk Upload</h3>
                            <p className="pt-1 text-elr-gray-400">
                              Click/Drop CSV or excel format only
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </CSVReader>
                </div>
                <div>
                  <div className="mt-10">
                    <div className="flex flex-col items-center mt-16 md:mt-20">
                      <p className="mb-3">Upload Market Runs CSV</p>

                      <CSVReader
                        onUploadAccepted={onMarketRunsCSVUploaded}
                        onDragOver={(event: DragEvent) => {
                          event.preventDefault();
                        }}
                        onDragLeave={(event: DragEvent) => {
                          event.preventDefault();
                        }}
                        config={{
                          header: true,
                          skipEmptyLines: true,
                        }}
                      >
                        {({
                          getRootProps,
                          acceptedFile,
                          ProgressBar,
                          getRemoveFileProps,
                          Remove,
                        }: any) => (
                          <div
                            className="bg-elr-gray flex-col justify-center items-center flex overflow-hidden p-2 gap-x-3.5 w-72 mb-3 h-48 border border-elr-purple border-dashed cursor-pointer"
                            {...getRootProps()}
                          >
                            {acceptedFile ? (
                              <>
                                <ProgressBar />
                                <div className="flex flex-col items-center">
                                  <h3 className="text-lg">
                                    {acceptedFile.name}(
                                    {formatFileSize(acceptedFile.size)})
                                  </h3>
                                  <span {...getRemoveFileProps()}>
                                    <Remove
                                      color={DEFAULT_REMOVE_HOVER_COLOR}
                                    />
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <img src={CloudUpload} alt="cloud-upload" />
                                <h3 className="text-lg">Market Runs Upload</h3>
                                <p className="pt-1 text-elr-gray-400">
                                  Click/Drop CSV or excel format only
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </CSVReader>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ProfileNavigationMenu>
      <ElrModal height="h-auto" isOpen={uploadModal} onClose={closeModal}>
        <div className="flex flex-col items-center justify-center pb-12 pt-7">
          <h3 className="text-lg">Uploading</h3>
          <p className="mt-5">
            {count}/{numberOfRecords}
          </p>
          <p className="text-xs">Complete</p>

          {errorMessage ? (
            <p className="my-4 text-center text-elr-error">{errorMessage}</p>
          ) : null}

          <div className="flex flex-col items-center mt-10 gap-y-4">
            {isDone ? (
              <p>
                Total Estimate: {currencyFormatter(estimateData?.estimate || 0)}
              </p>
            ) : (
              <ElrButton
                disabled
                text="Send delivery"
                className="px-10 py-2.5 rounded-3xl bg-elr-black text-elr-white"
              />
            )}
            <ElrButton
              onClick={showPreview}
              disabled={!isDone && !processedData}
              text="Preview delivery"
              className="px-10 py-2.5 rounded-3xl border border-elr-black text-elr-black"
            />
          </div>
        </div>
      </ElrModal>
    </div>
  );
};

export default BulkEstimate;
