import logoBlackWhite from 'assets/images/logo-white-black.svg';
import logoYellow from 'assets/images/logo-white-yellow.svg';
import logo from 'assets/images/logo.svg';

const currentTime = new Date().getHours();
let displayLogo;
const path = window.location.pathname;

export const isSunrise = currentTime <= 5;
export const isDayTime = currentTime > 10 && currentTime <= 14;
export const isSunset = currentTime > 14 && currentTime <= 18;
export const isDayTimeToSunset = currentTime > 10 && currentTime <= 18;
export const isNightTime = currentTime > 18 && currentTime <= 23;

export const currentTimeStyle = () =>
  `${isDayTimeToSunset || isDayTime ? 'text-elr-black ' : 'text-elr-gray'}`;

export const textStyle = (isDefault: boolean = false) =>
  `${
    isDayTimeToSunset || isDayTime || isDefault ? 'text-black' : 'text-white'
  }`;

export const currentTimeLogo = (isDefault: boolean = false) => {
  if (isDefault) return logo;
  if (isDayTime) {
    displayLogo = logo;
  } else if (isSunset) {
    displayLogo = logoBlackWhite;
  } else if (path === '/about-us') {
    displayLogo = logo;
  } else {
    displayLogo = logoYellow;
  }
  return displayLogo;
};

export const currentTimeNavBorder = () =>
  `${
    isDayTimeToSunset ||
    (isDayTime || window.location.pathname !== '/'
      ? 'border-elr-black text-black'
      : 'border-elr-white text-white')
  } border bg-transparent mr-4`;

export const currentTimeNavBotton = () =>
  `${
    isDayTimeToSunset ||
    window.location.pathname !== '/' ||
    (isDayTimeToSunset && window.location.pathname === '/')
      ? 'bg-elr-black text-elr-white '
      : 'bg-elr-white text-elr-black'
  } min-w-8 min-h-2 rounded-3xl flex justify-center items-center`;

export const persistOtherRouteNavStyle = () =>
  `${
    window.location.pathname !== '/'
      ? 'bg-elr-black text-elr-white '
      : 'bg-elr-white text-elr-black'
  } min-w-8 min-h-2 rounded-3xl flex justify-center items-center`;

export const mobileMenuIconStyle = (isDefault: boolean = false) =>
  `${isDayTimeToSunset || isDefault ? 'text-black' : 'text-white'} lg:hidden`;
