import { TopNav } from 'app/components/menu/Navbar';
import { Footer } from 'app/modules/landing/components';
import React, { useEffect } from 'react';
import { ElrAccordion, ElrPageTitle } from 'ui-components';
import PurpleVector from 'assets/images/policies-purple-vector.svg';
import YellowVector from 'assets/images/policies-yellow-vector.svg';
import PoliciesAppBanner from 'app/modules/landing/components/PoliciesAppBanner';

const RiderTermsAndCondtion: React.FC = () => {
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const [active, setActive] = React.useState<number>(0);
  const showDropdownOption = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  const boxes = [1, 2, 3, 4, 5];

  return (
    <div className=" bg-white">
      <ElrPageTitle title="Errandlr - Rider Terms and Condition" />
      <TopNav isDefault />
      <div className="mx-5 py-10 md:mx-40">
        <div className="text-xl mt-12 mb-16 bg-elr-gray relative rounded-xl overflow-hidden">
          <img
            src={PurpleVector}
            alt=""
            className="absolute md:bottom-0 w-3/12 md:w-32 -bottom-2 -left-2 md:left-0"
          />
          <img
            src={YellowVector}
            alt=""
            className="absolute md:top-0 -right-2 md:right-0 w-3/12 md:w-32 -top-2"
          />
          <p className="md:text-5xl text-center py-16 text-3xl">
            Riders’ Terms and Conditions
          </p>
        </div>
        <div className="text-base mt-16 mb-10 text-elr-black-300">
          <p className="pt-2 text-base">
            This Agreement (“Agreement”) is made and entered into between you,
            the undersigned contractor (“CONTRACTOR”, “the CONTRACTOR”,
            “rider”),an independent operator engaged in the business of
            performing the services contemplated by this Agreement, and ErrandLR
            LTD (“ERRANDLR,” “COMPANY,” “the COMPANY”,“we,” “us,” or “our”).
            CONTRACTOR may enter this Agreement either as an individual sole
            proprietor or a corporate entity. This Agreement will become
            effective on the date it is accepted regardless of whether you are
            eligible to, or ever do, perform any Contracted Services. RECITALS:
            ErrandLR is a company that provides an online platform using
            web-based technology that connects operators, individuals and/or
            other businesses (“ErrandLr platform” or “platform”). ErrandLr
            software permits registered users to place orders for delivery
            services from or to businesses or individual consumers. Once such
            orders are made, ErrandLr software notifies operators that a pick up
            and delivery opportunity is available and the ErrandLr software
            facilitates completion of the delivery. ErrandLr is not a delivery
            service. CONTRACTOR is an independent provider of delivery and other
            services, authorized to conduct the services contemplated by this
            Agreement in the geographic location(s) in which the CONTRACTOR
            operates. CONTRACTOR possesses all equipment and personnel necessary
            to perform the pick up, delivery and any other services contemplated
            by this Agreement in accordance with applicable laws. CONTRACTOR
            desires to enter into this Agreement for the right to receive pick
            up and delivery opportunities made available through ErrandLr’s
            platform. CONTRACTOR understands and expressly agrees that they are
            not an employee of ErrandLr or any other business or consumer who
            use the platform to request pick-ups and deliveries and that they
            are providing delivery and other services on behalf of themselves
            and their business, not on behalf of ErrandLr. CONTRACTOR
            understands (i) they are free to select the times they wish to be
            available on the platform to receive delivery opportunities; (ii)
            they are free to negotiate their compensation by among other things
            accepting or rejecting the opportunities transmitted through the
            ErrandLr platform by consumers, and can make such decisions to
            maximize their opportunity to profit; and (iii) they have the sole
            right to control the manner in which deliveries are performed and
            the means by which those deliveries are completed. In consideration
            of the above, as well as the mutual promises described herein,
            ErrandLr and the CONTRACTOR (collectively “the parties”) agree as
            follows:
          </p>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="1. Purpose of the Agreement"
          >
            <p className="mb-5 text-base">
              1. This Agreement shall govern the relationship between ErrandLr
              and the CONTRACTOR, and establish the parties’ respective rights
              and obligations thereof. In exchange for the promises contained in
              this Agreement, the CONTRACTOR shall have the right and obligation
              to perform the “Contracted Services” or “Delivery Services” as
              defined herein. However, nothing in this Agreement requires the
              CONTRACTOR to perform any particular volume of Contracted Services
              during the term of this Agreement, and nothing in this Agreement
              shall guarantee the CONTRACTOR any particular volume of business
              for any particular time period.
            </p>
            <p className="mb-5 text-base">
              2. CONTRACTOR shall have no obligation to accept or perform any
              particular “Delivery Opportunity” (as that term is defined herein)
              offered through the ErrandLr platform. However, once a Delivery
              Opportunity is accepted, the CONTRACTOR shall be contractually
              bound to complete the Contracted Services in accordance with all
              consumer and merchant specifications and the terms laid out in
              this Agreement.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="2. Contractors' Occupation"
          >
            <p className="mb-5 text-base">
              1. CONTRACTOR represents that they operate an independently
              established enterprise that provides delivery services, and that
              they satisfy all legal requirements and have all necessary
              licenses and permits necessary to perform any services
              contemplated by this Agreement. As an independent
              contractor/enterprise, CONTRACTOR shall be solely responsible for
              determining how to operate their business and how to perform the
              Contracted Services.
            </p>
            <p className="mb-5 text-base">
              2. CONTRACTOR agrees to provide ERRANDLR with information that is
              true and accurate, and to promptly inform ERRANDLR should any
              information require updating.
            </p>
            <p className="mb-5 text-base">
              3. CONTRACTOR agrees to fully perform the Contracted Services in a
              timely, efficient, safe, and lawful manner. ERRANDLR shall have no
              right to, and shall not, control the manner, method or means
              CONTRACTOR uses to perform the Contracted Services. Instead,
              CONTRACTOR shall be solely responsible for determining the most
              effective, efficient, and safe manner to perform the Contracted
              Services.
            </p>
            <p className="mb-5 text-base">
              4. As an independent business enterprise, CONTRACTOR retains the
              right to perform services (whether delivery services or other
              services) for other businesses, and CONTRACTOR represents that
              they advertise and hold themselves out to the general public as a
              separately established business. The parties recognize that they
              are or may be engaged in similar arrangements with other
              businesses to supply the same or similar services and nothing in
              this Agreement shall prevent CONTRACTOR or ERRANDLR from doing
              business with others.ERRANDLR does not have the right to restrict
              CONTRACTOR from performing services for CONTRACTOR’s own business,
              other businesses, customers, or consumers at any time, even if
              such business directly competes with ERRANDLR, and even during the
              time CONTRACTOR is logged into the ERRANDLR platform so long as
              such services do not otherwise violate this agreement.
              CONTRACTOR’s right to compete with ERRANDLR, or perform services
              for businesses that compete with ERRANDLR, will survive even after
              termination of this Agreement.
            </p>
            <p className="mb-5 text-base">
              5. CONTRACTOR is not required to purchase, lease, or rent any
              products, equipment or services from ERRANDLR as a condition of
              doing business with ERRANDLR or entering into this Agreement.
            </p>
            <p className="mb-5 text-base">
              6. CONTRACTOR agrees to immediately notify ERRANDLR in writing at
              support@errandlr.comif CONTRACTOR’s right to controlthe manner or
              method they use to perform services differs from the terms
              contemplated in this Section.
            </p>
            <p className="mb-5 text-base">
              7. When signing up to be a user of the ERRANDLR platform,
              CONTRACTOR’s information will be used to create an account.
              CONTRACTOR may not sign up to be an operator more than once by
              creating multiple accounts.
            </p>
            <p className="mb-5 text-base">
              8. CONTRACTOR acknowledges that when engaging with the ERRANDLR
              platform on a mobile device, data usage and rates may apply.
            </p>
            <p className="mb-5 text-base">
              9. By using the ERRANDLR platform, CONTRACTOR also agrees to be
              bound by the ERRANDLRTerms of serviceand that any breach of
              theERRANDLR Customer Terms of Service will be considered a breach
              of this Agreement. However, to the extent that this Agreement and
              the ERRANDLR Customer Terms of Service conflict, the terms of this
              Agreement shall govern.
            </p>
            <p className="mb-5 text-base">
              10.To prevent unauthorized access to CONTRACTOR’s account and to
              prevent unauthorized use of the ERRANDLR platform, CONTRACTOR
              agrees to protect and keep confidential their email, phone number,
              password, or other means of accessing their account via the
              ERRANDLR platform. CONTRACTOR acknowledges that unauthorized use
              of CONTRACTOR’s account, email, phone number and password could
              lead to financial loss and access to CONTRACTOR’s sensitive
              personal and account information. If CONTRACTOR discloses their
              account information, user ID, and/or password to any person or
              entity, CONTRACTOR assumes all risks and losses associated with
              such disclosure. If CONTRACTOR believes someone may attempt to use
              or has accessed CONTRACTOR’s account without CONTRACTOR’s
              permission, or that any other unauthorized use or security breach
              has occurred, CONTRACTOR agrees to immediately notify us at
              support@errandlr.com.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="3. Pick Up and Delivery Service"
          >
            <p className="mb-5 text-base">
              1. CONTRACTOR agrees that by logging into the ERRANDLR platform
              CONTRACTOR makes themselves available to receive delivery
              opportunities. CONTRACTOR shall be notified of a delivery
              opportunity when a user requests for a service within close
              proximity to the contractor using the ERRANDLR platform.
              CONTRACTOR may accept the request by following the steps provided
              on the platform. Upon acceptance, the contractor shall ensure that
              the pick up and delivery is made safely, in a timely fashion and
              in accordance with existing laws. Delivery Service shall include
              pick-up of the goods or items intended for delivery by the
              user/consumer, from a location which shall be stated or imputed on
              the platform upon making a delivery request, and drop-off/delivery
              of such goods/items at the location stated or imputed on the
              platform for delivery.
            </p>
            <p className="mb-5 text-base">
              2. A Delivery is considered complete when the order has been
              picked up and delivered to the ordering/receiving party, or, and
              only when applicable, placed in a designated area as selected by
              the consumer, in addition to any other task required for
              completion of the delivery. CONTRACTOR agrees to timely mark a
              delivery as completed through the ERRANDLR platform upon
              completion of delivery.
            </p>
            <p className="mb-5 text-base">
              3.CONTRACTOR understands and agrees that the requirements for the
              fulfillment of each delivery are determined and established by the
              user, not ERRANDLR. CONTRACTOR has the right to cancel a delivery
              when, in the exercise of CONTRACTOR&apos;s reasonable discretion
              and business judgment, it is appropriate to do so. Notwithstanding
              the foregoing,CONTRACTOR agreesto maintain both a customer rating
              and a completion rate as of the date this Agreement becomes
              effective. Failure to satisfy this obligation constitutes a
              material breach of this Agreement, and ERRANDLR shall have the
              right to terminate this Agreement and/or deactivate
              CONTRACTOR&apos;S account.
            </p>
            <p className="mb-5 text-base">
              4. CONTRACTOR acknowledges that ERRANDLR has discretion as to
              which, if any, delivery opportunity to present to CONTRACTOR, as
              the CONTRACTOR has the discretion whether to accept any Delivery
              Opportunity and to what extent.
            </p>
            <p className="mb-5 text-base">
              5. CONTRACTOR upholds that CONTRACTOR is engaged in CONTRACTOR’s
              own business, separate and apart from ERRANDLR’S business, which
              is to provide an online platform using web-based technology that
              connects contractors,users, consumers and other businesses.
            </p>
            <p className="mb-5 text-base">
              6. CONTRACTOR authorizes ERRANDLR, and the user during the course
              of a Contracted Service, to communicate with CONTRACTOR, consumer,
              and/or other business as the case requires, to assist CONTRACTOR,
              or facilitate direct communication between CONTRACTOR and the
              consumer, and/or business, to the extent permitted by CONTRACTOR,
              in facilitating deliveries. However, ERRANDLR may provide route
              suggestions through the platform in the form of GPS and/or built
              in maps navigation of shorter routes for the purpose of completing
              deliveries efficiently and in a shorter time depending on the type
              of delivery selected by the user/consumer on the platform.
            </p>
            <p className="mb-5 text-base">
              7.In the event CONTRACTOR fails to fully perform any Delivery
              Service (a &quot;Service Failure&quot;) due to CONTRACTOR&apos;s
              action or omission, CONTRACTOR shall forfeit all or part of the
              agreed upon fee for that delivery service. If CONTRACTOR disputes
              liability for a Service Failure, the dispute shall be resolved
              pursuant to the &quot;Payment Disputes&quot; provision below.
            </p>
            <p className="mb-5 text-base">
              8. CONTRACTOR agrees to immediately notify ERRANDLR in writing by
              submitting a Support inquiry through support@errandlr.comif
              CONTRACTOR&apos;sservices or scope of work differ in any way from
              what is contemplated in this Section.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="4. Relationship Between Parties"
          >
            <p className="mb-5 text-base">
              1. Both parties acknowledge and agree that this Agreement is
              between two, independent business enterprises that are separately
              owned and operated. By this agreement, the parties intend to
              create and enter into a relationship of principal and independent
              contractor and not that of employer and employee. Therefore,
              neither of the parties are employees, agents, joint venturers, or
              partners of each other, for any purpose. Neither party shall have
              the right to bind the other by contract or otherwise except as
              specifically provided in this Agreement.
            </p>
            <p className="mb-5 text-base">
              2. ERRANDLR shall report all payments made to CONTRACTOR on a
              calendar year basis using the appropriate FIRS form(s), if the
              volume of payments to CONTRACTOR qualifies. CONTRACTOR agrees to
              report all such payments and any cash gratuities to the
              appropriate federal, state and local taxing authorities.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="5. Payment for Services"
          >
            <p className="mb-5 text-base">
              1. ERRANDLR reserves the right to adjust or withhold all or a
              portion of payment owed to CONTRACTOR if ERRANDLR reasonably
              believes that the CONTRACTOR has defrauded or abused, or attempted
              to defraud or abuse, the platform. From time to time, ERRANDLR may
              offer opportunities for the CONTRACTOR to earn more money for
              performing Delivery Services at specified times or in specified
              locations. Nothing prevents the parties from negotiating a
              different rate of pay, and CONTRACTOR is free to accept or reject
              any such opportunities to be paid at different rates.
            </p>
            <p className="mb-5 text-base">
              2. ERRANDLR will process payments made by consumers and transmit
              applicable payment for the delivery services to the CONTRACTOR.
              Payments for all delivery services completed in a given week shall
              be transferred via a funds transfer process or system chosen and
              agreed on by the parties on no less than a weekly basis unless
              ERRANDLR notifies the CONTRACTOR otherwise in writing.
            </p>
            <p className="mb-5 text-base">
              3. CONTRACTOR is responsible for verifying the accuracy of their
              bank account and/or debit card information to receive timely
              payments. ERRANDLR is not responsible for lost or late payments
              due to incorrect routing and/or account information.
            </p>
            <p className="mb-5 text-base">
              4. By agreeing to any separate contract with a third-party that
              will process payments owed under this Agreement to the CONTRACTOR,
              the CONTRACTOR agrees that ERRANDLR shall discharge its payment
              obligations to the CONTRACTOR by funding the amounts payable and
              directing the third-party processor to pay such funded amounts to
              the CONTRACTOR. After ERRANDLR funds and directs payment, any
              continuing refusal by the third-party processor to issue payment
              to CONTRACTOR shall be the responsibility of CONTRACTOR to
              resolve.
            </p>
            <p className="mb-5 text-base">
              5. From time to time, ERRANDLR may offer various operators
              promotions or referral programs. CONTRACTOR agrees that they will
              not abuse the referral programs or operator promotions by (a)
              tampering with the location feature on their mobile phone; (b)
              collecting incentive or promotional pay when not eligible to
              receive such pay under relevant policies; or, (c) creating
              multiple operator or consumer accounts, among other things.
              CONTRACTOR understands that engaging in this type of manipulation
              or abuse constitutes a material breach of this Agreement and may
              lead to deactivation of their account.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="6. Equipment and Expenses"
          >
            <p className="mb-5 text-base">
              1. CONTRACTOR warrants that they have or can lawfully acquire all
              vehicles, including motorbikes, scooters, bicycles, trucks, cars,
              panniers, helmets and other safety equipment
              (&quot;Equipment&quot;) necessary for performing delivery
              services, and CONTRACTOR is solely responsible for ensuring that
              any vehicle used conforms to all vehicle laws pertaining to
              registration, safety, equipment, inspection, and operational
              capability.
            </p>
            <p className="mb-5 text-base">
              2. CONTRACTOR shall be responsible for every or any costs and
              expenses arising from maintaining the working conditions of
              equipment and staff/personnel performance. Except as otherwise
              required by law.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="7. Insurance"
          >
            <p className="mb-5 text-base">
              1. CONTRACTOR agrees, as a condition of doing business with
              ERRANDLR, that during the term of this Agreement, CONTRACTOR will
              maintain current insurance, in amounts and of types required by
              law to provide the Delivery Services and cover CONTRACTOR during
              performance of the Contracted Services, at their own expense.
              Failure to secure or maintain satisfactory insurance coverage
              shall be deemed a material breach of this Agreement and shall
              result in the termination of the Agreement and the loss of
              CONTRACTOR’s right to receive Delivery Opportunities.
            </p>
            <p className="mb-5 text-base">
              2. CONTRACTOR agrees to deliver to ERRANDLR, upon request, current
              certificates of insurance as proof of coverage. CONTRACTOR agrees
              to provide updated certificates each time CONTRACTOR purchases,
              renews, or alters CONTRACTOR’s insurance coverage. CONTRACTOR
              agrees to give ERRANDLR at least thirty (30) days’ prior written
              notice before cancellation of any insurance policy required by
              this Agreement.
            </p>

            <p className="mb-5 text-base">
              3. CONTRACTOR agrees that CONTRACTOR will maintain sufficient
              insurance to cover any risks or claims arising out of or related
              to CONTRACTOR’S relationship with Errandlr, including workers’
              compensation insurance where required by law. CONTRACTOR
              acknowledges and understands that CONTRACTOR will not be eligible
              for workers’ compensation benefits through ERRANDLR.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="8. Idemnity"
          >
            <p className="mb-5 text-base">
              1. ERRANDLR agrees to indemnify, protect and hold harmless the
              CONTRACTOR from any and all claims, demands, damages, suits,
              losses, liabilities and causes of action arising directly from
              ERRANDLR’s actions offering and facilitating the Delivery Services
              to CONTRACTOR.
            </p>
            <p className="mb-5 text-base">
              2. CONTRACTOR agrees to indemnify, protect and hold harmless
              ERRANDLR, including all parent, subsidiary and/or affiliated
              companies, as well as its and their past and present successors,
              assigns, officers, owners, directors, agents, representatives,
              attorneys, and employees, from any and all claims, demands,
              damages, suits, losses, liabilities and causes of action arising
              directly or indirectly from, as a result of or in connection with,
              the actions of CONTRACTOR and/or their Personnel arising from the
              performance of delivery services under this Agreement, including
              personal injury or death to any person (including to CONTRACTOR
              and/or their Personnel), as well as any liability arising from
              CONTRACTOR’s failure to comply with the terms of this Agreement.
              CONTRACTOR’s obligations hereunder shall include the cost of
              defense, including attorneys’ fees, as well as the payment of any
              final judgment rendered against or settlement agreed upon by
              ERRANDLR or its parent, subsidiary and/or affiliated companies.
            </p>
            <p className="mb-5 text-base">
              3. CONTRACTOR agrees to indemnify, protect and hold harmless
              ERRANDLR, including all parent, subsidiary, and/or affiliated
              companies, as well as its and their past and present successors,
              assigns, officers, owners, directors, agents, representatives,
              attorneys, and employees, from any and all tax liabilities and
              responsibilities for payment of all federal, state and local
              taxes, including, but not limited to all payroll taxes,
              self-employment taxes, workers compensation premiums, and any
              contributions imposed or required under federal, state and local
              laws, with respect to CONTRACTOR and CONTRACTOR’s Personnel.
            </p>
            <p className="mb-5 text-base">
              4. CONTRACTOR shall be responsible for, indemnify and hold
              harmless ERRANDLR, including all parent, subsidiary, and/or
              affiliated companies, as well as its and their past and present
              successors, assigns, officers, owners, directors, agents,
              representatives, attorneys, and employees, from all costs of
              CONTRACTOR’s business, including, but not limited to, the expense
              and responsibility for any and all applicable insurance, local,
              state or federal licenses, permits, taxes, and assessments of any
              and all regulatory agencies, boards or municipalities.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="9. Mutual Arbitration Provision"
          >
            <p className="mb-5 text-base">
              1. CONTRACTOR and ERRANDLR mutually agree to this Mutual
              Arbitration Provision, which is governed by the Arbitrators and
              Conciliators Act Chapter A18 LFN 2004 and shall apply to any and
              all disputes arising out of or relating to this Agreement,
              including but not limited to CONTRACTOR’s classification as an
              independent contractor, CONTRACTOR’s provision of Contracted
              (delivery) Services to consumers or other businesses, the payments
              received by CONTRACTOR for providing services to consumers or
              other businesses, the termination of this Agreement, and all other
              aspects of CONTRACTOR&apos;s relationship with ERRANDLR, past,
              present or future, whether arising under federal, state or local
              statutory and/or common law, including without limitation
              harassment, discrimination or retaliation claims and claims
              arising under the Labour Act (Cap L1 LFN 2004), FRS Act 2007(or
              it’s state equivalent), and all other federal, state or local
              claims arising out of or relating to CONTRACTOR’s relationship or
              the termination of that relationship with ERRANDLR. This Mutual
              Arbitration Agreement extends to disputes between CONTRACTOR and
              any ERRANDLR affiliates, subsidiaries, successors, agents, and
              employees arising out of or related to this Agreement. To the
              extent the parties have both arbitrable and non-arbitrable
              disputes that are related, the arbitrable disputes shall proceed
              first in arbitration and the non-arbitrable disputes shall be
              stayed, and any applicable statutes of limitations delayed,
              pending completion of the arbitration. The parties expressly agree
              that this Agreement shall be governed by the ACA even in the event
              CONTRACTOR and/or ERRANDLR are otherwise exempted from the ACA.
              Any disputes regarding the ACA’s application shall be resolved
              exclusively by an arbitrator. If for any reason the ACA does not
              apply, the state law governing arbitration and conciliation
              agreements in the state in which the CONTRACTOR operates shall
              apply.
            </p>
            <p className="mb-5 text-base">
              2. Any dispute, conflict, claim or controversy arising out of or
              broadly in connection with or relating to the Services or these
              Terms, including those relating to its validity, its construction
              or its enforceability (any “Dispute”) shall be first mandatorily
              submitted to mediation proceedings under the Nigerian Arbitrators
              and Conciliators Act. If such Dispute has not been settled after
              three meetings after a request for mediation has been submitted
              under the mediation rules, such Dispute can be referred to and
              shall be exclusively and finally resolved by arbitration under the
              Rules of Arbitration of the Arbitrators and Conciliators Act. The
              existence and content of the mediation and arbitration
              proceedings, including documents and briefs submitted by the
              parties, correspondence from and to the arbitrating body,
              correspondence from the mediator, and correspondence, orders and
              awards issued by the sole arbitrator, shall remain strictly
              confidential and shall not be disclosed to any third party without
              the express written consent from the other party.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="10. Class Action Litigation Waiver"
          >
            <p className="mb-5 text-base">
              1. Separate and apart from the Mutual Arbitration Provision found
              above and to the extent of applicable laws, the CONTRACTOR agrees
              that any proceeding to litigate in court any dispute arising out
              of or relating to this Agreement, whether because CONTRACTOR opted
              out of the Mutual Arbitration Provision or any other reason
              thereof, will be conducted solely on an individual basis, and the
              CONTRACTOR agrees not to seek creating any controversy, claim or
              dispute heard as a class action, a representative action, a
              collective action, a private attorney-general action, or in any
              proceeding in which CONTRACTOR acts or proposes to act in a
              representative capacity (“Class Action Litigation Waiver”).
              CONTRACTOR further agrees that no proceeding will be joined,
              consolidated, or combined with another proceeding, without the
              prior written consent of all parties to any such proceeding. If a
              court of competent jurisdiction determines that all or part of
              this Class Action Litigation Waiver is unenforceable,
              unconscionable, void or voidable, the remainder of this Agreement
              shall remain in full force and effect.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="11. Modification"
          >
            <p className="mb-5 text-base">
              1. ERRANDLR may modify this Agreement at any time. When such
              material changes to this Agreement are made, the revised Agreement
              will be posted on the ERRANDLR platform along with the “Last
              Updated” date on the Agreement. ERRANDLR will also provide notice
              of any material changes before the date the revised Agreement
              takes effect. If the CONTRACTOR disagrees with the revised
              Agreement, CONTRACTOR may terminate the Agreement immediately as
              provided herein. If CONTRACTOR does not terminate the Agreement
              before the date the revised Agreement becomes effective,
              CONTRACTOR’s continued access to or use of the ERRANDLR platform
              will constitute acceptance of the revised Agreement.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="12. Entire Agreement, Transferability and Waiver"
          >
            <p className="mb-5 text-base">
              1. This Agreement shall constitute the entire agreement and
              understanding between the parties with respect to the subject
              matter of this Agreement and shall not be modified, altered,
              changed or amended in any respect, unless in writing and signed by
              both parties, or unless ERRANDLR modifies the Agreement pursuant
              to Section above. This Agreement shall be binding over any prior
              contract between the parties. The decision to opt-out of the
              Mutual Arbitration Provision in this Agreement does not affect the
              enforceability of any arbitration agreement in the consumer facing
              Terms and Conditions Agreement to which CONTRACTOR may be bound
              (and vice versa). This Agreement may not be assigned by either
              party without written consent of the other and shall be binding
              upon the parties hereto, including their heirs and successors,
              provided, however, that ERRANDLR may assign its rights and
              obligations under this Agreement to an affiliate of ERRANDLR or
              any successor(s) to its business and/or purchaser of substantially
              all of its stock or assets. References in this Agreement to
              ERRANDLR shall be deemed to include such successor(s).
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="13. Third Party Services"
          >
            <p className="mb-5 text-base">
              1. From time to time, ERRANDLR may enable CONTRACTOR to receive
              services from other third parties while using the ERRANDLR
              platform. CONTRACTOR understands that these services are subject
              to the terms and conditions of each third-party provider.
              CONTRACTOR agrees that ERRANDLR is not responsible and may not be
              held liable for these services or the actions or omissions of any
              third-party provider.
            </p>
            <p className="mb-5 text-base">
              2. Google Maps: As a CONTRACTOR, while using the ERRANDLR platform
              CONTRACTOR may be able to use Google Maps in-app navigation
              services while performing Contracted Services. If CONTRACTOR does
              so, CONTRACTOR agree that Google may collect CONTRACTOR’s location
              data when the ERRANDLR platform is running in order to provide and
              improve Google’s services, that such data may also be shared with
              ERRANDLR in order to improve its operations, and that
              Google’sterms andprivacy policywill apply to this usage.
              CONTRACTORcan also use any other navigation app outside the
              ERRANDLR platform or none at all.
            </p>
          </ElrAccordion>
        </div>
        <div onClick={showDropdownOption}>
          <ElrAccordion
            setActive={setActive}
            index={boxes[2]}
            active={boxes[2] === active}
            transformPositionClass=""
            header="14. Other Provisions"
          >
            <p className="mb-5 text-base">
              1.
              <strong>SEVERABILITY:</strong> If any provision of herein is held
              to be illegal, void, voidable or unenforceable, in whole or in
              part, under any law, such provision or part thereof shall to that
              extent be deemed not to form part of this Agreement but the
              legality, validity and enforceability of the other provisions in
              this Agreement shall not be affected.
            </p>
            <p className="mb-5 text-base">
              2. <strong>GOVERNING LAW:</strong> The choice of law for
              interpretation of this Agreement, and the right of the parties
              hereunder, as well as substantive interpretation of claims
              asserted herein, shall be the rules of law of the state in which
              CONTRACTOR performs the majority of the services covered by this
              Agreement.
            </p>
            <p className="mb-5 text-base">
              3. <strong>NOTICE OF BREACH:</strong> CONTRACTOR agrees to notify
              ERRANDLR in writing at support@errandlr.comof any breach or
              perceived breachof this Agreement, of any claim arising out of or
              related to this Agreement, or of any claim that CONTRACTOR’s
              services or scope of work differ in any way from what is
              contemplated in this Agreement.
            </p>
            <p className="text-base">
              All those subject to this notice are referred to as ‘users’ in
              this notice.
            </p>
          </ElrAccordion>
        </div>
        <PoliciesAppBanner />
      </div>
      <Footer hideTopFooter />
    </div>
  );
};

export default RiderTermsAndCondtion;
