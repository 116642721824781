import React, { useEffect, useState } from 'react';
import { ElrDropdownIconToggle } from 'ui-components';

interface Props {
  containerStyles?: string;
  transformPositionClass?: string;
  iconClass?: string;
  iconSize?: 'small' | 'inherit' | 'default' | 'large';
  children?: React.ReactNode;
}

const DynamicIconDropDownMenu: React.FC<Props> = ({
  children,
  transformPositionClass = '-translate-x-25',
  containerStyles,
  iconClass = 'opacity-40 text-elr-black',
  iconSize,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (divRef.current?.contains(event.target)) {
      return;
    }
    setDropDown(!dropDown);
  };

  useEffect(() => {
    if (dropDown) {
      document.addEventListener('mouseup', handleClickOutside);
    } else {
      document.removeEventListener('mouseup', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [dropDown]);

  return (
    <div ref={divRef}>
      <div>
        <div className="flex justify-between w-auto">
          <div className={`${iconClass} flex-end`}>
            <ElrDropdownIconToggle
              fontSize={iconSize}
              isToggled={dropDown}
              toggle={setDropDown}
            />
          </div>
        </div>
      </div>
      {dropDown && (
        <div
          className={`${transformPositionClass} absolute mt-2 z-100 rounded-md ${containerStyles}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default DynamicIconDropDownMenu;
