import { RECAPTCHA_SITE_KEY } from 'app/constants';
import { useEffect } from 'react';

const loadReCaptcha = (siteKey: string) => {
  const script = document.createElement('script');
  script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};

export const useReCaptcha = (action: string) => {
  useEffect(() => {
    loadReCaptcha(RECAPTCHA_SITE_KEY || '');
  }, []);

  const getRecaptchaToken = async () => {
    let token = '';
    await window.grecaptcha
      .execute(RECAPTCHA_SITE_KEY, { action })
      .then((res: string) => {
        token = res;
      });
    return token;
  };

  return [getRecaptchaToken];
};
