import { PartnerDropdownOptions } from 'app/utilities/types/shared';
import BlackSpinner from 'assets/images/spinner-black.svg';
import React from 'react';
import { dropdownMenuStyle } from './ClassUtils';

const BatchRequestLocalityDisplay: React.FC<{
  dropdownOpen: boolean;
  onClickDropdown: () => void;
  isFetchingCompany: boolean;
  selectedOption: PartnerDropdownOptions;
  handleSelectOption: (options: PartnerDropdownOptions) => void;
  options: Array<PartnerDropdownOptions>;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  dropdownOpen,
  onClickDropdown,
  isFetchingCompany,
  selectedOption,
  handleSelectOption,
  options,
  setDropdownOpen,
}) => {
  const handleToggleDropdown = () => {
    if (!dropdownOpen) onClickDropdown();
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div
      className="w-full relative"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onBlur={() => setDropdownOpen(false)}
    >
      <div
        className="cursor-pointer text-elr-green border border-elr-green border-opacity-30 py-2 px-2.5 rounded-lg flex justify-between"
        onClick={handleToggleDropdown}
      >
        {selectedOption.name}
        {isFetchingCompany && (
          <img src={BlackSpinner} className="animate-spin h-5" alt="spinner" />
        )}
      </div>
      {dropdownOpen && (
        <div className="border bg-white h-286 overflow-y-scroll no-scrollbar border-elr-green border-opacity-30 absolute bottom-full -translate-y-5 w-full rounded-md">
          {options.map((option, idx) => (
            <div
              key={option.name}
              className={dropdownMenuStyle(idx, options.length)}
              onClick={() => handleSelectOption(option)}
            >
              <span>{option.name}</span>
              <div className="flex items-center">
                &nbsp;
                <span className="text-sm opacity-60">{option.location}</span>
              </div>
            </div>
          ))}
          {!isFetchingCompany && !options.length && (
            <div className="text-center opacity-70">no displayed interests</div>
          )}
        </div>
      )}
    </div>
  );
};

export default BatchRequestLocalityDisplay;
