interface Data {
  header: string;
  paragraph: string | JSX.Element;
}
const data = [
  {
    header: 'Order Processing',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Once you place an order through Errandlr, we will review it and
            verify your payment information.
          </li>
          <li>
            Processing times may vary depending on the type of service,
            availability of the errand runner, and any special instructions.
          </li>
          <li>
            You will receive a confirmation email or notification within the app
            once your order is accepted and assigned to an errand runner.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Delivery Areas',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Errandlr currently operates within Nigeria, United States, United
            Kingdom and Canada.
          </li>
          <li>
            We are constantly expanding our service area, so please check back
            for updates.
          </li>
          <li>
            You can enter your delivery address at checkout to confirm
            availability in your area.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Delivery Timeframes',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Errandlr offers various delivery timeframes depending on your
            urgency and the chosen service.
          </li>
          <li>
            Estimated delivery times are displayed during checkout and are
            subject to change due to traffic, weather conditions, or unforeseen
            circumstances.
          </li>
          <li>
            We will keep you updated on any delays impacting your delivery.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Delivery Fees',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Delivery fees are calculated based on several factors, including
            distance, service type, and delivery timeframe.
          </li>
          <li>
            The final delivery fee will be displayed at checkout before you
            confirm your order.
          </li>
          <li>
            Errandlr may offer promotions and discounts that affect delivery
            fees. Please check our app or website for current offers.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Delivery Methods',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Errandlr uses a network of reliable errand runners and shipping
            partners to deliver your goods and complete your errands.
          </li>
          <li>
            The chosen delivery method (e.g., car, bike, walking, ship, air)
            will depend on the type of service, size of the order, and
            efficiency for your location.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Delivery Instructions',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            You can provide specific delivery instructions during checkout. This
            could include preferred drop-off locations, access codes, or special
            handling requests.
          </li>
          <li>
            We will communicate these instructions to the errand runner to
            ensure successful delivery.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Package Handling',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Errandlr takes utmost care in handling your packages. We encourage
            you to provide clear instructions and fragile item warnings in your
            order details.
          </li>
          <li>
            In case of damaged or lost items, please refer to our Returns and
            Claims Policy for further information.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Order Tracking',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Once your order is assigned to an errand runner, you will receive a
            tracking link within the app or a notification email.
          </li>
          <li>
            You can use this link to track the progress of your delivery in
            real-time.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Delivery Acceptance',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Upon delivery, the errand runner may require you to sign for
            confirmation.
          </li>
          <li>
            If you are unavailable to receive the delivery, please provide
            alternative instructions during checkout or contact us to
            reschedule.
          </li>
          <li>
            Errandlr is not responsible for lost or stolen packages left
            unattended upon attempted delivery.
          </li>
          <li>
            While we strive for on-time deliveries, unforeseen circumstances
            like traffic, weather, or unavailability of errand runners can cause
            delays.
          </li>
          <li>
            We will communicate any delays to you through the app or email and
            keep you updated on the estimated revised delivery time.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Prohibited Items',
    paragraph:
      'Errandlr restricts the delivery of all items deemed illegal, dangerous, or hazardous under laws of various countries.',
  },
  {
    header: 'Changes to Delivery Policy',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Errandlr reserves the right to update this Delivery Policy at any
            time. We will post any changes on our website and app.
          </li>
          <li>
            Your continued use of the platform constitutes your agreement to the
            revised Delivery Policy.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Purchase Process',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            Product Selection: Browse our extensive selection of products from
            various partner stores within your delivery area.
          </li>
          <li>
            Ordering: Once you find the desired product, add it to your cart and
            proceed to checkout.
          </li>
          <li>
            Payment: We offer secure payment options through the app. You can
            choose your preferred payment method at checkout.
          </li>
          <li>
            Order Confirmation: Upon successful payment, you will receive a
            confirmation email or notification within the app with your order
            details.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Product Availability',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            We strive to provide accurate and up-to-date information on product
            availability. However, real-time stock levels may vary at partner
            stores.
          </li>
          <li>
            In case an ordered product is unavailable, we will notify you at the
            earliest and offer alternative options like a substitute item, store
            credit, or a full refund.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Pricing and Promotions',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            All product prices are displayed on the platform and are inclusive
            of applicable taxes.
          </li>
          <li>
            Errandlr may offer promotions and discounts on products. Please
            check our app or website for current offers.
          </li>
          <li>
            We reserve the right to adjust pricing due to market fluctuations or
            errors. In such cases, we will display the updated price before
            order confirmation.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Order Cancellation',
    paragraph: (
      <div className="flex flex-col gap-3">
        <ul className="ml-8 list-disc flex flex-col gap-3">
          <li>
            You can cancel your order before it is assigned to an errand runner.
          </li>
          <li>
            Cancellation fees may apply depending on the order processing stage.
          </li>
        </ul>
      </div>
    ),
  },
  {
    header: 'Contact Us',
    paragraph: (
      <p>
        If you have any questions regarding our Delivery Policy or encounter any
        issues with your delivery, please do not hesitate to contact us through
        the Errandlr app or by email at{' '}
        <a href="mailto:support@errandlr.com" className="text-elr-purple">
          support@errandlr.com
        </a>
        .
      </p>
    ),
  },
];

const DeliveryPolicyContents = () => (
  <div className="pt-10">
    {data.map((contents: Data) => (
      <div key={contents.header} className="pt-12">
        <h2 className="text-22 md:text-3xl text-elr-black leading-6 md:leading-9">
          {contents.header}
        </h2>
        <div className="pt-6 text-14 leading-5 text-elr-black text-opacity-60">
          {contents.paragraph}
        </div>
      </div>
    ))}
  </div>
);

export default DeliveryPolicyContents;
