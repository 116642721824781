import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import {
  CreateEventProps,
  EventChargesType,
  Ticket,
} from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ElrButton,
  ElrInput,
  ElrLocationSelect,
  ElrTextAreaInput,
} from 'ui-components';
import { ElrRadio } from 'ui-components/components/ElrRadio';
import { ElrUploadV2 } from 'ui-components/components/ElrUpload';
import TimePicker from 'ui-components/components/ErlTimePicker';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  BackIconWonWithBG,
  CalendarIcon,
  DeleteIcon,
  PlusIcon,
} from '../../SvgAssets';

const validationSchema = Yup.object({
  eventName: Yup.string().required('Event name is required'),
  eventLocation: Yup.object({
    label: Yup.string().required('Event location is required'),
  }).required('Event location is required'),
  eventDescription: Yup.string().required('Event Description is required'),
  eventDate: Yup.date().nullable().required('Event date is required'),
  eventTime: Yup.string().required('Event time is required'),
  ticketTypes: Yup.array().of(
    Yup.object({
      type: Yup.string().required('Ticket type is required'),
      price: Yup.number().required('Price is required'),
      quantity: Yup.number().required('Quantity is required'),
      expiryDate: Yup.date().nullable().required('Expiry Date is required'),
      whoPays: Yup.string().required('You must select who pays'),
      ticketCategory: Yup.string()
        .oneOf(['single', 'group'])
        .required('Ticket category is required'),
      groupQuantity: Yup.number()
        .nullable()
        .when('ticketCategory', {
          is: 'group',
          then: (schema) =>
            schema.required('Group quantity is required for group ticket'),
          otherwise: (schema) => schema.nullable(),
        }),
    })
  ),
  bannerImage: Yup.mixed().optional(),
});

const EventForm = ({ event }: { event: CreateEventProps | null }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { eventUUId } = useParams();
  const queryClient = useQueryClient();

  const eventData =
    event || state?.event || queryClient.getQueryData(['event', eventUUId]);
  const isEditing = !!eventUUId;

  const { handleSubmit, values, handleChange, setFieldValue, isValid } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        eventName: eventData?.name || '',
        eventLocation: eventData?.location
          ? { label: eventData.location }
          : { label: '' },
        eventDescription: eventData?.description || '',
        eventDate: eventData?.eventDate ? new Date(eventData.eventDate) : null,
        eventTime: eventData?.eventDate
          ? new Date(eventData.eventDate).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })
          : '',
        bannerUrl: eventData?.bannerUrl || '',
        ticketTypes: eventData?.tickets
          ? eventData.tickets.map((ticket: Ticket) => ({
              ...ticket,
              whoPays: ticket.charge || EventChargesType.Customer,
              ticketCategory: ticket.ticketCategory || 'single',
              groupQuantity: ticket.groupQuantity || null,
            }))
          : [
              {
                id: 1,
                type: '',
                price: '',
                quantity: '',
                expiryDate: null,
                whoPays: EventChargesType.Customer,
                ticketCategory: 'single',
                groupQuantity: null,
              },
            ],
        bannerImage: eventData?.bannerImage || eventData?.bannerUrl || null,
      },
      validationSchema,
      onSubmit: async () => {
        try {
          if (values.eventDate && values.eventTime) {
            const [hours, minutes] = values.eventTime.split(/[: ]/);
            const period = values.eventTime.includes('PM') ? 12 : 0;
            const eventDateWithTime = new Date(values.eventDate);
            eventDateWithTime.setHours((parseInt(hours, 10) % 12) + period);
            eventDateWithTime.setMinutes(parseInt(minutes, 10));
            values.eventDate = eventDateWithTime;
          }

          values.ticketTypes = values.ticketTypes.map((ticket: Ticket) => ({
            ...ticket,
            expiryDate: ticket.expiryDate ? new Date(ticket.expiryDate) : null,
            ticketCategory: ticket.ticketCategory || 'single',
            groupQuantity:
              ticket.ticketCategory === 'group' ? ticket.groupQuantity : null,
          }));

          const navigateTo = isEditing
            ? `/${COMMERCE}/${privateRoutes.ecommerce}/${eventUUId}/${privateRoutes.eventsDashboard}`
            : `/${COMMERCE}/${privateRoutes.ecommerce}/${privateRoutes.eventsPreview}`;

          navigate(navigateTo, { state: { ...values, isEditing: true } });
        } catch (error) {
          // Handle error
        }
      },
    });

  const handleAddTicketType = () => {
    const newTicketTypes = [
      ...values.ticketTypes,
      {
        id: values.ticketTypes.length + 1,
        type: '',
        price: '',
        quantity: '',
        expiryDate: null,
        whoPays: '',
      },
    ];
    setFieldValue('ticketTypes', newTicketTypes);
  };

  const handleDeleteTicketType = (index: number) => {
    const newTicketTypes = values.ticketTypes.filter(
      (_: Ticket, i: number) => i !== index
    );
    setFieldValue('ticketTypes', newTicketTypes);
  };

  const handleOptionClick = (index: number, option: string) => {
    const updatedTicketTypes = values.ticketTypes.map(
      (ticket: Ticket, i: number) =>
        i === index ? { ...ticket, whoPays: option } : ticket
    );
    setFieldValue('ticketTypes', updatedTicketTypes);
  };

  const isDisabled = !isValid || !values.eventTime || !values.eventDate;
  const eventDatePickerRef = useRef<DatePicker | null>(null);
  const salesDeadlinePickerRefs = useRef<(DatePicker | null)[]>([]);

  const getMinDate = (eventDate: string | Date | null) => {
    if (eventDate && new Date().getDate() === new Date(eventDate).getDate()) {
      return new Date(new Date().setHours(23, 0, 0, 0));
    }
    return new Date();
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center w-full">
        <section
          className="flex ml-6 cursor-pointer gap gap-x-2"
          onClick={() => navigate(-1)}
        >
          <BackIconWonWithBG />
          <p className="self-center">Back</p>
        </section>
        <div className="pt-12 mb-32">
          <header className="mb-6 text-center">
            <h1 className="mb-2 text-2xl font-normal leading-7 text-elr-black">
              Event Information
            </h1>
            <p className="text-lg leading-5 text-elr-black-400">
              Kindly {isEditing ? 'edit' : 'fill'} your event details
            </p>
          </header>
          <form
            className="w-full px-10 py-8 mx-auto mb-10 bg-elr-white-400"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col gap-3">
              <ElrInput
                onChange={handleChange}
                value={values.eventName}
                name="eventName"
                className="border-none rounded-none bg-elr-gray !h-12 outline-none"
                placeholder="Event Name"
              />
              <ElrLocationSelect
                className="bg-white pr-2 !h-12 !mb-3"
                currentValue={values.eventLocation?.label}
                placeholder="Event Location"
                onChange={(value) => setFieldValue('eventLocation', value)}
              />
              <ElrTextAreaInput
                onChange={handleChange}
                value={values.eventDescription}
                name="eventDescription"
                className="-mb-1 border-none rounded-none outline-none bg-elr-gray"
                placeholder="Event Description"
              />
              <div className="flex flex-row items-center justify-between gap-4 align-middle md:flex-row">
                <div className="flex items-center justify-between w-full md:w-1/2 bg-elr-gray">
                  <DatePicker
                    selected={values.eventDate}
                    onChange={(date) => setFieldValue('eventDate', date)}
                    className="w-full h-12 py-2 pl-3 pr-10 focus:outline-none bg-elr-gray"
                    placeholderText="Event Date"
                    dateFormat="MM/dd/yyyy"
                    ref={eventDatePickerRef}
                    minDate={new Date()}
                  />
                  <div
                    className="pr-3 cursor-pointer"
                    onClick={() => eventDatePickerRef.current?.setOpen(true)}
                  >
                    <CalendarIcon />
                  </div>
                </div>

                <div className="flex items-center justify-between w-full md:w-1/2 bg-elr-gray">
                  <TimePicker
                    className=" py-2 bg-elr-gray focus:outline-none !border-0 w-full pr-2"
                    placeholder="Event Time"
                    clearIcon={null}
                    value={values.eventTime}
                    onChange={(time) =>
                      setFieldValue(
                        'eventTime',
                        time ? time.format('h:mm A') : ''
                      )
                    }
                  />
                </div>
              </div>

              <div className="mb-5">
                <h3 className="mb-2">Event Banner (optional)</h3>
                <ElrUploadV2
                  autoUpload={false}
                  accept=".jpeg,.jpg,.png"
                  tag={''}
                  onUploaded={() => {}}
                  defaultFile={values.bannerImage}
                  onTempUpload={(file) => setFieldValue('bannerImage', file)}
                />
              </div>
              <section>
                {values.ticketTypes.map((ticket: Ticket, index: number) => (
                  <div key={ticket.id}>
                    <div className="flex items-center justify-between pb-2">
                      <h3>Ticket Type</h3>
                      {index > 0 && (
                        <div
                          className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-elr-orange-900/10"
                          onClick={() => handleDeleteTicketType(index)}
                        >
                          <DeleteIcon />
                        </div>
                      )}
                    </div>
                    <ElrInput
                      onChange={(e) => handleChange(e)}
                      value={values.ticketTypes[index].type}
                      name={`ticketTypes[${index}].type`}
                      className="border-none rounded-none bg-elr-gray !h-12 mb-1 outline-none"
                      placeholder="Enter Ticket Type. e.g vip"
                    />
                    <div className="flex items-center h-12 mt-2 mb-3">
                      <span className="inline-flex items-center h-12 px-3 text-sm border-none bg-elr-deep-gray">
                        NGN
                      </span>
                      <ElrInput
                        onChange={(e) =>
                          setFieldValue(
                            `ticketTypes[${index}].price`,
                            Number(e.target.value)
                          )
                        }
                        value={values.ticketTypes[index].price}
                        name={`ticketTypes[${index}].price`}
                        className="border-none rounded-none bg-elr-gray outline-none !h-12"
                        placeholder="Ticket Price"
                      />
                    </div>
                    <ElrInput
                      onChange={(e) =>
                        setFieldValue(
                          `ticketTypes[${index}].quantity`,
                          Number(e.target.value)
                        )
                      }
                      value={values.ticketTypes[index].quantity}
                      name={`ticketTypes[${index}].quantity`}
                      className="border-none rounded-none bg-elr-gray !h-12 outline-none mb-3"
                      placeholder="Available Quantity"
                    />
                    <div className="flex items-center justify-between w-full bg-elr-gray">
                      <DatePicker
                        selected={values.ticketTypes[index].expiryDate}
                        onChange={(date) =>
                          setFieldValue(
                            `ticketTypes[${index}].expiryDate`,
                            date
                          )
                        }
                        className="h-12 py-2 pl-3 pr-10 bg-elr-gray focus:outline-none"
                        placeholderText="Sales Deadline"
                        minDate={getMinDate(values.eventDate)}
                        maxDate={values.eventDate || undefined}
                        ref={(el) => {
                          salesDeadlinePickerRefs.current[index] = el;
                        }}
                      />
                      <div
                        className="pr-3 cursor-pointer"
                        onClick={() =>
                          salesDeadlinePickerRefs.current[index]?.setOpen(true)
                        }
                      >
                        <CalendarIcon />
                      </div>
                    </div>
                    <div className="mt-3 flex flex-col gap-1.5">
                      <h3 className="pl-1">
                        How many people does this ticket permit?
                      </h3>
                      <div className="flex gap gap-x-6 text-elr-elr-black-300">
                        <ElrRadio
                          checked={ticket.ticketCategory === 'single'}
                          onChange={() =>
                            setFieldValue(
                              `ticketTypes[${index}].ticketCategory`,
                              'single'
                            )
                          }
                          radioClassName="accent-elr-black"
                          label="One Person"
                          labelClassName="text-elr-black-300 before:align-text-top gap-2"
                        />
                        <ElrRadio
                          checked={ticket.ticketCategory === 'group'}
                          onChange={() =>
                            setFieldValue(
                              `ticketTypes[${index}].ticketCategory`,
                              'group'
                            )
                          }
                          radioClassName="accent-elr-black"
                          label="A group of people"
                          labelClassName="text-elr-black-300 before:align-text-top gap-2"
                        />
                      </div>

                      {values.ticketTypes[index].ticketCategory === 'group' && (
                        <ElrInput
                          onChange={(e) =>
                            setFieldValue(
                              `ticketTypes[${index}].groupQuantity`,
                              Number(e.target.value)
                            )
                          }
                          value={values.ticketTypes[index].groupQuantity || ''}
                          name={`ticketTypes[${index}].groupQuantity`}
                          className="border-none rounded-none bg-elr-gray !h-12 outline-none mb-3 mt-3"
                          placeholder="Enter maximum people allowed"
                        />
                      )}
                    </div>
                    <div className="mt-3 flex flex-col gap-1.5">
                      <h3 className="pl-1">Who pays service charges?</h3>
                      <p className="pl-1 text-sm text-elr-green">
                        (We charge a flat fee of NGN 150 per ticket purchase.)
                      </p>
                      <div className="flex gap gap-x-6 text-elr-elr-black-300">
                        <div className="flex gap gap-x-6 text-elr-black-300">
                          <ElrRadio
                            checked={
                              ticket.whoPays === EventChargesType.Business
                            }
                            onChange={() =>
                              handleOptionClick(
                                index,
                                EventChargesType.Business
                              )
                            }
                            radioClassName="accent-elr-black"
                            label="I Pay"
                            labelClassName="text-elr-black-300 before:align-text-top gap-2"
                          />
                          <ElrRadio
                            checked={
                              ticket.whoPays === EventChargesType.Customer
                            }
                            onChange={() =>
                              handleOptionClick(
                                index,
                                EventChargesType.Customer
                              )
                            }
                            radioClassName="accent-elr-black"
                            label="Customer Pays"
                            labelClassName="text-elr-black-300 before:align-text-top gap-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-4 border bg-elr-gray-neutral-200 border-elr-gray-neutral-200" />
                  </div>
                ))}

                <div
                  className="flex items-center justify-end cursor-pointer gap gap-x-2 text-elr-purple"
                  onClick={handleAddTicketType}
                >
                  <PlusIcon />
                  <p>Add Another Ticket Type</p>
                </div>
              </section>
            </div>
            <div className="mx-auto text-center mt-14">
              <ElrButton
                text={isEditing ? 'Update Event' : 'Preview'}
                className="w-full rounded-sm bg-elr-black text-elr-white-400"
                onClick={() => handleSubmit()}
                disabled={isDisabled}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
