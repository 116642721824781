import CSV from 'assets/images/CSV.svg';
import { SENDER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import { ElrDropdownIconToggle } from 'ui-components';
import { useState } from 'react';

interface Props {
  textContainerClass?: string;
}

const EstimateBulkContainer: React.FC<Props> = ({
  textContainerClass = 'w-52',
}) => {
  const [showCSV, setShowCSV] = useState(false);

  return (
    <div>
      <div className="flex justify-center items-center relative bottom-2 hover:text-elr-green ">
        <p className="text-sm opacity-60 relative top-0.5">For bulk delivery</p>
        <span
          className="pt-0.6 opacity-30 relative top-0.5 cursor-pointer"
          onClick={() => setShowCSV(!showCSV)}
        >
          <ElrDropdownIconToggle isToggled={showCSV} toggle={setShowCSV} />
        </span>
      </div>
      {showCSV && (
        <div className="mb-4">
          <Link to={`/${SENDER}/${privateRoutes.bulkEstimate}`}>
            <div className="border-elr-green border rounded-md h-16 w-full py-4 flex">
              <div className=" justify-self-start">
                <img className="w-8 h-7 ml-4" src={CSV} alt="CSV icon" />
              </div>
              <div className={`flex-initial ${textContainerClass} self-center`}>
                <p className="text-center opacity-70 ">Upload CSV</p>
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default EstimateBulkContainer;
