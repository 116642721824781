/* eslint-disable react-hooks/exhaustive-deps */
import { BatchedInterface } from 'app/api/d';
import { InitialInterfaceData } from 'app/api/types';
import BatchContainer from 'app/components/dashboard/BatchContainer';
import BatchUpcomingContainer from 'app/components/dashboard/BatchUpcomingContainer';
import DashboardContainer from 'app/components/DashboardContainer';
import MyWallet from 'app/components/MyWallet';
import {
  useFetchDeliveriesMade,
  useFetchNewBatchRequests,
  useFetchNewRequests,
  useFetchUpcomingRequests,
  useFetchProductsList,
} from 'app/hooks/requests/dashboard';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import DashboardPanel from 'app/platform/modules/DashboardPanel';
import DeliveriesMade from 'app/platform/modules/DeliveriesMade';

import {
  batchRequestTabs,
  dispatchTabs,
  roundUpAmount,
} from 'app/utilities/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import ProductsList from 'app/commerce/modules/product/ProductsLists';

const Dashboard: React.FC = () => {
  const [stepState, setStateState] = useState<number>(0);
  const [tabs, setTabs] = useState(dispatchTabs);
  const {
    id: userId,
    currentAccessRole,
    accountInformation: { id: roleId, ...accountData },
  } = useGetProfile();

  // hooks for fetching requests (single)
  const { latestData: singleNewRequestData } =
    useFetchNewRequests('marketplace');
  const { latestData: upcomingSingleDeliveries } = useFetchUpcomingRequests('');
  const { latestData: deliveriesMadeRequestData } = useFetchDeliveriesMade();
  const { totalDocs } = useFetchProductsList();

  // hooks for fetching requests (batches)
  const { latestData: newBatchRequestData } = useFetchNewBatchRequests(
    batchRequestTabs[0].id
  );

  const [getWalletBalance, wallet] = useGetWalletBalance(
    userId,
    roleId,
    currentAccessRole
  );

  // states for requests (single, batches)
  const [batchDeliveriesMade] = useState<BatchedInterface>({
    batches: InitialInterfaceData,
  });

  const getBalance = useCallback(async () => {
    await getWalletBalance();
  }, [wallet.balance]);

  const toggleBatchTab = () => {
    const clonedTab = [...tabs];
    const batchId = clonedTab.findIndex((item) => item.id === 'batch');
    clonedTab[batchId].disabled = !accountData.batchingActive;
    setTabs(clonedTab);
  };

  useEffect(() => {
    getBalance();
    toggleBatchTab();
  }, [accountData.batchingActive]);

  const walletTotal = roundUpAmount(+wallet.balance);

  const panels = [
    null,
    <BatchContainer tabs={tabs} displayOriginalFees />,
    <BatchUpcomingContainer displayOriginalFees />,
    <DeliveriesMade displayOriginalFees />,
    <MyWallet walletBalance={walletTotal} />,
    <ProductsList />,
  ];

  const onChangePanel = (index: number) => {
    setStateState(index);
  };

  const newRequestsTotal =
    singleNewRequestData.totalDocs + newBatchRequestData.totalDocs;
  const upcomingRequestsTotal = upcomingSingleDeliveries.totalDocs;
  const deliveriesMadeTotal =
    deliveriesMadeRequestData.totalDocs + batchDeliveriesMade.batches.totalDocs;
  const productsListTotal = totalDocs || 0;

  return (
    <DashboardContainer
      titleTag="Errandlr - API Dashboard"
      panelComponent={panels[stepState] ? panels[stepState] : null}
      dashboardPanel={
        <DashboardPanel
          totalDocs={{
            accountBalance: walletTotal,
            newRequestsTotal,
            upcomingRequestsTotal,
            deliveriesMadeTotal,
            productsListTotal,
          }}
          onClick={onChangePanel}
          financeLabel="Wallet"
          panelActive={stepState}
        />
      }
    />
  );
};

export default Dashboard;
