import { useFormik } from 'formik';
import { ElrSwitch } from 'ui-components';
import { useGetProfile } from 'app/hooks/user';
import validationSchema from './validationSchema';
import { SettingsValue } from '.';

interface BatchModeProps {
  updateSettings: (settings: SettingsValue) => void;
  handleToggle: (
    settingKey: keyof SettingsValue,
    setFieldValue: (valueKey: string, newValue: boolean) => void,
    values: SettingsValue
  ) => void;
}

const BatchMode = ({ handleToggle, updateSettings }: BatchModeProps) => {
  const { accountInformation } = useGetProfile();
  const { values, setFieldValue } = useFormik({
    initialValues: {
      autoProcessBatch: accountInformation.autoProcessBatch ?? false,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      updateSettings(values as SettingsValue);
    },
  });

  return (
    <div className="weight-normal text-sm relative border-elr-gray bg-white border-opacity-30 px-8 py-6">
      <div className="flex justify-between">
        <div>
          <p className="text-xl pb-2">Batch Mode</p>
          <p className="text-base text-elr-black-400">
            Automatic batch processing
          </p>
        </div>
        <div className="flex items-center gap-2 cursor-pointer">
          <ElrSwitch
            toggle={() =>
              -handleToggle(
                'autoProcessBatch',
                setFieldValue,
                values as SettingsValue
              )
            }
            isToggled={Boolean(values.autoProcessBatch)}
            toggledParentClassName="!h-6 !w-9 !bg-elr-black"
            toggledCircleClassName="!bg-white !w-4 !h-4"
            parentClassName="!h-6 !w-9"
            circleClassName="!w-4 !h-4"
          />
        </div>
      </div>
    </div>
  );
};

export default BatchMode;
