import copyToClipboard from 'app/utilities/copyToClipboard';
import copy from 'assets/images/copyIcon.svg';
import mark from 'assets/images/mark-icon.svg';
import markDark from 'assets/images/mark-icon-black.svg';
import { useState } from 'react';
import copyIcon from 'assets/images/copy-link.svg';
import tick from 'assets/images/tick-icon.svg';

interface Props {
  content: string;
  theme?: 'light' | 'dark';
}

export const ElrCopy = ({ content, theme = 'light' }: Props) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    copyToClipboard(content);
    setCopied(!copied);
  };

  const copyTheme = {
    light: {
      copy: () => (
        <button
          className="flex items-center focus:opacity-60 bg-elr-gray-600 bg-opacity-60 px-2 py-1 h-5 w-7"
          type="button"
          onClick={handleCopy}
        >
          <img alt="copy" src={copy} />
        </button>
      ),
      check: () => (
        <div className=" h-5 w-7 rounded bg-elr-purple bg-opacity-20 justify-center items-center">
          <img src={mark} alt="mark icon" className="pl-2 pt-2" />
        </div>
      ),
    },

    dark: {
      copy: () => (
        <span onClick={handleCopy}>
          <img className="h-5 md:h-4" alt="copy" src={copy} />
        </span>
      ),
      check: () => <img className="h-4" src={markDark} alt="mark icon" />,
    },
  };

  return (
    <div>{!copied ? copyTheme[theme].copy() : copyTheme[theme].check()}</div>
  );
};

export const ElrCopyLight = ({ content, ...props }: Props) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    copyToClipboard(content);
    setCopied(true);
  };
  return (
    <div
      {...props}
      className="border border-elr-purple bg-elr-purple bg-opacity-10 rounded-md"
      onClick={handleCopy}
    >
      <p className="text-elr-purple flex gap-2 pl-2 pr-1 items-center pt-1 xxl:pl-1 xxl:pt-0 xxl:gap-1 cursor-pointer">
        <span className="xxl:pt-0.5">{copied ? 'Copied' : 'Copy Link'}</span>
        <img
          src={copied ? tick : copyIcon}
          className=" pb-1 xxl:pt-1 xxl:pb-1 "
          alt="copy icon"
        />
      </p>
    </div>
  );
};
