import { useQuery } from '@tanstack/react-query';
import { staticLocationTypes } from 'app/constants';
import { defaultEstimateData, useSetEstimateCheckout } from 'app/hooks/utils';
import { ESTIMATE_CHECKOUT_PAYLOAD } from 'app/state/constants';
import { Session } from 'app/utilities/Session';
import { getCurrentCountry } from 'app/utilities/country';
import {
  HttpLocationValues,
  LocationTagValues,
} from 'app/utilities/types/shared';
import { FormikErrors } from 'formik';
import { capitalize } from 'lodash';
import React from 'react';
import { ElrLocationSelect } from 'ui-components';
import { ElrSelect } from 'ui-components/components/ElrSelect/ElrSelect';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  values: HttpLocationValues;
  errors: FormikErrors<HttpLocationValues>;
  onLocationInputChange: (field: string, value: any, option?: boolean) => void;
}

const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const DeliveryDestinationsInput: React.FC<Props> = ({
  errors,
  values,
  onLocationInputChange,
}) => {
  const [setEstimateCheckoutState] = useSetEstimateCheckout();
  const uuid = uuidv4();

  const handleDestinationsChange = (
    value: { label: string; id: string; duration: number; distance: number },
    index: number
  ) => {
    const destinations = [...values.dropoffLocations];
    destinations[index] = value;
    const cleanDestinations = destinations.filter(Boolean);

    if (cleanDestinations.length < 1)
      cleanDestinations[0] = { id: '', label: '', distance: 0, duration: 0 };

    onLocationInputChange(
      staticLocationTypes.dropoffLocations,
      cleanDestinations
    );
  };

  const tenantData = Session.tenantData();
  const userCountry = getCurrentCountry();
  const states = tenantData[userCountry]
    ? Object.keys(tenantData[userCountry].states)
    : [];

  const onChangeState = ({
    value,
    direction,
  }: {
    value: string;
    direction: 'to' | 'from';
  }) => {
    // update estimate payload
    if (direction === 'to') {
      setEstimateCheckoutState({ toState: value });
    }
    if (direction === 'from') {
      setEstimateCheckoutState({ fromState: value });
    }
  };

  return (
    <div className="mt-3">
      <StateSelector
        onChange={({ value }) => onChangeState({ value, direction: 'from' })}
        type="from"
        states={states}
      />
      <div
        className={`text-xs mb-1 text-elr-error ${
          errors?.pickupLocation?.label ? 'visible h-3.5' : 'invisible'
        }`}
      >
        {errors?.pickupLocation?.label ? errors.pickupLocation?.label : null}
      </div>
      <ElrLocationSelect
        currentValue={values.pickupLocation?.label}
        placeholder="Pickup Location"
        onChange={(value) =>
          onLocationInputChange(staticLocationTypes.pickupLocation, value)
        }
      />
      <StateSelector
        onChange={({ value }) => onChangeState({ value, direction: 'to' })}
        type="to"
        states={states}
      />
      {values.dropoffLocations.map((destination, index: number) => {
        const error = errors?.dropoffLocations
          ? (errors?.dropoffLocations?.[index] as
              | FormikErrors<LocationTagValues>
              | LocationTagValues)
          : null;

        const origin =
          index > 0
            ? values.dropoffLocations[index - 1] &&
              values.dropoffLocations[index - 1]?.label
            : values.pickupLocation?.label;

        return (
          <div
            key={`${uuid}${getRandomNumber(0, 1543)}${+new Date()}`}
            className="relative"
          >
            <div
              className={`text-xs mb-1 text-elr-error text-right ${
                error ? 'visible h-3.5' : 'invisible'
              }`}
            >
              {error?.label}
            </div>
            <ElrLocationSelect
              isDestination
              origin={origin}
              estimate={destination}
              currentValue={destination?.label}
              placeholder="Delivery Destination"
              onChange={(value) =>
                handleDestinationsChange(value as any, index)
              }
            />
          </div>
        );
      })}
    </div>
  );
};

const StateSelector: React.FC<{
  states: string[];
  type: 'from' | 'to';
  onChange: (obj: { value: string }) => void;
}> = ({ states, type, onChange }) => {
  const { data = { fromState: '', toState: '' } } = useQuery({
    queryKey: [ESTIMATE_CHECKOUT_PAYLOAD],
    queryFn: () => defaultEstimateData,
  });

  const value = data[`${type}State`]
    ? {
        label: capitalize(data[`${type}State`]),
        value: data[`${type}State`],
      }
    : undefined;

  return (
    <div className="relative flex items-center justify-between">
      <div className="flex items-center">
        <p className="mr-3 font-bold text-elr-black text-opacity-70">
          {capitalize(type)}:
        </p>
        <ElrSelect
          options={states.map((state) => ({
            label: capitalize(state),
            value: state,
          }))}
          containerBackgroundColor="transparent"
          controlBackgroundColor="transparent"
          controlHeight={20}
          containerHeight={20}
          bgcolor="#FFF"
          className="w-32"
          showIndicator
          isClearable={false}
          value={value}
          {...{ onChange }}
        />
      </div>
      {!data[`${type}State`] && (
        <p className="text-xs text-elr-error">Required</p>
      )}
    </div>
  );
};
