import { Session } from 'app/utilities/Session';
import { DEFAULT_COUNTRY } from 'app/utilities/helpers';
import countryFromTimezone from 'countries-and-timezones';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useGetDomainCountry } from './domain';

const useGetCountry = () => {
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const domainCountry = useGetDomainCountry();

  useEffect(() => {
    const timezone = DateTime.local().zoneName;
    const timezoneCountry = (
      countryFromTimezone.getCountryForTimezone(timezone)?.name ||
      DEFAULT_COUNTRY
    ).toLowerCase();
    let derivedCountry = domainCountry || timezoneCountry;

    const tenantData = Session.tenantData();
    const availableCountries = Object.keys(tenantData);

    if (!availableCountries.includes(derivedCountry)) {
      derivedCountry = DEFAULT_COUNTRY;
    }
    setCountry(derivedCountry);
  }, [domainCountry]);
  return country;
};

interface getCountries {
  result: {
    countryKey: string;
    countryShortCode: string;
    currency: string;
    countryLongName: string;
    countryLocale: string;
    countryPhoneCode: string;
    countryLangCode: string;
  }[];
  select: {
    label: string;
    value: string;
  }[];
  list: string[];
}

export const useGetCountries = (): getCountries => {
  const tenantData = Session.tenantData();
  const countries = Object.keys(tenantData);

  const result = Object.entries(tenantData).reduce<
    {
      countryKey: string;
      countryShortCode: string;
      currency: string;
      countryLongName: string;
      countryLocale: string;
      countryPhoneCode: string;
      countryLangCode: string;
    }[]
  >((acc, [countryKey, countryValue]) => {
    const {
      countryShortCode,
      countryLongName,
      countryLocale,
      countryPhoneCode,
      currency,
      countryLangCode,
    } = countryValue;
    acc.push({
      countryKey,
      countryShortCode,
      countryLongName,
      countryLocale,
      countryPhoneCode,
      currency,
      countryLangCode,
    });
    return acc;
  }, []);

  const select = Object.entries(tenantData).reduce<
    {
      value: string;
      label: string;
    }[]
  >((acc, [, countryValue]) => {
    const { countryShortCode, countryLongName } = countryValue;
    acc.push({
      value: countryShortCode,
      label: countryLongName,
    });
    return acc;
  }, []);

  return {
    result,
    select,
    list: countries,
  };
};

/**
 * Pass ISO value to get result
 * @param value
 */
export const useGetCountryLocale = () => {
  const countryList = useGetCountries();
  const getData = (value: string) =>
    countryList.result.find((r) => r.countryShortCode === value);
  return [getData];
};

export default useGetCountry;
