import DeliveryDetails from 'app/components/DeliveryDetails';
import { MobileHeader } from 'app/components/Header';
import { useSubmitAgentRating } from 'app/hooks/requests/agent';
import { useGetParcelDetails } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import { getCurrentCountryData } from 'app/utilities/country';
import { currencyFormatter } from 'app/utilities/helpers';
import { Request } from 'app/utilities/types/shared';
import Logo from 'assets/images/logo.svg';
import { truncate } from 'lodash';
import { FormEvent, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ElrButton,
  ElrDropdownIconToggle,
  ElrModal,
  ElrPageTitle,
  ElrPillButton,
  ElrStarRating,
  ElrTextAreaInput,
} from 'ui-components';
import { ElrCopy } from 'ui-components/components/ElrCopy/ElrCopy';

type UrlParams = {
  trackingId: string;
};

const Rate = () => {
  const { trackingId } = useParams() as UrlParams;
  const { data: parcel }: { data: Request | undefined } =
    useGetParcelDetails(trackingId);

  const [sendAgentRating, isLoading] = useSubmitAgentRating();
  const { id: userId } = useGetProfile();

  const [modal, setModal] = useState(false);
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const textAreaRef = useRef('');

  if (!parcel) {
    return <div />;
  }

  const {
    id: parcelId,
    designatedAgent: { id: agentId },
    pickupAddress,
    priceEstimate,
  } = parcel;

  const handleModal = () => {
    navigate(-1);
    setModal(false);
  };

  const onTextAreaChange = (e: any) => {
    textAreaRef.current = e.target.value;
  };

  const onSubmitRating = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await sendAgentRating(userId, {
      rating,
      comment: textAreaRef.current,
      agentId,
      request: parcelId,
    });
    res !== null && setModal(true);
  };

  return (
    <>
      <ElrModal
        height="h-155"
        width="w-304"
        isOpen={modal}
        onClose={() => setModal(false)}
      >
        <div className="flex flex-col gap-2 text-center">
          <p>Your review of the rider has been recorded, Thank you.</p>
        </div>
        <div className="flex justify-center gap-8 mt-10">
          <ElrPillButton
            onClick={() => handleModal()}
            size="sm"
            text="OK"
            className="flex items-center justify-center h-12 px-8 text-base text-white bg-elr-black"
          />
        </div>
      </ElrModal>

      <div className="bg-elr-gray-500 h-1000">
        <ElrPageTitle title="Errandlr - Track Parcel" />
        <div className="w-full md:w-11/12 md:mx-auto">
          <Link to="/">
            <img className="hidden pt-10 md:block" src={Logo} alt="logo" />
          </Link>
          <MobileHeader mdHide />
        </div>
        <div className="flex items-center justify-center">
          <div className="flex flex-col gap-3 mt-16 md:w-600 w-350">
            <div className="h-40 border rounded-md bg-elr-white-400 border-opacity-40 border-elr-green">
              <h1 className="pt-12 text-3xl text-center">How did we do?</h1>
              <div className="flex items-center justify-center gap-1 pt-5 md:gap-4">
                <h2 className="text-sm opacity-50">
                  Delivery ID: {trackingId}
                </h2>
                <ElrCopy content={trackingId} />
              </div>
            </div>
            <div className="p-4 mt-4 border rounded-md w-350 md:w-600 bg-elr-white-400 border-opacity-40 border-elr-green">
              <p className="pb-2 border-b text-16 border-opacity-20 border-elr-black ">
                Summary
              </p>
              <div className="flex justify-between pt-4 opacity-60 ">
                <span>
                  {truncate(pickupAddress.fullAddress, { length: 42 })}
                </span>
                <span>{currencyFormatter(+priceEstimate)}</span>
                <span>
                  <ElrDropdownIconToggle
                    isToggled={isToggled}
                    toggle={setIsToggled}
                  />
                </span>
              </div>
              {isToggled && (
                <div>
                  <DeliveryDetails details={parcel} />
                  <div className="flex justify-between mt-10 mb-2 md:text-base">
                    <p className="opacity-60"> Total charged: </p>
                    <p className="text-xl">
                      &nbsp;
                      {getCurrentCountryData().currencyCode.toUpperCase()}&nbsp;
                      {priceEstimate}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <form
              onSubmit={onSubmitRating}
              className="p-5 border rounded-md bg-elr-white-400 border-opacity-40 border-elr-green"
            >
              <p className="text-16">Rate rider</p>
              <div className="flex gap-2 mt-5 mb-7">
                <ElrStarRating rating={rating} setRating={setRating} />
              </div>
              <p className="pb-5 text-16">Additional Comments</p>
              <ElrTextAreaInput
                className="p-4 text-sm bg-elr-gray-600 bg-opacity-40"
                placeholder="I have just contacted the recipient of the parcel I sent which was suppose to arrive at 12noon, its 3pm and it hasnt been recieved! "
                type="text"
                onChange={onTextAreaChange}
              />
              <ElrButton
                text="Submit"
                loading={isLoading}
                submit
                spinnerColor="White"
                className="text-lg text-white mt-7 bg-elr-black"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rate;
