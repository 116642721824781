import { httpGetAgents } from 'app/api/agent';
import { CenterLogoHeader } from 'app/components/Header';
import { SaveForLater } from 'app/components/SaveForLaterTooltip';
import { useGetAgents } from 'app/hooks/dispatch';
import { defaultProfileEntry, Profile, useGetProfile } from 'app/hooks/user';
import { AGENT, DISPATCHER } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import check from 'assets/images/CheckCircle.svg';
import editIcon from 'assets/images/editIcon.svg';
import spinner from 'assets/images/spinner-black.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ElrButton, ElrLeftArrowButton, ElrPageTitle } from 'ui-components';
import AgentForm from './AgentForm';

interface Props {
  stepIndex: number;
  onChangeStep: (index: number) => void;
}

const useUpdateUserInformation = () => {
  const queryClient = useQueryClient();

  const update = () => {
    queryClient.setQueryData(['user'], (oldData: any | Profile) => {
      if (!oldData) return defaultProfileEntry;
      return {
        ...oldData,
        access: [...oldData.access, DISPATCHER, AGENT],
      };
    });
  };
  return [update];
};

const AddAgent: React.FC<Props> = ({ stepIndex, onChangeStep }) => {
  const GetAgents = () => useGetAgents();
  const history = useNavigate();
  const agents = GetAgents();
  const [saving, setSaving] = useState<boolean>(false);
  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [displayAddAgentForm, setDisplayAddAgentForm] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const { id: userId, accountInformation } = useGetProfile();
  const [update] = useUpdateUserInformation();

  const prefetchAgents = useCallback(async () => {
    try {
      const response = await httpGetAgents(userId, accountInformation.id, {
        limit: 5,
      });
      queryClient.setQueryData(['agentsList'], response.docs);
    } catch (error) {
      /** no op */
    }
  }, [accountInformation.id, queryClient, userId]);

  // prefetch
  useEffect(() => {
    if (accountInformation.id) {
      prefetchAgents();
    }
  }, [accountInformation.id, prefetchAgents]);

  const save = () => {
    setSaving(!saving);
    setTimeout(() => {
      setSaving(false);
      setShowBtn(true);
    }, 1000);
  };

  const onCompleteAddingAgent = () => {
    setDisplayAddAgentForm(false);
  };

  const NoListOfAgents = () => (
    <>
      <div className="mt-12 mb-24 md:mb-20 text-xl text-center">Add Riders</div>

      <div className="h-80 md:h-20 flex flex-col md:flex-row justify-between">
        <div className="h-10 flex justify-center">
          <ElrButton
            text="Add rider"
            size="xm"
            onClick={() => setDisplayAddAgentForm(true)}
            className="bg-elr-purple bg-opacity-10 w-12 h-7 text-elr-purple"
          />
        </div>
      </div>
      <div>
        <SaveForLater onSaveForLater={save} isLoading={saving} />
      </div>
    </>
  );

  const AgentLists = () => (
    <>
      <div>
        <div className="mt-12 mb-24 md:mb-20 text-xl text-center">
          Add Riders
        </div>
        {agents.map(({ agentUUID, id, firstname, lastname }) => (
          <div
            className="h-10 md:h-12 mb-2.5 px-2.5 py-6 md:py-7 flex items-center bg-elr-gray w-full"
            key={id}
          >
            <img src={check} alt="error" className="mr-9" />
            <div className="mr-4 text-sm text-elr-gray-400"> {agentUUID} </div>
            <div className="text-sm md:text-base text-elr-gray-400">
              {firstname} {lastname}
            </div>
            <img src={editIcon} alt="edit" className="ml-auto" />
          </div>
        ))}
      </div>
      <div className="h-60 pt-9 mb-7 md:mb-12 md:h-20 flex flex-col md:flex-row justify-between">
        <div className="h-10 flex">
          <ElrButton
            text="Add new"
            size="xm"
            onClick={() => setDisplayAddAgentForm(true)}
            className="bg-elr-purple bg-opacity-10 h-7 text-elr-purple"
          />
        </div>
        <div className="md:h-7 flex ml-auto text-elr-purple opacity-50">
          {saving && (
            <span className="mt-2 -mr-2">
              <img src={spinner} className="animate-spin h-3.5" alt="spinner" />
            </span>
          )}
          <ElrButton
            text="save for later"
            size="xm"
            onClick={save}
            className="bg-elr-white-400 w-12 h-7 text-elr-purple"
          />
        </div>
      </div>
    </>
  );

  const displayView = () => {
    if (displayAddAgentForm) {
      return (
        <AgentForm onClose={() => {}} onComplete={onCompleteAddingAgent} />
      );
    }
    return agents.length ? <AgentLists /> : <NoListOfAgents />;
  };

  const onCompleteOnboarding = () => {
    update();
    return history(privateRoutes.dashboard(DISPATCHER));
  };

  return (
    <div>
      <ElrPageTitle title="Errandlr - Add Agent" />
      <div className="flex flex-col items-center">
        <CenterLogoHeader />
        <div className="px-7 md:px-10 h-full w-11/12 md:w-430 bg-elr-white-400 rounded-2xl flex flex-col mb-12">
          {displayView()}
          <div>
            <div className="w-full border-b border-elr-gray-600 mb-10 mt-3 md:mb-12 opacity-60" />
            <div className="flex justify-between">
              <div onClick={() => onChangeStep(stepIndex - 1)}>
                <ElrLeftArrowButton />
              </div>
              {(agents.length || showBtn) && (
                <div>
                  <ElrButton
                    text="Complete"
                    className="bg-elr-black text-white text-lg w-40 mb-3"
                    onClick={onCompleteOnboarding}
                  />
                </div>
              )}
            </div>
            <div className="mb-10 md:mb-12" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddAgent);
