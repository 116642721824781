import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  httpOnboardDispatcher,
  httpUpdateDispatcherProfile,
} from 'app/api/dispatcher';
import { CenterLogoHeader } from 'app/components/Header';
import { useGetProfile } from 'app/hooks/user';
import { privateRoutes } from 'app/utilities/routes';
import { CompanyDetails as CompanyDetailsInterface } from 'app/utilities/types/dispatcherTypes';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ElrButton,
  ElrInput,
  ElrLeftArrowButton,
  ElrLocationSelect,
  ElrPageTitle,
} from 'ui-components';
import * as Yup from 'yup';

interface Props {
  stepIndex: number;
  onChangeStep: (index: number) => void;
}

interface FormValue {
  companyName: string;
  addressNumber: string;
  fullAddress: {
    id: string;
    label: string;
    value: string;
  };
}

const CompanyDetailSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  addressNumber: Yup.string()
    .required('Required')
    .test(
      'positiveNumbers',
      'must be positive numbers',
      (value: any) => value && value.match(/^[!^1-9]\d*$/)
    ),
  fullAddress: Yup.object().shape({
    id: Yup.string().required('Required'),
    label: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
  }),
});

const CompanyDetails: React.FC<Props> = ({ stepIndex, onChangeStep }) => {
  const history = useNavigate();
  const [incomplete, setIncomplete] = useState(
    (new URLSearchParams(useLocation().search).get('incomplete') && true) ||
      undefined
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIncomplete(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  const queryClient = useQueryClient();
  const {
    id: userId,
    accountInformation: {
      id,
      mainAddress = {
        addressNumber: '',
        streetName: '',
        localGovtArea: '',
        registeredCity: '',
        fullAddress: '',
        placeId: '',
      },
      companyName,
    } = {},
  } = useGetProfile();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: CompanyDetailsInterface) => {
      if (companyName) {
        return httpUpdateDispatcherProfile(
          { ...payload, dispatcherId: id },
          userId
        );
      }
      return httpOnboardDispatcher(payload, userId);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: object | undefined) =>
        oldData ? { ...oldData, accountInformation: data } : {}
      );
    },
  });

  const onUpdateCompanyDetails = async (values: FormValue) => {
    try {
      const payload = {
        companyName: values.companyName,
        mainAddress: {
          addressNumber: `${values.addressNumber}`,
          fullAddress: values.fullAddress.label,
          placeId: values.fullAddress.id,
        },
      };

      await mutateAsync(payload);

      onChangeStep(stepIndex + 1);
    } catch (error) {
      /* noop */
    }
    return null;
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik<FormValue>({
      initialValues: {
        companyName: companyName || '',
        addressNumber: mainAddress?.addressNumber || '',
        fullAddress: {
          id: mainAddress?.placeId || '',
          label: mainAddress?.fullAddress || '',
          value: mainAddress?.placeId || '',
        },
      },
      validationSchema: CompanyDetailSchema,
      onSubmit: (value: FormValue) => onUpdateCompanyDetails(value),
    });

  const goToLoginPortal = () => history(privateRoutes.selectPortal);

  return (
    <div>
      <ElrPageTitle title="Errandlr - Login" />
      <div className="flex flex-col items-center">
        <CenterLogoHeader />
        <form onSubmit={handleSubmit} className="w-11/12 h-full mb-12 md:w-430">
          <div className="flex flex-col px-7 md:px-10 bg-elr-white-400 rounded-2xl">
            {incomplete && (
              <p className="mt-6 text-xs text-center text-elr-error">
                Please fill in all the Required Information.
              </p>
            )}
            <div className="mb-16 text-xl text-center mt-14">
              <p>Company details</p>
            </div>
            <div>
              {errors?.companyName && (
                <span className="text-xs text-elr-error">
                  {errors?.companyName}
                </span>
              )}
              <ElrInput
                className="h-10 border-none rounded-none bg-elr-gray mb-14"
                placeholder="Company name"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
              />
              {errors?.addressNumber && (
                <span className="text-xs text-elr-error">
                  {errors?.addressNumber}
                </span>
              )}
              <ElrInput
                className="mb-3 border-none rounded-none bg-elr-gray"
                placeholder="Company address number"
                name="addressNumber"
                type="number"
                value={values.addressNumber}
                onChange={handleChange}
              />
              {errors?.fullAddress && (
                <span className="text-xs text-elr-error">Required</span>
              )}
              <ElrLocationSelect
                className="bg-white"
                currentValue={values.fullAddress?.label}
                placeholder="Enter address here"
                onChange={(value) => setFieldValue('fullAddress', value)}
              />
            </div>
            <div className="w-full mb-10 border-b border-elr-gray-600 md:mb-12 opacity-60" />
            <div className="flex justify-between">
              <div
                onClick={goToLoginPortal}
                className="rounded-full h-9 w-9 mr-4 pl-2.5"
              >
                <ElrLeftArrowButton />
              </div>
              <ElrButton
                text="Next"
                submit
                disabled={isPending}
                className="w-40 mb-3 text-lg text-white bg-elr-black"
              />
            </div>
            <div className="mb-10 md:mb-12" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyDetails;
