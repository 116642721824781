import { httpGenerateApiKey, httpPostWebhookUrl } from 'app/api/commerce';
import LabelBox from 'app/commerce/components/LabelBox';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import infoIcon from 'assets/images/infoIcon.svg';
import warningIcon from 'assets/images/warning-icon.svg';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';
import isValidUrl from 'validator/es/lib/isURL';
import * as styles from './ClassUtils';

const Developer: React.FC = () => {
  const profile = useGetProfile();

  const { webhookUrl = '' } = profile.accountInformation;
  const [liveWebhookURL, setLiveWebhookURL] = useState(webhookUrl);
  const [loading, setIsLoading] = useState(false);

  const { id: userId, accountInformation } = profile;

  const commerceId = accountInformation.id;

  const generateApiKey = async () => {
    try {
      setIsLoading(true);
      await httpGenerateApiKey(userId, commerceId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePostWebhookUrl = async () => {
    if (
      liveWebhookURL &&
      !isValidUrl(liveWebhookURL, { protocols: ['https'] })
    ) {
      toast.error('Webhook URL is not properly formatted. HTTPS only');
      return;
    }

    try {
      setIsLoading(true);
      await httpPostWebhookUrl(userId, commerceId, liveWebhookURL);
      setIsLoading(false);
      toast.success('Webhook updated successfully');
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full">
      <ElrPageTitle title="Errandlr - API Documentation" />
      <ProfileNavigationMenu>
        <div className="pb-10">
          <TopNavigationMenu />
          <div className="px-8 md:px-24 flex justify-between pt-10 pb-8 text-xl">
            <p className="text-center w-[50%]">API Keys & Webhooks</p>
            <p className="text-center w-[50%]">API Keys & Webhooks</p>
          </div>
          <div className="px-8 md:px-24 flex flex-col md:flex-row justify-center">
            {/* Live mode box */}
            <div className="h-full md:min-h-[450px] border-elr-green border-03 border-opacity-60 rounded w-full md:w-1/2 md:mr-4 mb-5 md:mb-0">
              <p className="mt-6 mb-4 text-center text-xl">
                API Configuration - Live Mode
              </p>
              <div className="py-3.5 px-1.5 w-full flex items-center justify-center text-xs bg-elr-green mb-7">
                <img src={warningIcon} alt="warning" />
                <span className="ml-2 text-white">
                  DO NOT USE THIS ON THE CLIENT SIDE
                </span>
              </div>
              <div className="px-5 flex">
                <p className="text-xs xs:text-sm mt-5 w-48 opacity-40">
                  Live Secret Key
                </p>
                <div className="w-full">
                  <LabelBox content="••••••••••••••••••••••••••" liveMode />
                  <p className="text-sm flex text-elr-purple text-opacity-80 mb-4">
                    <ElrButton
                      text="Generate new secret key"
                      className="min-h-0"
                      onClick={generateApiKey}
                      loading={loading}
                    />
                    <img className="ml-1.5" src={infoIcon} alt="info" />
                  </p>
                </div>
              </div>
              <div className="px-5 flex">
                <p className="text-xs xs:text-sm mt-5 w-48 opacity-40">
                  Live Webhook URL
                </p>
                <div className="w-full">
                  <ElrInput
                    value={liveWebhookURL}
                    onChange={(e) => setLiveWebhookURL(e.target.value)}
                    className={styles.inputClass()}
                  />
                </div>
              </div>
              <div className="flex justify-end items-center mt-10 mb-16">
                <ElrButton
                  disabled={loading}
                  loading={loading}
                  className="text-sm px-5 flex items-center text-elr-purple text-opacity-80"
                  onClick={handlePostWebhookUrl}
                  text="Save Changes"
                />
              </div>
            </div>

            {/* Test mode box */}
            <div className="md:min-h-[450px] border-elr-green border-03 border-opacity-60 rounded w-full h-full md:w-1/2">
              <p className="mt-6 mb-4 text-center text-xl">
                API Configuration - Test Mode
              </p>
              <div className="py-3.5 px-1.5 w-full flex items-center justify-center text-xs bg-elr-green mb-7">
                <img src={warningIcon} alt="warning" />
                <span className="ml-2 text-white">
                  These keys are for testing only, please DO NOT use them in
                  production
                </span>
              </div>
              <div className="px-5 flex">
                <p className="text-xs xs:text-sm mt-5 w-48 opacity-40">
                  Test Secret Key
                </p>
                <div className="w-full">
                  <LabelBox content="••••••••••••••••••••••••••" testMode />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default Developer;
