import { TopNav } from 'app/components/menu/Navbar';
import { useEffect } from 'react';
import careersImg from '../../../assets/images/careers.jpg';
import { Footer } from '../landing/components';

const Careers = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <div>
      <TopNav isDefault />
      <div className="flex justify-between md:flex-nowrap flex-wrap px-5 lg:pr-16 py-10 lg:pl-219 items-center">
        <div className="md:pr-16">
          <h2 className="md:text-40 text-32">Want to join Us?</h2>
          <p className=" w-full md:w-364 pt-4 pb-14 md:text-14 text-12">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            nisl eros, pulvinar facilisis justo mollis, auctor consequat
            urna.Lorem ipsum dolor sit amet,{' '}
          </p>
        </div>
        <div>
          <img src={careersImg} alt="Careers jpg" />
        </div>
      </div>
      <div className="bg-white px-5 py-10 lg:pt-69 lg:pb-84 lg:pl-362 lg:pr-264">
        <h3 className="md:text-32 text-22">Our mission</h3>
        <p className="pt-6 md:text-14 text-12">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl
          eros, pulvinar facilisis justo mollis, auctor consequat urna.Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros,
          pulvinar facilisis justo mollis, auctor consequat urna. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Quisque nisl eros,
          pulvinar facilisis justo mollis, auctor consequat urna. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Quisque nisl eros,
          pulvinar facilisis justo mollis, auctor consequat urna. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Quisque nisl eros,
          pulvinar facilisis justo mollis, auctor consequat urna.{' '}
        </p>
      </div>
      <div className="elr-green-50 flex justify-between items-center gap-14 lg:gap-32 flex-col lg:pt-24 lg:pb-48 pt-12 pb-184">
        <h3 className="md:text-32 text-22">See openings</h3>
        <p className="md:text-4xl text-28 opacity-60">No Openings Currently</p>
      </div>
      <Footer hideTopFooter />
    </div>
  );
};

export default Careers;
