import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import { privateRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import BackArrow from 'assets/images/arrowLeft.svg';
import { useState } from 'react';
import { isCurrentTab, requestTabs } from 'app/utilities/helpers';
import FilterInput from 'app/platform/components/FilteredInput/FilteredInput';

const TotalRequests = () => {
  const [currentTab, setCurrentTab] = useState(requestTabs[0]);
  const [requestType, setRequestType] = useState<{
    value: string;
    label: string;
  }>({
    value: '',
    label: '',
  });
  const profile = useGetProfile();
  const { currentAccessRole } = profile;

  const onChangeRequestType = (option: any) => {
    const { value, label } = option;
    setRequestType({
      value,
      label,
    });
  };

  return (
    <div>
      <ElrPageTitle title="Total Requests" />
      <ProfileNavigationMenu>
        <div className="h-1000 ml-[3%] md:ml-[6.1%] mr-[3%]">
          <TopNavigationMenu />
          <div className="">
            <Link
              to={privateRoutes.dashboard(currentAccessRole)}
              className="border-none text-elr-black text-opacity-60 flex gap-3 pt-3 md:pt-auto"
            >
              <img
                className="inline md:opacity-60 md:mr-3 w-5"
                src={BackArrow}
                alt="Back to Dashboard"
              />
              <span>Back</span>
            </Link>
          </div>
          <div className="mb-2 pt-8 w-[600px]">
            <div className="flex gap-x-10 border-elr-gray-400 border-opacity-20 border-b">
              {requestTabs.map((tab) => (
                <button
                  type="button"
                  key={tab.id}
                  onClick={() => setCurrentTab(tab)}
                  className={`cursor-pointer pb-3
                  ${tab.disabled ? 'hidden' : ''}
                  ${isCurrentTab(tab.id, currentTab.id) ? 'border-b' : ''}`}
                >
                  <span
                    className={
                      !isCurrentTab(tab.id, currentTab.id)
                        ? 'text-elr-black text-opacity-60'
                        : ''
                    }
                  >
                    {tab.label}
                  </span>
                </button>
              ))}
            </div>
            <div className="pt-6 border-b border-elr-gray-400 border-opacity-20">
              <p>Total = 200</p>
            </div>
            <div className="hidden md:block">
              <FilterInput
                onChangeRequestType={onChangeRequestType}
                requestType={requestType}
              />
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default TotalRequests;
