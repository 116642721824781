import { useQueryClient } from '@tanstack/react-query';
import { httpLogin } from 'app/api/auth';
import { httpUserProfile } from 'app/api/user';
import { SignUpLinkBtn } from 'app/components/AuthLinkBtn';
import { CenterLogoHeader } from 'app/components/Header';
import { roles, Roles } from 'app/hooks/user';
import { PagesState } from 'app/sender/modules/authOptionsModal';
import { SENDER } from 'app/utilities/roles';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { Session } from 'app/utilities/Session';
import React, { FormEvent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';
import {
  loginButtonClass,
  loginButtonContainer,
  loginContainerClass,
  loginDivClass,
  loginFormClass,
  loginTextClass,
} from './ClassUtils';

type LocationProp = {
  state: {
    from: {
      pathname: string;
    };
  };
};

export const LoginChildComponent: React.FC<{
  slideInMode?: boolean;
  setCurrentScreen?: (state: PagesState) => void;
}> = ({ slideInMode, setCurrentScreen }) => {
  const queryClient = useQueryClient();
  const defaultState = {
    email: '',
    password: '',
  };
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formState, setState] = useState(defaultState);
  const location = useLocation();

  const isSlideInMode = slideInMode && setCurrentScreen;

  const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);
    try {
      const user = await queryClient.fetchQuery({
        queryKey: ['user'],
        queryFn: () =>
          httpLogin({
            email: formState.email,
            password: formState.password,
          }),
      });

      const splitPath = (location as LocationProp).state?.from?.pathname?.split(
        '/'
      )[1];
      const accessInPath = (roles.includes(splitPath) && splitPath) || SENDER;
      Session.setValue('accessUserType', accessInPath);
      await queryClient.fetchQuery({
        queryKey: ['user'],
        queryFn: () => httpUserProfile(accessInPath as Roles),
      });

      setLoading(false);

      if (isSlideInMode) {
        return setCurrentScreen('wallet');
      }

      const roleIncluded = user.access.includes(splitPath as Roles);
      const url =
        (roleIncluded && (location as LocationProp).state?.from?.pathname) ||
        privateRoutes.selectPortal;

      return history(url);
    } catch (error) {
      setLoading(false);
      // display error here
      return error;
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setState({
      ...formState,
      [e.target?.name]: e.target?.value,
    });

  return (
    <div className={loginContainerClass(slideInMode)}>
      <form onSubmit={onSubmit} className={loginFormClass(slideInMode)}>
        <h2 className={loginTextClass(slideInMode)}>Login</h2>

        <div className="mb-3">
          <ElrInput
            className="border-none rounded-none bg-elr-gray"
            onChange={onInputChange}
            name="email"
            type="email"
            required
            placeholder="Email"
          />
        </div>
        <div className="mb-10">
          <ElrInput
            className="border-none rounded-none bg-elr-gray"
            onChange={onInputChange}
            type="password"
            required
            placeholder="Password"
            name="password"
          />
        </div>
        <div className={loginButtonContainer(slideInMode)}>
          <ElrButton
            text="Login"
            submit
            spinnerColor="White"
            loading={loading}
            className={loginButtonClass(slideInMode)}
          />
        </div>
      </form>
      <div className="text-sm text-elr-gray-400 opacity-70 sm:mt-2 pb-14">
        Don’t have an account ?
        <SignUpLinkBtn
          slideInMode={slideInMode}
          setCurrentScreen={setCurrentScreen}
        />
        <Link to={publicRoutes.forgotPassword}>
          <div className="mt-5 text-center text-elr-purple opacity-60">
            Forgot Password
          </div>
        </Link>
      </div>
    </div>
  );
};

interface Props {
  slideInMode?: boolean;
  setCurrentScreen?: (state: PagesState) => void;
}

const Login: React.FC<Props> = ({ slideInMode, setCurrentScreen }) => (
  <div>
    <ElrPageTitle title="Errandlr - Login" />
    <div className={loginDivClass(slideInMode)}>
      <CenterLogoHeader slideInMode={slideInMode} />
      <LoginChildComponent
        slideInMode={slideInMode}
        setCurrentScreen={setCurrentScreen}
      />
    </div>
  </div>
);

export default Login;
