import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpUpdateDispatcherBankInformation } from 'app/api/dispatcher';
import { defaultProfileEntry, Profile, useGetProfile } from 'app/hooks/user';
import { BankDetails } from 'app/utilities/types/dispatcherTypes';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { ElrButton, ElrLeftArrowButton } from 'ui-components';
import { SaveForLater } from '../SaveForLaterTooltip';
import BankDetailsInput from './BankDetailsInput';

interface IBankInfoForm {
  editMode?: boolean;
  stepIndex: number;
  onChangeStep: (index: number) => void;
}

const BankInfoForm: React.FC<IBankInfoForm> = ({
  editMode,
  onChangeStep,
  stepIndex,
}) => {
  const queryClient = useQueryClient();
  const {
    id: userId,
    accountInformation: { bankDetails },
  } = useGetProfile();

  const [stateBankDetails, setBankDetails] = useState<BankDetails>({
    bankCode: bankDetails?.bankCode || '',
    bankName: bankDetails?.bankName || '',
    accountName: bankDetails?.accountName || '',
    accountNumber: bankDetails?.accountNumber || '',
  });
  const [savedForLater, setSavedForLater] = useState<boolean>(false);
  const [successSaved, setSuccessSaved] = useState<boolean>(false);

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: (payload: BankDetails) =>
      httpUpdateDispatcherBankInformation(payload, userId),

    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: any | Profile) => {
        if (!oldData) return defaultProfileEntry;
        return {
          ...oldData,
          accountInformation: {
            ...oldData.accountInformation,
            bankDetails: data,
          },
        };
      });
      setSuccessSaved(true);
    },
    onError: () => {
      if (successSaved) {
        setSuccessSaved(false);
      }
    },
  });

  const makeUpdate = async () => {
    try {
      const payload = stateBankDetails;
      await mutateAsync(payload);
      !editMode && onChangeStep(stepIndex + 1);
    } catch (error: any) {
      /** noop */
    }
  };

  const onSaveForLater = () => {
    setSavedForLater(true);
  };

  const onSubmit = () => {
    if (!savedForLater) {
      return makeUpdate();
    }
    return onChangeStep(stepIndex + 1);
  };

  const checkAccountInputs = Object.keys(stateBankDetails).some(
    (ds) => !(stateBankDetails as any)[ds]
  );

  const onboardingSaveButton = () => (
    <>
      <div>
        <SaveForLater onSaveForLater={onSaveForLater} isLoading={isPending} />
      </div>
      <div className="w-full mb-10 border-b border-elr-gray-600 md:mb-12 opacity-60" />
      <div className="flex justify-between">
        <div
          onClick={() => onChangeStep(stepIndex - 1)}
          className="rounded-full h-9 w-9 mr-4 pl-2 pl-2.5"
        >
          <ElrLeftArrowButton />
        </div>
        <div>
          <ElrButton
            text="Next"
            loading={isPending}
            spinnerColor="White"
            size="lg"
            disabled={
              isPending ||
              (isEmpty(bankDetails) && checkAccountInputs && !savedForLater) ||
              (checkAccountInputs && !savedForLater)
            }
            onClick={onSubmit}
            className="w-40 mb-3 text-lg text-white bg-elr-black"
          />
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div
        className={`${
          editMode ? 'mt-8 mb-8' : 'mt-14 mb-16'
        } text-xl text-center`}
      >
        Bank Information
      </div>
      <div>
        <BankDetailsInput
          bankDetails={stateBankDetails}
          setBankDetails={setBankDetails}
        />
        {isError && (
          <p className="text-xs mb-1 h-3.5 text-elr-green">Error occured</p>
        )}
        {successSaved && (
          <p className="text-xs mb-1 h-3.5 text-elr-green">
            Successfully saved
          </p>
        )}
        {editMode ? (
          <div className="flex justify-end">
            <ElrButton
              text="Save"
              size="lg"
              disabled={isPending || checkAccountInputs}
              onClick={onSubmit}
              className="float-right w-40 mt-10 mb-3 text-lg text-white bg-elr-black"
            />
          </div>
        ) : (
          onboardingSaveButton()
        )}
      </div>
    </div>
  );
};
export default BankInfoForm;
