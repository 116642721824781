import RemoveIcon from 'assets/images/cancel.svg';
import React, { useRef } from 'react';

interface ElrUploadThumbnailProps {
  thumbnails: string[];
  setThumbnails: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ElrUploadThumbnail: React.FC<ElrUploadThumbnailProps> = ({
  thumbnails,
  setThumbnails,
}) => {
  const uploadRef = useRef<HTMLInputElement>(null);

  const upload = () => {
    uploadRef?.current?.click();
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    for (const file of e.target.files) {
      const objectUrl = URL.createObjectURL(file);
      setThumbnails((t: string[]) => [...t, objectUrl]);
    }
  };

  const removeThumb = (index: number) => {
    setThumbnails((t) => [...t.filter((_, i) => i !== index)]);
  };

  return (
    <div className="">
      {!thumbnails.length && (
        <div className="cursor-pointer bg-elr-gray  w-full h-24 flex flex-col justify-center items-center mb-5 outline-elr-purple-300 outline-dashed">
          <div onClick={upload} className="text-elr-purple pb-2  font-thin">
            Upload image
          </div>
          <p className="text-sm opacity-40">Files supported: JPEG, PNG</p>
          <p className="text-sm opacity-40">Maximum size: 5MB</p>
        </div>
      )}

      {thumbnails &&
        thumbnails.map((thumb, index) => (
          <div
            key={thumb}
            className="bg-elr-gray  w-full h-20 flex justify-center items-center mb-5  outline-elr-purple-300 outline-dashed relative"
          >
            <button
              onClick={() => removeThumb(index)}
              className="absolute -top-2 -right-2"
              type="button"
            >
              <img width="10px" src={RemoveIcon} alt="remove" />
            </button>
            <img
              className="object-contain w-full h-20"
              src={thumb}
              alt={`thumb-${index}`}
            />
          </div>
        ))}

      <input
        multiple
        accept=".gif,.png,.jpg,.jpeg"
        ref={uploadRef}
        type="file"
        className="hidden"
        onChange={handleUpload}
      />
    </div>
  );
};

export default ElrUploadThumbnail;
