import React from 'react';

interface Props {
  checked: boolean;
  onChange: () => void;
  label?: string;
  color?: string;
  index?: string;
  labelClassName?: string;
  radioClassName?: string;
}

export const ElrRadio: React.FC<Props> = ({
  checked,
  onChange,
  label,
  color = 'accent-black',
  index,
  labelClassName,
  radioClassName,
}) => (
  <label className={`flex items-center cursor-pointer ${labelClassName}`}>
    <input
      id={`elrRadio-${index}`}
      type="radio"
      className={`scale-125 cursor-pointer ${color} ${radioClassName}`}
      checked={checked}
      onChange={onChange}
    />
    <span>{label}</span>
  </label>
);
