import { FinanceAggregate } from 'app/api/d';
import { Aggregation } from 'app/dispatcher/modules/components/financePanel/Aggregation';
import { useFetchAggregate } from 'app/hooks/requests/finance';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import FundWallet from 'app/sender/components/FundWallet';
import { getCurrentCountryData } from 'app/utilities/country';
import {
  isCommerce,
  isDispatcher,
  isSender,
  roundUpAmount,
} from 'app/utilities/helpers';
import { SENDER } from 'app/utilities/roles';
import RefreshIcon from 'assets/images/refresh-icon.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { ElrButton, ElrCopy, ElrDropdownButton } from 'ui-components';
import { walletBalancestyle } from './ClassUtils';

const MyWallet: React.FC<{ walletBalance: number }> = () => {
  const {
    id: userId,
    currentAccessRole = SENDER,
    accountInformation: { id: roleId },
  } = useGetProfile();
  const [getWalletBalance, wallet, , isLoadingBalance] = useGetWalletBalance(
    userId,
    roleId,
    currentAccessRole
  );
  const [walletBalance, setWalletBalance] = useState(0);
  const [transferDetails, setTransferDetails] = useState({
    paystackAccountName: '',
    paystackAccountNumber: '',
  });

  const [fetchAggregate, isAggregateLoading, isAggregateError] =
    useFetchAggregate(userId, currentAccessRole);
  const [financialAggregate, setFinancialAggregate] =
    useState<FinanceAggregate>();
  const [showTransferDetails, setShowTransferDetails] = useState(false);
  const [showFundModal, setShowFundModal] = useState(false);
  // eslint-disable-next-line consistent-return
  const getFinancialAggregate = useCallback(async () => {
    const response = fetchAggregate();
    if (response.isError) {
      return response.isError;
    }
    setFinancialAggregate(response.payload);
  }, [fetchAggregate]);

  const getBalance = useCallback(async () => {
    const account: any = await getWalletBalance();
    if (account.data) {
      const { balance, paystackAccountName, paystackAccountNumber } =
        account.data;
      setWalletBalance(roundUpAmount(+balance));
      paystackAccountName &&
        setTransferDetails({ paystackAccountName, paystackAccountNumber });
    }
  }, [wallet?.balance]);

  useEffect(() => {
    getFinancialAggregate();
    getBalance();
  }, [getFinancialAggregate, getBalance]);

  const canTopWallet =
    isSender(currentAccessRole) || isCommerce(currentAccessRole);

  const canShowTransferDetails =
    transferDetails.paystackAccountName || !isDispatcher(currentAccessRole);

  return (
    <div className="w-full md:w-1/2 bg-yellow">
      {/* this part displays only on mobile */}
      <div className="w-11/12 px-6 py-4 mx-auto mb-2 border rounded-md xs:hidden sm:block md:hidden bg-elr-white-400 border-elr-green border-opacity-30 h-36">
        <div className="pt-1 pb-4 text-sm"> My Wallet</div>
        <div
          className={`${walletBalancestyle(
            walletBalance
          )} text-opacity-60 text-xl bg-elr-gray border-b-2 border-elr-black w-max px-2`}
        >
          {walletBalance}&nbsp;
          <span className="text-xs">
            {getCurrentCountryData().currencyCode.toUpperCase()}
          </span>
        </div>
      </div>
      {showFundModal && (
        <FundWallet
          amount={0}
          skipOrderMethod={!!transferDetails.paystackAccountName}
          close={() => setShowFundModal(false)}
        />
      )}
      {/* this part displays on web */}
      <div className="w-11/12 md:w-full md:ml-5 pb-5 mb-5 mx-auto border border-elr-green border-opacity-30 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md">
        <div className="pt-5 text-lg md:flex text-elr-black">
          <div className="justify-between hidden md:flex">
            <div className="text-elr-black md:text-elr-black text-opacity-60">
              My Wallet
            </div>
            <div
              className={`${walletBalancestyle(
                walletBalance
              )} ml-5 text-opacity-60 text-xl bg-elr-black bg-opacity-10 w-max border-elr-black border-b-2 px-2`}
            >
              {walletBalance} &nbsp;
              <span className="text-xs">
                {getCurrentCountryData().currencyCode.toUpperCase()}
              </span>
            </div>
          </div>
        </div>

        <div className="pt-4 md:mt-5 md:block md:border-t border-elr-gray-400 border-opacity-20">
          <div className="flex flex-col items-center md:mt-3">
            <p className="mb-3 text-sm">Current balance</p>
            <div
              className={`${walletBalancestyle(
                walletBalance
              )} text-center text-opacity-60 text-4xl bg-elr-gray border-elr-black border-b-2 pt-1 px-2`}
            >
              {walletBalance} &nbsp;
              <span className="text-xs">
                {getCurrentCountryData().currencyCode.toUpperCase()}
              </span>
            </div>
          </div>
          {canShowTransferDetails ? (
            <div className="flex flex-col md:items-center mt-8">
              <div
                className={`${
                  showTransferDetails && 'bg-elr-gray rounded-xl'
                } w-96`}
              >
                {canTopWallet && (
                  <ElrDropdownButton
                    text="Top up wallet"
                    className="h-8 bg-elr-black w-full text-elr-white"
                    size="xm"
                    onClick={() => setShowTransferDetails((prev) => !prev)}
                    isToggled={showTransferDetails}
                  />
                )}
                <div
                  className={`${
                    showTransferDetails ? 'block' : 'hidden'
                  } px-4 py-2`}
                >
                  <div className="flex flex-row pb-1">
                    <div className="flex flex-wrap gap-x-10 gap-y-2">
                      <span>
                        <p className="text-xs text-elr-black-200">Bank name</p>
                        <p className="text-sm">Paystack Titan Micro</p>
                      </span>
                      <span>
                        <p className="text-xs text-elr-black-200">
                          Account number
                        </p>
                        <p className="text-sm">
                          {transferDetails.paystackAccountNumber}
                        </p>
                      </span>
                      <span>
                        <p className="text-xs text-elr-black-200">
                          Account name
                        </p>
                        <p className="text-sm">
                          {transferDetails.paystackAccountName}
                        </p>
                      </span>
                    </div>
                    <div className="bg-elr-gray-200 h-fit w-fit p-1 rounded mt-2">
                      <ElrCopy
                        theme="dark"
                        content={transferDetails.paystackAccountNumber ?? ''}
                      />
                    </div>
                  </div>
                  <div className="w-fit mx-auto mt-1 mb-3">
                    <ElrButton
                      text="Refresh balance"
                      size="xm"
                      imageSrc={RefreshIcon}
                      imageAlt="refresh"
                      loading={isLoadingBalance}
                      onClick={getBalance}
                      className="bg-elr-black text-xs text-elr-gray px-2 py-1 flex items-center justify-center gap-x-1"
                    />
                  </div>
                  <ElrButton
                    text="Or Fund wallet via Paystack"
                    className="h-7 text-elr-purple bg-elr-purple bg-opacity-10 w-full"
                    size="xm"
                    onClick={() => {
                      setShowFundModal(true);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <ElrButton
                text="Top up wallet"
                size="sm"
                onClick={() => setShowFundModal(true)}
                className="px-10 mt-8 h-9 bg-elr-black text-elr-white-400 md:text-base"
              />
            </div>
          )}
          <div className="mt-10 border-t border-b border-elr-gray-400 border-opacity-20" />
        </div>

        <Aggregation
          financialAggregate={financialAggregate}
          isError={isAggregateError}
          isLoading={isAggregateLoading}
        />
      </div>
    </div>
  );
};

export default MyWallet;
