import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useQuery } from '@tanstack/react-query';
import { httpGetApiKeyApiUser } from 'app/api/commerce';
import { useGetProfile } from 'app/hooks/user';
import copyIcon from 'assets/images/copy.svg';
import React, { useEffect, useRef, useState } from 'react';

const LabelBox: React.FC<{
  content: string;
  liveMode?: boolean;
  testMode?: boolean;
}> = ({ content, liveMode, testMode }) => {
  const profile = useGetProfile();
  const [text, setText] = useState<string | null>(null);
  const [showCopy, setShowCopy] = useState(true);
  const [displayKey, setDisplayKey] = useState(false);
  const copyRef = useRef<HTMLDivElement | null>(null);

  const { id: userId, accountInformation } = profile;

  const commerceId = accountInformation.id;

  const data = useQuery({
    queryKey: ['apiKey'],
    queryFn: async () => httpGetApiKeyApiUser(userId, commerceId),
  });

  const liveSecretKey = data?.data?.apiKey;
  const testSecretKey = data?.data?.testKey;

  const textCopied = (mode: string) => {
    navigator.clipboard
      .writeText(
        (mode === 'liveMode' && liveSecretKey) ||
          (mode === 'testMode' && testSecretKey)
      )
      .then(() => {
        setText('Copied!');
        setShowCopy(true);
      });
  };

  const handleClickOutside = (event: any) => {
    if (copyRef.current?.contains(event.target)) {
      return;
    }
    setShowCopy(!showCopy);
  };

  const handleDisplayApiKey = () => {
    setDisplayKey(!displayKey);
  };

  useEffect(() => {
    if (showCopy) {
      document.addEventListener('mouseup', handleClickOutside);
    } else {
      document.removeEventListener('mouseup', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [showCopy]);

  return (
    <div className="h-50 flex items-center justify-between px-2 mb-2.5 bg-elr-gray">
      <p className="w-56 overflow-hidden text-sm opacity-80">
        {liveMode && (displayKey ? liveSecretKey : content)}
        {testMode && (displayKey ? testSecretKey : content)}
      </p>
      <div className="flex gap-2.5">
        {liveMode && (
          <div
            onClick={() => textCopied('liveMode')}
            className="relative"
            ref={copyRef}
          >
            {!!text && (
              <p
                className={`absolute text-sm text-elr-purple text-opacity-80 right-0 bottom-6 ${
                  showCopy ? 'block' : 'hidden'
                }`}
              >
                {text}
              </p>
            )}
            <img className="h-6 cursor-pointer" src={copyIcon} alt="copy" />
          </div>
        )}
        {testMode && (
          <div
            onClick={() => textCopied('testMode')}
            className="relative"
            ref={copyRef}
          >
            {!!text && (
              <p
                className={`absolute text-sm text-elr-purple text-opacity-80 right-0 bottom-6 ${
                  showCopy ? 'block' : 'hidden'
                }`}
              >
                {text}
              </p>
            )}
            <img className="h-6 cursor-pointer" src={copyIcon} alt="copy" />
          </div>
        )}
        <VisibilityOutlinedIcon
          className="cursor-pointer opacity-10"
          htmlColor="#081120"
          fontSize="medium"
          onClick={handleDisplayApiKey}
        />
      </div>
    </div>
  );
};

export default LabelBox;
