import {
  AgentInterface,
  ValueAgentInterface,
} from 'app/utilities/types/shared';
import React from 'react';
import { ElrButton } from 'ui-components';

const ConfirmOrderPicked: React.FC<{
  closeModal: (value: boolean) => void;
  onProceed: () => void;
  agent: AgentInterface | ValueAgentInterface;
  loading: boolean;
}> = ({ closeModal, onProceed, agent, loading }) => (
  <div className="w-[240px] absolute bg-elr-green text-white -mt-36 -ml-3.5 pb-5 px-5 rounded-xl z-40 top-60 right-12">
    <div
      className="text-sm flex justify-end mt-1.5 text-elr-gray inline cursor-pointer"
      onClick={() => closeModal(false)}
    >
      x
    </div>
    <p className="text-sm">
      {agent.firstname} {agent.lastname} ({agent.phone}) has picked up the
      parcel ?
    </p>
    <div className="flex justify-end mt-5">
      <ElrButton
        onClick={onProceed}
        spinnerColor="Black"
        loading={loading}
        text="Confirm"
        size="sm"
        className="bg-elr-yellow text-elr-black border-opacity-30 text-sm px-2"
      />
    </div>
  </div>
);

export default ConfirmOrderPicked;
