import CountElement from 'app/components/CountElement';
import FilterInput from 'app/platform/components/FilteredInput/FilteredInput';
import { getCurrentCountryData } from 'app/utilities/country';
import { ProductType } from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { useFetchProductsList } from '../../../hooks/requests/dashboard';
import AddProductSlideIn from './components/AddProductSlideIn';

const ProductsList: React.FC = () => {
  const [slideInAddProducts, setSlideInAddProducts] = useState<boolean>(false);

  const { docs = [] } = useFetchProductsList();

  return (
    <>
      {slideInAddProducts && (
        <AddProductSlideIn
          setShowSlideIn={setSlideInAddProducts}
          showModal={slideInAddProducts}
        />
      )}
      <div className="w-full md:w-1/2">
        <div className="block md:hidden">
          <FilterInput
            productsSortdata={[]}
            productsTab
            setShowSlideIn={setSlideInAddProducts}
          />
        </div>
        <div className="w-11/12 md:w-full md:ml-5 mx-auto border border-elr-green border-opacity-30 h-450 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md">
          <div>
            <div className="flex items-center justify-center pt-5 md:justify-between">
              <div className="hidden text-lg md:flex md:items-center text-elr-black">
                Products
                <CountElement bgColor="elr-green" size="sm" classNames="ml-5">
                  {docs.length || 0}
                </CountElement>
              </div>
            </div>
            <div className="hidden pt-4 border-b md:block border-elr-gray-400 border-opacity-20" />
            <div className="hidden md:block">
              <FilterInput
                productsSortdata={[]}
                productsTab
                setShowSlideIn={setSlideInAddProducts}
              />
            </div>
            <div className="hidden border-b md:block border-elr-gray-400 border-opacity-20" />
            <div className="w-11/12 md:w-full border opacity-60  md:border-none hidden md:flex items-center border-elr-green border-opacity-30 mb-2.5 md:mb-1 md:ml-0 max-h-32 md:h-16 rounded-md md:flex mx-auto bg-elr-white-400 px-2 md:px-auto no-scrollbar overflow-x-auto overflow-y-hidden">
              <div className="flex items-center w-5/12 pl-8">Products</div>
              <div className="flex items-center justify-center w-3/12 ">
                Price
              </div>
              <div className="flex items-center justify-center w-3/12">
                Quantity
              </div>
            </div>
            <div className="hidden border-b md:block border-elr-gray-400 border-opacity-20" />
          </div>

          <div className="w-full overflow-y-scroll h-690 bg-green md:h-4/5 no-scrollbar">
            {docs.map((each: ProductType) => (
              <Products
                key={each.id}
                name={each.name}
                quantity={each.quantity}
                id={each.id}
                price={each.price}
                Image={each.images[0]}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsList;

interface Props {
  Image: string;
  name: string;
  id: string;
  quantity: number;
  price: string;
}

const Products: React.FC<Props> = ({ Image, name, id, quantity, price }) => (
  <div className="relative flex justify-between w-full py-4 text-sm border-b cursor-pointer md:justify-none md:text-base border-elr-gray-400 border-opacity-20">
    <div className="!w-8/12 flex gap-4 ">
      <div className="flex w-full gap-2 md:gap-0">
        <img src={Image} alt={name} />
        <div className="w-full  md:flex md:items-center md:justify-center">
          <div className="w-3/5 opacity-60">
            <p className="text-base opacity-100">{name}</p>
            <p className="text-sm  text-elr-green">{id}</p>
          </div>
          <div className=" w-2/5 md:!w-3/12 flex md:justify-center opacity-60 items-center whitespace-nowrap">
            <p>{`${getCurrentCountryData().currencyCode.toUpperCase()}  ${price}`}</p>
          </div>
        </div>
      </div>
    </div>

    <div className="flex flex-col flex-col-reverse items-center justify-center pr-4 md:flex-row md:pl-0 md:w-4/12 ">
      <div className="!w-9/12 flex  md:flex justify-center opacity-60 items-center whitespace-nowrap ">
        <span className="md:hidden">In stock : </span>
        <span>{quantity}</span>
      </div>
      <div className="relative w-full md:w-1/12">
        <EllipsisContent options={['Copy Link', 'Edit', 'Preview', 'Delete']} />
      </div>
    </div>
  </div>
);

const EllipsisContent: React.FC<{ options: string[] }> = ({ options }) => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  return (
    <>
      <div
        className="flex items-center justify-end w-full text-3xl opacity-60"
        onClick={toggleOptions}
      >
        &#x22EE;
      </div>
      {showOptions && (
        <ul className="absolute z-20 w-32 p-2 bg-white rounded shadow-lg shadow-zinc-500/500 top-23 right-1 ">
          {options.map((each) => (
            <div
              key={each}
              className="p-1 opacity-60 hover:bg-elr-gray"
              onClick={() => setShowOptions(false)}
            >
              {each}
            </div>
          ))}
        </ul>
      )}
    </>
  );
};
