import { ElrMainSelect } from 'ui-components';

const ProductsSort: React.FC<{ productsSortFilterdata: Array<string> }> = ({
  productsSortFilterdata,
}) => (
  <div className="text-elr-black flex md:mr-1 items-center">
    <span className="opacity-60 text-base mr-2"> Sort </span>
    <ElrMainSelect
      data={productsSortFilterdata}
      // @ts-ignore
      containerHeight="30px"
      containerBackgroundColor="#FFFFFF"
      controlBackgroundColor="transparent"
      controlHeight="28px"
      minHeight="0"
      className="w-24 mt-4 border md:z-30 border-opacity-40 border-elr-green rounded-md"
      placeholder="Recent"
      boxShadowStyle="0 0 14px rgba(0, 0, 0, 0.274)"
      showIndicator
    />
  </div>
);

export default ProductsSort;
