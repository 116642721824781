import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  RequestsDataInterface,
  UpcomingDeliveriesInterface,
  WalletBalance,
  prePopulatedWalletData,
} from 'app/api/d';
import {
  cancelRequestResponse,
  httpCancelRequest,
  httpGenerateTrackingID,
} from 'app/api/requests';
import {
  httpGetDeliveriesMade,
  httpGetUpcomingDeliveries,
} from 'app/api/sender';
import { httpTrackParcel } from 'app/api/trackParcel';
import {
  httpGetBillingHistory,
  httpGetBillingRecord,
  httpGetWalletBalance,
} from 'app/api/wallet';
import {
  useFetchInfiniteQuery,
  useFetchQueryOnce,
  useFetchRequestQueries,
} from 'app/components/dashboard/hooks';
import {
  RequestCountContext,
  RequestsCountType,
} from 'app/contexts/requestCount';
import { DELIVERIES_MADE_REQUESTS } from 'app/state/constants';
import { getDynamicRequestsData } from 'app/utilities/helpers';
import { COMMERCE, SENDER } from 'app/utilities/roles';
import { useCallback, useContext, useEffect, useState } from 'react';
import { TBillingHistory } from 'app/api/types';
import { Roles, useGetProfile } from '../user';

type UseCancelRequestHook = (
  trackingId: string,
  accountId: string,
  userId: string,
  cancelReason: string
) => {
  loading: boolean;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelModal: boolean;
  cancelRequest: () => Promise<cancelRequestResponse | void>;
  error: string | null;
};

export const useCancelRequest: UseCancelRequestHook = (
  trackingId,
  accountId,
  userId,
  cancelReason
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const cancelRequest = async () => {
    setError(null);
    try {
      setLoading(true);
      const response = await httpCancelRequest(
        trackingId,
        accountId,
        userId,
        cancelReason
      );
      setShowCancelModal(false);
      return response;
    } catch (err: any) {
      return setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, setShowCancelModal, showCancelModal, cancelRequest, error };
};

type UseGetBalanceHook = (
  userId: string,
  roleId: string,
  role?: Roles
) => [() => Promise<{ data: WalletBalance }>, WalletBalance, boolean, boolean];

export const useGetWalletBalance: UseGetBalanceHook = (
  userId: string,
  roleId,
  role = SENDER
): any => {
  const queryClient = useQueryClient();

  const { isLoading, isError, refetch } = useQuery({
    queryKey: ['walletBanlance'],
    queryFn: () =>
      httpGetWalletBalance({
        roleId,
        userId,
        role,
      }),
    notifyOnChangeProps: ['data'],
  });

  const walletData: WalletBalance =
    queryClient.getQueryData(['walletBanlance']) || prePopulatedWalletData;

  return [refetch, walletData, isError, isLoading];
};

type UseGetRecordHook = (
  userId: string,
  roleId: string,
  role?: Roles
) => {
  refetch: () => Promise<{ data: WalletBalance }>;
  docsList: [] | null;
  totalDebit: number;
  totalCredit: number;
  error: string | null;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
};

export const useGetBillingRecord: UseGetRecordHook = (
  userId: string,
  roleId,
  role?: Roles
): any => {
  const response = useFetchInfiniteQuery({
    key: 'walletRecord',
    fn: httpGetBillingRecord,
    payload: { roleId, userId, role },
  });

  const walletData = response.docsList || [prePopulatedWalletData];
  return {
    ...response,
    totalDebit: response.latestData.totalDebit || 0,
    totalCredit: response.latestData.totalCredit || 0,
    docsList: walletData,
  };
};

type UseGenerateTrackingId = (
  userId: string,
  batch: boolean
) => [() => Promise<string>, string, string | null];
export const useGetTemporaryTrackingId: UseGenerateTrackingId = (
  userId: string,
  batch: boolean
): any => {
  const [error, setError] = useState<string | null>(null);
  const [trackingId, setTrackingId] = useState<string>('');

  const generateTrackingID = useCallback(async (): Promise<
    WalletBalance | any
  > => {
    setError(null);
    try {
      const response = await httpGenerateTrackingID({
        userId,
        batch,
      });
      setTrackingId(response);
      return response;
    } catch (err: any) {
      return setError(err);
    }
  }, [userId]);

  return [generateTrackingID, trackingId, error];
};

type FetchUpcomingDeliveriesHook = () => [
  (
    nextPage: number,
    userId: string,
    setUpcomingDeliveriesCb: (
      arg0: (_: UpcomingDeliveriesInterface) => {
        deliveries: RequestsDataInterface;
      }
    ) => void
  ) => Promise<void>,
  string | null,
];

export const useFetchUpcomingDeliveries: FetchUpcomingDeliveriesHook = () => {
  const [error, setError] = useState<string | null>(null);
  const { updateRequestCount }: RequestsCountType =
    useContext(RequestCountContext);

  const fetchUpcomingDeliveries = useCallback(
    async (
      nextPage: number,
      userId: string,
      setUpcomingDeliveriesCb: (
        arg0: (_: UpcomingDeliveriesInterface) => {
          deliveries: RequestsDataInterface;
        }
      ) => void
    ): Promise<void> => {
      setError(null);

      try {
        const response = await httpGetUpcomingDeliveries({
          userId,
          nextPage,
        });
        updateRequestCount(
          'UPCOMING_COMMERCE_SINGLE_REQUEST_COUNT',
          response.totalDocs
        );
        response.docs.length &&
          setUpcomingDeliveriesCb((prevState: UpcomingDeliveriesInterface) => {
            const dynamicRequests = getDynamicRequestsData(
              prevState.deliveries.docs,
              response
            );
            return {
              deliveries: dynamicRequests,
            };
          });
      } catch (err) {
        // no op
      }
    },

    []
  );
  return [fetchUpcomingDeliveries, error];
};

type FetchDeliveriesHook = () => [
  (userId: string, nextPage: number) => Promise<void>,
  boolean,
  string | null,
];

export const useFetchDeliveries: FetchDeliveriesHook = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendQuery] = useFetchRequestQueries();

  const fetchDeliveriesMade = useCallback(
    async (userId: string, nextPage = 0) => {
      setError(null);
      try {
        await sendQuery(
          httpGetDeliveriesMade({
            userId,
            nextPage,
          }),
          DELIVERIES_MADE_REQUESTS
        );
        setIsLoading(false);
      } catch (err) {
        /** noop */
        setIsLoading(false);
      }
    },

    []
  );
  return [fetchDeliveriesMade, isLoading, error];
};

export const useGetParcelDetails = (parcelId: string) =>
  useFetchQueryOnce({
    key: 'parcelDetails',
    fn: httpTrackParcel,
    payload: parcelId,
  });

export const useFetchBillingHistory = (
  limit: number = 10,
  role = COMMERCE
): [TBillingHistory[], boolean] => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const {
    id: userId,
    accountInformation: { id: roleId },
  } = profile;

  const [loading, setLoading] = useState(true);
  const [billRecords, setBillRecords] = useState<TBillingHistory[]>([]);

  useEffect(() => {
    const loadBillingHistory = async () => {
      try {
        setLoading(true);
        const fetchedData = await queryClient.fetchQuery({
          queryKey: ['billingHistory', userId, roleId],
          queryFn: () => httpGetBillingHistory({ userId, roleId, limit, role }),
        });

        setBillRecords(fetchedData);
      } catch (error) {
        // no opp
      } finally {
        setLoading(false);
      }
    };

    if (userId && roleId) {
      loadBillingHistory();
    }
  }, [queryClient, userId, roleId, limit]);
  return [billRecords, loading];
};
