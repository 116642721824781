import { useQuery } from '@tanstack/react-query';
import { httpLogout } from 'app/api/auth';
import { Session } from 'app/utilities/Session';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { useNavigate } from 'react-router-dom';
import { useGetProfile } from './user';

// make http request here
const mockFn = async () => ({
  token: '123456789100',
  data: {
    email: 'xxx@gmail.com',
    password: '1111',
  },
});

export const useMockData = () =>
  useQuery({
    queryKey: ['mockData'],
    queryFn: () => mockFn(),
  });

export const useLogout = () => {
  const { id } = useGetProfile();
  const sendLogout = async () => {
    await httpLogout(id);
    window.location.pathname = '/';
  };
  return [sendLogout];
};

export const useLogin = () => {
  const history = useNavigate();

  const sendLogin = () => {
    const session = Session.currentUser();

    return session
      ? history(privateRoutes.selectPortal)
      : history(publicRoutes.login);
  };
  return [sendLogin];
};
