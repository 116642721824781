import { ProfileImage } from 'app/components/ProfileImage';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import EditPersonalDetails from 'app/components/settings/EditPersonalDetails';
import { useGetProfile } from 'app/hooks/user';
import { ResetPasswordChildComponent } from 'app/modules/passwordSetting/ResetPassword';
import { SenderView } from 'app/utilities/ComponentView';
import React, { useState } from 'react';
import { ElrModal, ElrPageTitle } from 'ui-components';
import BankSenderInfoForm from 'app/components/settings/BankSenderInfoForm';
import { BankDetails } from 'app/utilities/types/dispatcherTypes';
import { initials } from '../../../utilities/helpers';

const PersonalProfile: React.FC = () => {
  const {
    currentAccessRole,
    accountInformation: { bankDetails },
    ...profile
  } = useGetProfile();

  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAddBankAccount, setShowAddBankAccount] = useState<boolean>(false);
  const [updateBankInfo, setUpdateBankInfo] = useState<BankDetails>({
    bankCode: bankDetails?.bankCode || '',
    bankName: bankDetails?.bankName || '',
    accountName: bankDetails?.accountName || '',
    accountNumber: bankDetails?.accountNumber || '',
  });

  const onReset = () => setShowModal(!showModal);
  const editDetails = () => setShowProfileModal(!showProfileModal);
  const toggleBankAccountModal = () =>
    setShowAddBankAccount(!showAddBankAccount);

  const handleBankDetailsUpdated = (updatedBankDetails: BankDetails) => {
    setUpdateBankInfo(updatedBankDetails);
  };

  return (
    <div className="h-full">
      <ElrPageTitle title="Errandlr - Personal Profile" />
      <ProfileNavigationMenu>
        <div className="h-1000 md:h-auto">
          <TopNavigationMenu />
          <div className="px-8 pt-10 pb-8 text-xl md:px-24">
            Personal details
          </div>
          <div className="pb-16 md:pb-28 mx-4 md:mx-24 border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-5 mb-5">
            <div
              className="mt-3.5 w-10 text-sm p-0.5 text-elr-purple bg-opacity-10 bg-elr-purple ml-auto text-center mb-8 cursor-pointer"
              onClick={editDetails}
            >
              Edit
            </div>
            {showProfileModal && (
              <ElrModal
                height="h-4/5 md:h-550"
                isOpen={showProfileModal}
                onClose={editDetails}
              >
                <EditPersonalDetails />
              </ElrModal>
            )}
            <div className="flex flex-col mx-4 md:mx-10 md:flex-row">
              <div className="flex w-full mb-10 md:w-5/12 md:mb-0">
                <ProfileImage
                  initials={`${initials(profile.firstname)}${initials(
                    profile.lastname
                  )} `}
                  classes="w-16 h-16 flex items-center justify-center"
                />
                <div className="ml-4 md:ml-9">
                  <p className="mb-2 text-sm text-elr-black md:mb-5">
                    Full name
                  </p>
                  <p className="text-sm text-elr-black opacity-60">
                    {profile.firstname} {profile.lastname}
                  </p>
                </div>
              </div>
              <div className="mb-10 ml-16 md:w-4/12 md:mb-0 md:ml-0">
                <p className="mb-2 ml-3 text-sm md:ml-0 text-elr-black md:mb-5">
                  Phone numbers
                </p>
                <p className="mb-2 ml-3 text-sm md:ml-0 text-elr-black opacity-60 md:mb-5">
                  {profile.phone}
                  <span className="w-12 text-xs rounded-2xl bg-elr-gray text-elr-green ml-2 p-1.5">
                    primary
                  </span>
                </p>
              </div>
              <div className="ml-16 md:w-4/12 md:ml-0">
                <p className="mb-2 ml-3 text-sm md:ml-0 text-elr-black md:mb-5">
                  Email Address
                </p>
                <p className="ml-3 text-sm md:ml-0 text-elr-black opacity-60">
                  {profile.email}
                </p>
              </div>
            </div>
          </div>

          <SenderView role={currentAccessRole}>
            <div className="pb-7 mx-4 md:mx-24 border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-5 mb-7">
              <div
                className="mt-3.5 w-14 text-sm mx-0.5 text-elr-purple bg-opacity-10 bg-elr-purple ml-auto text-center mb-8 cursor-pointer"
                onClick={toggleBankAccountModal}
              >
                Change
              </div>
              {showAddBankAccount && (
                <ElrModal
                  height="h-4/5 md:h-550"
                  isOpen={showAddBankAccount}
                  onClose={toggleBankAccountModal}
                >
                  <BankSenderInfoForm
                    setShowAddBankAccount={toggleBankAccountModal}
                    onBankDetailsUpdated={handleBankDetailsUpdated}
                  />
                </ElrModal>
              )}

              <p className="mx-4 mb-2 text-sm md:mx-10 text-elr-black md:mb-5">
                Bank Information
              </p>
              <div className="flex flex-col mx-4 md:mx-10 md:flex-row">
                <div className="flex w-full mb-10 md:w-5/12 md:mb-0">
                  <div>
                    <p className="text-sm text-elr-black opacity-60">
                      {updateBankInfo?.accountName}
                    </p>
                  </div>
                </div>
                <div className="mb-10 md:w-4/12 md:mb-0">
                  <p className="text-sm text-elr-black opacity-60">
                    {updateBankInfo?.accountNumber}
                  </p>
                </div>
                <div className="md:w-4/12">
                  <p className="text-sm text-elr-black opacity-60">
                    {updateBankInfo?.bankName}
                  </p>
                </div>
              </div>
            </div>
          </SenderView>

          <div className="flex flex-col flex-wrap pb-8 mx-4 md:flex-row md:mx-24 gap-x-4 gap-y-5">
            <div className="md:w-64 h-36 border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-7">
              <div
                onClick={onReset}
                className="mt-3.5 w-12 text-sm p-0.5 text-elr-purple bg-opacity-10 bg-elr-purple ml-auto text-center cursor-pointer"
              >
                Reset
              </div>
              <ElrModal
                height="h-4/5 md:h-1/2"
                isOpen={showModal}
                onClose={onReset}
              >
                <ResetPasswordChildComponent editMode />
              </ElrModal>
              <div className="ml-3">Password</div>
              <div className="mt-3 ml-3 text-elr-gray-400">*********</div>
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default PersonalProfile;
