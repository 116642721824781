import { MobileHeader } from 'app/components/Header';
import { privateRoutes } from 'app/utilities/routes';
import profile from 'assets/images/accountIcon.svg';
import phone from 'assets/images/phoneIcon.svg';
import React from 'react';
import { Link } from 'react-router-dom';

const SidebarMenu: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="flex flex-col md:h-full">
    <div className="w-full md:w-72 h-44 md:h-full overflow-x-hidden md:fixed bg-elr-gray">
      <div className="md:w-3/5 md:max-w-6xl md:mx-auto">
        <MobileHeader />
        <div>
          <div className="flex w-11/12 mx-auto md:w-full md:flex-col mt-4 md:mt-0 justify-between items-center md:items-baseline">
            <div className="h-20 flex justify-start items-center gap-3">
              <span>
                <img src={profile} alt="icon" />
              </span>
              <p>Profile</p>
            </div>
            <Link to={privateRoutes.personalSupport}>
              <div className=" flex justify-start items-center gap-3">
                <span className="bg-elr-green bg-opacity-10 w-6 h-6 flex items-center justify-center">
                  <img src={phone} alt="icon" />
                </span>
                <p>1 to 1 support</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="h-full md:ml-72 bg-white">{children}</div>
  </div>
);

export default SidebarMenu;
