/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import DashboardContainer from 'app/components/DashboardContainer';
import MyWallet from 'app/components/MyWallet';
import BatchUpcomingContainer from 'app/components/dashboard/BatchUpcomingContainer';
import {
  useFetchDeliveriesMade,
  useFetchNewRequests,
  useFetchUpcomingRequests,
} from 'app/hooks/requests/dashboard';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import DashboardPanel from 'app/platform/modules/DashboardPanel';
import DeliveriesMade from 'app/platform/modules/DeliveriesMade';
import { dispatchTabs, roundUpAmount } from 'app/utilities/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import NewRequests from './components/NewRequests';

const Dashboard: React.FC = () => {
  const [stepState, setStateState] = useState<number>(0);
  const [tabs, setTabs] = useState(dispatchTabs);
  const {
    id: userId,
    accountInformation: { id: roleId, ...accountData },
    currentAccessRole,
  } = useGetProfile();
  const [getWalletBalance, wallet] = useGetWalletBalance(
    userId,
    roleId,
    currentAccessRole
  );

  const { latestData: newRequests } = useFetchNewRequests();
  const { latestData: upcomingDeliveries } = useFetchUpcomingRequests();
  const { latestData: deliveriesMade } = useFetchDeliveriesMade();

  const toggleBatchTab = () => {
    const clonedTab = [...tabs];
    const batchId = clonedTab.findIndex((item) => item.id === 'batch');
    clonedTab[batchId].disabled = !accountData.batchingActive;
    setTabs(clonedTab);
  };

  const getBalance = useCallback(async () => {
    await getWalletBalance();
  }, [wallet.balance]);

  useEffect(() => {
    getBalance();
    toggleBatchTab();
  }, []);

  const walletTotal = roundUpAmount(+wallet.balance);

  const panels = [
    null,
    <NewRequests displayOriginalFees />,
    <BatchUpcomingContainer displayOriginalFees />,
    <DeliveriesMade displayOriginalFees />,
    <MyWallet walletBalance={walletTotal} />,
  ];

  const onChangePanel = (index: number) => {
    setStateState(index);
  };

  return (
    <DashboardContainer
      titleTag="Errandlr - Sender Dashboard"
      panelComponent={panels[stepState] ? panels[stepState] : null}
      dashboardPanel={
        <DashboardPanel
          totalDocs={{
            accountBalance: walletTotal,
            newRequestsTotal: newRequests.totalDocs,
            upcomingRequestsTotal: upcomingDeliveries.totalDocs,
            deliveriesMadeTotal: deliveriesMade.totalDocs,
          }}
          onClick={onChangePanel}
          financeLabel="Wallet"
          panelActive={stepState}
        />
      }
    />
  );
};

export default Dashboard;
