import { capitalize } from '@material-ui/core';
import { ProfileImage } from 'app/components/ProfileImage';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { AVAILABLE_RIDERS } from 'app/constants';
import { useGetProfile } from 'app/hooks/user';
import { initials } from 'app/utilities/helpers';
import { AgentInterface } from 'app/utilities/types/shared';
import agentIcon from 'assets/images/agentsIcon.svg';
import star from 'assets/images/star.svg';
import CommentIcon from 'assets/images/CommentIcon.svg';
import React from 'react';
import { ElrButton, ElrRatingDisplay } from 'ui-components';
import ArrowDown from '@material-ui/icons/ExpandMore';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  agent: AgentInterface;
  active: string;
}

const reviews: { name: string; text: string; rating: 1 | 2 | 3 | 4 | 5 }[] = [
  {
    name: 'MaryJane',
    text: 'It was fast and reliable and i got everything intact',
    rating: 3,
  },
  {
    name: 'MaryJane',
    text: 'It was fast and reliable and i got everything intact',
    rating: 5,
  },
  {
    name: 'MaryJane',
    text: 'It was fast and reliable and i got everything intact',
    rating: 2,
  },
  {
    name: 'MaryJane',
    text: 'It was fast and reliable and i got everything intact',
    rating: 4,
  },
  {
    name: 'MaryJane',
    text: 'It was fast and reliable and i got everything intact',
    rating: 1,
  },
];

const CommentsDisplay = ({
  name,
  text,
  rating,
}: {
  name: string;
  text: string;
  rating: 1 | 2 | 3 | 4 | 5;
}) => (
  <div className="bg-elr-gray-100 rounded-2xl min-h-24 w-120 m-4 max-md:pb-2 max-md:pr-2 ">
    <div className="pl-4 pt-3">{name}</div>
    <div className="pl-4 pt-2">{text}</div>
    <div className="flex justify-end pr-4">
      <ElrRatingDisplay rating={rating} starStyle="w-3.5 h-5 pb-2" />
    </div>
  </div>
);

const ViewAgent: React.FC<Props> = ({ isOpen, onClose, agent, active }) => {
  const { isAnOperator } = useGetProfile();

  return (
    <SlideIn
      shown={isOpen}
      close={onClose}
      leftMarginOffset="lg:ml-52p md:ml-36p"
    >
      <div className="px-4 py-6 rounded-md border-elr-black border mt-8 md:mt-0">
        <div className="flex flex-col md:flex-row justify-between md:items-end">
          <div className="flex items-center">
            <ProfileImage
              initials={`${initials(agent.firstname)}${initials(
                agent.lastname
              )} `}
              classes="h-16 w-16 px-1 flex items-center justify-center"
            />
            <div>
              <h1 className="text-18">
                {agent.firstname} {agent.lastname}
              </h1>
              <div className="flex gap-5 pt-3 items-center">
                <p className="text-14 opacity-80">Individual rider</p>
                <div className="flex gap-1 items-center">
                  <div className="w-1.5 h-1.5 bg-elr-yellow" />
                  <p className="text-12 opacity-60">5km away</p>
                </div>
              </div>
            </div>
          </div>
          {active === AVAILABLE_RIDERS && (
            <div>
              <ElrButton
                text="Request rider"
                className="mt-6 md:mt-0 bg-elr-black text-white py-1 px-3 min-h-0 text-12 min-w-0"
              />
            </div>
          )}
        </div>
        <div className="flex md:gap-20 gap-7 justify-between md:justify-start text-elr-green text-14 pt-6 opacity-70">
          {agent.phone && <p>{agent.phone}</p>}
          <p>5483</p>
          <div className="flex gap-2">
            <img src={agentIcon} alt="Agent Icon" />
            <p>{agent.tripsCount}</p>
          </div>
        </div>
        <div className="flex justify-between pl-5">
          <div className="border-b border-black p-2">
            <div className="flex pl-2 relative -bottom-1">
              <img src={CommentIcon} alt="CommentIcon" />
              <div className="font-medium m-2 text-xl">32 reviews</div>
            </div>
          </div>
          <div className="flex items-center rounded-md border border-elr-green h-8 mt-6 mr-7 pl-1.5">
            <ElrRatingDisplay rating={4} starStyle="w-3.5 h-5" />
            <ArrowDown className="pt-0.5" />
          </div>
        </div>
        <div className="pl-4">
          <div className="flex font-medium m-2 text-lg">
            Average 4.5
            <img src={star} className="w-4 h-5 pt-1" alt="star" />
          </div>
        </div>
        {reviews.map((item) => (
          <CommentsDisplay
            name={item.name}
            text={item.text}
            rating={item.rating}
          />
        ))}
        {active === AVAILABLE_RIDERS && (
          <>
            <div className="py-5 flex  flex-wrap gap-y-3 gap-20">
              <FieldDetail title="Country*" value={capitalize(agent.country)} />
              <FieldDetail title="City/Town*" value="Ikeja" />
              <FieldDetail title="Local government area*" value="Ikeja LGA" />
            </div>
            <FieldDetail title="Address line 1*" value="Address" />
            <div className="flex gap-16 pt-1">
              <div>
                <span className="opacity-40 text-12">
                  Motocyle registration document*
                </span>
                <p className="opacity-80 text-elr-purple underline text-14">
                  Registration.jpg
                </p>
              </div>
              <FieldDetail
                title="State of operation"
                value={capitalize(agent.state)}
              />
            </div>

            <div className="flex gap-16 pt-5">
              <FieldDetail
                title="Country of operation"
                value={capitalize(agent?.country)}
              />
              {isAnOperator && (
                <div>
                  <span className="opacity-40 text-12">Additional ID *</span>
                  <p className="opacity-80 text-elr-purple underline text-14">
                    NIN.jpg
                  </p>
                </div>
              )}
            </div>

            <div className="flex gap-14 pt-5">
              <FieldDetail title="Riders License" value="123-HU-168" />
              <FieldDetail
                title="Motorcycle registration number"
                value={agent.vehiclePlateNumber}
              />
            </div>
          </>
        )}
      </div>
    </SlideIn>
  );
};

export default ViewAgent;

const FieldDetail: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => (
  <div>
    <span className="opacity-40 text-12">{title}</span>
    <p className="opacity-80 text-14">{value}</p>
  </div>
);
