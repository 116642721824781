import appBannerVector from 'assets/images/app-banner-vector.svg';
import errandlrAppMockup from 'assets/images/errandlr-app-mockup.svg';
import appStore from 'assets/images/apple-fill-logo.svg';
import googlePlay from 'assets/images/google-play-line.svg';

const PoliciesAppBanner = () => (
  <div className="relative my-24 md:my-40 bg-elr-gray rounded-xl object-cover ">
    <div className="absolute inset-0">
      <img
        src={appBannerVector}
        alt="errandlr-pattern"
        className="md:w-auto object-cover md:h-full h-80 bottom-0 absolute right-0 rounded-xl overflow-hidden"
      />
    </div>
    <div className="relative lg:flex lg:flex-row md:flex-col align-middle justify-between items-center md:px-14 px-10 z-10 overflow-hidden">
      <div className="flex flex-col py-12">
        <p className="pb-4 md:pb-6 md:text-2xl text-lg text-elr-green">
          Download the Errandlr App
        </p>
        <h2 className="text-28 md:text-40 leading-tight font-bold w-full text-elr-black">
          Shop Anywhere, Ship
          <br /> Anywhere, Pay Anywhere.
        </h2>

        <div className="md:flex md:flex-row items-center md:gap-6 md:mt-24 mt-12 flex flex-col gap-4 md:w-10/12 w-full">
          <a
            href="https://apps.apple.com/app/errandlr/id6499235011"
            className="bg-white rounded-full flex py-3 px-7 align-middle items-center gap-2 justify-center w-full"
          >
            <img src={appStore} alt="apple-store" className="cursor-pointer" />
            <p>Get on iPhone</p>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share"
            className="bg-white rounded-full flex py-3 px-7 align-middle items-center gap-2 justify-center w-full"
          >
            <img
              src={googlePlay}
              alt="google-play"
              className="cursor-pointer"
            />
            <p>Get on Andriod</p>
          </a>
        </div>
      </div>
      <div className="flex lg:w-560 w-full justify-center align-middle items-center gap-4 md:gap-0 md:px-6">
        <img
          src={errandlrAppMockup}
          alt="errandlr-app-mockup"
          className=" relative md:-bottom-10 -bottom-8"
        />
      </div>
    </div>
  </div>
);

export default PoliciesAppBanner;
