import { useGetCountries } from 'app/hooks/useGetCountry';
import { mobileNumberFormatter } from 'app/utilities/phoneUtils';
import React, { useState } from 'react';
import isMobilePhone, {
  MobilePhoneLocale,
} from 'validator/es/lib/isMobilePhone';

interface Props {
  containerClass?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange: (
    payload: {
      isValid: boolean;
      mobile: string;
      formattedNumber: string;
    },
    options: string | object
  ) => void;
}

export const onPhoneInput = (
  mobile: string,
  onChange: Function,
  selectedCountryValue: string,
  selectedCountryOptions: {
    countryLocale: MobilePhoneLocale;
    countryShortCode: string;
  } = { countryLocale: 'en-NG', countryShortCode: 'NG' }
) => {
  const validatePhone = isMobilePhone(
    mobile,
    selectedCountryOptions.countryLocale
  );
  const formattedNumber = validatePhone
    ? mobileNumberFormatter(
        `${selectedCountryValue}${mobile}`,
        selectedCountryOptions.countryShortCode
      )
    : mobile;

  return onChange({
    isValid: validatePhone,
    mobile,
    formattedNumber,
  });
};

export const ElrPhoneInput: React.FC<Props> = ({
  value,
  containerClass,
  onChange,
  placeholder = '080 670 000 00',
  disabled,
  className = '',
}) => {
  const [selectValue, setSelectValue] = useState('+234');
  const [selectedPhoneLocale, setSelectedPhoneLocale] = useState<{
    countryLocale: MobilePhoneLocale;
    countryShortCode: string;
  }>({ countryLocale: 'en-NG', countryShortCode: 'NG' });
  const countries = useGetCountries();

  const onChangeSelect = (val: string) => {
    setSelectValue(val);
    const getLocale = countries.result.find(
      (c) => c.countryPhoneCode === val
    ) || { countryLocale: 'en-NG', countryShortCode: 'NG' };

    setSelectedPhoneLocale({
      countryLocale: getLocale?.countryLocale as MobilePhoneLocale,
      countryShortCode: getLocale.countryShortCode,
    });
    onPhoneInput('', onChange, val);
  };

  return (
    <div className={containerClass}>
      <select
        value={selectValue}
        onChange={(e) => onChangeSelect(e.target.value)}
        className={`${className} focus:outline-none bg-elr-gray h-12 w-2/6 text-elr-black pl-1.5 text-base text-opacity-40 font-medium`}
      >
        {countries.result.map((data) => (
          <option value={data.countryPhoneCode} key={data.countryPhoneCode}>
            {data.countryPhoneCode}
          </option>
        ))}
      </select>
      <input
        type="number"
        value={value}
        onChange={(e) =>
          onPhoneInput(
            e.target.value,
            onChange,
            selectValue,
            selectedPhoneLocale
          )
        }
        placeholder={placeholder}
        disabled={disabled}
        className="bg-none outline-none border-0 bg-elr-gray w-3/5 h-12 ml-2 pl-2"
      />
    </div>
  );
};

export const ElrPhoneInputFlat: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  className = '',
  containerClass = '',
  placeholder = '080 670 000 00',
}) => {
  const [selectValue, setSelectValue] = useState('+234');
  const [selectedPhoneLocale, setSelectedPhoneLocale] = useState<{
    countryLocale: MobilePhoneLocale;
    countryShortCode: string;
  }>({ countryLocale: 'en-NG', countryShortCode: 'NG' });
  const countries = useGetCountries();

  const onChangeSelect = (val: string) => {
    setSelectValue(val);
    const getLocale = countries.result.find(
      (c) => c.countryPhoneCode === val
    ) || { countryLocale: 'en-NG', countryShortCode: 'NG' };

    setSelectedPhoneLocale({
      countryLocale: getLocale?.countryLocale as MobilePhoneLocale,
      countryShortCode: getLocale.countryShortCode,
    });
    onPhoneInput('', onChange, val);
  };

  return (
    <div className={`${containerClass} w-full mb-3 flex justify-between`}>
      <select
        value={selectValue}
        onChange={(e) => onChangeSelect(e.target.value)}
        className={`${className} w-0.53 focus:outline-none border-b-05  text-elr-black pl-1.5 text-base text-opacity-40 font-medium`}
      >
        {countries.result.map((data) => (
          <option value={data.countryPhoneCode} key={data.countryPhoneCode}>
            {data.countryPhoneCode}
          </option>
        ))}
      </select>
      <input
        type="number"
        value={value}
        onChange={(e) =>
          onPhoneInput(
            e.target.value,
            onChange,
            selectValue,
            selectedPhoneLocale
          )
        }
        placeholder={placeholder}
        disabled={disabled}
        className={`${className} h-10 w-full bg-none outline-none border-0 border-b-05 ml-2 pl-2 border-solid border-elr-black border-opacity-40`}
      />
    </div>
  );
};
