import React, { useState } from 'react';
import AddAgent from './AddAgent';
import BankInfo from './BankInfo';
import CompanyDetails from './CompanyDetails';
import CompanyDocument from './CompanyDocument';
import OperationAreas from './OperationAreas';

const Onboarding: React.FC = () => {
  const [stepState, setStateState] = useState(0);
  const onChangeStep = (index: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setStateState(index);
  };

  const components = [
    CompanyDetails,
    CompanyDocument,
    OperationAreas,
    BankInfo,
    AddAgent,
  ];

  return (
    <span>
      {components.map(
        (Component, index) =>
          index === stepState && (
            <Component
              key={+new Date()}
              onChangeStep={onChangeStep}
              stepIndex={index}
            />
          )
      )}
    </span>
  );
};

export default Onboarding;
