import { Divider } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import { httpGetCommerceBySlug } from 'app/api/commerce';
import { SendRequest } from 'app/api/d';
import { httpGetEstimate } from 'app/api/estimate';
import { httpInitializeBusinessRequest } from 'app/api/requests';
import Loading from 'app/components/Loading';
import {
  LockerSizes,
  PAYSTACK_PUBLIC_KEY,
  PackageTypeCode,
  PackageTypeLabel,
} from 'app/constants';
import { useRequestDataPayload } from 'app/hooks/utils';
import { STATE_BUSINESS_INFORMATION } from 'app/state/constants';
import {
  getCurrentCountry,
  getCurrentCountryData,
  getDefaultState,
} from 'app/utilities/country';
import { currencyFormatter } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import {
  HttpDispatchResponse,
  LocationTagValues,
} from 'app/utilities/types/shared';
import cartIcon from 'assets/images/cart.svg';
import React, { RefObject, useRef, useState, useEffect } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { useNavigate, useParams } from 'react-router';
import {
  ElrButton,
  ElrInput,
  ElrLocationSelect,
  ElrPhoneInput,
} from 'ui-components';
import { ElrSelect } from 'ui-components/components/ElrSelect/ElrSelect';
import * as styles from './ClassUtils';
import DirectProductListing, { CartSlider } from './ProductListing';
import useProductsCart from './useProductCart';
import { CustomerEstimateFooter, CustomerEstimateHeader } from './utils';

type UrlParams = {
  commerceSlug: string;
};

const CustomerEstimate: React.FC = () => {
  const productListRef = useRef<HTMLDivElement>(null);
  const placeOrderRef = useRef<HTMLDivElement>(null);
  const history = useNavigate();
  const [isEconomyDelivery, setIsEconomyDelivery] = useState<boolean>(true);
  const [locationLabel, setLocationLabel] = useState<string>('');
  const [inputData, setInputData] = useState<{ name: string }>({
    name: '',
  });
  const [requestSuccess, setRequestStatus] = useState<boolean>(false);
  const [requestDataPayload] = useRequestDataPayload();
  const [estimateData, setEstimateData] = useState<HttpDispatchResponse | null>(
    null
  );
  const [phoneNumber, setPhoneNumber] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });
  const defaultLockerSize = `${LockerSizes[0].sizename} - ${LockerSizes[0].description}`;
  const [selectedLockerSize, setSelectedLockerSize] = useState<{
    value: string;
    label: string;
  }>({ value: LockerSizes[0].sizeid, label: defaultLockerSize });

  const { cart } = useProductsCart();
  const cartTotal = cart.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const params = useParams<keyof UrlParams>() as UrlParams;
  const { commerceSlug } = params;

  const onPhoneInputChange = (inputPayload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setPhoneNumber((prev) => ({ ...prev, ...inputPayload }));
  };

  const { isLoading, isError, isFetched, data } = useQuery({
    queryKey: [STATE_BUSINESS_INFORMATION],
    queryFn: () => httpGetCommerceBySlug(commerceSlug),
    enabled: Boolean(commerceSlug),
    retry: 0,
  });

  const onHandleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputData((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const hasError = !isLoading && isError && isFetched;

  if (hasError) {
    history('/not-found');
  }

  useEffect(() => {
    if (isFetched && data?.lockerClient) {
      window.location.href = 'https://onelink.to/ph8jez';
    }
  }, [isFetched, data, history]);

  const toggleDeliveryType = (value: boolean) => {
    setIsEconomyDelivery(value);
  };

  const price =
    (isEconomyDelivery
      ? estimateData?.batchEstimate
      : estimateData?.estimate) || 0;

  const config = {
    email: `${phoneNumber.mobile}@errandlr.com`,
    amount: (cartTotal + price) * 100,
    publicKey: PAYSTACK_PUBLIC_KEY,
    channels: ['card', 'ussd', 'bank_transfer'],
    metadata: {
      business: true,
    },
  };

  const initializePayment = usePaystackPayment(config as any);

  const authorizedRequestCall = async (payload: SendRequest) => {
    try {
      if (!data) return;
      const init = await httpInitializeBusinessRequest({
        roleId: data.roleId,
        payload: {
          ...payload,
          businessPays: data.businessPays,
          batch: isEconomyDelivery,
        },
      });
      const redirectUrl = `${publicRoutes.dispatchDetails}/${init.request}?track=${init.trackingId}`;
      window.location.href = redirectUrl;
    } catch (error) {
      setRequestStatus(false);
      /** no op, but catch with sentry */
    }
  };

  const makeRequestDataPayload = () => {
    /**
     * @todo
     * There's a bug here when user logs in and lands on estimate page
     * which is already populated, hence lat and lng will be undefined
     * fix is to refetch the coordinates.
     * temp fix is to return if no data, so they refresh.
     */
    if (!data || !estimateData) return;

    const updatedSenderInfo = {
      state: data.state,
      email: data.email,
      name: data.businessName,
      phone: data.phone,
    };

    const requestPayload = requestDataPayload({
      geoId: estimateData.geoId,
      data: {
        deliverToInformation: [
          {
            order: 0,
            name: inputData.name,
            phone: phoneNumber.formattedNumber,
            packageValue: 0,
            packageType: PackageTypeLabel.SMALL_PARCEL,
            state: getDefaultState(),
            country: getCurrentCountry(),
          },
        ],
        lockerSize: selectedLockerSize?.value,
      },
      senderInformation: updatedSenderInfo,
      geolocation: {
        geolocation: {
          lat: Number(data.mainAddress.latitude),
          lng: Number(data.mainAddress.longitude),
        },
        place: {
          city: data.mainAddress.city,
          country: data.mainAddress.country,
          state: data.mainAddress.state,
          localGovt: data.mainAddress.localGovt,
        },
      },
      batch: true,
    });

    MakeRequestInitiationCall(requestPayload);
  };

  const MakeRequestInitiationCall = async (req: SendRequest) => {
    try {
      setRequestStatus(true);
      return authorizedRequestCall(req);
    } catch (error) {
      setRequestStatus(false);
      /** no op, but catch with sentry */
      return error;
    }
  };

  const onSubmit = () => {
    makeRequestDataPayload();
  };

  const goToPaystack = () => {
    const MINIMUM_PAYSTACK = 50;
    price >= MINIMUM_PAYSTACK &&
      initializePayment({
        onSuccess: onSubmit,
        onClose: () => {},
      });
  };

  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return <div />;
  }

  const pickupLocation = {
    id: data.mainAddress.placeId || '',
    label: data.mainAddress.fullAddress || '',
    value: data.mainAddress.placeId || '',
    distance: 0,
    duration: 0,
  };

  const onLocationInputChange = async (
    value: LocationTagValues
  ): Promise<void> => {
    if (value) {
      setLocationLabel(value.label);
      if (value.id) {
        const res = await httpGetEstimate({
          pickupLocation,
          dropoffLocations: [value],
          parcelCode: PackageTypeCode.SMALL_PARCEL,
        });
        setEstimateData(res);
      }
    }
  };

  const onProceedBooking = () => {
    if (!data.businessPays) {
      return goToPaystack();
    }
    return onSubmit();
  };

  const renderEstimateInformation = () => {
    if (data.businessPays) return null;

    return (
      <>
        {estimateData?.estimateLabel && (
          <div className="mt-8">
            <div className="flex flex-col items-start justify-center gap-y-3">
              <label className={styles.DeliveryModeLabel(!isEconomyDelivery)}>
                <div className="flex flex-row items-center justify-center gap-x-3">
                  <input
                    className="accent-elr-black-200"
                    type="radio"
                    checked={!isEconomyDelivery}
                    onChange={() => toggleDeliveryType(false)}
                  />
                  <div className="flex flex-col gap-y-0.5">
                    <span className="leading-5 text-12 md:text-14 opacity-60">
                      Standard
                    </span>
                    <span className="leading-5 text-12 opacity-60">
                      {estimateData?.estimateLabel}
                    </span>
                  </div>
                </div>
                <p className="font-bold leading-4 text-12 md:text-14">
                  {currencyFormatter(
                    estimateData?.estimate || 0,
                    'en-US',
                    getCurrentCountryData().currencyCode.toUpperCase()
                  )}
                </p>
              </label>
            </div>
          </div>
        )}
        {estimateData?.batchLabel && (
          <div className="mt-8">
            <div className="flex flex-col items-start justify-center gap-y-3">
              <label className={styles.DeliveryModeLabel(isEconomyDelivery)}>
                <div className="flex flex-row items-center justify-center gap-x-3">
                  <input
                    className="accent-elr-black-200"
                    type="radio"
                    checked={isEconomyDelivery}
                    onChange={() => toggleDeliveryType(true)}
                  />
                  <div className="flex flex-col gap-y-0.5">
                    <span className="leading-5 text-12 md:text-14 opacity-60">
                      Business
                    </span>
                    <span className="leading-5 text-12 opacity-60">
                      {estimateData?.batchLabel}
                    </span>
                  </div>
                </div>
                <p className="font-bold leading-4 text-12 md:text-14">
                  {currencyFormatter(
                    estimateData?.batchEstimate || 0,
                    'en-US',
                    getCurrentCountryData().currencyCode.toUpperCase()
                  )}
                </p>
              </label>
            </div>
          </div>
        )}
      </>
    );
  };

  const hasRequiredData = estimateData && inputData.name && phoneNumber.isValid;
  // Check if the user has selected a locker size (if applicable)
  const hasSelectedLockerSize = data.lockerClient
    ? selectedLockerSize && selectedLockerSize.value !== ''
    : true;
  // Combine the conditions
  const canPlaceOrder = hasRequiredData && hasSelectedLockerSize;
  const canAddProducts = data.activateDirectory;

  const scrollToProductList = () => {
    const { current } = productListRef;
    if (current !== null) {
      current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.MainContainer(canAddProducts)}>
      <div className={styles.DeliveryDetailsContainer(canAddProducts)}>
        <CustomerEstimateHeader
          companyName={data.businessName}
          companyAddress={data.mainAddress.fullAddress}
        />
        {canAddProducts && (
          <button
            type="button"
            onClick={scrollToProductList}
            className="my-3 text-sm underline text-elr-purple md:hidden"
          >
            Get more items from our store
          </button>
        )}
        {canAddProducts && cart.length ? (
          <>
            <div className="self-center mt-7 text-white rounded-lg text-sm bg-elr-purple w-96 py-1.5 px-3">
              Amazing! You&apos;ve added {cart.length} new item
              {cart.length > 1 && 's'}
              &nbsp;from our store. Please enter your delivery details to
              continue
            </div>
            <CartSlider />
          </>
        ) : null}

        <div
          ref={placeOrderRef}
          className={styles.DeliveryDetailsInnerContainer(canAddProducts)}
        >
          <h3 className="mb-3 font-medium">Enter your delivery details</h3>
          <Divider />
          <div className="mt-5">
            <ElrLocationSelect
              currentValue={locationLabel}
              placeholder="Delivery destination"
              onChange={(value) => onLocationInputChange(value as any)}
              isClearable
            />

            {data.lockerClient && (
              <ElrSelect
                value={selectedLockerSize}
                placeHolder="Select Locker Size"
                bgcolor="#F7F7F7"
                controlHeight={50}
                containerHeight={45}
                className="w-54"
                showIndicator
                isClearable={false}
                options={LockerSizes.map((size) => ({
                  value: size.sizeid,
                  label: `${size.sizename} - ${size.description}`,
                }))}
                onChange={setSelectedLockerSize}
              />
            )}

            <ElrInput
              className="mb-3 border-none rounded-none bg-elr-gray"
              placeholder="Receiver Name*"
              type="text"
              name="name"
              value={inputData.name}
              onChange={onHandleInputChange}
            />
            <ElrPhoneInput
              containerClass="w-full mb-3 flex justify-between"
              value={phoneNumber.mobile}
              onChange={onPhoneInputChange}
              placeholder="Receiver Phonenumber*"
            />
            {!phoneNumber.isValid && phoneNumber.mobile && (
              <p className="text-xs text-elr-error">Mobile number incorrect</p>
            )}

            {renderEstimateInformation()}

            <div className="mt-7">
              {cart.length ? (
                <div className="flex flex-col items-center my-4">
                  <p className="text-elr-black text-opacity-70">
                    Amount to pay
                  </p>
                  <p className="text-lg font-bold">NGN {cartTotal + price}</p>
                </div>
              ) : null}

              <ElrButton
                text="Place order"
                disabled={!canPlaceOrder || requestSuccess}
                onClick={onProceedBooking}
                loading={requestSuccess}
                spinnerColor="White"
                className="w-full text-white bg-elr-black"
              />
            </div>
          </div>
        </div>
        {canAddProducts && <CustomerEstimateFooter />}
      </div>
      {canAddProducts ? (
        <>
          <DirectProductListing
            roleId={data.roleId}
            containerRef={productListRef}
          />
          <BottomMobileFooter placeOrderRef={placeOrderRef} />
        </>
      ) : null}
    </div>
  );
};

const BottomMobileFooter: React.FC<{
  placeOrderRef: RefObject<HTMLDivElement>;
}> = ({ placeOrderRef }) => {
  const [showCart, setShowCart] = useState(false);
  const { cart, cartCount, addToCart, reduceFromCart } = useProductsCart();
  const cartTotal = cart.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const toggleShowCart = () => setShowCart(!showCart);

  const scrollToPlaceOrder = () => {
    const { current } = placeOrderRef;
    if (current !== null) {
      current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div className="fixed bottom-0 flex items-center justify-between w-full px-4 py-4 rounded-b-2xl md:hidden bg-elr-black">
        <div className="w-1/2">
          <p className="text-lg font-bold text-white">
            Total fee: {currencyFormatter(cartTotal)}
          </p>
          <ElrButton
            onClick={scrollToPlaceOrder}
            text="Place order"
            spinnerColor="Black"
            size="sm"
            className="w-full mt-1.5 text-black bg-white"
          />
        </div>
        <div className="flex items-center justify-center w-16 h-16 bg-white rounded-full">
          <button className="relative" onClick={toggleShowCart} type="button">
            <img className="w-9" src={cartIcon} alt="cart" />
            {cartCount ? (
              <span className="absolute flex items-center justify-center w-5 h-5 text-sm text-white rounded-full bg-elr-black -right-1 -top-1">
                {cartCount}
              </span>
            ) : null}
          </button>
        </div>
      </div>

      {showCart && cartCount ? (
        <div className="fixed z-10 flex flex-col w-full px-1 py-3 bg-white rounded-lg shadow-lg md:hidden gap-y-3 bottom-24">
          {cart.map((item) => (
            <div
              key={`cartItem-${item.id}`}
              className="flex items-start justify-between px-4 py-1 mb-2 cursor-pointer"
            >
              <div className="flex items-center gap-x-4">
                <img alt="product" className="w-12" src={item.image} />
                <div>
                  <h4 className="text-elr-black text-opacity-70">
                    {item.name}
                  </h4>
                  <div className="flex items-center justify-between px-3 text-elr-black bg-elr-gray-100 gap-x-2 mt-0.5 text-sm rounded-3xl">
                    <button
                      type="button"
                      onClick={() => reduceFromCart(item.id)}
                      className="p-0.5 font-bold"
                    >
                      -
                    </button>
                    <span className="">{item.quantity}</span>
                    <button
                      type="button"
                      onClick={() => addToCart(item)}
                      className="p-0.5 font-bold"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <p>NGN {currencyFormatter(Number(item.price))}</p>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default CustomerEstimate;
