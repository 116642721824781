import { GOOGLE_API_KEY } from 'app/constants';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useLoadGoogleApi = () => {
  const [loading, setloading] = React.useState<boolean>(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
    script.addEventListener('load', () => {
      setloading(false);
    });
    script.addEventListener('error', () => {
      toast.error('Error loading google script');
    });

    document.body.appendChild(script);
  }, []);

  return loading;
};
