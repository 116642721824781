import { getNationalNumber } from 'app/utilities/phoneUtils';
import React, { useState } from 'react';
import { ElrDestinationPhoneInput } from 'ui-components';
import { PhoneNumberInputProps, isPhoneValid } from './UserPhone';

export const PhoneNumberInputDestination: React.FC<PhoneNumberInputProps> = ({
  field,
  label,
  optional,
  form,
  phoneValidityStatus,
  required,
  ...other
}) => {
  const { errors } = form;
  const [phoneNumberValue, setInputNumber] = useState<string>(
    `${getNationalNumber(field.value)}`
  );
  const value = field.value || '';
  const error = errors[field.name];
  const numberValid = isPhoneValid(value).isValid;
  phoneValidityStatus(numberValid);

  const setNumberOnChange = (evt: {
    formattedNumber: string;
    mobile: string;
  }) => {
    setInputNumber(evt.mobile);
    field.onChange({
      target: { value: `${evt.formattedNumber}`, name: field.name },
    });
  };

  return (
    <>
      <div className="text-base text-elr-black">{label}</div>
      <div>
        <ElrDestinationPhoneInput
          {...other}
          value={phoneNumberValue}
          onChange={setNumberOnChange}
          numberValid={numberValid}
        />
      </div>
      {error ||
        (!numberValid && phoneNumberValue && (
          <div className="text-sm mt-2 text-elr-error">
            {error || 'Phone number is invalid'}
          </div>
        ))}
    </>
  );
};
