export const OperatorDetailsContainer = (accepted: boolean) =>
  `${
    accepted ? '' : 'h-28 opacity-40'
  } mt-4 w-350 md:w-600 bg-elr-white-400 p-4 pb-6 border border-elr-black border-opacity-40 rounded-md`;

export const IntransitContainer = (collected: boolean) =>
  `${
    collected ? 'h-136' : 'h-28 opacity-40'
  } mt-4 w-350 md:w-600 bg-elr-white-400 p-4 border border-elr-black border-opacity-40 rounded-md`;

export const ParcelCompleted = (completed: boolean) =>
  `${
    completed ? '' : 'h-28 bg-elr-white-400 opacity-40'
  } my-4 w-350 md:w-600 bg-elr-green text-elr-white-400 px-4 py-5 border border-elr-black border-opacity-40 rounded-md`;

export const ParcelClosed = (closed: boolean) =>
  `${
    closed ? 'opacity-40' : ''
  } mt-4 w-350 md:w-600 bg-elr-white-400 p-4 pb-6 border border-elr-black border-opacity-40 rounded-md`;

export const CommmentsButton = (checkText: string, text: string) =>
  ` ${
    checkText === text
      ? 'bg-elr-black text-white'
      : 'bg-transparent border-elr-black  border-opacity-30 opacity-60 text-black'
  }  min-h-1 border  px-2  text-12`;
