export const UpcomingDeliveriesLabel = () => (
  <div className="flex justify-between">
    <div className="text-xs md:text-lg text-elr-black md:text-elr-black text-opacity-60">
      <span>Upcoming Deliveries</span>
    </div>
  </div>
);

export const NewRequestLabel = () => (
  <p className="text-xs md:text-lg text-elr-black md:text-elr-black text-opacity-60">
    New Requests
  </p>
);

export const DeliveriesMadeLabel = () => (
  <div className="flex justify-between">
    <div className="text-xs md:text-lg text-elr-black md:text-elr-black text-opacity-60">
      <span>Deliveries Made</span>
    </div>
  </div>
);
export const ProductsLabel = () => (
  <div className="flex justify-between">
    <div className="text-xs md:text-lg text-elr-black md:text-elr-black text-opacity-60">
      <span>Products</span>
    </div>
  </div>
);
