import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios } from 'app/utilities/axios';
import { ProductsListData } from 'app/utilities/types/shared';
import { toast } from 'react-toastify';
import { RequestsDataInterface } from './d';

/**
 *@description Get new requests for senders
 */
interface Params {
  userId: string;
  nextPage: number;
}

export const httpGetDeliveriesMade = async ({
  userId,
  nextPage,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(`/sender/${userId}/get-total-deliveries`, {
      params: { page: nextPage },
      ...SupportHeader(),
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

export const httpGetUpcomingDeliveries = async ({
  userId,
  nextPage,
}: Params): Promise<RequestsDataInterface> => {
  try {
    const response = await axios.get(
      `/sender/${userId}/get-upcoming-deliveries`,
      {
        params: { page: nextPage },
        ...SupportHeader(),
      }
    );
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};

interface productsParams {
  userId: string;
  nextPage: number;
}

export const httpGetDirectProductsList = async ({
  userId,
  nextPage,
}: productsParams): Promise<ProductsListData> => {
  const limit = 10;
  try {
    const response = await axios.get(`/product/public/${userId}`, {
      params: {
        page: nextPage,
        limit,
      },
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message);
    throw new Error(error);
  }
};
