import { Roles } from 'app/hooks/user';
import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios } from 'app/utilities/axios';
import { COMMERCE } from 'app/utilities/roles';
import { toast } from 'react-toastify';
import { WalletBalance, WalletSettings } from './d';

export const httpGetWalletBalance = async ({
  userId,
  roleId,
  role = COMMERCE,
}: {
  userId: string;
  roleId: string;
  role: Roles;
}): Promise<WalletBalance | null> => {
  try {
    const response = await axios.get(
      `/wallet/${userId}/balance?roleId=${roleId}&role=${role}`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const httpGetBillingRecord = async ({
  userId,
  roleId,
  role,
  nextPage,
}: {
  userId: string;
  roleId: string;
  role: Roles;
  nextPage: number;
}): Promise<WalletBalance | null> => {
  try {
    const response = await axios.get(
      `/wallet/${userId}/record?roleId=${roleId}&role=${role}&limit=${30}&page=${nextPage}`,
      SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const httpUpdateWalletSettings = async ({
  payload,
  userId,
  roleId,
}: {
  payload: WalletSettings;
  userId: string;
  roleId: string;
}) => {
  try {
    const response = await axios.put(
      `/wallet/${userId}/settings?roleId=${roleId}`,
      { ...payload },
      SupportHeader()
    );
    toast.success('Setting Updated', { position: 'top-right' });
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.message, { position: 'top-right' });
    throw new Error(error.response?.data?.message);
  }
};

export const httpGetBillingHistory = async ({
  userId,
  roleId,
  limit = 10,
  page = 1,
  role = COMMERCE,
}: {
  userId: string;
  roleId: string;
  limit?: number;
  page?: number;
  role: string;
}) => {
  try {
    const response = await axios.get(
      `wallet/${userId}/billing-history?roleId=${roleId}&role=${role}&limit=${limit}&page=${page}`,
      await SupportHeader()
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
