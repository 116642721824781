import markIcon from 'assets/images/mark-icon.svg';
import { FieldInputProps, FormikProps } from 'formik';
import React, { InputHTMLAttributes } from 'react';
import { ElrTextAreaInputVar2 } from 'ui-components';

interface InputProps {
  label?: string;
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  optional?: boolean;
  preComponent?: React.ReactNode;
}

const TextAreaInput: React.FC<
  InputHTMLAttributes<HTMLInputElement> & InputProps
> = ({ field, form, preComponent, type, optional }) => {
  const { touched, errors } = form;
  const error = errors[field.name] as string;
  const dirty = touched[field.name];
  return (
    <span>
      {preComponent && <div className="mr-2">{preComponent}</div>}
      <ElrTextAreaInputVar2
        {...field}
        value={field.value || ''}
        type={type || 'text'}
        max={100}
      />
      {!error && dirty && !optional && <img src={markIcon} alt="done" />}
      {error && dirty && (
        <div className="text-xs mt-2 text-elr-green text-right">{error}</div>
      )}
    </span>
  );
};

export default TextAreaInput;
