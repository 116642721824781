import { useFetchUpcomingRequests } from 'app/hooks/requests/dashboard';
import FilterInput from 'app/platform/components/FilteredInput/FilteredInput';
import { parseRequestType } from 'app/utilities/helpers';
import { RequestType } from 'app/utilities/types/shared';
import React, { useState } from 'react';
import CountElement from '../CountElement';
import SingleUpcomingDelivery from './SingleUpcomingDelivery';

interface RequestsPropInterface {
  displayOriginalFees?: boolean;
}

const BatchUpcomingContainer: React.FC<RequestsPropInterface> = ({
  displayOriginalFees,
}) => {
  const [fetchType, setFetchType] = useState<RequestType | ''>('');
  const { latestData: singleUpcomingRequestData } =
    useFetchUpcomingRequests(fetchType);

  const [requestType, setRequestType] = useState<{
    value: string;
    label: string;
  }>({
    value: '',
    label: '',
  });

  const onChangeRequestType = (option: any) => {
    const { value, label } = option;
    setRequestType({
      value,
      label,
    });
    const requestTypeValue: any = parseRequestType(label);
    setFetchType(requestTypeValue);
  };

  return (
    <div className="w-full md:w-1/2">
      <div className="w-11/12 flex flex-col md:w-full md:ml-5 max-md:mb-3 mx-auto border border-elr-green border-opacity-30 h-450 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md overflow-y-hidden">
        <div>
          <div className="flex items-center justify-center pt-5 md:justify-between">
            <div className="hidden text-lg md:flex md:items-center text-elr-black">
              <span>Upcoming Deliveries</span>
              <CountElement bgColor="elr-purple" size="sm" classNames="ml-5">
                {singleUpcomingRequestData.totalDocs}
              </CountElement>
            </div>
          </div>
          <div className="hidden pt-4 border-b md:block border-elr-gray-400 border-opacity-20" />
          <div className="items-center hidden md:flex">
            <div>
              <FilterInput
                onChangeRequestType={onChangeRequestType}
                requestType={requestType}
                hideAddressSelect
              />
            </div>
          </div>
        </div>
        <SingleUpcomingDelivery
          displayOriginalFees={displayOriginalFees}
          containerClassName="h-full overflow-y-scroll no-scrollbar md:border-t border-elr-gray-400 border-opacity-20"
          requestType={parseRequestType(requestType.label)}
        />
      </div>
    </div>
  );
};

export default BatchUpcomingContainer;
