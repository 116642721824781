import { BatchTabsInterface } from 'app/utilities/types/shared';
import React from 'react';
import { ElrPillButton } from 'ui-components';
import { activeSpan, upcomingBatchTabsStyles } from './ClassUtils';

interface RequestTabsInterface {
  tabNameArray: BatchTabsInterface[];
  activeTab: BatchTabsInterface;
  onTabClick: (tab: BatchTabsInterface) => void;
  totalSingleRequests: number;
  totalBatchRequests: number;
  classNames?: string;
}

export const RequestTabs: React.FC<RequestTabsInterface> = ({
  tabNameArray,
  activeTab,
  onTabClick,
  totalSingleRequests,
  totalBatchRequests,
  classNames,
}) => {
  const requestCount = {
    [tabNameArray[0].id]: totalSingleRequests,
    [tabNameArray[1].id]: totalBatchRequests,
  };

  return (
    <div className={`${classNames} flex w-full border-l border-elr-black`}>
      {tabNameArray.map((tab: BatchTabsInterface) => (
        <div
          key={tab.id}
          className={`cursor-pointer w-1/2 text-center border-t border-b border-r border-elr-black`}
          onClick={() => onTabClick(tab)}
        >
          <p className={`${activeSpan(tab, activeTab)} py-1`}>
            {tab.label} ({requestCount[tab.id]})
          </p>
        </div>
      ))}
    </div>
  );
};

interface BatchRequestsTab {
  batchRequestTabs: Array<BatchTabsInterface>;
  onChangeBatchType: (tab: BatchTabsInterface) => void;
  currentTypeTab: BatchTabsInterface;
}

export const BatchRequestsTabs: React.FC<BatchRequestsTab> = ({
  batchRequestTabs,
  onChangeBatchType,
  currentTypeTab,
}) => (
  <>
    {batchRequestTabs.map((item: BatchTabsInterface) => (
      <ElrPillButton
        key={item.id}
        text={item.label}
        size="sm"
        className={upcomingBatchTabsStyles(currentTypeTab, item)}
        onClick={() => onChangeBatchType(item)}
      />
    ))}
  </>
);
