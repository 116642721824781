import blackBg from 'assets/images/blackGeometricGradient.svg';
import deliveryMockup from 'assets/images/iPhone13Delivery.png';
import marketMockup from 'assets/images/iPhone13Market.png';
import appStore from 'assets/images/app-store-btn.svg';
import googlePlay from 'assets/images/google-play-btn.svg';

const AppBanner = () => (
  <div className="relative my-36 md:mt-48 lg:mx-32 md:mx-24">
    <div className="absolute inset-0 mx-4">
      <img
        src={blackBg}
        alt=""
        className="w-full h-full object-cover rounded-xl overflow-hidden"
      />
    </div>
    <div className="relative lg:flex lg:flex-row md:flex-col align-middle justify-between items-center px-14 md:pt-14 pt-16 z-10 overflow-hidden">
      <div className="flex flex-col">
        <h2 className="text-28 md:text-40 leading-tight font-bold w-full text-white">
          Ready to Get Started?
        </h2>
        <p className="lg:w-1/2 text-xl w-11/12 pt-6 pb-10 md:pb-11 opacity-80 text-white">
          Join thousands of satisfied users enjoying seamless shopping,
          shipping, and financial services.
        </p>
        <div className="flex md:w-10/12 lg:w-[300px] items-center gap-6 h-11 mb-16">
          <a href="https://apps.apple.com/app/errandlr/id6499235011">
            <img src={appStore} alt="apple-store" className="cursor-pointer" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.errandlr.commerce&pcampaignid=web_share">
            <img
              src={googlePlay}
              alt="google-play"
              className="cursor-pointer"
            />
          </a>
        </div>
      </div>
      <div className="flex lg:w-560 w-full justify-center align-middle items-center mt-10 gap-4 md:gap-0 md:px-6">
        <img
          src={deliveryMockup}
          alt=""
          className="md:w-1/2 w-40 relative md:-bottom-16 -bottom-8 md:mt-[4px] mt-0.5"
        />
        <img src={marketMockup} alt="" className="md:w-1/2 w-40 md:ml-4" />
      </div>
    </div>
  </div>
);

export default AppBanner;
