export const loginContainerClass = (slideInMode?: boolean) =>
  `${
    slideInMode ? 'w-full' : 'w-11/12 md:w-96 rounded-2xl'
  } bg-elr-white-400  flex flex-col items-center`;

export const loginTextClass = (slideInMode?: boolean) =>
  `${
    slideInMode ? 'mt-5 text-center md:text-left' : 'mt-14 text-center'
  } mb-7 text-xl `;

export const loginFormClass = (slideInMode?: boolean) =>
  `${slideInMode ? 'w-full' : 'w-5/6 md:w-72'}`;

export const loginButtonContainer = (slideInMode?: boolean) =>
  slideInMode ? 'flex flex-col items-center' : '';

export const loginButtonClass = (slideInMode?: boolean) =>
  `bg-elr-black text-white text-lg mb-4 md:mb-4 ${
    slideInMode ? 'w-72' : 'w-full'
  }`;

export const loginDivClass = (slideInMode?: boolean) =>
  `flex flex-col h-screen items-center ${
    slideInMode ? 'bg-elr-white-400' : 'bg-elr-gray-500'
  } `;
