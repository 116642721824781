import { httpResetPassword, httpUpdatePassword } from 'app/api/user';
import { CenterLogoHeader } from 'app/components/Header';
import { useGetProfile } from 'app/hooks/user';
import { publicRoutes } from 'app/utilities/routes';
import { useFormik } from 'formik';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';
import * as Yup from 'yup';

interface FormValue {
  password: string;
  confirmPassword: string;
}

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'password must be at least 8 characters')
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/,
      'must contain at least 1 letter and 1 number'
    ),
  confirmPassword: Yup.string()
    .required()
    .nullable()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

export const ResetPasswordChildComponent: React.FC<{ editMode?: boolean }> = ({
  editMode,
}) => {
  const { id: userId } = useGetProfile();
  const history = useNavigate();

  const token =
    new URLSearchParams(useLocation().search).get('ref') || undefined;

  const [loading, setLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const onSubmit = async (values: FormValue) => {
    try {
      setLoading(true);
      if (!editMode) {
        //  if user is logged out
        await httpResetPassword(values.password, token);
        return history(publicRoutes.login);
      }

      await httpUpdatePassword(values, userId);
      setLoading(false);
      setShowSuccess(true);
      return true;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  const { handleSubmit, handleChange, values, errors } = useFormik<FormValue>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: PasswordSchema,
    onSubmit: (value) => onSubmit(value),
  });

  return (
    <div
      className={`md:pb-4 pb-9 ${
        editMode ? 'w-full px-2 md:px-3' : 'w-11/12 md:w-96 px-7 md:px-9'
      } bg-elr-white-400 rounded-2xl flex flex-col mb-12`}
    >
      <div className="text-xl text-center mt-14 mb-7">Reset Password </div>
      {showSuccess && (
        <p className="py-3 text-sm text-elr-green">
          Password Reset Successful.
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-10 md:mb-11">
          {errors?.password && (
            <span className="text-xs text-elr-error">{errors.password}</span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="New Password"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          {errors?.confirmPassword && (
            <span className="text-xs text-elr-error">
              {errors.confirmPassword}
            </span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Confirm New Password"
            type="password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-center">
          <ElrButton
            text="Reset"
            submit
            spinnerColor="White"
            loading={loading}
            className="bg-elr-black text-white text-lg w-full h-13.5 mb-3"
          />
          {!editMode && (
            <>
              <p className="w-10/12 mx-auto text-xs md:w-11/12 text-elr-gray-400 opacity-70 sm:mt-2">
                By clicking the &quot;Reset&quot; button, you agree to
                Erandlr&apos;s&nbsp;
                <Link to={publicRoutes.privacyPolicy}>
                  <span className="cursor-pointer text-elr-purple opacity-60">
                    terms of acceptable use.
                  </span>
                </Link>
              </p>
              <p className="w-10/12 pb-5 mx-auto mt-6 text-xs text-center md:mt-11 text-elr-gray-400 opacity-70">
                Already have an account? &nbsp;
                <Link to={publicRoutes.login}>
                  <span className="cursor-pointer text-elr-purple opacity-60">
                    Login
                  </span>
                </Link>
              </p>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

const ResetPassword: React.FC = () => (
  <div>
    <ElrPageTitle title="Errandlr - Reset Password" />
    <div className="flex flex-col items-center">
      <CenterLogoHeader />
      <ResetPasswordChildComponent />
    </div>
  </div>
);

export default ResetPassword;
