import { Session } from './Session';

const SupportHeader = (extraMetadata: object = {}) => {
  let header = {};
  const token = Session.currentUser();

  if (token) {
    header = {
      headers: {
        Authorization: `Bearer ${token}`,
        ...extraMetadata,
      },
    };
  }
  return header;
};

export default SupportHeader;
