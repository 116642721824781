import { ENV } from 'app/constants';
import { useEffect } from 'react';
import packageJson from '../package.json';

window.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;

    return a > b || isNaN(b);
  }
  return false;
};

const CacheBust = () => {
  const forceReload = () => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = window.location.href;
    document.body.appendChild(form);
    form.submit();
  };

  const refreshCacheAndReload = async () => {
    forceReload();
  };

  useEffect(() => {
    if (ENV !== 'development') {
      fetch('/meta.json', { method: 'post' })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = window.appVersion;

          const shouldForceRefresh = semverGreaterThan(
            latestVersion,
            currentVersion
          );
          if (shouldForceRefresh) {
            refreshCacheAndReload();
          } else {
            // console.log(
            //   `You already have the latest version - ${latestVersion}. No cache refresh needed.`
            // );
          }
        });
    }
  });
  return null;
};

export default CacheBust;
