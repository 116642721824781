import AgentForm from 'app/modules/onboarding/AgentForm';
import { AgentInterface } from 'app/utilities/types/shared';
import React from 'react';
import { ElrModal } from 'ui-components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCompleted: () => void;
  agent: AgentInterface;
}

const EditAgent: React.FC<Props> = ({ isOpen, onClose, onCompleted, agent }) =>
  isOpen ? (
    <ElrModal isOpen={isOpen} onClose={onClose}>
      <AgentForm
        onComplete={onCompleted}
        onClose={onClose}
        agent={agent}
        editAgent
      />
    </ElrModal>
  ) : null;

export default EditAgent;
