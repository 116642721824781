import AddUserIcon from '@material-ui/icons/PersonAdd';

interface UnmanagedAgentsInterface {
  onClick?: () => void;
  unmanagedAgentsCount?: number;
}

export const UnmanagedAgentsIcon: React.FC<UnmanagedAgentsInterface> = ({
  onClick,
  unmanagedAgentsCount,
}) => (
  <div className="mr-4 relative">
    <span className="cursor-pointer" onClick={onClick}>
      <span className="bg-elr-purple bg-opacity-10 text-elr-purple px-1.5 py-0.5 text-xs absolute -left-6 -top-3 md:-top-1">
        {unmanagedAgentsCount || 0}
      </span>
      <AddUserIcon fontSize="medium" role="button" />
    </span>
  </div>
);
