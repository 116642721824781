import { BATCHID, SINGLEID } from 'app/constants';
import {
  useFetchNewBatchRequests,
  useFetchNewRequests,
} from 'app/hooks/requests/dashboard';
import FilterInput from 'app/platform/components/FilteredInput/FilteredInput';
import { CustomAccessView } from 'app/utilities/ComponentView';
import {
  batchRequestTabs,
  dispatchTabs,
  isCurrentTab,
  isSingleTab,
  parseRequestType,
} from 'app/utilities/helpers';
import { COMMERCE, DISPATCHER, OPERATOR } from 'app/utilities/roles';
import { BatchTabsInterface, RequestType } from 'app/utilities/types/shared';
import React, { useState } from 'react';
import CountElement from '../CountElement';
import BatchDelivery from './BatchDelivery';
import { BatchRequestsTabs, RequestTabs } from './RequestTabs';
import SingleDelivery from './SingleDelivery';

interface RequestsPropInterface {
  displayOriginalFees?: boolean;
  tabs: {
    id: string;
    label: string;
    disabled: boolean;
  }[];
}

const BatchContainer: React.FC<RequestsPropInterface> = ({
  displayOriginalFees,
  tabs,
}) => {
  const [active, setActive] = useState(0);
  const [fetchType, setFetchType] = useState<RequestType>('marketplace');
  const [currentTypeTab, setCurrentTypeTab] = useState(batchRequestTabs[0]);

  const { latestData: newSingleRequestData } = useFetchNewRequests(fetchType);
  const { latestData: newBatchRequestData } = useFetchNewBatchRequests(
    currentTypeTab.id
  );

  const [currentTab, setCurrentTab] = useState(dispatchTabs[0]);

  const totalSingleRequestDocs = newSingleRequestData.totalDocs;
  const totalBatchRequestDocs = newBatchRequestData.totalDocs;

  const [requestType, setRequestType] = useState<{
    value: string;
    label: string;
  }>({
    value: 'marketplace',
    label: 'marketplace',
  });

  const onChangeRequestType = (option: any) => {
    const { value, label } = option;
    setRequestType({
      value,
      label,
    });
    const requestTypeValue: any = parseRequestType(label);
    setFetchType(requestTypeValue);
  };

  const requestTypeValue = parseRequestType(requestType.label);

  const onTabClicked = (tab: BatchTabsInterface) => {
    setCurrentTab(tab);
  };

  const onChangeBatchType = async (tab: BatchTabsInterface) => {
    setCurrentTypeTab(tab);
  };

  const requestCount = totalSingleRequestDocs + totalBatchRequestDocs;

  return (
    <div className="w-full md:w-1/2">
      <div className="w-11/12 flex flex-col max-md:mb-3 md:w-full md:ml-5 mx-auto border border-elr-green border-opacity-30 h-450 md:mt-20 bg-elr-white-400 px-2.5 md:px-5 rounded-md overflow-y-auto">
        <div>
          <div className="flex items-center justify-center pt-5 md:justify-between">
            <div className="hidden text-lg md:flex md:items-center text-elr-black">
              New Requests
              <CountElement bgColor="elr-yellow" size="sm" classNames="ml-5">
                {requestCount}
              </CountElement>
            </div>

            <div className="w-full md:w-72">
              <CustomAccessView showFor={[OPERATOR, COMMERCE]}>
                <RequestTabs
                  tabNameArray={tabs}
                  activeTab={currentTab}
                  onTabClick={onTabClicked}
                  totalSingleRequests={totalSingleRequestDocs}
                  totalBatchRequests={totalBatchRequestDocs}
                />
              </CustomAccessView>
              {isCurrentTab(BATCHID, currentTab.id) && (
                <CustomAccessView showFor={[OPERATOR, COMMERCE, DISPATCHER]}>
                  <div className="mb-5 md:hidden mt-7">
                    <BatchRequestsTabs
                      batchRequestTabs={batchRequestTabs}
                      onChangeBatchType={onChangeBatchType}
                      currentTypeTab={currentTypeTab}
                    />
                  </div>
                </CustomAccessView>
              )}
            </div>
          </div>
          <div className="hidden pt-4 border-b md:block border-elr-gray-400 border-opacity-20" />
          <div className="items-center justify-between hidden md:flex">
            <div>
              <FilterInput
                onChangeRequestType={onChangeRequestType}
                requestType={requestType}
                showType={isSingleTab(currentTab.id)}
                hideAddressSelect
              />
            </div>
            {isCurrentTab(BATCHID, currentTab.id) && (
              <CustomAccessView showFor={[OPERATOR]}>
                <div>
                  <BatchRequestsTabs
                    batchRequestTabs={batchRequestTabs}
                    onChangeBatchType={onChangeBatchType}
                    currentTypeTab={currentTypeTab}
                  />
                </div>
              </CustomAccessView>
            )}
          </div>
        </div>
        <div className="hidden border-b md:block border-elr-gray-400 border-opacity-20" />
        {isCurrentTab(SINGLEID, currentTab.id) && (
          <SingleDelivery
            displayOriginalFees={displayOriginalFees}
            containerClassName="h-full overflow-y-scroll no-scrollbar"
            requestType={requestTypeValue}
          />
        )}
        {isCurrentTab(BATCHID, currentTab.id) && (
          <BatchDelivery
            active={active}
            setActive={setActive}
            currentTypeTab={currentTypeTab}
          />
        )}
      </div>
    </div>
  );
};

export const CountLabels = ({
  isSingleOptionTab,
  isBatchOptionTab,
  totalSingleRequestDocs,
  totalBatchRequestDocs,
}: {
  isSingleOptionTab: boolean;
  isBatchOptionTab: boolean;
  totalSingleRequestDocs: number;
  totalBatchRequestDocs: number;
}) => (
  <>
    {isSingleOptionTab && <span> ({totalSingleRequestDocs})</span>}
    {isSingleOptionTab && (
      <span className="rounded-full bg-elr-yellow w-[6px] h-[6px] inline-block mb-2 ml-1" />
    )}

    {isBatchOptionTab && <span> ({totalBatchRequestDocs})</span>}
    {isBatchOptionTab && (
      <span className="rounded-full bg-elr-yellow w-[6px] h-[6px] inline-block mb-2 ml-1" />
    )}
  </>
);

export default BatchContainer;
