import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { BackIconWonWithBG } from 'app/components/SvgAssets';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ElrButton } from 'ui-components';
import { useScanTicket } from 'app/hooks/event';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import QrSuccessIcon from '../../../../assets/images/payment-success-logo.svg';
import QrCodeIcon from '../../../../assets/images/qr-code-search.svg';

const EventQrScan = () => {
  const { scanTicket, loading, ticketValid } = useScanTicket();
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [eventData, setEventData] = useState<{
    event: string;
    date: string;
    name: string;
    ticketType: string;
    numberOfTickets: string;
  } | null>(null);
  const [isEvent, setIsEvent] = useState(false);
  const navigate = useNavigate();

  useEffect(
    () =>
      showScanner
        ? () => {
            const timeout = setTimeout(() => {
              if (!scanResult) {
                setShowScanner(false);
              }
            }, 100000);

            return () => clearTimeout(timeout);
          }
        : () => {},
    [showScanner, scanResult]
  );

  const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
    if (detectedCodes.length > 0) {
      const result = detectedCodes[0].rawValue;
      setScanResult(result);
      setShowScanner(false);

      try {
        const parsedResult = JSON.parse(result);
        const ticketId = parsedResult.id;

        if (
          parsedResult.eventName &&
          parsedResult.eventDate &&
          parsedResult.eventUUID
        ) {
          setIsEvent(true);
          setEventData({
            event: parsedResult.eventName,
            date: parsedResult.eventDate,
            name: parsedResult.eventUUID,
            ticketType: parsedResult.type || 'Standard',
            numberOfTickets: parsedResult.numberOfTickets
              ? String(parsedResult.numberOfTickets)
              : '1',
          });
        } else if (
          parsedResult.event &&
          parsedResult.date &&
          parsedResult.name &&
          parsedResult.type
        ) {
          setIsEvent(false);
          setEventData({
            event: parsedResult.event,
            date: parsedResult.date,
            name: parsedResult.name,
            ticketType: parsedResult.type,
            numberOfTickets: parsedResult.numberOfTickets
              ? String(parsedResult.numberOfTickets)
              : '1',
          });
        }
        if (ticketId) {
          await scanTicket(ticketId);
        }
      } catch (error) {
        // Handle error
      }
    }
  };

  const handleScanNow = () => {
    setShowScanner(true);
    setScanResult(null);
    setEventData(null);
  };

  const handleGoBack = () => {
    if (showScanner) {
      setShowScanner(false);
    } else {
      navigate(-1);
    }
  };

  const capitalize = (s: any) => s && s[0].toUpperCase() + s.slice(1);

  const renderTicketValidity = () => {
    if (loading) return <p>Loading...</p>;
    if (ticketValid === null)
      return <p className="text-elr-error">Ticket could not be validated</p>;
    return ticketValid.ok ? (
      <p>Valid</p>
    ) : (
      <p className="text-elr-error">Ticket used already</p>
    );
  };

  return (
    <div className="flex justify-center min-h-screen bg-elr-gray w-full align-middle items-center">
      <div className="p-10 w-full md:w-4/12 bg-white rounded-2xl flex flex-col items-center">
        {!scanResult && !showScanner && (
          <>
            <div className="bg-elr-gray w-40 h-40 rounded-full flex items-center justify-center mb-8">
              <img src={QrCodeIcon} alt="qr code" />
            </div>
            <p className="text-2xl text-elr-black pb-4">Scan QR Code</p>
            <p className="text-lg w-10/12 text-center text-elr-black-400">
              Streamline your event check-in process by verifying attendees with
              a quick QR code scan
            </p>
            <div className="pt-12 w-full flex items-center align-middle justify-center">
              <ElrButton
                text="Scan Now"
                className="bg-elr-black text-white w-full rounded-md"
                onClick={handleScanNow}
              />
            </div>
          </>
        )}

        {showScanner && (
          <div className=" w-full">
            <section
              className="flex gap gap-x-2 cursor-pointer pb-6"
              onClick={handleGoBack}
            >
              <BackIconWonWithBG />
              <p className="self-center">Back</p>
            </section>
            <Scanner onScan={handleScan} />
            <ElrButton
              text="Scanning ..."
              className="bg-elr-black text-white w-full rounded-md mt-6"
              disabled
              spinnerColor="White"
            />
          </div>
        )}

        {!showScanner && scanResult && eventData && (
          <div className="w-full">
            <div className="flex flex-col justify-center">
              <div className="w-full h-20 flex items-center align-middle justify-center mb-8">
                <img src={QrSuccessIcon} alt="qr-success" />
              </div>
              <p className="text-2xl text-elr-black mb-6 text-center">
                {isEvent ? 'Event Information' : 'Attendee Information'}
              </p>
              <div className="flex flex-col gap-6">
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 text-elr-black-300 pb-1">Name</p>
                  <p className="md:w-1/2 w-9/10">{eventData.name}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 text-elr-black-300 pb-1">Event Name</p>
                  <p className="md:w-1/2 w-9/10">{eventData.event}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 text-elr-black-300 pb-1">
                    Date Purchased
                  </p>
                  <p className="md:w-1/2 w-9/10">{eventData.date}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 text-elr-black-300 pb-1">
                    Number of Tickets
                  </p>
                  <p className="md:w-1/2 w-9/10">{eventData.numberOfTickets}</p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 text-elr-black-300 pb-1">Ticket Type</p>
                  <p className="md:w-1/2 w-9/10">
                    {capitalize(eventData.ticketType)}
                  </p>
                </section>
                <section className="flex-col md:flex md:flex-row">
                  <p className="w-1/2 text-elr-black-300 pb-1">
                    Ticket Validity
                  </p>
                  {renderTicketValidity()}
                </section>
              </div>
              <div className="pt-12 w-full flex items-center align-middle justify-center">
                <ElrButton
                  text="Scan Another Code"
                  className="bg-elr-black text-white w-full rounded-md"
                  onClick={handleScanNow}
                />
              </div>
            </div>
          </div>
        )}

        {!showScanner && scanResult && !eventData && (
          <>
            <div className="flex items-center justify-center mb-8">
              <img src={ErrorIcon} alt="error-icon" />
            </div>
            <p className="text-2xl text-elr-black pb-4">Invalid QR Code</p>
            <p className="text-lg w-8/12 text-center text-elr-black-400">
              The QR code could not be verified. Please try again
            </p>
            <div className="pt-12 w-full flex items-center align-middle justify-center">
              <ElrButton
                text="Scan Again"
                className="bg-elr-black text-white w-full rounded-md"
                onClick={handleScanNow}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EventQrScan;
