import libPhoneNumber from 'google-libphonenumber';
import memoizeOne from 'memoize-one';

const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
const PNF = libPhoneNumber.PhoneNumberFormat;

export const parseCountryNumber = (
  number: string | undefined,
  countryShortCode: string = 'NG'
) => {
  const NG = phoneUtil.isValidNumberForRegion(
    phoneUtil.parse(number, countryShortCode),
    countryShortCode
  );
  return NG ? countryShortCode : undefined;
};

export const mobileNumberFormatter = (
  number: string,
  countryShortCode: string
) => {
  try {
    const validNumberEntry = parseCountryNumber(number, countryShortCode);
    return (
      phoneUtil.format(phoneUtil.parse(number, validNumberEntry), PNF.E164) ||
      undefined
    );
  } catch (error) {
    return null;
  }
};

export const getNationalNumber = memoizeOne(
  (phone: string, countryShortCode: string = 'NG') => {
    const minNumberLength = 9;
    if (phone?.length >= minNumberLength) {
      const number = phoneUtil.parseAndKeepRawInput(phone, countryShortCode);
      return number.getNationalNumber();
    }
    return '';
  }
);
