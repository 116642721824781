import { useQuery } from '@tanstack/react-query';
import {
  httpGetAnalyticsData,
  httpGetAnalyticsTotal,
} from 'app/api/dispatcher';
import DynamicIconDropDownMenu from 'app/components/DynamicIconDropDownMenu';
import { BarChart, PieChart } from 'app/components/chart/Chart';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetProfile } from 'app/hooks/user';
import { STATE_LOGISTICS_ANALYTICS_RECORDS } from 'app/state/constants';
import { currencyFormatter, statusColors } from 'app/utilities/helpers';
import { privateRoutes } from 'app/utilities/routes';
import BackArrow from 'assets/images/arrowLeft.svg';
import Download from 'assets/images/download.svg';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ElrPageTitle } from 'ui-components';
import { ElrRectangleBox } from 'ui-components/components/ElrRectangleBox';

const ranges: string[] = ['daily', 'weekly', 'monthly', 'yearly', 'default'];

const OperatorAnalytics = () => {
  const [range, setRange] = React.useState('default');
  const profile = useGetProfile();
  const {
    currentAccessRole,
    id: userId,
    accountInformation: { id: dispatcherId },
  } = profile;

  const { data: logisticsAnalyticsRecords, refetch } = useQuery({
    queryKey: [STATE_LOGISTICS_ANALYTICS_RECORDS],
    queryFn: async () => {
      const response = await httpGetAnalyticsTotal(userId, dispatcherId, range);
      return response;
    },
    enabled: true,
  });

  const { data } = useQuery({
    queryKey: ['data'],
    queryFn: async () => {
      const response = await httpGetAnalyticsData(userId, dispatcherId);
      return response;
    },
  });

  useEffect(() => {
    if (range) {
      refetch();
    }
  }, [range, refetch]);

  const statusAccumulation: { status: string; total: number }[] =
    logisticsAnalyticsRecords?.getTotalRecords?.statusAccumulation;

  const statusSwitch = (status: string) => {
    switch (status) {
      case 'accepted':
        return 0;
      case 'completed':
        return 1;
      default:
        return 2;
    }
  };

  return (
    <div>
      <ElrPageTitle title="Errandlr - Operator Analytics" />
      <ProfileNavigationMenu>
        <div className="h-1000 ml-[3%] md:ml-[6.1%] mr-[3%]">
          <TopNavigationMenu />
          <div className="">
            <Link
              to={privateRoutes.dashboard(currentAccessRole)}
              className="flex gap-3 pt-3 border-none text-elr-black text-opacity-60 md:pt-auto"
            >
              <img
                className="inline w-5 md:opacity-60 md:mr-3"
                src={BackArrow}
                alt="Back to Dashboard"
              />
              <span>Back</span>
            </Link>
            <div className="flex justify-between pt-5 md:pt-[34px]">
              <h1 className="text-18">Analytics</h1>
              <div className="flex gap-1">
                <img src={Download} alt="download icon" />
                <h2 className="text-18">Financial statement</h2>
              </div>
            </div>
            <div className="grid gap-4 pt-5 grid-cols-small-fit-column-two md:grid-cols-medium-fit-column-four">
              <ElrRectangleBox
                title="Total Income"
                num={currencyFormatter(
                  logisticsAnalyticsRecords?.getTotalRecords?.amount || 0
                )}
                hyperLink={false}
              />
              <ElrRectangleBox
                title="Total Trips"
                num={logisticsAnalyticsRecords?.getTotalRecords?.count || 0}
                hyperLink={false}
              />
              <ElrRectangleBox
                title="Total Bookings"
                num={logisticsAnalyticsRecords?.getBookingRecords?.count || 0}
                hyperLink={false}
              />
              <ElrRectangleBox
                title="Total Riders"
                num={logisticsAnalyticsRecords?.getTotalRiders?.count || 0}
                hyperLink
              />
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row gap-x-4 mt-[30px] pb-[30px]">
            <div className="w-[100%] md:w-[70%]">
              <div className="hidden p-6 border md:block border-elr-black border-opacity-20 rounded-xl">
                <div className="flex justify-between mb-4">
                  <div>
                    Overall amount: &nbsp;
                    <span>
                      {currencyFormatter(
                        logisticsAnalyticsRecords?.getTotalRecords?.amount || 0
                      )}
                    </span>
                  </div>
                  <div className="relative flex">
                    <p>{range}</p>
                    <DynamicIconDropDownMenu
                      containerStyles="border-05 border-elr-green bg-white border-opacity-40 z-100"
                      transformPositionClass="-translate-x-24"
                    >
                      <ul className="text-sm list-none bg-white text-elr-black text-opacity-40">
                        <div>
                          {ranges.map((item) => (
                            <div onClick={() => setRange(item)} key={item}>
                              <li className="w-24 px-1 m-2 leading-7 cursor-pointer hover:bg-elr-green hover:bg-opacity-10">
                                {item}
                              </li>
                            </div>
                          ))}
                        </div>
                      </ul>
                    </DynamicIconDropDownMenu>
                  </div>
                </div>
                <div className="h-[360px] w-[100%]">
                  {data && <BarChart data={data} range={range} />}
                </div>
              </div>
            </div>

            <div className="w-[100%] md:w-[30%]">
              <div className="px-6 pt-4 pb-3 md:border border-elr-black border-opacity-20 rounded-xl">
                <div>Total Requests</div>
                {logisticsAnalyticsRecords && (
                  <PieChart statusAccumulation={statusAccumulation} />
                )}
                <div className="flex justify-between pt-5">
                  <div>
                    {statusAccumulation?.map((t) => (
                      <div className="flex items-center gap-1" key={t.status}>
                        <div
                          className={`bg-${
                            statusColors[statusSwitch(t.status)]
                          } h-3 w-[12px]`}
                        />
                        <p>
                          {t.status.replace(/./, (c) => c.toUpperCase())} ={' '}
                          {t.total}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-end ">
                    <Link
                      className="leading-5 underline text-12"
                      to={privateRoutes.totalRequests}
                    >
                      View all
                    </Link>
                  </div>
                </div>
              </div>
              <div className="h-[450px] md:h-[500px] mt-5 border border-elr-black border-opacity-20 rounded-xl p-4 overflow-y-scroll no-scrollbar">
                <div className="pb-5">Top Riders</div>
                {[1, 2, 3].map((_, idx) => {
                  const number = idx + 1;
                  return (
                    <div className="flex w-full" key={number}>
                      <div className="w-[7%]">{number}.</div>
                      <div className="w-[93%]">
                        <div className="mb-2">John Paul</div>
                        <div className="flex justify-between mb-6 md:mb-10">
                          <p className="opacity-60">6 Trips</p>
                          <p className="text-elr-green">N2,1203</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default OperatorAnalytics;
