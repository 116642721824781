import { isCurrentTab } from 'app/utilities/helpers';
import { BatchTabsInterface } from 'app/utilities/types/shared';

export const itemStyle = (opacity: boolean = true) =>
  `${opacity ? 'opacity-60' : ''} md:w-4/12`;

export const acceptButtonStyle = (active: boolean) =>
  `${
    active ? 'bg-elr-purple' : 'bg-elr-black bg-opacity-50'
  } h-10 w-full md:w-28 text-white text-base flex items-center justify-center`;

export const batchUpcomingTabContainer = (
  tab: BatchTabsInterface,
  currentTypeTab: BatchTabsInterface
) =>
  `cursor-pointer ${tab.disabled ? 'hidden' : ''} ${
    isCurrentTab(tab.id, currentTypeTab.id) ? 'border-b pb-2.5' : ''
  }`;

export const batchUpcomingTabSpan = (
  tab: BatchTabsInterface,
  currentTypeTab: BatchTabsInterface
) =>
  !isCurrentTab(tab.id, currentTypeTab.id)
    ? 'text-elr-black text-opacity-60'
    : '';

export const activeSpan = (
  tab: BatchTabsInterface,
  activeTab: BatchTabsInterface
) =>
  `${
    tab.id === activeTab.id
      ? 'bg-elr-black text-white border-2.5 border-elr-black'
      : 'bg-white text-elr-black text-opacity-50'
  }`;

export const upcomingBatchTabsStyles = (
  currentTypeTab: BatchTabsInterface,
  item: BatchTabsInterface
) =>
  `bg-white border border-elr-black text-sm px-2 py-0 mr-4 md:mr-0 md:ml-4 ${
    currentTypeTab.id === item.id && 'bg-elr-black text-elr-white'
  }`;

export const batchItemContainer =
  'text-sm cursor-pointer md:text-base py-4 flex justify-between rounded px-4 group border-b border-elr-gray-400 border-opacity-20';

export const dropdownMenuStyle = (idx: number, Arrlength: number) =>
  `${
    idx !== Arrlength - 1 && 'border-b border-elr-green border-opacity-30'
  } p-3 cursor-pointer`;

export const locationIndicatorDot = (idx: number) =>
  `h-1.5 w-1.5 ml-0.5 md:ml-0 mt-1.5 my-2 rounded-full ${
    idx === 0 ? 'bg-elr-purple' : 'bg-elr-black opacity-60'
  }`;
