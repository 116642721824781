/* eslint-disable camelcase */
import {
  AgentPayloadInterface,
  Batch,
  PaginateResponseInterface,
  Request,
} from 'app/utilities/types/shared';

export interface SendRequest {
  geoId: string;
  deliveryNotes?: string;
  pickupNotes?: string;
  name: string;
  email: string;
  phone: string;
  deliverToInformation: Array<{
    order: number;
    name: string;
    packageValue: string;
    phone: string;
    country: string;
    state: string;
  }>;
  state: string;
  country: string;
  dispatcherId?: string;
  hasInsurance?: boolean;
  titc?: string; // generated for tracking for later
  batch?: boolean;
}

export interface SendRequestState {
  toState: string;
  fromState: string;
  geoId: string;
  deliveryNotes?: string;
  pickupNotes?: string;
  name: string;
  email: string;
  phone: string;
  deliverToInformation: Array<{
    order: number;
    name: string;
    packageValue: string;
    phone: string;
    country: string;
    state: string;
  }>;
  state: string;
  country: string;
  dispatcherId?: string;
  hasInsurance?: boolean;
  titc?: string; // generated for tracking for later
}

// const AgentInterface = {
//   email: '',
//   agentUUID: '',
//   firstname: '',
//   id: '',
//   lastname: '',
//   phone: '',
//   trips: [],
//   tripsCount: 0,
//   vehicleType: '',
//   status: '',
//   country: 'nigeria',
//   state: 'lagos',
//   vehiclePlateNumber: '',
//   driversLicense: '',
//   additionalId: '',
//   vehicleRegistrationDocument: '',
//   bankDetails: undefined,
// };

export const BatchPrepopulatedData = {
  area: '',
  count: 0,
  createdAt: '',
  country: '',
  state: '',
  deliverToInformation: [],
  destinationArea: {
    latitude: '',
    longitude: '',
    area: '',
    _id: '',
  },
  mergedInformation: [],
  pickupArea: {
    latitude: '',
    longitude: '',
    area: '',
    _id: '',
  },
  priceEstimate: '',
  senderInformation: {
    data: {
      email: '',
      name: '',
      phone: '',
      state: '',
      _id: '',
    },
    type: '',
  },
  trackingId: '',
  _id: '',
};

export const prePopulatedWalletData: WalletBalance = {
  id: '',
  balance: 0,
  account: '',
  walletId: '',
  record: [],
  paystackAccountName: '',
  paystackAccountNumber: '',
};

export interface VerifyResponse {
  status: boolean;
  message: string;
  data: {
    amount: number;
    currency: string;
    // eslint-disable-next-line camelcase
    transaction_date: string;
    status: 'success' | 'failed';
  };
}

export interface VerifyParams {
  reqRef: string;
}

export interface RequestsDataInterface extends PaginateResponseInterface {
  docs: Request[];
}

export interface BatchDataInterface extends PaginateResponseInterface {
  docs: Batch[];
}

export interface UpcomingDeliveriesInterface {
  deliveries: RequestsDataInterface;
}

export interface RequestsInterface {
  requests: RequestsDataInterface;
}
export interface BatchedInterface {
  batches: BatchDataInterface;
}

export interface DeliveriesMadeInterface {
  deliveriesMade: RequestsDataInterface;
}

export interface GetAgentsInterface {
  docs: AgentPayloadInterface[];
  hasNextPage?: boolean;
  nextPage?: number;
  page: number;
  limit: number;
}

export type RequestPayoutResponse = [
  message: string | null | undefined,
  error: string | null,
];

export interface GetFinanceIncome {
  // eslint-disable-next-line camelcase
  balance: { ledger: number; income: number; due_requests: string[] };
  record: {
    _id: { hasPaymentProcessing: boolean };
    hasPaymentInProcess: boolean;
    amount: string;
  }[];
  hasPaymentInProcess: boolean;
}

export interface WalletSettings {
  lowBalanceNotification?: {
    lowBalanceNotificationChannel?: {
      email: boolean;
      webhook: boolean;
    };
    warningAmount?: number;
    criticalAmount?: number;
    lowBalanceNotificationEmail?: string[];
  };
}
export interface WalletBalance extends WalletSettings {
  id: string;
  balance: number;
  account: string;
  walletId: string;
  record: [];
  paystackAccountName?: string;
  paystackAccountNumber?: string;
}

export type FinanceAggregate = {
  month: number;
  totalMonthAmount: string;
  numTrips: number;
}[];

export interface IFetchNearbyAgentsPayload {
  agents: { name: string; phone: string; id: string }[];
  location: { lat: string | undefined; lng: string | undefined };
}

export interface PackageTypeI {
  [key: string]: {
    code: string;
    label: string;
  };
}

export interface itemType {
  name: string;
  code: string;
  imageSrc: string;
}

export interface ITenantData {
  [key: string]: {
    countryShortCode: string;
    countryLangCode: string;
    countryPhoneCode: string;
    countryLocale: string;
    currency: string;
    countryLongName: string;
    active: boolean;
    canOperate: boolean;
    states: {
      [key: string]: {
        country: string;
        active: boolean;
        canOperate: boolean;
        localGovt: {
          [key: string]: {
            lat: string;
            lng: string;
          };
        };
        trackRiderEnabled: boolean;
        canAddUnassignedRiders: boolean;
      };
    };
  };
}
