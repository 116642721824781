import { httpForgotPassword } from 'app/api/user';
import { CenterLogoHeader } from 'app/components/Header';
import { publicRoutes } from 'app/utilities/routes';
import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';

const ForgotPasswordChildComponent: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setEmail(e.target.value);

  const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    try {
      setLoading(true);
      await httpForgotPassword(email);
      setLoading(false);
      return setShowSuccess(true);
    } catch (error) {
      return setLoading(false);
    }
  };

  return (
    <span>
      <div className="px-7 md:px-9 md:pb-4 pb-9 w-11/12 md:w-96 bg-elr-white-400 rounded-2xl flex flex-col mb-12 mx-auto">
        <div className="mt-14 mb-7 text-xl text-center">Forgot Password </div>
        {showSuccess && (
          <p className="text-sm py-3 text-elr-green">
            Check your mail for the reset password link.
          </p>
        )}
        <form onSubmit={onSubmit}>
          <div className="mb-10 md:mb-11">
            <ElrInput
              className="bg-elr-gray rounded-none border-none mb-3"
              placeholder="Email Address*"
              required
              type="email"
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col items-center">
            <ElrButton
              text="Send Reset Link"
              submit
              loading={loading}
              spinnerColor="White"
              disabled={!email.trim()}
              className="bg-elr-black text-white text-lg w-full mb-3 rounded-full"
            />
            <p className="text-xs w-10/12 md:w-11/12 mx-auto text-elr-gray-400 opacity-70 sm:mt-2">
              By clicking the “Send Reset Link” button, you agree to
              Erandlr&apos;s&nbsp;
              <Link to={publicRoutes.privacyPolicy}>
                <span className="text-elr-purple opacity-60">
                  terms of acceptable use.
                </span>
              </Link>
            </p>
            <p className="mt-6 md:mt-11 text-elr-gray-400 opacity-70 text-sm text-center w-10/12 mx-auto pb-5">
              Already have an account? &nbsp;
              <Link to={publicRoutes.login}>
                <span className="text-elr-purple opacity-60">Login </span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </span>
  );
};

const ForgotPassword: React.FC = () => (
  <div>
    <ElrPageTitle title="Errandlr - Forgot Password" />
    <div className="flex flex-col items-center">
      <CenterLogoHeader />
      <ForgotPasswordChildComponent />
    </div>
  </div>
);

export default ForgotPassword;
