import { httpShareLink } from 'app/api/estimate';
import { useGetProfile } from 'app/hooks/user';
import { publicRoutes } from 'app/utilities/routes';
import SmsIcon from 'assets/images/sms.svg';
import WhatsappIcon from 'assets/images/whatsapp.svg';
import phone from 'phone';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ElrButton, ElrInput, ElrLeftArrowButton } from 'ui-components';
import * as style from './ClassUtils';

type Channel = {
  sms: 'sms';
  whatsapp: 'whatsapp';
};

interface PaymentLinkValues {
  link: string;
}

const PaymentLink: React.FC<PaymentLinkValues> = ({ link }) => {
  const [showSms, setShowSms] = useState(false);
  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const [phoneSms, setPhoneSms] = useState<string>('+234');
  const [loading, setLoading] = useState(false);

  const channels: Channel = {
    whatsapp: 'whatsapp',
    sms: 'sms',
  };

  const { id } = useGetProfile();
  const confirmDetailsURL = `${window.location.origin}${publicRoutes.confirmDetails}/${link}`;

  const sendSms = async () => {
    setLoading(true);
    const result = await httpShareLink(id, confirmDetailsURL, phoneSms);
    if (result) {
      toast.success('Sms sent successfully');
    } else {
      toast.error('Something went wrong, please try again');
    }
    setShowSms(false);
    setLoading(false);
  };

  const sendWhatsappMessage = () => {
    const message = `To pay for your delivery, please click this ${confirmDetailsURL}, once payment is confirmed your delivery will be processed. \nErrandlr.`;

    window.open(
      `https://wa.me/${phoneSms}/?text=${encodeURI(message)}`,
      '_blank'
    );

    setShowWhatsapp(false);
  };

  const sendTo = (where: keyof typeof channels) => {
    if (where === channels.sms) {
      setShowWhatsapp(false);
      setShowSms(true);
    } else {
      setShowWhatsapp(true);
      setShowSms(false);
    }
  };

  const getPhoneCodes = phone(phoneSms) as {
    countryIso2: string;
    isValid: boolean;
  };
  const isPhoneValid = getPhoneCodes.isValid;

  const title = 'Share delivery invoice link';

  return (
    <div className="my-5 flex flex-col items-center">
      <h3 className="text-lg text-center">{title}</h3>
      <div className="flex mt-8">
        <div
          onClick={() => sendTo(channels.whatsapp)}
          className={style.whatsAppContainer(showWhatsapp)}
        >
          <p className="bg-elr-green-light bg-opacity-20 p-2 rounded-full w-9">
            <img src={WhatsappIcon} alt="whatsapp" />
          </p>
          <span className="text-sm mt-1">WhatsApp</span>
        </div>
        <div
          onClick={() => sendTo(channels.sms)}
          className={style.smsContainer(showSms)}
        >
          <p className="bg-elr-gray-300 bg-opacity-20 p-2 rounded-full w-9">
            <img src={SmsIcon} alt="sms" />
          </p>
          <span className="text-sm mt-1">SMS</span>
        </div>
      </div>

      {showSms || showWhatsapp ? (
        <div className="mt-8">
          <p className="text-elr-black text-opacity-60 text-sm">
            Enter the phone number of a trusted person you will like to send the
            delivery payment link to via {showSms && channels.sms.toUpperCase()}
            {showWhatsapp && 'WhatsApp'}
          </p>
          <ElrInput
            type="tel"
            value={phoneSms}
            onChange={(e) => setPhoneSms(e.target.value)}
            placeholder="Phone Number"
            className="bg-elr-gray-600 bg-opacity-20 mt-2"
          />
          <small className="text-elr-green text-xs">
            {phoneSms && !isPhoneValid && 'Phone number is invalid'}
          </small>
          <div className="mt-14 flex justify-between items-center">
            <ElrLeftArrowButton
              disabled={false}
              onClick={() =>
                showSms ? setShowSms(false) : setShowWhatsapp(false)
              }
            />
            <ElrButton
              onClick={showSms ? sendSms : sendWhatsappMessage}
              spinnerColor="White"
              disabled={!isPhoneValid}
              text="Send"
              loading={loading}
              className="bg-elr-black text-elr-white-400 md:text-base"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PaymentLink;
