import { httpGetConfirmationRequest } from 'app/api/requests';
import { MobileHeader } from 'app/components/Header';
import FundWallet from 'app/sender/components/FundWallet';
import {
  currencyFormatter,
  getArrLastItem,
  isRequestPaid,
} from 'app/utilities/helpers';
import { privateRoutes } from 'app/utilities/routes';
import { DestinationInterface, Request } from 'app/utilities/types/shared';
import Logo from 'assets/images/logo.svg';
import truncate from 'lodash/truncate';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ElrButton,
  ElrDropdownIconToggle,
  ElrLeftArrowButton,
  ElrPageTitle,
} from 'ui-components';

interface IDetailItem {
  index: number;
  delivery: DestinationInterface;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<number>>;
  last?: number;
}

const DetailItem: React.FC<IDetailItem> = ({
  delivery,
  last,
  index,
  setActive,
  active,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const notLastItem = last !== index;
  return (
    <>
      <div className="flex items-center bg-elr-gray pr-2.5 h-50 mt-2.5">
        <div
          className={`flex flex-col mx-2.5 ${notLastItem ? '-mt-2' : '-mt-9'}`}
        >
          <span className="h-8 md:mr-02 md:ml-2 ml-04 w-0.3 bg-elr-black bg-opacity-40" />
          <span className="h-2 w-1.5 md:w-[0.5rem] ml-1  my-0.5 bg-elr-yellow" />
          {notLastItem && (
            <span className="h-7 mx-02 w-0.3 bg-elr-black bg-opacity-40" />
          )}
        </div>
        <div className="flex justify-between w-full ml-3 cursor-pointer">
          <span className="text-sm opacity-60 md:text-base">
            {truncate(delivery.address.fullAddress, {
              length: 39,
            })}
          </span>
          <span className="flex items-center justify-around ml-auto text-sm rounded-md bg-elr-white-400 w-11">
            <p className="h-1.5 w-1.5 bg-elr-yellow"> </p>
            {delivery.address.distance}km
          </span>
          <span className="opacity-40 ml-7" onClick={() => setActive(index)}>
            <ElrDropdownIconToggle
              isToggled={showDetails && active}
              toggle={setShowDetails}
            />
          </span>
        </div>
      </div>
      {/* inner part of address */}
      {showDetails && active && (
        <div className="flex animate md:mx-3.5 mx-08 -mt-0.5 h-full">
          {notLastItem && <span className="w-0.3 bg-elr-black bg-opacity-40" />}
          <div className="flex flex-col justify-center w-full px-3 my-8 mb-6 ml-4">
            <div className="text-sm flex mb-2.5 opacity-60">
              <p className="mr-28"> Name </p>
              <p> {delivery.name} </p>
            </div>
            <div className="text-sm mb-2.5 flex opacity-60">
              <p className="mr-16"> Phone number </p>
              <p> {delivery.phone} </p>
            </div>
            <div className="flex text-sm opacity-60">
              <p className="w-24 mr-14"> Package value </p>
              <p>{currencyFormatter(delivery.packageValue)} </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ConfirmDetails: React.FC = () => {
  const { trackingId } = useParams();
  const [toggled, setToggleState] = useState<boolean>(true);
  const [active, setActive] = useState<number>(0);
  const navigate = useNavigate();
  const toggleOption = () => {
    setToggleState(!toggled);
  };
  const [request, setRequest] = useState<Request>();

  const fetchRequest = async () => {
    if (!trackingId) return;
    const response = await httpGetConfirmationRequest(trackingId);

    if (isRequestPaid(response.status)) {
      navigate(privateRoutes.selectPortal);
      return;
    }

    setRequest(response);
  };

  useEffect(() => {
    fetchRequest();
  }, []);

  const [show, setShow] = useState<boolean>(false);

  const handleShowPaymentOptions = (): void => {
    setShow(true);
  };

  const closePaymentOptions = (): void => {
    setShow(false);
  };

  if (!request) {
    return <div />;
  }

  const {
    senderInformation,
    pickupAddress,
    priceEstimate,
    deliverToInformation,
    id: requestId,
  } = request;

  return (
    <div className="bg-white md:h-screen">
      <ElrPageTitle title="Errandlr - Estimate Your Delivery" />
      <div className="w-full md:w-p-85 md:mx-auto">
        <MobileHeader mdHide />
      </div>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-6/12 md:h-screen md:overflow-y-scroll md:no-scrollbar">
          <img
            className="hidden pt-10 pl-16 md:inline pb-14"
            src={Logo}
            alt="logo"
          />
          <div className="mb-5 md:mx-[11%] mx-5">
            <div className="mb-4 text-xl">Confirmation details</div>
            <div>
              <div className="flex bg-elr-gray items-center h-50 px-2.5">
                <div className="flex flex-col justify-center align-center">
                  {/* Line 1 */}
                  <span className="h-1.5 md:h-2 w-1.5 md:w-[0.5rem] ml-[0.3rem] mb-0.5 my-2 rounded-full bg-elr-purple" />
                  <span className="-mb-3 h-7 ml-04 md:ml-2 w-0.3 bg-elr-black bg-opacity-40" />
                </div>
                <div
                  className="flex justify-between w-full ml-3 cursor-pointer"
                  onClick={toggleOption}
                >
                  <span className="text-sm opacity-60 md:text-base">
                    {truncate(pickupAddress.fullAddress, {
                      length: 39,
                    })}
                  </span>
                  <span className="opacity-40">
                    <ElrDropdownIconToggle
                      isToggled={toggled}
                      toggle={setToggleState}
                    />
                  </span>
                </div>
              </div>
              {/* inner part of address */}
              {toggled && (
                <div className="flex md:mx-3.5 mx-08 -mt-2.5 h-full">
                  <span className="w-0.3 ml-1 bg-elr-black bg-opacity-40" />
                  <div className="flex flex-col justify-center w-full px-3 my-8 mb-6 ml-4">
                    <div className="text-sm flex mb-2.5 opacity-60">
                      <p className="mr-28"> Name </p>
                      <p> {senderInformation.data.name} </p>
                    </div>
                    <div className="flex text-sm opacity-60">
                      <p className="mr-14"> Phone number </p>
                      <p> {senderInformation.data.phone} </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {deliverToInformation.map((delivery: DestinationInterface, i) => {
              const last = getArrLastItem(deliverToInformation);
              return (
                <DetailItem
                  delivery={delivery}
                  index={i}
                  key={delivery.id}
                  active={active === i}
                  setActive={setActive}
                  last={last}
                />
              );
            })}
            <div className="w-full mb-4 border-b border-elr-gray-600 opacity-60" />
            <span className="flex justify-between mb-14 md:mb-20 md:text-base">
              <p className="opacity-60"> Order Estimate: </p>
              <p className="text-xl">{currencyFormatter(+priceEstimate)} </p>
            </span>
            <div className="flex justify-between">
              <ElrButton
                onClick={handleShowPaymentOptions}
                text="Make Payment"
                className="w-36 h-9 bg-elr-black text-elr-white-400 md:text-base"
              />
              {show && (
                <FundWallet
                  amount={+priceEstimate}
                  close={closePaymentOptions}
                  request={requestId}
                  trackingId={trackingId}
                  phoneNumber={senderInformation.data.phone}
                />
              )}
              <ElrLeftArrowButton />
            </div>
            <p className="mt-2 text-xs text-elr-green text-opacity-60">
              Complete your delivery details to place order
            </p>
            <p className="mt-2 text-xs text-elr-green text-opacity-60">
              Once payment is successful, we would receive your order.
            </p>
          </div>
        </div>
        <div className="mb-6 md:w-3/5 md:mb-0 h-96 md:h-full bg-elr-white">
          <div className="w-full md:block h-96 md:h-screen" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDetails;
