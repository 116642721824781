import UserOne from 'assets/images/user-1.png';
import UserTwo from 'assets/images/user-2.png';
import UserThree from 'assets/images/user-3.png';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'TamedLux',
      socialHandle: '@tamedlux',
      message:
        'Errandlr transformed our business logistics. Their same-day deliveries and competitive rates have significantly improved our customer satisfaction and sales performance.',
      image: UserOne,
    },
    {
      id: 2,
      name: 'Michael Edith',
      socialHandle: '@michaeledith',
      message:
        "As a small business owner, Errandlr's all-in-one platform saves me countless hours. From deliveries to payments, everything is streamlined and professional.",
      image: UserTwo,
    },
    {
      id: 3,
      name: 'Mama Dee',
      socialHandle: '@mamadee',
      message:
        'Shopping internationally used to be complicated until I found Errandlr. Now I can easily shop from global markets and manage all my deliveries in one place.',
      image: UserThree,
    },
  ];

  return (
    <div className="pb-20 md:py-28 py-20 px-4 lg:px-32 md:px-24 text-center flex flex-col justify-center items-center bg-elr-gray">
      <h1 className="text-14 md:text-2xl leading-5 text-elr-green">
        Our Clients Say
      </h1>
      <div className="flex justify-center pt-4 px-6 pb-12 md:pt-6 md:pb-16">
        <h2 className="text-28 md:text-40 leading-tight font-bold w-full md:w-9/12">
          Trusted by Businesses and Individuals Alike
        </h2>
      </div>
      <div className="md:flex lg:flex-row md:flex-col md:gap-8">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="bg-white p-6 rounded-lg text-left flex flex-col items-start md:my-0 my-6"
          >
            <div className="flex items-center mb-4">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div className="flex flex-col items-start">
                <h3 className="text-lg font-semibold">{testimonial.name}</h3>
                <p className="text-sm text-elr-gray-600">
                  {testimonial.socialHandle}
                </p>
              </div>
            </div>
            <p className="text-elr-gray-400 leading-snug md:w-11/12 w-full">
              {testimonial.message}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
