import { httpCreateProduct } from 'app/api/commerce';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useGetProfile } from 'app/hooks/user';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  ElrButton,
  ElrInput,
  ElrProductUpload,
  ElrSwitch,
  ElrUploadThumbnail,
} from 'ui-components';

interface Props {
  setShowSlideIn: (value: boolean) => void;
  showModal: boolean;
}

interface FormValue {
  name: string;
  productPrice: string;
  quantityInstock: string;
  enableIntDelivery: boolean;
  weight: string;
  length: string;
  breadth: string;
}

const AddProductSlideIn: React.FC<Props> = ({ setShowSlideIn, showModal }) => {
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const { values, setFieldValue } = useFormik<FormValue>({
    initialValues: {
      name: '',
      productPrice: '',
      quantityInstock: '',
      enableIntDelivery: false,
      weight: '',
      length: '',
      breadth: '',
    },
    onSubmit: () => {
      console.log('submit form');
    },
  });

  const {
    id: userId,
    accountInformation: { id: roleId },
  } = useGetProfile();

  const handleSetFieldValue = (fieldName: any, newFieldValue: any) => {
    setFieldValue(fieldName, newFieldValue);
  };

  const setEnableIntDelivery = () => {
    setFieldValue('enableIntDelivery', !values.enableIntDelivery);
  };

  const enableIntDeliveryInput =
    values.enableIntDelivery &&
    (!values.breadth || !values.length || !values.weight);

  const disableButton =
    !values.name ||
    !values.productPrice ||
    !values.quantityInstock ||
    enableIntDeliveryInput;

  const createProducts = async () => {
    const products = {
      name: values.name,
      price: +values.productPrice,
      quantity: +values.quantityInstock,
      images: thumbnails,
      categories: [],
      international: values.enableIntDelivery,
      weight: +values.weight,
      length: +values.length,
      breadth: +values.breadth,
    };
    const response = await httpCreateProduct({ products, userId, roleId });
    if (response) {
      setShowSlideIn(false);
    }
  };

  return (
    <SlideIn shown={showModal} close={() => setShowSlideIn(!showModal)}>
      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-20 text-xl bg-elr-gray">
        <p>Add a Product</p>
      </div>
      <div className="flex flex-col gap-2 pt-28">
        <ElrInput
          className="h-24 mb-3 border-none rounded-none bg-elr-gray"
          placeholder="Products Name"
          onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
            handleSetFieldValue('name', newValue.target.value)
          }
          value={values.name}
        />
        <ElrInput
          className="mb-3 border-none rounded-none bg-elr-gray"
          placeholder="Products Price"
          onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
            handleSetFieldValue('productPrice', newValue.target.value)
          }
          type="number"
          value={values.productPrice}
        />
        <ElrInput
          className="mb-3 border-none rounded-none bg-elr-gray"
          placeholder="Quantity In stock"
          onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
            handleSetFieldValue('quantityInstock', newValue.target.value)
          }
          value={values.quantityInstock}
        />
        <div>
          <p className="pb-4 opacity-60">Product Image</p>
          <ElrUploadThumbnail
            thumbnails={thumbnails}
            setThumbnails={setThumbnails}
          />
          <ElrProductUpload
            accept=".png,.jpg,.jpeg,.webp"
            maxMBSize="3"
            filesSupported="JPEG, PNG, JPG, WEBP"
          />
          <div className="flex items-center gap-4">
            <p className="text-xl">Enable International Delivery</p>
            <ElrSwitch
              isToggled={values.enableIntDelivery}
              toggle={setEnableIntDelivery}
            />
          </div>
          {values.enableIntDelivery && (
            <InternationalDelivery
              values={values}
              handleSetFieldValue={handleSetFieldValue}
            />
          )}

          <div className="pt-24">
            <ElrButton
              text="Add Product"
              className="w-full h-6 text-white bg-elr-black"
              size="lg"
              spinnerColor="White"
              disabled={disableButton}
              onClick={createProducts}
            />
          </div>
        </div>
      </div>
    </SlideIn>
  );
};

export default AddProductSlideIn;

interface InternationalDeliveryProps {
  values: FormValue;
  handleSetFieldValue: (fieldName: string, newFieldValue: string) => void;
}

const InternationalDelivery: React.FC<InternationalDeliveryProps> = ({
  values,
  handleSetFieldValue,
}) => (
  <div className="flex flex-col gap-2 pt-5">
    <ElrInput
      className="mb-3 border-none rounded-none bg-elr-gray"
      placeholder="Weight (kg)"
      onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
        handleSetFieldValue('weight', newValue.target.value)
      }
      value={values.weight}
    />

    <div className="flex gap-5">
      <ElrInput
        className="mb-3 border-none rounded-none bg-elr-gray"
        placeholder="Length (cm)"
        onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
          handleSetFieldValue('length', newValue.target.value)
        }
        value={values.length}
      />
      <ElrInput
        className="mb-3 border-none rounded-none bg-elr-gray"
        placeholder="Breadth (cm)"
        onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
          handleSetFieldValue('breadth', newValue.target.value)
        }
        value={values.breadth}
      />
    </div>
  </div>
);
