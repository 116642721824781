import React, { useEffect, useRef, useState } from 'react';
import { ElrButton, ElrModal, ElrTextAreaInput } from 'ui-components';
import { activeReasonStyle } from './ClassUtils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCancelDelivery: () => void;
  loading: boolean;
  text: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reason: string;
}

const reasons = [
  'Too much delay',
  'Rider not responding',
  'Incorrect details',
  'Other reason',
];

const CancelDeliveryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCancelDelivery,
  loading,
  text = 'Are you sure you want to cancel delivery?',
  setReason,
  reason,
}) => {
  const [refStateUpdated, setRefStateUpdated] = useState(false);
  const textAreaRef = useRef('');
  const onTextAreaChange = (e: any) => {
    textAreaRef.current = e.target.value;
  };

  useEffect(() => {
    if (refStateUpdated) {
      onCancelDelivery();
      setRefStateUpdated(false);
    }
  }, [refStateUpdated]);

  const textAreaActive =
    reason === reasons[reasons.length - 1] || !!textAreaRef.current;

  const onCancel = () => {
    textAreaActive && setReason(textAreaRef.current);
    setRefStateUpdated(true);
  };

  return isOpen ? (
    <ElrModal
      width="w-11/12 md:w-560"
      height="h-7/12 md:h-fit"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex h-full flex-col justify-center items-center py-6">
        <h5 className="mb-4 text-xl">What&apos;s the reason?</h5>
        <div className="flex flex-row flex-wrap">
          <Reasons
            reason={reason}
            setReason={setReason}
            textAreaActive={textAreaActive}
            textAreaChange={onTextAreaChange}
          />
        </div>
        {textAreaActive && (
          <ElrTextAreaInput
            className="text-sm bg-elr-gray-600 bg-opacity-40 p-4 mb-1"
            placeholder="Tell us why..."
            type="text"
            onChange={onTextAreaChange}
          />
        )}
        <p className="text-center mx-3 text-sm mt-1.5 mb-2.5">{text}</p>
        <div className="flex justify-between mt-3">
          <ElrButton
            onClick={onClose}
            text="Back"
            className="text-opacity-60"
          />
          <ElrButton
            onClick={onCancel}
            text="Yes, cancel"
            className="px-10 text-sm text-white bg-elr-black"
            loading={loading}
            spinnerColor="White"
          />
        </div>
      </div>
    </ElrModal>
  ) : null;
};

interface ReasonI {
  reason: string;
  setReason: (reason: string) => void;
  textAreaActive?: boolean;
  textAreaChange?: (e: any) => void;
}

const Reasons: React.FC<ReasonI> = ({
  reason,
  setReason,
  textAreaActive,
  textAreaChange,
}) => {
  const onClickReason = (selectedReason: string) => {
    setReason(selectedReason);
    if (textAreaChange) {
      textAreaChange({ target: { value: '' } });
    }
  };

  const activeReason = (reasonMap: string, index: number) =>
    reasonMap === reason || !!(textAreaActive && index === reasons.length - 1);

  return (
    <>
      {reasons.map((reasonMap: string, index: number) => (
        <ElrButton
          onClick={() => onClickReason(reasonMap)}
          text={reasonMap}
          key={reasonMap}
          size="sm"
          className={activeReasonStyle(activeReason(reasonMap, index))}
        />
      ))}
    </>
  );
};

export default CancelDeliveryModal;
