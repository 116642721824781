import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface Props {
  title: string;
}

const helmetContext = {};

export const ElrPageTitle: React.FC<Props> = ({ title }) => (
  <HelmetProvider context={helmetContext}>
    <Helmet>
      <title> {title} </title>
    </Helmet>
  </HelmetProvider>
);
