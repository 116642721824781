import React from 'react';
import { publicRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';

export const OrderCancelled: React.FC<{
  trackingId?: string;
  onTrackUpdate?: boolean;
}> = ({ trackingId, onTrackUpdate }) => (
  <div
    className={`mt-16 ${
      onTrackUpdate ? 'w-350' : 'w-11/12'
    } mx-auto md:w-600 h-72 bg-elr-white-400 border border-elr-green border-opacity-30 rounded flex flex-col items-center`}
  >
    <div className="mt-9 mb-5 text-base md:text-sm opacity-60">
      Sorry to see you go
    </div>
    <div className="mb-6 md:mb-5 text-3xl md:text-4xl"> Order Cancelled </div>
    <div className="text-base md:text-sm opacity-60 mb-6 md:mb-8">
      Delivery ID : {trackingId}
    </div>
    <Link to={publicRoutes.estimate}>
      <ElrButton
        text="Reorder"
        className="bg-elr-black text-white text-lg w-40 mb-3"
      />
    </Link>
    <div className="text-xs text-elr-purple text-opacity-50">
      Give us feedback
    </div>
  </div>
);
