import React from 'react';
import { ElrButton, ElrModal } from 'ui-components';

interface modalProp {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  markAsCompleted: () => Promise<void>;
}

export const ConfirmationModal: React.FC<modalProp> = ({
  isOpen,
  onClose,
  isLoading,
  markAsCompleted,
}) => {
  const handleMarkAsCompleted = async () => {
    markAsCompleted().then(() => {
      onClose();
    });
  };

  return (
    <ElrModal
      isOpen={isOpen}
      onClose={onClose}
      height="h-198 sm:220 md:h-262"
      width="w-300 sm:w-320 md:w-366"
    >
      <div className="w-full h-full flex justify-center items-center">
        <div className="p-2">
          <p className="text-center pb-10 text-sm px-1">
            Are you sure you want to mark this trip as completed?
          </p>
          <div className="flex justify-between">
            <button type="button" className="text-sm" onClick={onClose}>
              Cancel
            </button>
            <ElrButton
              text="Yes, Mark as completed"
              size="lg"
              spinnerColor="White"
              loading={isLoading}
              onClick={handleMarkAsCompleted}
              className="rounded-full py-4 px-5 text-sm bg-elr-black text-white"
            />
          </div>
        </div>
      </div>
    </ElrModal>
  );
};
