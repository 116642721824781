import React, { useState } from 'react';
import { ElrDropdownIconToggle } from '../ElrDropdownIconToggle';

interface Props {
  active: boolean;
  setActive: (index: number) => void;
  iconClass?: string;
  transformPositionClass?: string;
  header?: string;
  index: number;
  children: React.ReactNode;
}

export const ElrAccordion: React.FC<Props> = ({
  transformPositionClass = '-translate-x-25',
  iconClass = 'opacity-40 text-elr-black',
  active,
  setActive,
  index,
  children,
  header,
}) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div
      className={`border rounded-5 border-05 border-elr-green border-opacity-30 mt-7 mb-7 ${
        !active ? 'h-24' : ''
      }`}
    >
      <div className="flex w-full justify-between pt-9 p-6">
        <p className="text-14">{header}</p>
        <div className={`${iconClass}`} onClick={() => setActive(index)}>
          <ElrDropdownIconToggle
            isToggled={dropdown && active}
            toggle={setDropdown}
          />
        </div>
      </div>
      {dropdown && active && (
        <div className={`${transformPositionClass} p-6 pt-0 opacity-60`}>
          {children}
        </div>
      )}
    </div>
  );
};
