import React, { useEffect, useState } from 'react';
import { logistics } from './data';

const LogisticsCompanies: React.FC = () => {
  const [data, setData] = useState<
    {
      id: number;
      name: string;
      address: string;
      phoneNumber: string;
      website: string;
      description: string;
    }[]
  >([]);

  useEffect(() => {
    setData(logistics);
  }, []);

  return (
    <div className="h-screen p-5">
      {!data.length && (
        <div className="text-center">
          <h2>There is no logistics company on the list yet</h2>
        </div>
      )}
      <table className="table-auto border-2 text-center border-elr-green">
        <thead>
          <tr>
            <th className="border">Company&apos;s name</th>
            <th className="border">Company&apos;s address</th>
            <th className="border">Phone number</th>
            <th className="border">Website</th>
            <th className="border">Description</th>
          </tr>
        </thead>
        {data.map((company) => (
          <tbody key={company.id}>
            <tr>
              <td key={company.id} className="border">
                {company.name}
              </td>
              <td className="border">{company.address}</td>
              <td className="border">{company.phoneNumber}</td>
              <td className="border">{company.website}</td>
              <td className="border">{company.description}</td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default LogisticsCompanies;
